import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, Tab, Tabs, CircularProgress, FormControl, MenuItem, Select, InputLabel, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DATE_FORMATS, ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";
import { Add, Done, Inventory, Save, SaveAlt, Send } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/WarehouseModal";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import PackagingListModal from "../../components/modals/packagingListModal";
import { useParams } from "react-router-dom";
import moment from "moment";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import PreviewIcon from '@mui/icons-material/Preview';
import PlannedSoModal from "../../components/modals/plannedSoModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';



const isMobile = window.innerWidth < 900;

const SHIPPING_ORDERS = [

]
const AIR_SHIPPING_ORDERS = [
    
]
const openPackaging = (val) => {
    window.open(val, '_blank');
  };





const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 205px);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const TabsContainer = styled.div`
//   ${isMobile &&
//   `
//     height: calc(100vh - 56px - 20px);
//   `}
// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Delivered(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [shippingOrders, setShippingOrders] = useState(SHIPPING_ORDERS);
  const [showPackagingListModal, setShowPackagingListModal] = useState();
  const [showItemsModal, setShowItemsModal] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
const [statusInventory, setStatusInventory] = useState(10)
const [containerDetails,setContainerDetails] = useState();




const onSentButton = (val) => {

}

useEffect(()=>{
    // console.log("id",id)
 fetchData()
}, [statusInventory,selectedTab])

const fetchData = async () => {
  try {
    setLoading(true)
    let results = await window.Platform.database.fetchDelivered(id);
    // console.log("RESULTS",results)
    let tabvalue;

    if (selectedTab === 1) {
      tabvalue = results.filter(order => order.quotation.attributes.typeOfShipment === "Sea");
    } else if (selectedTab === 2) {
      tabvalue = results.filter(order => order.quotation.attributes.typeOfShipment === "Air");
    } else {
      tabvalue = results;
    }

    // console.log("TABVALUE", tabvalue);
    setShippingOrders(tabvalue);
    setLoading(false)
  } catch (err) {
    console.log(err);
    setLoading(false)
  }
};

  const getInventoryColumns = () => {
    let result
   
     result = [
      {
        field: "id",
        headerName: "SO Id",
        width: 290,
        sortable: false,
        dataGetter: params => params.row.id|| 'N/A',
        renderCell: params => {
          const value = params.row.id || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "containerId",
        headerName: "Container Id",
        width: 200,
        sortable: false,
        // valueGetter:params=>
        valueGetter: params => params.row?.container?.id ,
        // dataGetter: params => params.row?.container?.id || 'N/A',
        renderCell: params => {
          // console.log(params.row?.container?.id)
          const value = params.row?.container?.id || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }},
        {
            field: "containerName",
            headerName: "Container Name",
            width: 200,
            sortable: false,
            valueGetter: params => params.row?.container?.attributes?.containerName|| 'N/A',
            renderCell: params => {
              // console.log(params.row?.container?.id)
              const value = params.row?.container?.attributes?.containerName|| 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }},
      
      {
        field: "deliveryDate",
        headerName: "Expected Delivery Date(Customer)",
        width: 290,
        sortable: false,
        valueGetter: params => moment.unix(params.row.quotation.attributes.deliveryDate).format(DATE_FORMATS.DEFAULT) ,
        renderCell: params => {
          const value = moment.unix(params.row.quotation.attributes.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "deliveredTo",
        headerName: "Delivered To",
        width: 290,
        sortable: false,
        valueGetter: params => params.row.currentLocation? params.row.currentLocation ==="Delivered"?"Customer":params.row?.nextWarehouse?.attributes.name+`${"("+params.row.nextWarehouse.id+")"}` : 'N/A',
        renderCell: params => {
          const value =  params.row.currentLocation? params.row.currentLocation ==="Delivered"?"Customer":params.row?.nextWarehouse?.attributes.name+`${"("+params.row.nextWarehouse.id+")"}` : 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
    //   {
    //     field: "totalCbm",
    //     headerName: "Total CBM",
    //     width: 200,
    //     sortable: false,
    //     dataGetter: params => params.row.totalCbm || 'N/A',
    //     renderCell: params => {
    //       const value = params.row.totalCbm || 'N/A'
    //       return <Tooltip title={value}>{value}</Tooltip>
    //     }
    //   },
    //   {
    //     field: "totalWeight",
    //     headerName: "Total Weight",
    //     width: 200,
    //     sortable: false,
    //     dataGetter: params => params.row.totalWeight || 'N/A',
    //     renderCell: params => {
    //       const value = params.row.totalWeight || 'N/A'
    //       return <Tooltip title={value}>{value}</Tooltip>
    //     }
    //   },
    //   {
    //     field: "totalVolume",
    //     headerName: "totalVolume",
    //     width: 200,
    //     sortable: false,
    //     dataGetter: params => params.row.totalVolume || 'N/A',
    //     renderCell: params => {
    //       const value = params.row.totalVolume || 'N/A'
    //       return <Tooltip title={value}>{value}</Tooltip>
    //     }
    //   },
    //   {
    //     field: "totalAmount",
    //     headerName: "Total Amount",
    //     width: 200,
    //     sortable: false,
    //     dataGetter: params => params.row.totalAmount || 'N/A',
    //     renderCell: params => {
    //       const value = params.row.totalAmount || 'N/A'
    //       return <Tooltip title={value}>{value}</Tooltip>
    //     }
    //   },
      {
        field: "typeOfShipment",
        headerName: "Shipment Type",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.quotation.attributes.typeOfShipment || 'N/A',
        renderCell: params => {
          const value = params.row.quotation.attributes.typeOfShipment || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "items",
        headerName: "Items",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row.order || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return<Tooltip title="Click to view item details"><IconButton variant="extended" color="primary" onClick={() => setShowItemsModal(value)} >
          <ViewComfyAltIcon/>
        </IconButton></Tooltip>
        }
      },
      {
        field: "containerDetails",
        headerName: "Container Details",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params?.row?.container?.attributes || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return<Tooltip title="Click to view container details"><IconButton variant="extended" color="primary" onClick={() => setContainerDetails(value)} >
            <PreviewIcon/>
          </IconButton></Tooltip> 
        }
      },
      {
        field: "Packaginglist",
        headerName: "Packaging list",
        width: 200,
        headerAlign: "center", // Align the header text to the center
        align: "center",
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row.quotation.attributes.packagingList || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title="Click to view packging list"><IconButton variant="extended" color="primary" disabled={!params.row.quotation.attributes.packagingList} onClick={(e) => {
            e.stopPropagation();
          openPackaging(value);}} >
            <VisibilityIcon/>
          </IconButton></Tooltip>
        }
      }

    ];
   

    return result
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "Delivered",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleTabChange = async (value) => {
    setShippingOrders([]);
    setSelectedTab(value);
    // if(value === TABS.SEA){
      setShippingOrders(SHIPPING_ORDERS)
    // }
    // else if(value === TABS.AIR)
    // {
    //   setShippingOrders(AIR_SHIPPING_ORDERS)
    // }
  }


  const renderTabContent = () => {
    
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={shippingOrders}
          columns={getInventoryColumns()}
          rowHeight={70}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const renderModals = () => {
    return <>
      {showItemsModal && <InventoryItemsModal
        onClose={() => setShowItemsModal(null)}
        items={showItemsModal}
      />}
      {showPackagingListModal && <PackagingListModal
        onClose={() => setShowPackagingListModal(null)}
        list={showPackagingListModal}
      />}
      {
        containerDetails && <PlannedSoModal mode={null} temp={containerDetails}  onClose={()=>setContainerDetails(null)}/>
      }
      
    </>
  }

  return (
    <>
      {isMobile && (
        <Drawer props={props}/>
      )}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Delivered</Typography>
          {/* <FormControl>
  <InputLabel id="demo-simple-select-label">Inventory Status</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    sx={{width: 150}}
    id="demo-simple-select"
    defaultValue={10}
    value={statusInventory || ''}
    label="Inventory Status"
    onChange={(e,value)=> setStatusInventory(e.target.value)}
  >
    <MenuItem value={10}>Pending SOs</MenuItem>
    <MenuItem value={20}>Recieved SOs</MenuItem>
  </Select>
</FormControl> */}
{loading && <OpaqueLoading />}
        </HeaderContainer>

        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="All Items" />
              <Tab label="Sea Inventories" />
              <Tab label="Air Inventories" />
            </StyledTabs>
            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
        {renderModals()}
      </StaffEditorPageContainer>

    </>
  );
}
