import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import Typography from "@mui/material/Typography";

import styled from "styled-components";

import { Button, IconButton, Stack, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import { Add, Delete, HelpOutline, Info } from "@mui/icons-material";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";




const StyledDialogContent = styled(DialogContent)`

  padding: 16px;

`;



const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const isMobile = window.innerWidth < 900;




const StyledDialog = styled(Dialog)`

  //   position: relative;

  //   max-width: 700px;

`;




const ModalHeaderContainer = styled.div`

  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;

`;

const StyledDivField = styled.div`

  overflow-x: hidden; /* Hides horizontal scrollbar */

  overflow-y: auto; /* Shows vertical scrollbar when content overflows */

  max-height: 250px; /* Set a maximum height for the div, adjust as needed */
  // min-height: 250px;

  `;


const StyledDiv = styled.div`

  white-space: pre-wrap; /* Enables word wrapping */

  overflow-wrap: break-word; /* Word wrapping for non-space characters */

  max-width: 100%; /* Limits the width of the div */

  overflow-x: hidden; /* Hides horizontal scrollbar */

  overflow-y: auto; /* Shows vertical scrollbar when content overflows */

  max-height: 180px; /* Set a maximum height for the div, adjust as needed */

  min-height: 180px;

  `;

  const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;


const TemplateModal = ({ template, open, onClose, activateTemplate, saveTemplate }) => {

  // State to hold the value of the TextField

  const [textFieldValue, setTextFieldValue] = useState(template.templates[0]);

  const [tabs, setTabs] = useState(template.templates);
  const [selectedTab, setSelectedTab] = useState(0);

  const [active, setActive] = useState(template.activated)
const [onChange, setOnChange] = useState(null)
const [loading, setLoading] = useState(false);

  const onClickSave = () => {

    console.log('onClickSave', textFieldValue , template.channel, selectedTab)

    saveTemplate(textFieldValue, template.channel, selectedTab)
    setOnChange(null)

  }
  // Function to handle TextField value change

  const handleTextChange = (event) => {

    setTextFieldValue(event);

  };

  const handleTextFieldChanges = (event) => {
    setOnChange(true)
    const sanitizedValue = event.target.value.replace(/\n/g, '↵');

    setTextFieldValue(sanitizedValue);
    template.templates[selectedTab] = sanitizedValue;
  };

  const handleTabChange = (event) => {
    console.log(event)
  setSelectedTab(event-1)
  handleTextChange(template.templates[event-1])
  }

  const getHtmlContent = () => {
    // Replace the visible character (e.g., space) with an actual line break
    const htmlContent = textFieldValue
    .replace(/↵/g, '<br>') // Handle line breaks
    .replace(/¤(.*?)¤/g, '<b>$1</b>') // Handle <b></b> tags
    .replace(/\\n/g, '<br>') // Handle \n for new lines
    .replace(/\*(.*?)\*/g, '<b>$1</b>') // Handle * * for bold

    .replace(/\_(.*?)\_/g, '<i>$1</i>') // Handle * * for bold
    // .replace(/\_(.*?)\_/g, '<u>$1</u>') // Handle * * for bold

    // .replace(/_ _/g, '<i>$1</i>'); // Handle _ _ for italic
    return { __html: htmlContent };
  };

  // useEffect(()=>{

  //   setTextFieldValue(template.mess);

  // },[])

 
  




  return (

    <StyledDialog

      disableEnforceFocus

      maxWidth="xs"

      fullWidth={true}

      open={open}

      PaperProps={{

        style: isMobile

          ? {}

          : {

              minWidth: 550,

              maxWidth: 550,

              minHeight: 500,

              maxHeight: 550,

            },

      }}

    >

      <StyledDialogContent>
{loading && <OpaqueLoading/>}
        <ModalHeaderContainer>
        {/* <Tooltip title="Wrap Text in * to make it bold,
Wrap text in _ to make it italic">
              <IconButton
                // onClick={() =>
                //   window.NotificationUtils.showInfo(
                //     "Wrap Text in  * to make it bold, Wrap text in _ to make it italic"
                //   )
                // }
                sx={{

                  '&:hover': {

                    backgroundColor: 'transparent',

                  },

                }}

              ><HelpOutline sx={{ fontSize: 22 }} /></IconButton>
            </Tooltip> */}
            { (template.channel == 'Whatsapp Customer' || template.channel == 'Whatsapp Supplier') &&
            <NoteText variant="body2">
            <Info/> Wrap text in * for bold, _ for italic, and \n for newline
            </NoteText>
}
{ (template.channel == 'wechat' ) &&
            <NoteText variant="body2">
            <Info/> \n for newline
            </NoteText>
}
          <Typography variant="h5">{template.name}</Typography>

          <Button variant="contained"
         disabled= {selectedTab === active ? true: false}
         onClick={()=> {activateTemplate(template, selectedTab)
        setActive(selectedTab)}
        }
          >

                {selectedTab === active ? 'Activated': 'Activate'}

                </Button>

          {/* <IconButton variant="contained" onClick={()=> setTabs([...tabs,{label:'Template'}])}><Delete /></IconButton> */}

          {/* <Button variant="contained" onClick={()=> setTabs([...tabs,{label:'Template'}])}>
            Add New Template
          </Button> */}
         
        </ModalHeaderContainer>
        <div style={{ width: "100%" }}>
          <StyledTabs
            value={selectedTab}
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {tabs.map((tab, index) => 
              {
              // console.log(++index)
              return <Tab key={index} label={'Template' +' '+ ++index}
            onClick={()=> handleTabChange(index)}
              
              />
              }
            )}
          </StyledTabs>
        </div>
        <Stack direction={"column"} spacing={4}>

          {/* The div tag component that updates according to the TextField value */}
          {/* <StyledDivField> */}
          <TextField
          required

            value={textFieldValue}
            
            onChange={handleTextFieldChanges}
      multiline
      rows={5}
          />
{/* </StyledDivField> */}
      <StyledDiv dangerouslySetInnerHTML={getHtmlContent()} />

          <Stack

              direction="row"

              justifyContent="flex-end"

              gap="10px"

              align-items="center"

            >    <Button onClick={onClose}>

                Close

                </Button>
{onChange &&
<>
                <Button onClick={() =>    { setTextFieldValue(template.templates[selectedTab])
                  setOnChange(null)
                }}>

Undo

</Button>

            <Button onClick={onClickSave}>

                Save

                </Button>
</>

}

                </Stack>




          {/* The TextField component */}

        </Stack>

      </StyledDialogContent>

    </StyledDialog>

  );

};




export default TemplateModal;