import React, { useEffect, useState } from "react";
import {
    Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { useLocation } from "react-router-dom";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const isMobile = window.innerWidth < 900;


const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const CostingModal = ({ onCancel, items , onSubmit }) => {
    // console.log("ssdasd",items)
    const [form, setForm] = useState({
    //  invoiceNo: items.invoiceNumber || '',
    //  pklStatus: items.pklStatus || false
    ...items.costing
    });

    const [disableCheck, setDisableCheck] = useState(false)
    let location = useLocation();

    useEffect(()=>{
        if(location.pathname !== '/costingandbilling'){
            setDisableCheck(true)
        }
    },[])
const [loading,setLoading] = useState(false)
     // useEffect(()=>{
    //   console.log("Customer Name",customerData)
    // },[customerData])
    // console.log("customerdata", initialData)
  
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    };
  
    const submitHandler = () => {
        // setLoading(true);
    //     if(!form.chaAmount?.length || !form.chaName?.length ||
    //         !form.dutyPaidByAmount?.length || !form.dutyPaidByName?.length ||
    //         !form.chaReceiptedAmount?.length || !form.chaReceiptedName?.length ||
    //         !form.chaKkAmount?.length || !form.chaKkName?.length ||
    //         !form.freightAmountExRate?.length || !form.freightAmount?.length ||
    //         !form.chennaiToMumAmount?.length || !form.chennaiToMumName?.length ||
    //         !form.chennaiToDlAmount?.length || !form.chennaiToDlName?.length ||
    //         !form.dlToMumAmount?.length || !form.dlToMumName?.length ||
    //         !form.portNsToDlAmount?.length || !form.portNsToDlName?.length ||
    //         !form.portNsToMumAmount?.length || !form.portNsToMumName?.length ||
    //         !form.generalAmount?.length || !form.generalName?.length 
    //         // !form.detentionAmount?.length || !form.detentionName?.length 

    //         )
    //     window.NotificationUtils.showError("Please enter the required details")
    // else{

            // console.log(form)
        onSubmit({...form, id : items.id})
        // setLoading(false)
        // onCancel()
    // }
    //   console.log({...form, supplierName: initialData.SupplierName});
    // if(form.remark && form.typeOfShipment)
    //   onSubmit({...form})
    //   else
    //   window.NotificationUtils.showError("Please fill all the fields")
    };
    const options = [ { value: 'Air', label: 'Air' },
    { value: 'Sea', label: 'Sea' },
  ]
  
    return (
      <StyledDialog 
      disableEnforceFocus
      // maxWidth="xs"
      fullWidth={true}
      open={true}
      PaperProps={{
        style: isMobile
          ? {}
          : {
              minWidth: 750,

              maxWidth: 850,

              minHeight: 500,

              maxHeight: 550,
            },
      }}
      >
         <ModalHeaderContainer style={{padding:'10px'}}>
          <Typography variant="h5"> {!disableCheck? 'Update Costing Details': 'Costing Details'}</Typography>

          <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Clearance Cost</Typography>

          {/* <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton> */}
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>CHA:</Typography>
       
    
      <TextField
      // required
        label="Name"
          disabled={disableCheck}
        value={form?.chaName}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("chaName", e.target.value)
          }
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"
        name="awbBlNumber"
        value={form?.chaAmount}
        // value={formData.awbBlNumber}
        disabled={disableCheck}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("chaAmount", e.target.value)
          }
        fullWidth
        // margin="normal"
      />
      </Stack>
               

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px', width: '300px'}}>Duty Paid By:</Typography>
       
    
      <TextField
      // required
        label="Name"
        name="shipperName"
        value={form?.dutyPaidByName}
        onChange={(e) =>
            changeHandler("dutyPaidByName", e.target.value)
          }
          disabled={disableCheck}
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      value={form?.dutyPaidByAmount}
      // required
        label="Amount"
        type="number"

        name="awbBlNumber"
        disabled={disableCheck}
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("dutyPaidByAmount", e.target.value)
          }
        fullWidth
        // margin="normal"
      />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>CHA Receipted:</Typography>
       
    
      <TextField
      // required
        label="Name"
        disabled={disableCheck}
        value={form?.chaReceiptedName}
        name="shipperName"
        // value={formData.shipperName}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("chaReceiptedName", e.target.value)
          }
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.chaReceiptedAmount}
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("chaReceiptedAmount", e.target.value)
          }
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>CHA KK:</Typography>
       
    
      <TextField
      // required
        label="Name"
        disabled={disableCheck}
        value={form?.chaKkName}
        onChange={(e) =>
            changeHandler("chaKkName", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        disabled={disableCheck}
       value={form?.chaKkAmount}
        onChange={(e) =>
            changeHandler("chaKkAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Freight Amount INR:</Typography>
       
    
      <TextField
      // required
        label="Ex Rate"
        name="shipperName"
        type="number"

        disabled={disableCheck}
        value={form?.freightAmountExRate}
        // value={formData.shipperName}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("freightAmountExRate", e.target.value)
          }
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

          value={form?.freightAmount}
          disabled={disableCheck}
        onChange={(e) =>
            changeHandler("freightAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Divider style={{width:'100%'}}/>

 <ModalHeaderContainer>
          <Typography variant="h5">Transport Cost</Typography>

          {/* <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton> */}
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Chennai to Mum:</Typography>
       
    
      <TextField
      // required
        label="Name"
        name="shipperName"
        disabled={disableCheck}
        // value={formData.shipperName}
        value={form?.chennaiToMumName}
        onChange={(e) =>
            changeHandler("chennaiToMumName", e.target.value)
          }
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.chennaiToMumAmount}
        onChange={(e) =>
            changeHandler("chennaiToMumAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
               

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px', width: '300px'}}>Chennai to DL:</Typography>
       
    
      <TextField
      // required
        label="Name"
        disabled={disableCheck}
        value={form?.chennaiToDlName}
        // value={formData.shipperName}
        onChange={(e) =>
            changeHandler("chennaiToDlName", e.target.value)
          }
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        value={form?.chennaiToDlAmount}
        disabled={disableCheck}
        onChange={(e) =>
            changeHandler("chennaiToDlAmount", e.target.value)
          }

        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>DL to MUM:</Typography>
       
    
      <TextField
      // required
        label="Name"
        disabled={disableCheck}
        value={form?.dlToMumName}
        // value={formData.shipperName}
        onChange={(e) =>
            changeHandler("dlToMumName", e.target.value)
          }
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.dlToMumAmount}
        onChange={(e) =>
            changeHandler("dlToMumAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>PORT NS TO DL:</Typography>
       
    
      <TextField
      // required
        label="Name"
        disabled={disableCheck}
        value={form?.portNsToDlName}
        onChange={(e) =>
            changeHandler("portNsToDlName", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.portNsToDlAmount}
        onChange={(e) =>
            changeHandler("portNsToDlAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>PORT NS TO MUM:</Typography>
       
    
      <TextField
      // required
        label="Name"
        disabled={disableCheck}
        value={form?.portNsToMumName}
        onChange={(e) =>
            changeHandler("portNsToMumName", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.portNsToMumAmount}
        onChange={(e) =>
            changeHandler("portNsToMumAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>General:</Typography>
       
    
      <TextField
      // required
        label="Name"
        disabled={disableCheck}
        value={form?.generalName}
        // value={formData.shipperName}
        onChange={(e) =>
            changeHandler("generalName", e.target.value)
          }
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.generalAmount}
        onChange={(e) =>
            changeHandler("generalAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Divider style={{width:'100%'}}/>
</Stack>

<ModalHeaderContainer>
          <Typography variant="h5">Extra(if any) Cost</Typography>

          {/* <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton> */}
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Detention:</Typography>
       
    
      <TextField
      // required
        label="Name"
        disabled={disableCheck}
        value={form?.detentionName}
        onChange={(e) =>
            changeHandler("detentionName", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      // required
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.detentionAmount}
        onChange={(e) =>
            changeHandler("detentionAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
               

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px', width: '300px'}}>Remark:</Typography>
       
    
      <TextField

        label="Name"
        disabled={disableCheck}
        value={form?.extraRemark1Name}
        onChange={(e) =>
            changeHandler("extraRemark1Name", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.extraRemark1Amount}
        onChange={(e) =>
            changeHandler("extraRemark1Amount", e.target.value)
          }

        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Remark:</Typography>
       
    
      <TextField
        label="Name"
        value={form?.extraRemark2Name}
        disabled={disableCheck}
        onChange={(e) =>
            changeHandler("extraRemark2Name", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
        label="Amount"
        type="number"

        value={form?.extraRemark2Amount}
        onChange={(e) =>
            changeHandler("extraRemark2Amount", e.target.value)
          }
          disabled={disableCheck}
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Remark:</Typography>
       
    
      <TextField
      value={form?.extraRemark3Name}
        label="Name"
        disabled={disableCheck}
        onChange={(e) =>
            changeHandler("extraRemark3Name", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.extraRemark3Amount}
        onChange={(e) =>
            changeHandler("extraRemark3Amount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Remark:</Typography>
       
    
      <TextField
      
        label="Ex Rate"
        disabled={disableCheck}
        value={form?.extraRemark4Name}
        onChange={(e) =>
            changeHandler("extraRemark4Name", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      
        label="Amount"
        type="number"

        disabled={disableCheck}
        value={form?.extraRemark4Amount}
        onChange={(e) =>
            changeHandler("extraRemark4Amount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Divider style={{width:'100%'}}/>
</Stack>
      

          </Stack>
          </StyledDialogContent>
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap="10px"
              align-items="center"
              style={{padding:'10px'}}
            >
              <Button color="error" onClick={onCancel}>
                Close
              </Button>
              <Button
                color="primary"
                disabled={disableCheck}
                variant="contained"
                onClick={submitHandler}
              >
                Submit
              </Button>
            </Stack>
    </StyledDialog>
    );
  };
  
  export default CostingModal;
  