import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  TextField
} from "@mui/material"; // Update import to "@mui/material"
import styled from "styled-components";
// import OpaqueLoading from "../opaqueLoading/OpaqueLoading"; // Update component name to "OpaqueLoading" with capital letters
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"; // Update import to "@mui/icons-material"
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
  position:relative;
  z-index: 10000;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  position:relative !important;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const CloseIconButton = styled(IconButton)`
  position: relative;
  z-index: 10000;
`;

const AddStateModal = (props) => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [hasChange,setHasChange] = useState(false);

  const [stateName,setStateName] = useState(props.selectedState.stateName ? props.selectedState.stateName:"");

  const isNewState = props.selectedState.id ? false:true;

  useEffect(() => {
    let change = false;
    if (isNewState && stateName) {
      change = true;
    } else if (!isNewState && stateName !== props.selectedState.stateName) {
      change = true;
    }
    if (change !== hasChange) {
      setHasChange(change);
    }
  }, [stateName]);

  const checkErrors = async() => {
    if(!stateName){
        window.NotificationUtils.showError('Please input a valid state name');
        return true;
    }
    let stateNameAvailability = await window.Platform.database.checkStateNameAvailability(stateName);
    if(!stateNameAvailability){
      window.NotificationUtils.showError('State name is already in use');
      return true;
    }
    return false;
  }

  const onClickSave = async() => {
      try {
          setLoading(true);
          let hasErrors = await checkErrors();
          if(hasErrors){
            setLoading(false);
            return;
          }
          let response = await window.Platform.database.updateState({...props.selectedState, stateName});
          window.NotificationUtils.showSuccess("State updated successfully");
          props.closeModal(response);
          setLoading(false);
      } catch (error) {
          console.error(error);
          window.NotificationUtils.showError("Error trying to save state");
          setLoading(false);
      }
  }
  
  const renderModalContent = () => {
    return (
      <>
      <InputFieldContainer>
        <TextField
              required
              fullWidth={true}
              label="State Name"
              placeholder="Enter state name"
              variant="outlined"
              value={stateName}
              disabled={loading}
              onChange={(e) => setStateName(e.target.value.toString())}
            />
        </InputFieldContainer>
        <ModalActionsContainer>
          <FirstButton
            disabled={loading}
            onClick={() => props.closeModal()}
            variant="contained"
            color="primary"
          >
            Cancel
          </FirstButton>
          <Button
            disabled={loading || !hasChange}
            onClick={onClickSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog maxWidth="xs" fullWidth={true} open={showModal}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">{props.selectedState.id ? props.selectedState.stateName || "":"New State"}</Typography>
          <CloseIconButton disabled={loading} onClick={() => props.closeModal()}>
            <CloseOutlinedIcon />
          </CloseIconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default AddStateModal;
