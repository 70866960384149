import { Avatar, Button, CircularProgress, Divider, Fab, Tab, Tabs, Tooltip, Typography, IconButton } from '@mui/material';

import React, { useState, useEffect, useContext } from 'react'

import styled from "styled-components";

import MemberDetails from "./memberDetails";

import Box from '@mui/material/Box';

import {

  DataGrid,

  GridToolbarContainer,

  GridToolbarColumnsButton,

  GridToolbarFilterButton,

  GridToolbarDensitySelector,

  GridToolbarExport,

  GridDensitySelector,

} from "@mui/x-data-grid";



import SucceedCheck from "../../components/common/LotteStyles/succeedCheck.json"

import Drawer from "../../components/common/drawer/drawer";

import { TABS, ROLES, DATE_FORMATS } from '../../constants';

// import ReplaceManagerModal from "../../components/modals/replaceManagerModal";

// import BulkUploadRoleSelector from "../../components/modals/bulkUploadRoleSelector";

// import BulkUserDelete from '../../components/modals/bulkUserDelete';

// import DeletionReasonModal from '../../components/modals/deletionReasonModal';

import moment from 'moment';

import { AuthContext } from '../../components/contextAPI/ContextAPI';

import { Add, AddAPhoto, AddTask, SaveAlt, SaveAltOutlined, SaveAs, ManageSearch } from '@mui/icons-material';

// import { LottiePlayer } from 'lottie-react';

import Lottie from 'lottie-react';

import DeletionReasonModal from '../../components/modals/deletionReasonModal';

import { useNavigate } from 'react-router-dom';





const isMobile = window.innerWidth < 900;



const StaffEditorPageContainer = styled.div`

    height: ${isMobile ? `auto` : `calc(100vh - 90px)`};

    width:100%;

    background-color:white;

    padding:10px 15px;

    ${isMobile &&

  `

        position: relative;

   

        `}

`



const HeaderContainer = styled.div`

    display:flex;

    align-items:center;

    justify-content:space-between;

    margin-bottom:10px;

`



const TableContainer = styled.div`

height: calc(100% - 43px);

  width: 100%;

  border: solid 1px lightGrey;

  border-radius: 8px;

  display: ${isMobile ? 'flex' : 'grid'};

  grid-template-columns: minmax(0, 1fr) auto 1fr; // Set minimum width for the left column

  ${isMobile &&

  `

    flex-direction: column;

  `}

`



const DetailsPreviewContainer = styled.div`

    height:100%;

    width:100%;

    display:flex;

    justify-content:center;

    align-items:center;

    ${isMobile && `

    height: calc(100vh - 56px - 20px);

  `}

`



const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;



const AvatarContainer = styled.div`

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

`;



const DataGridContainer = styled.div`

  height: calc(100% - 49px);

  // width:80%;

 

`



const LoadingContainer = styled.div`

height: calc(100% - 49px);

display:flex;

justify-content:center;

align-items:center;

`



const TabsContainer = styled.div`

  ${isMobile && `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}

`



const BulkUploadButton = styled(Button)`

  margin-right:10px !important;

`

const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;





const UserManagement = (props) => {



  const navigation = useNavigate()

  const [staffMembers, setStaffMembers] = useState([]);

  const [visibleEmployees, setVisibleEmployees] = useState([]);

  const [reasonModalObj, setReasonModalObj] = useState({});

  const [selectedMember, setSelectedMember] = useState(null);

  const [selectedTab, setSelectedTab] = useState(TABS.REGISTERED);

  const [showReplacementPicker, setShowReplacementPicker] = useState(false);

  const [showDeletionReasonPicker, setShowDeletionReasonPicker] = useState(false);

  const [showBulkUploadPicker, setShowBulkUploadPicker] = useState(false);

  const [showBulkUserDeleteModal, setShowBulkUserDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const { auth, setAuth } = useContext(AuthContext)



  let contextState = { ...auth }



  // const [contextState, setContextState] = [...a]

  useEffect(() => {

    // if(contextStat)


    // window?.NotificationUtils.showSuccess("hello this is userManagement")

  }, [contextState])



  var disableScroll = false;



  const isManager = [ROLES.SERVICE_MANAGER

    // , ROLES.SALES_MANAGER

    , ROLES.SALES_MANAGER_ENGAGEMENT].includes(auth?.user.role);



  const getEmployeeName = (employeeCode) => {

    let result = "";

    let member = staffMembers.find(member => member.employeeCode === employeeCode);

    if (member) {

      result = `${member.firstName} ${member.lastName ? member.lastName : ""}`

    }

    else {

      member = visibleEmployees.find(member => member.employeeCode === employeeCode);

      if (member) {

        result = `${member.firstName} ${member.lastName ? member.lastName : ""}`

      }

    }

    return result;

  }



  var employeeColumns = [



    // {

    //   field: " ",

    //   headerName: "Profile pic",

    //   flex: 0.04,

    //   editable: false,

    //   disableColumnMenu:true,

    //   disableExport:true,

    //   disableReorder:true,

    //   hideSortIcons:true,

    //   renderCell: (params) => {

    //     return (

    //       <AvatarContainer>

    //         <Avatar src={params.row.profilePicURL ? params.row.profilePicURL : ""} />

    //       </AvatarContainer>

    //     )

    //   },

    // },

    {

      field: "firstName",

      headerName: "Name",

      minWidth: 200,

      valueGetter: (params) => {

        let name = getEmployeeName(params.row.employeeCode)

        return name;

      },

      flex: 0.22,

      editable: false,

      renderCell: (params) => {

        let name = getEmployeeName(params.row.employeeCode)

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

    },

    {

      field: "email",

      headerName: "Email",

      minWidth: 250,

      flex: 0.35,

      editable: false,

      renderCell: (params) => {

        return (

          <Tooltip title={params.row.email || "undefined"}>

            <Typography variant="inherit">{params.row.email}</Typography>

          </Tooltip>

        )

      },

    },

    {

      field: "mobile",

      headerName: "Mobile",

      minWidth: 250,

      flex: 0.35,

      editable: false,

      renderCell: (params) => {

        return (

          <Tooltip title={params.row.mobile || "undefined"}>

            <Typography variant="inherit">{params.row.mobile}</Typography>

          </Tooltip>

        )

      },

    },

    // {

    //   field: "employeeCode",

    //   headerName: "Employee code",

    //   minWidth:200,

    //   flex: 0.17,

    //   editable:false,

    //   renderCell: (params) => {

    //     return (

    //       <Tooltip title={params.row.employeeCode || "undefined"}>

    //         <Typography variant="inherit">{params.row.employeeCode}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    {

      field: "role",

      headerName: "Role",

      minWidth: 200,

      flex: 0.22,

      editable: false,

      renderCell: (params) => {

        return (

          <Tooltip title={params.row.role || "undefined"} >

            <Typography variant="inherit">{params.row.role}</Typography>

          </Tooltip>

        )

      },

    },

    // {

    //   field: "zonalHead",

    //   headerName:"Zonal head",

    //   editable:false,

    //   hide:true,

    //   minWidth:200,

    //   valueGetter: (params) => {

    //     let name = params.row.zonalHead ? getEmployeeName(params.row.zonalHead):"N.A.";

    //     return name;

    //   },

    //   renderCell:(params) => {

    //     let name = params.row.zonalHead ? getEmployeeName(params.row.zonalHead):"N.A.";

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    // {

    //   field: "areaHead",

    //   headerName:"Area head",

    //   editable:false,

    //   hide:true,

    //   minWidth:200,

    //   valueGetter: (params) => {

    //     let name = params.row.areaHead ? getEmployeeName(params.row.areaHead):"N.A.";

    //     return name;

    //   },

    //   renderCell:(params) => {

    //     let name = params.row.areaHead ? getEmployeeName(params.row.areaHead):"N.A.";

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    // {

    //   field: "cityHead",

    //   headerName:"City head",

    //   editable:false,

    //   hide:true,

    //   minWidth:200,

    //   valueGetter: (params) => {

    //     let name = params.row.cityHead ? getEmployeeName(params.row.cityHead):"N.A.";

    //     return name;

    //   },

    //   renderCell:(params) => {

    //     let name = params.row.cityHead ? getEmployeeName(params.row.cityHead):"N.A.";

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    // {

    //   field: "salesManager",

    //   headerName:"Sales manager",

    //   editable:false,

    //   hide:true,

    //   minWidth:200,

    //   valueGetter: (params) => {

    //     let name = params.row.salesManager ? getEmployeeName(params.row.salesManager):"N.A.";

    //     return name;

    //   },

    //   renderCell:(params) => {

    //     let name = params.row.salesManager ? getEmployeeName(params.row.salesManager):"N.A.";

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    // {

    //   field: "serviceManager",

    //   headerName:"Service manager",

    //   editable:false,

    //   hide:true,

    //   minWidth:200,

    //   valueGetter: (params) => {

    //     let name = params.row.serviceManager ? getEmployeeName(params.row.serviceManager):"N.A.";

    //     return name;

    //   },

    //   renderCell:(params) => {

    //     let name = params.row.serviceManager ? getEmployeeName(params.row.serviceManager):"N.A.";

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    // {

    //   field: "headMerchantEngagement",

    //   headerName:"Head merchant engagement",

    //   editable:false,

    //   hide:true,

    //   minWidth:200,

    //   valueGetter: (params) => {

    //     let name = params.row.headMerchantEngagement ? getEmployeeName(params.row.headMerchantEngagement):"N.A.";

    //     return name;

    //   },

    //   renderCell:(params) => {

    //     let name = params.row.headMerchantEngagement ? getEmployeeName(params.row.headMerchantEngagement):"N.A.";

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    // {

    //   field: "salesManagerEngagement",

    //   headerName:"Sales manager engagement",

    //   editable:false,

    //   hide:true,

    //   minWidth:200,

    //   valueGetter: (params) => {

    //     let name = params.row.salesManagerEngagement ? getEmployeeName(params.row.salesManagerEngagement):"N.A.";

    //     return name;

    //   },

    //   renderCell:(params) => {

    //     let name = params.row.salesManagerEngagement ? getEmployeeName(params.row.salesManagerEngagement):"N.A.";

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    // {

    //   field: "isAgency",

    //   headerName:"Is agency ?",

    //   editable:false,

    //   hide:true,

    //   minWidth:150,

    //   valueGetter: (params) => {

    //     let name = params.row.isAgency ? "Yes":"No";

    //     return name;

    //   },

    //   renderCell:(params) => {

    //     let name = params.row.isAgency ? "Yes":"No";

    //     return (

    //       <Tooltip title={name || "undefined"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // }

  ];

  var deletedColumns = [

    {

      field: "firstName",

      headerName: "Name",

      minWidth: 200,

      valueGetter: (params) => {

        let name = getEmployeeName(params.row.employeeCode)

        return name;

      },

      flex: 0.22,

      editable: false,

      renderCell: (params) => {

        let name = getEmployeeName(params.row.employeeCode)

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

    },

    {

      field: "email",

      headerName: "Email",

      minWidth: 250,

      flex: 0.35,

      editable: false,

      valueGetter: (params) => {

        return params.row.previousEmail || ""

      },

      renderCell: (params) => {

        return (

          <Tooltip title={params.row.previousEmail || "undefined"}>

            <Typography variant="inherit">{params.row.previousEmail}</Typography>

          </Tooltip>

        )

      },

    },



    {

      field: "role",

      headerName: "Role",

      minWidth: 200,

      flex: 0.22,

      editable: false,

      renderCell: (params) => {

        return (

          <Tooltip title={params.row.role || "undefined"}>

            <Typography variant="inherit">{params.row.role}</Typography>

          </Tooltip>

        )

      },

    },



    {

      field: "deletionReason",

      headerName: "De-Activation reason",

      minWidth: 250,

      flex: 0.35,

      editable: false,

      valueGetter: (params) => {

        let name = params.row.deletionReason

        if (name == null || (name.length == 0)) name = "No reason provided"

        return name;

      },

      renderCell: (params) => {

        let name = params.row.deletionReason

        if (name == null || (name.length == 0)) name = "No reason provided"

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

    },

    {

      field: "terminationDate",

      headerName: "Date of leaving",

      minWidth: 250,

      flex: 0.35,

      editable: false,

      valueGetter: (params) => {

        // console.log(moment.unix(params.row.terminationDate).format("MM/DD/YYYY"))

        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY")



        if (name == 'Invalid date')

          name = 'Termination date not provided'

        return name;

      },

      renderCell: (params) => {

        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY")

        if (name == 'Invalid date')

          name = 'Termination date not provided'

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

      renderCell: (params) => {

        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY")

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },

    },

  ];

  useEffect(() => {

    //   await fetchAllEmployeeBasics();

    fetchEmployees();

  }, [])



  const handleTabChange = async (value, keepSelected = false) => {

    setVisibleEmployees([]);
    if (!keepSelected) {
      setSelectedMember(null);
    }

    setSelectedTab(value);

    if (value === TABS.REGISTERED) {

      // await fetchAllEmployeeBasics();

      await fetchEmployees();

      // console.log("hello")

    }

    else if (value === TABS.DELETED) {

      await deletedUsers();

      // console.log("hello")

    }

    else {

      await fetchPendingEmployees();

    }

  }



  const addNewMember = () => {

    if (selectedTab !== TABS.PENDING) {

      handleTabChange(TABS.PENDING);

    }

    setSelectedMember({});

    if (isMobile) {

      scrollToPreview();

    }

  }



  const onClickBulkUpload = () => {

    try {

      if (selectedTab !== TABS.PENDING) {

        handleTabChange(TABS.PENDING);

      }

      setShowBulkUploadPicker(true);

    } catch (error) {

      console.error(error);

    }

  }



  const onClickDeleteEmployee = async (reportingManager = "") => {
    try {
      let data = { ...selectedMember };
      data = {...data, warehouse : data.warehouse?.id}
      let { reason, terminationDate } = reasonModalObj;
      console.log(data)
      await window.Platform.database.deleteEmployee(data, { reason, terminationDate });
      if (selectedTab === TABS.PENDING) { 
        let index = visibleEmployees.findIndex(
          (member) => member.id === data.id
        );
        let newMembers = [...visibleEmployees];
        newMembers.splice(index, 1);
        setVisibleEmployees(newMembers);
        setSelectedMember(null);
      }
      else {
        if (showDeletionReasonPicker) {
          setShowDeletionReasonPicker(false);
        }
        await handleTabChange(TABS.REGISTERED);
      }
      window.NotificationUtils.showSuccess("Successfully deleted employee");
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const setReasonModalData = (name, value) => {

    try {

      setReasonModalObj({ ...reasonModalObj, [name]: value });

    } catch (error) {

      console.error(error);

      throw error;

    }

  }

  const deletedUsers = async () => {

    try {

      setLoading(true);

      let results = await window.Platform.database.deletedUsers();

      // console.log(results)

      // results.forEach((value,i)=>{



      //   value.terminationDate = moment.unix(value.terminationDate).format("MM/DD/YYYY");

      // })



      setVisibleEmployees(results);

      setLoading(false);

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }



  const fetchPendingEmployees = async () => {

    try {

      setLoading(true);

      let results = await window.Platform.database.fetchPendingEmployees();

      setVisibleEmployees(results);

      setLoading(false);

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }



  const fetchAllEmployeeBasics = async () => {

    try {

      if (auth?.user?.role === ROLES.ADMIN) {

        return

      }

      setLoading(true);

      let results = await window.Platform.database.fetchAllEmployeeBasics();

      setStaffMembers(results);

      setLoading(false);

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }



  const fetchEmployees = async () => {

    try {

      setLoading(true);

      let results = await window.Platform.database.fetchEmployees();

      // console.log("results", results);

      if (auth?.user?.role === ROLES.ADMIN) {

        setStaffMembers(results);

      }

      setVisibleEmployees(results);

      setLoading(false);

    } catch (error) {

      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);

    }

  }





  const scrollToPreview = () => {

    window.scrollTo(0, document.getElementById("detailsPreviewContainer").getBoundingClientRect().top);

  }



  const renderLoading = () => {

    return (

      <LoadingContainer>

        <CircularProgress color='primary' />

      </LoadingContainer>

    )

  }



  function CustomToolbar() {

    return (

      <GridToolbarContainer>

        <GridToolbarColumnsButton />

        <GridToolbarFilterButton />

        <GridToolbarDensitySelector />

        <GridToolbarExport

          csvOptions={{

            fileName: "users-report",

          }}

        />

      </GridToolbarContainer>

    );

  }



  const renderEmptyPlaceHolder = () => {

    return (

      <>

        <span>Select an employee to view details</span>

      </>

    )

  }



  const getUserCreationRoles = () => {

    switch (auth?.user?.role) {

      case ROLES.ADMIN:

        return Object.values(ROLES);

      case ROLES.ZONAL_HEAD:

        return [ROLES.CITY_HEAD, ROLES.AREA_HEAD, ROLES.BD_FOS, ROLES.SALES_MANAGER, ROLES.SERVICE_MANAGER, ROLES.RETENTION_FOS];

      case ROLES.HME:

        return [ROLES.SALES_MANAGER_ENGAGEMENT, ROLES.FOS_SPECIAL];

      case ROLES.AREA_HEAD:

        return [ROLES.CITY_HEAD, ROLES.BD_FOS, ROLES.SALES_MANAGER, ROLES.SERVICE_MANAGER, ROLES.RETENTION_FOS];

      case ROLES.CITY_HEAD:

        return [ROLES.BD_FOS, ROLES.SALES_MANAGER, ROLES.SERVICE_MANAGER, ROLES.RETENTION_FOS];

      default:

        return Object.values(ROLES);

    }

  }





  const renderTabContent = () => {

    if (loading) {

      return renderLoading();

    }



    if (selectedTab === TABS.DELETED) {

      return (

        <DataGridContainer>

          <DataGrid

            className="payrollGrid"

            checkboxSelection={true}

            rows={visibleEmployees}

            columns={deletedColumns}

            disableSelectionOnClick

            // disableSelectionOnClick

            disableRowSelectionOnClick

            onRowClick={(rowParams, event) => {

              if (event.target.tagName !== "INPUT") {

                setSelectedMember(rowParams.row);

                if (isMobile) {

                  scrollToPreview();

                }

              }

            }}

            autoPageSize

            components={{

              Toolbar: CustomToolbar,

            }}

          />

        </DataGridContainer>

      );

    }

    else

      return (

        <DataGridContainer>

          <DataGrid

            className="payrollGrid"

            checkboxSelection={true}

            rows={visibleEmployees}

            columns={employeeColumns}

            // disableSelectionOnClick

            disableSelectionOnClick

            disableRowSelectionOnClick

            onRowClick={(rowParams, event) => {

              if (event.target.tagName !== "INPUT") {

                setSelectedMember(rowParams.row);

                if (isMobile) {

                  scrollToPreview();

                }

              }

            }}

            autoPageSize

            components={{

              Toolbar: CustomToolbar,

            }}

          />

        </DataGridContainer>

      );

  }



  const deleteConfirmationModal = () => {

    setShowBulkUserDeleteModal(true);

  }

  return (

    <>

      {isMobile && (

        <Drawer

          setShowNotifications={auth?.setShowNotifications}

          onAuthUserChanged={auth?.onAuthUserChanged} user={auth?.user} 
          props={props}
          />

      )}

      <StaffEditorPageContainer>

        <HeaderContainer>

          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>

            <Typography variant="h5">User Management</Typography>

            <Tooltip title='Access Management'>

              <IconButton color='primary' onClick={() => navigation('/accessmanagement')} ><ManageSearch /></IconButton>

            </Tooltip>

          </div>

          <div>

            {/* {!isMobile && !isManager && ![TABS.DELETED, TABS.PENDING].includes(selectedTab)  && (

              <BulkUploadButton

                onClick={deleteConfirmationModal}

                color="primary"

                variant="contained"

                disabled = {loading}

              >

                Bulk FOS Deactivate

              </BulkUploadButton>

            )} */}

            {/* {!isMobile && !isManager && (

              <BulkUploadButton

                onClick={onClickBulkUpload}

                color="primary"

                variant="contained"

                disabled = {loading}

              >

                Bulk Upload

              </BulkUploadButton>

            )} */}

            {!isManager && (

              // <Button

              //   onClick={addNewMember}

              //   color="primary"

              //   variant="contained"

              //   disabled = {loading}

              // >

              //   Add New

              <FabStyle variant="extended" color="primary"

                onClick={addNewMember}

              >

                <Add sx={{ mr: 1 }} />

                Add New

              </FabStyle>



              // </Button>



            )}

          </div>

        </HeaderContainer>

        <TableContainer>

          <TabsContainer>

            <StyledTabs

              variant="fullWidth"

              value={selectedTab}

              onChange={(event, value) => handleTabChange(value)}

              indicatorColor="primary"

              textColor="primary"

            >

              <Tab label="Active" />

              {!isManager && <Tab label="Pending" />}

              {!isManager && <Tab label="Inactive" />}

            </StyledTabs>

            {renderTabContent()}

          </TabsContainer>

          <Divider orientation={isMobile ? "horizontal" : "vertical"} />

          <DetailsPreviewContainer id="detailsPreviewContainer">

            {!selectedMember && renderEmptyPlaceHolder()}

            {selectedMember && (

              <MemberDetails
                selectedTab={selectedTab}
                isDeleted={Boolean(selectedTab === TABS.DELETED)}
                setSelectedMember={setSelectedMember}
                isInvite={Boolean(selectedTab === TABS.PENDING)}
                staffMembers={staffMembers}
                visibleEmployees={visibleEmployees}
                setStaffMembers={setStaffMembers}
                setVisibleEmployees={setVisibleEmployees}
                data={selectedMember}
                history={auth?.history}
                handleTabChange={handleTabChange}
                onClickDeleteEmployee={onClickDeleteEmployee}
                setShowReplacementPicker={setShowReplacementPicker}
                setShowDeletionReasonPicker={setShowDeletionReasonPicker}
                // getManagers={getManagers}
                getUserCreationRoles={getUserCreationRoles}
                refreshData = {fetchPendingEmployees}
                isManager={isManager}
                fetchEmployees={fetchEmployees}
              />

            )}

          </DetailsPreviewContainer>

        </TableContainer>

      </StaffEditorPageContainer>



      {/* {showReplacementPicker && (

        <ReplaceManagerModal

          selectedMember={selectedMember}

          staffMembers={staffMembers}

          closeModal={() => setShowReplacementPicker(false)}

          onClickDeleteEmployee={onClickDeleteEmployee}

          openDeletionReasonModal = {() => setShowDeletionReasonPicker(true)}

          />

          )} */}

      {showDeletionReasonPicker && (

        <DeletionReasonModal

          selectedMember={selectedMember}

          data={reasonModalObj}

          setData={setReasonModalData}

          staffMembers={staffMembers}

          closeModal={() => setShowDeletionReasonPicker(false)}

          onClickDeleteEmployee={onClickDeleteEmployee}

        />

      )}

      {/*

      {showBulkUploadPicker && (

        <BulkUploadRoleSelector

          closeModal={() => setShowBulkUploadPicker(false)}

          setVisibleEmployees={setVisibleEmployees}

          visibleEmployees={visibleEmployees}

          // getManagers={getManagers}

          getUserCreationRoles={getUserCreationRoles}

          handleTabChange={handleTabChange}

        />

      )}

      {showBulkUserDeleteModal && (

        <BulkUserDelete

          closeModal={() => setShowBulkUserDeleteModal(false)}

          // getManagers={getManagers}

          // getUserCreationRoles={getUserCreationRoles}

          staffMembers={staffMembers}

          getEmployeeName={getEmployeeName}

          setVisibleEmployees={setVisibleEmployees}

          visibleEmployees={visibleEmployees}

        />

      )} */}

    </>

  );

}



export default UserManagement