import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const WarehouseModal = ({ onCancel, loading, onSubmit, template }) => {

  const [form, setForm] = useState({
    warehouseName: "",
    address: "",
    warehouseCity:"",
    contactNumber: "",
    workingHours: "",
    warehouseCountry: "",
  });

  const [cityVal, setCityVal] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const fetchCities = async () => {
    try {
      setLoading(true);

      let response = await window.Platform.database.fetchCities();
      // let val=response.cityName
 
     setCityVal(response.map(city => ({
      value: city.id,
      label: city.cityName
    })));

      setLoading(false);
    } catch (error) {
      console.error(error);

      window.NotificationUtils.showError("Error fetching cities");

      setLoading(false);
    }
  };
  const [isEditMode, setIsEditMode] = useState(!!template);

  useEffect(()=>{
   fetchCities();
  },[])
  useEffect(() => {
    if (template) {
      let city=template?.city?.attributes?.cityName
      setForm({
        id: template.id,
        warehouseName: template.name || "",
        address: template.address || "",
        warehouseCity:city ||"",
        contactNumber: template.contactNumber || "",
        workingHours: template.workingHours || "",
        warehouseCountry: template.warehouseCountry || "",
        
      });
      setSelectedCountry(template.warehouseCountry )
    }
  }, []);

  const [loading1,setLoading] = useState(false);



  const changeHandler = (fieldName, value) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));

  };

  const submitHandler = () => {
    if (
      !form.warehouseName.length ||
      !form.address.length ||
      // !form.personName.length ||
      !form.contactNumber.length ||
      !form.workingHours.length
      ||
      (form.warehouseCountry=== 'India' && !form.warehouseCity.value.length)||!form.warehouseCountry
    )
  {  
      return window.NotificationUtils.showError("Enter all details");
  }
    else{let newForm= {warehouseName: form.warehouseName ,
    address:form.address ,
    warehouseCity:form.warehouseCity.value,
    contactNumber: form.contactNumber ,
    workingHours: form.workingHours ,
    warehouseCountry: form.warehouseCountry

    }
    onSubmit({ ...newForm, isEditMode, id: form.id }) ;
  }};
  const options = [
    { value: "India", label: "India" },
    { value: "China", label: "China" },
  ];


  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="xs"
      fullWidth={true}
      open={true}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">
            {isEditMode ? "Edit Warehouse" : "Add New Warehouse"}
          </Typography>

          <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {/* <Typography variant="h10">Customer Name: {initialData.customerName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
        <Stack gap="20px" style={{ padding: "10px" }}>
          {/* {loading && <OpaqueLoading />} */}
          <Stack>
            <TextField
            required
              variant="outlined"
              label="Warehouse Name"
              value={form.warehouseName}
              onChange={(e) => changeHandler("warehouseName", e.target.value)}
            />
          </Stack>
          <Stack>
            <TextField
            required
              variant="outlined"
              label="Warehouse Address"
              value={form.address}
              onChange={(e) => changeHandler("address", e.target.value)}
            />
          </Stack>
          {/* <Stack>
              <TextField
              required
                variant="outlined"
                label="Person Name"
                value={form.personName}
                onChange={(e) =>
                  changeHandler("personName", e.target.value)
                }
              />
               {/* <Stack>
              <TextField
                variant="outlined"
                label=""
                value={form["name"]}
                onChange={(e) =>
                  changeHandler("name", e.target.value)
                }
              />
            </Stack> */}
          {/* </Stack> */}
          <Stack>
            <TextField
            required
              variant="outlined"
              label="Contact Number"
              value={form.contactNumber}
              type="number"
              onChange={(e) => {
                const input = e.target.value;
                // Custom validation to limit the input to a maximum of 10 digits
                if (input.length <= 15 && /^\d*$/.test(input)) {
                  changeHandler("contactNumber", input);
                }
              }}
            />
          </Stack>
          <Stack>
            <TextField
            required
              variant="outlined"
              label="Working Hours"
              value={form.workingHours}
              type="number"
              onChange={(e) => changeHandler("workingHours", e.target.value)}
            />
          </Stack>
          <Stack>
            <Autocomplete
              // size="small"
              value={form.warehouseCountry}
              options={options}
              // style={{ width: !isMobile?'200px':'90%' }}
              // disabled={supplierChoosed}
              onChange={(e, value) => {
                setSelectedCountry(value?.value);
                changeHandler("warehouseCountry", value?.value);
              }}
              // style={{ width: !isMobile ? "400px" : "90%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Warehouse Country"
                  variant="outlined"
                  required
                  //   style={{ width: !isMobile?'200px':'100%' }}
                  //   inputRef={fields[3]}
                  //   onKeyUp={(e) => {
                  //     if (e.key === 'Enter') {
                  //       // e.preventDefault();
                  //       e.preventDefault();
                  //       console.log(e.target.value)
                  //       changeHandlerForItem("category", e.target.value);
                  //       fields[4].current.focus();
                  //       // setCurrentField(currentField + 1);
                  //     }
                  //   }}
                />
              )}
            />
          </Stack>
         
          {selectedCountry === "India" &&
          <Stack>
          <Autocomplete
              // size="small"
              // value={form.warehouseCity}
              value={form.warehouseCity||""}
              options={cityVal}
              // getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              // style={{ width: !isMobile?'200px':'90%' }}
              // disabled={supplierChoosed}
              onChange={(e, value) => {
                // const selectedCity = cityVal.find((city) => city?.id === value?.id);
               
                changeHandler("warehouseCity", value || null);

              }}
              // style={{ width: !isMobile ? "400px" : "90%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Warehouse City"
                  variant="outlined"
                  required
                 
                />
              )}
            />

          </Stack>}
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="10px"
            align-items="center"
          >
            <Button color="error" onClick={onCancel}>
              Close
            </Button>
            <Button color="primary" variant="contained" onClick={submitHandler}>
              {isEditMode ? "Save" : "Create"}
            </Button>
          </Stack>
        </Stack>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default WarehouseModal;
