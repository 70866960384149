
import react, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, TextField, Autocomplete, IconButton, Grid, InputAdornment, FormControl, InputLabel } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR, PRIMARY_COLOR } from "../../constants";
import { HelpOutline, QuestionMark, Save, SaveAlt } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { useCallback } from "react";
import AddModal from "../../components/modals/CustomerModal";
import AddSupplierModal from "../../components/modals/SupplierModal";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const isMobile = window.innerWidth < 900;
const TextFieldContainer = styled.div`
// margin-bottom: 10px;
padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ResponsiveTextField = styled(TextField)`
  // flex: 1;
width: 250px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
const AutocompleteContainer = styled(Autocomplete)`
  width: 250px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const BodyRight = styled.div`
  display:  ${!isMobile && "flex"};
  align-items: ${!isMobile && "center"};

 margin-bottom:${!isMobile && "10px"};
 justify-content: ${isMobile ? "flex-start" : "space-between"};
  padding-right: ${!isMobile && "10px"};
  text-align: ${!isMobile && "right"};
  `;


const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const SmallDatePicker = styled(DatePicker)({
  "& .MuiInputBase-root": {
    fontSize: "14px", // Adjust the font size as needed
    height: "37px", // Adjust the height as needed

  },
  //  "& .MuiInputLabel-root": {
  //   transform: "translate(14px, 20px) scale(1)", // Adjust the positioning as needed
  // },
});
// const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
    position: relative;
    // top:56px;
    `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
  display:flex;
  align-items:center;
  gap:10px;
`;

const TableContainer = styled.div`
height: calc(
  100vh - ${isMobile ? "56px - 20px - 82.23px" : "64px - 20px - 50px"}
);
width: 100%;
border: solid 1px lightGrey;
border-radius: 8px;
overflow:auto;
`;

const BulkUploadButton = styled(Button)`
  margin-right: 10px !important;
`;

const TabsContainer = styled.div`
  height: 100%;
`;

// const StyledTabs = styled(Tabs)`
//   border-bottom: solid 1px lightGrey;
// `;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 350px;
  margin-bottom:10px;
`;


const LoadingContainer = styled.div`
height: calc(100% - 49px);
display:flex;
justify-content:center;
align-items:center;
`

export default function PackagingListBySea() {

  const [currentField, setCurrentField] = useState(0);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");

  const [imagePreview, setImagePreview] = useState(null);

  const fields = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  // const volumeTextFieldRef = useRef(null);
  const [showCustomerFormModal, setShowCustomerFormModal] = useState();
  const [showSupplierFormModal, setShowSupplierFormModal] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date().toISOString().split('T')[0];
  const [itemVal, setItemVal] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalCbm, setTotalCbm] = useState(0);

  // const [tableVal,]

  const checkingForQtyCtn = (item) => {
    return +itemVal.qty / +itemVal.ctn
  }
  const checkingForAmount = (item, value) => {


    let unit = value;
    let amountdata = 0;
    if (unit === "CTN") {

      amountdata = +item.rate * +item.ctn;
    } else if (unit === "Qty / Ctn") {
      amountdata = +item.rate * (+item.qty / +item.ctn);
    } else if (unit === "QTY") {
      amountdata = +item.rate * +item.qty;
    } else if (unit === "CBM") {
      amountdata = +item.rate * +item.cbm;
    } else if (unit === "WEIGHT (KGS)") {
      amountdata = +item.rate * +item.weight;
    }
    else
      amountdata = -1
    return amountdata
  }
  const changeHandler = (fieldName, value) => {

    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };
  const changeHandlerForItem = (fieldName, value) => {
  
    setItemVal((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };


  // useEffect(()=>{

  // },[itemVal])
  useEffect(() => {
    let totalAm = 0;
    let totalWei = 0;
    let totalcbm = 0
    if (accessChanges.length) {
      accessChanges.forEach((val) => {
        // console.log(val.amount)
        totalAm += +val.amount;
        totalWei += +val.weight;
        totalcbm += +val.cbm;
      });
    }

    setTotalAmount(totalAm);
    setTotalWeight(totalWei)
    setTotalCbm(totalcbm)
  }, [accessChanges]);


  const handleKeyPress = (val) => {
    const value = val.target

  }

  const onclicksubmit = () => {

  }

  const rows = [
    {
      id: 1,
      sno: 1,
      shippingmark: "ABC123",
      chinesedescription: "商品1",
      englishdescription: "Product 1",
      brandlogo: "brand1.png",
      pcsctn: 10,
      ctns: 100,
      quantity: 1000,
      gwctnkgs: 25,
      gwkgs: 250,
      ctnsize: "10x10x10",
      cbm: 1.0,
      photo: "product1.jpg",
    },
    {
      id: 2,
      sno: 2,
      shippingmark: "XYZ456",
      chinesedescription: "商品2",
      englishdescription: "Product 2",
      brandlogo: "brand2.png",
      pcsctn: 20,
      ctns: 50,
      quantity: 100,
      gwctnkgs: 15,
      gwkgs: 75,
      ctnsize: "8x8x8",
      cbm: 0.5,
      photo: "product2.jpg",
    },
    // More rows can be added here...
  ];


  useEffect(() => {
    setAccessChanges(rows);
  }, []);
  useEffect(() => {

  }, [accessChanges]);
  const dropdownOptions = ['Option 1', 'Option 2', 'Option 3'];
  const options = [{ value: 'apple', label: 'Apple' },
  { value: 'banana', label: 'Banana' },
  { value: 'orange', label: 'Orange' },]

  const units = [{ value: 'Qty / Ctn', label: 'Qty / Ctn' },
  { value: 'CTN', label: 'CTN' },
  { value: 'QTY', label: 'QTY' },
  { value: 'WEIGHT (KGS)', label: 'WEIGHT (KGS)' },
  { value: 'CBM', label: 'CBM' },

  ]

  const columns = () => {
    let result = [
      {
        field: "sno",
        headerName: "S.No",
        sortable: false,
        width: 150,
        editable: true,
      },
      {
        field: "shippingmark",
        headerName: "SHIPPING MARK",
        width: 200,
        sortable: false,
      },
      {
        field: "chinesedescription",
        headerName: "CHINESE DESCRIPTION",
        sortable: false,
        width: 200,
      },
      {
        field: "englishdescription",
        headerName: "ENGLISH DESCRIPTION",
        sortable: false,
        width: 200,
      },
      {
        field: "brandlogo",
        headerName: "BRAND LOGO",
        sortable: false,
        width: 200,
      },
      {
        field: "pcsctn",
        headerName: "PCS/CTN",
        sortable: false,
        width: 200,
      },
      {
        field: "ctns",
        headerName: "CTNS",
        sortable: false,
        width: 200,
      },
      {
        field: "quantity",
        headerName: "QUANTITY",
        sortable: false,
        width: 200,
      },
      {
        field: "gwctnkgs",
        headerName: "G.W/CTN(KGS)",
        sortable: false,
        width: 200,
      },
      {
        field: "gwkgs",
        headerName: "G.W(KGS)",
        sortable: false,
        width: 200,
      },
      {
        field: "ctnsize",
        headerName: "CTN SIZE",
        sortable: false,
        width: 200,
      },
      {
        field: "cbm",
        headerName: "CBM",
        sortable: false,
        width: 200,
      },
      {
        field: "photo",
        headerName: "PHOTO",
        sortable: false,
        width: 200,
      }
    ];
    return result
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const updateCustomerData = async (value) => {
    try {
      setLoading(true)

      let results = await window.Platform.database.createNewCustomer(value);
      window.NotificationUtils.showSuccess("Customer added to the system")
    } catch (error) {

      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      setLoading(false)
      setShowCustomerFormModal(null)
    }
  }

  const updateSupplierData = async (value) => {
    try {
      setLoading(true)

      let results = await window.Platform.database.createNewSupplier(value);
      window.NotificationUtils.showSuccess("Customer added to the system")
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      setLoading(false)
      setShowSupplierFormModal(null)
    }
  }


  const handleEditCellChange = (params, event) => {
    // const { id, field, value } = params;
    let amountCheck = params.row.amount;

    // if (['ctn', 'qty', 'weight', 'cbm', 'qtyCtn'].includes(params.field)) {
    // console.log(params.row.unitOfAmount)
    //   amountCheck = checkingForAmount(params.row,params.row.unitOfAmount)
    //   console.log("dtaa", amountCheck)
    // }
    const updatedChanges = accessChanges.map((row) => {
      if (row.id === params.row.id) {
        if (['ctn', 'qty', 'weight', 'cbm', 'qtyCtn'].includes(params.field)) {

          amountCheck = checkingForAmount({ ...row, [params.field]: event.target.value }, params.row.unitOfAmount)

        }
        return { ...row, [params.field]: event.target.value, amount: amountCheck };
      }
      return row;
    });


    setAccessChanges(updatedChanges);
  };

  const renderContent = () => {
    return (
      <DataGridContainer>

        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={accessChanges}
          columns={columns()}
          rowHeight={50}

          // sx={ {minHeight: 300}}
          disableSelectionOnClick
          disableRowSelectionOnClick
          //  onRowEditCommit={ (params)=> console.log(params)}
          onCellEditStop={handleEditCellChange}
          // editable

          autoPageSize
          components={{
            Toolbar: CustomToolbar
          }}
        />
      </DataGridContainer>

    );
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {isMobile && (
        <Drawer 
        />
      )}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Packaging List By Sea</Typography>
          <div>
          </div>
        </HeaderContainer>


        {/* /////////////////// */}

        <Grid
          container
          spacing={2}
          style={{ paddingLeft: "10px", paddingBottom: "20px" }}
        >
          <Grid item xs={12} sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: "10px",
                paddingRight: "10px",
              }}
            >

              {/* <Autocomplete
                // size="small"
                options={options}

                style={{ width: !isMobile ? "400px" : "90%" }}
                // onChange={(e)=>{
                //   console.log(e.target.)
                // }}

                renderInput={(params) => (
                  <TextField {...params} label="Items" variant="outlined" required
                    // ref={fields[0]}
                    // value={}
                    inputRef={fields[0]}

                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        //   // e.preventDefault();
                        // e.preventDefault();
                        // handleKeyPress(e)

                        console.log(e.target.value)
                        // console.log(params.inputProps.ref.current.value)
                        changeHandlerForItem("item", e.target.value);
                        // console.log(fields[0].current.value)
                        fields[1].current.focus();
                        // setCurrent\F\ield(currentField + 1);
                      }
                    }}
                  />

                )}
              /> */}

              <TextField style={{ width: isMobile && '90%' }}
              
                // size="small"
                label="Shipping Mark" required inputRef={fields[1]}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    fields[2].current.focus();
                    // setCurrentField(currentField + 1);
                  }
                }}

                onChange={(e) =>
                  changeHandlerForItem("shippingMark", e.target.value)
                }
              />
              <TextField style={{ width: isMobile && '90%' }}
                //  size="small"
                label="Chinese Description" required inputRef={fields[2]}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    fields[3].current.focus();
                    // setCurrentField(currentField + 1);
                  }
                }}
                onChange={(e) =>
                  changeHandlerForItem("chineseDescription", e.target.value)
                }
              />
              <TextField
                // size="small"
                label="English Description" style={{ width: isMobile && '90%' }} required
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    fields[5].current.focus();
                    // setCurrentField(currentField + 1);
                  }
                }}
                inputRef={fields[4]}
                onChange={(e) =>
                  changeHandlerForItem("englishDescription", e.target.value)
                }
              />
              <TextField
                // size="small" 
                label="Brand Logo" style={{ width: isMobile && '90%' }} required
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    fields[6].current.focus();
                    // setCurrentField(currentField + 1);
                  }
                }}
                inputRef={fields[5]}
                onChange={(e) =>
                  changeHandlerForItem("brandLogo", e.target.value)
                }
              />
              <TextField
                // size="small"
                label="Pcs/CTN" style={{ width: isMobile && '90%' }} required
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    fields[7].current.focus();
                    // setCurrentField(currentField + 1);
                  }
                }}
                inputRef={fields[6]}
                onChange={(e) =>
                  changeHandlerForItem("pcsctn", e.target.value)
                }
              />

              <TextField
                label="CTNS" style={{ width: isMobile && '90%' }} required
                inputRef={fields[7]}
                onChange={(e) =>
                  changeHandlerForItem("ctns", e.target.value)
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();

                    fields[8].current.focus();
                  }
                }}
              />

              <TextField
                label="Quantity" style={{ width: isMobile && '90%' }} required
                inputRef={fields[7]}
                onChange={(e) =>
                  changeHandlerForItem("quantity", e.target.value)
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    fields[9].current.focus();
                  }
                }}
              />

              <TextField
                label="G.W/CTN(Kgs)" style={{ width: isMobile && '90%' }} required
                inputRef={fields[7]}
                onChange={(e) =>
                  changeHandlerForItem("gwctnkgs", e.target.value)
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    fields[9].current.focus();
                  }
                }}
              />

            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: "10px",
                paddingRight: "10px",
              }}
            >
              <TextField
                label="G.W(KGS)" style={{ width: isMobile && '90%' }} required
                inputRef={fields[7]}
                onChange={(e) =>
                  changeHandlerForItem("gwkgs", e.target.value)
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    fields[9].current.focus();
                  }
                }}
              />

              <TextField
                label="CTN Size" style={{ width: isMobile && '90%' }} required
                inputRef={fields[7]}
                onChange={(e) =>
                  changeHandlerForItem("ctnsize", e.target.value)
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    fields[9].current.focus();
                  }
                }}
              />

              <TextField
                label="CBM" style={{ width: isMobile && '90%' }} required
                inputRef={fields[7]}
                onChange={(e) =>
                  changeHandlerForItem("cbm", e.target.value)
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    fields[9].current.focus();
                  }
                }}
              />

              {imagePreview && (
                <img src={imagePreview} alt="Preview" style={{
                  maxWidth: '100px', maxHeight: '55px', padding: 2,
                  marginLeft: 8, marginRight: 16, borderColor: 'red', borderWidth: 1,
                  borderStyle: 'solid'
                }} />
              )}

              <div style={{ flexDirection: 'column', textAlign: 'left' }}>
                <h6>Photo</h6>
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  style={{ width: isMobile && '90%', height: '36px', cursor: 'pointer' }}
                />

              </div>

              <Button variant="contained"
                onClick={() => {
                  let amountdata = checkingForAmount(itemVal, itemVal.unitOfAmount)
                  let srNo = accessChanges.length;
                  // console.log("srno", ++srNo);
                  setAccessChanges([
                    ...accessChanges,
                    { ...itemVal, id: srNo, amount: amountdata },
                  ]);
                }}
              //  onKeyUp={(e) => {
              //   if (e.key === 'Enter') {

              //   }
              // }}
              >Add Item</Button>
            </div>
          </Grid>

        </Grid>
        {renderContent()}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ padding: '10px', fontSize: '17px', whiteSpace: 'nowrap' }}>
                Total amount:{' '}
              </Typography>
              <TextField
              required
                // size="small"
                // label="Amount"
                value={totalAmount}
                style={{ paddingRight: '20px' }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ padding: '10px', fontSize: '17px', whiteSpace: 'nowrap' }}>
                Total weight:{' '}
              </Typography>
              <TextField
              required
                // size="small"
                // label="Amount"
                value={totalWeight}
                style={{ paddingRight: '20px' }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ padding: '10px', fontSize: '17px', whiteSpace: 'nowrap' }}>
                Total CBM:{' '}
              </Typography>
              <TextField
              required
                // size="small"
                // label="Amount"
                value={totalCbm}
                style={{ paddingRight: '20px' }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FabStyle
              variant="extended"
              color="primary"
              onClick={() => {
                onclicksubmit();
                // console.log(accessChanges);
                window.NotificationUtils.showSuccess('Changes successfully made');
              }}
            >
              {/* <SaveAlt sx={{ mr: 1 }} /> */}
              Submit
            </FabStyle>
          </Grid>
          {/* <Typography>23</Typography> */}
        </Grid>


        {/* </TableContainer> */}

        {/* </TableContainer> */}
        {/* </TableContainer> */}
        {
          showCustomerFormModal &&

          <AddModal
            onCancel={() => setShowCustomerFormModal(null)}
            // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
            loading={loading}
            onSubmit={updateCustomerData}
          // loading={subLoading}
          />
        }
        {
          showSupplierFormModal &&
          <AddSupplierModal
            onCancel={() => setShowSupplierFormModal(null)}
            // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
            loading={loading}
            onSubmit={updateSupplierData}
          />
        }

      </StaffEditorPageContainer>
    </>
  );
}


