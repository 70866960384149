import { Button, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
// import { Button } from "@mui/base";

const isMobile = window.innerWidth < 900;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;

  // max-width: 700px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;
`;

const ContainerSOSplit = ({ temp, onClose,fetchData }) => {
  // console.log("TEMP", temp);

  const [newCtnValues, setNewCtnValues] = React.useState({});
  const [formvalue,setFormValue]=useState([])
  const [gridRows,setGridRows]=useState(temp.orders)
  const [loading,setLoading]=useState(false)
  const [ disableCheck, setDisableCheck] = useState(true)

  
const calculateNewWeight=(wt,ctn,newCtn)=>{
  let weightPerCtn = wt / ctn;
  let newWeight = weightPerCtn * newCtn;
  return newWeight.toFixed(2);
}
const calculateNewVol=(wt,ctn,newCtn)=>{
  let weightPerCtn = wt / ctn;
  let newWeight = weightPerCtn * newCtn;
  return newWeight.toFixed(10);
}

const calculateNewCbm=(cbm,ctn,newCtn)=>{
  let weightPerCtn = cbm / ctn;
  let newWeight = weightPerCtn * newCtn;
  return newWeight.toFixed(2);
}
const calculateNewQTY=(cbm,ctn,newCtn)=>{
  let weightPerCtn = cbm / ctn;
  let newWeight = weightPerCtn * newCtn;
  return newWeight.toFixed(0);
}

  const handleNewCtnChange = (params, newValue) => {
    setDisableCheck(false);
    if (newValue > +params.row.attributes.ctn) {
   
      newValue = params.row.attributes.ctn;
    }
    setNewCtnValues((prevValues) => ({
      ...prevValues,
      [params.id]: newValue,
    }));
   
  };
  const handleSubmit = async() => {
    try{
      if (!validateNewCtn()) {
        window.NotificationUtils.showError(
          "Please enter different ctn for SO splitting"
        );
        return;
      }
      setLoading(true)
    // Collect updated form data and update the gridRows
    const updatedRows = gridRows.map((row) => {
      const updatedFormValue = newCtnValues[row.id];
      // console.log("ROWSS",row)
      // console.log("rooww id",updatedFormValue)
      delete row.quotation
      delete row.shippingOrder
      if (updatedFormValue) {
        // console
        return {
          ...row,
          ctn: +updatedFormValue, // Set new CTN directly
          weight: +calculateNewWeight(row.attributes.weight, row.attributes.ctn, updatedFormValue),
          cbm: +calculateNewCbm(row.attributes.cbm, row.attributes.ctn, updatedFormValue), 
          volume:+calculateNewVol(row.attributes.volume, row.attributes.ctn, updatedFormValue),
          amount:+calculateNewWeight(row.attributes.amount, row.attributes.ctn, updatedFormValue),
          qty:+calculateNewQTY(row.attributes.qty, row.attributes.ctn, updatedFormValue),
        };
      }
      else{
        return null;
      }
      // return row;
    }).filter((row) => row !== null||undefined);
    
// console.log("currentData: " , temp)
    // console.log('Updated',updatedRows);
    let totalWeight=calculateTotalWeight()
    let totalCBM=calculateTotalCbm()
    let totalVolume=calculateTotalVolume()
    let totalAmount=calculateTotalAmount()
    let totalCtn=calculateTotalCTN()
    // console.log("TOTALCTN",totalCtn)
    // console.log("VOLUME",totalVolume)
    // console.log("amount",totalAmount)
    // console.log("CBM",totalCBM)
    // console.log("WEIGHTTT",totalWeight)
    
    let results = await window.Platform.database.containerSoSplit({item:updatedRows, id:temp.id,totalCbm:totalCBM, totalWeight:totalWeight,totalAmount:totalAmount,totalVolume:totalVolume,totalCtn:totalCtn});
    // Perform additional actions like sending data to the server
    fetchData()
    // Close the modal
    onClose();
    window.NotificationUtils.showSuccess('SO Split succesfully')

    setLoading(false);
  }
    catch(err){
      console.log(err);
      setLoading(false);
    }
  };

// console.log('setNewCtnValues',gridRows)


  const gridColumns = () => {
    let columns = [
      {
        field: "id",
        headerName: "ID",
        sortable: false,
        width: 150,
        editable: false,
        renderCell: (params) => {
          // console.log(params)
        return params.row.id||"N/A"
        }
      },
      {
        field: "item",
        headerName: "Item Name",
        sortable: false,
        width: 150,
        editable: false,
        renderCell: (params) => {
          // console.log(params)
        return params.row.attributes.item||"N/A"
        }
      },
      {
        field: "ctn",
        headerName: "CTN",
        sortable: false,
        width: 150,
        renderCell: (params) => {
          // console.log(params)
        return params.row.attributes.ctn||"N/A"
        }
        // valueGetter: (params) =>
        // (newCtnValues[params.id]*10 || params.row.ctn) ,
      },
      {
        field: "New CTN",
        headerName: "Deliverable CTN",
        sortable: false,
        width: 150,
        renderCell: (params) => {
          // console.log(params)

          // const value = params.row.label || "N/A";

          return <TextField type="number" style={{ height: "30px",marginTop: "-10px",marginLeft:"-5px"}}
          inputProps={{
            style: { height: "10px" },
            min: 0, // Set the minimum value
            max: params.row.ctn, // Set the maximum value
            type: "number",
          }}
          // defaultValue={params.row.ctn}
          fullWidth
          defaultValue={params.row.attributes.ctn}
          // value={newCtnValues[params.id] ||""}
          value={newCtnValues[params.id] !== undefined ? newCtnValues[params.id] : params.row.attributes.ctn}
          onChange={(e)=>handleNewCtnChange(params, e.target.value)}

          />
      }
      },
      {
        field: "weight",
        headerName: "Weight",
        sortable: false,
        width: 150,
        valueGetter: (params) =>{
         if(newCtnValues[params.id]){
          const weightPerCtn = params.row.attributes.weight / params.row.attributes.ctn;
          const newWeight = weightPerCtn * newCtnValues[params.id];
          return newWeight.toFixed(2);
        }
        else{
          return params.row.attributes.weight
        }
      }
        // (newCtnValues[params.id] || params.row.ctn) ,
      },
      {
        field: "cbm",
        headerName: "CBM",
        sortable: false,
        width: 150,
        valueGetter: (params) =>{
          if(newCtnValues[params.id]){
            const cbmPerCtn = params.row.attributes.cbm / params.row.attributes.ctn;
            const newCbm = cbmPerCtn * newCtnValues[params.id];
            return newCbm.toFixed(2);
         }
         else{
           return params.row.attributes.cbm
         }
       }
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        width: 150,
        valueGetter: (params) =>{
          if(newCtnValues[params.id]){
            const cbmPerCtn = params.row.attributes.amount / params.row.attributes.ctn;
            const newCbm = cbmPerCtn * newCtnValues[params.id];
            return newCbm.toFixed(2);
         }
         else{
           return params.row.attributes.amount.toFixed(2)
         }
       }
      },
      {
        field: "qty",
        headerName: "Quantity",
        sortable: false,
        width: 150,
        valueGetter: (params) =>{
          if(newCtnValues[params.id]){
            const cbmPerCtn = params.row.attributes.qty / params.row.attributes.ctn;
            const newCbm = cbmPerCtn * newCtnValues[params.id];
            return newCbm.toFixed(2);
         }
         else{
           return params.row.attributes.qty
         }
       }
      },
      {
        field: "volume",
        headerName: "Volume",
        sortable: false,
        width: 150,
        valueGetter: (params) =>{
            if (newCtnValues[params.id]) {
      const volume = parseFloat(params.row.attributes.volume);
      const ctn = params.row.attributes.ctn;
      const volumePerCtn = volume / ctn;
      
      if (isNaN(volumePerCtn) || isNaN(newCtnValues[params.id])) {
        // console.log("Invalid data:", volume, ctn, newCtnValues[params.id]);
        return "N/A";
      }
      
      const newVolume = volumePerCtn * newCtnValues[params.id];
      return newVolume.toFixed(4); // Display with 4 decimal places (adjust as needed)
    } else {
      return parseFloat(params.row.attributes.volume).toFixed(4); // Display with 4 decimal places (adjust as needed)
    }
  }
      },
    ];
    return columns;
  };

  // console.log("Form Value",formvalue)

const calculateTotalWeight = () => {
  let totalWeight = 0;
  gridRows.forEach((row) => {
    const updatedFormValue = newCtnValues[row.id];
    if (updatedFormValue) {
      let wt = calculateNewWeight(row.attributes.weight, row.attributes.ctn, updatedFormValue);
      totalWeight = totalWeight + parseFloat(wt); // Convert string to number
    } else {
      totalWeight = totalWeight + parseFloat(row.attributes.weight); // Convert string to number
    }
  });
  // console.log("TOTLA",totalWeight)
  return totalWeight.toFixed(2);
};
const calculateTotalVolume = () => {
  let totalWeight = 0;
  gridRows.forEach((row) => {
    const updatedFormValue = newCtnValues[row.id];
    if (updatedFormValue) {
      let wt = calculateNewWeight(row.attributes.volume, row.attributes.ctn, updatedFormValue);
      totalWeight = totalWeight + parseFloat(wt); // Convert string to number
    } else {
      totalWeight = totalWeight + parseFloat(row.attributes.volume); // Convert string to number
    }
  });
  // console.log("TOTLA",totalWeight)
  return totalWeight.toFixed(5);
};
const calculateTotalAmount = () => {
  let totalWeight = 0;
  gridRows.forEach((row) => {
    const updatedFormValue = newCtnValues[row.id];
    if (updatedFormValue) {
      let wt = calculateNewWeight(row.attributes.amount, row.attributes.ctn, updatedFormValue);
      totalWeight = totalWeight + parseFloat(wt); // Convert string to number
    } else {
      totalWeight = totalWeight + parseFloat(row.attributes.amount); // Convert string to number
    }
  });
  // console.log("TOTLA",totalWeight)
  return totalWeight.toFixed(2);
};

const calculateTotalCTN = () => {
  let totalWeight = 0;
  gridRows.forEach((row) => {
    const updatedFormValue = newCtnValues[row.id];
    if (updatedFormValue) {
      let wt = updatedFormValue
      totalWeight = totalWeight + parseFloat(wt); // Convert string to number
    } else {
      totalWeight = totalWeight + parseFloat(row.attributes.ctn); // Convert string to number
    }
  });
  // console.log("TOTLA",totalWeight)
  return totalWeight.toFixed(2);
};

const calculateTotalCbm = () => {
  let totalCbm = 0;
  gridRows.forEach((row) => {
    const updatedFormValue = newCtnValues[row.id];
    if (updatedFormValue) {
      let cbm = calculateNewCbm(row.attributes.cbm, row.attributes.ctn, updatedFormValue);
      totalCbm = totalCbm + parseFloat(cbm); // Convert string to number
    } else {
      totalCbm = totalCbm + parseFloat(row.attributes.cbm); // Convert string to number
    }
  });
  return totalCbm.toFixed(2);
};

const shouldDisableSaveButton = () => {
  for (const row of gridRows) {
    const updatedCtn = newCtnValues[row.id];
    if (
      updatedCtn === undefined ||
      updatedCtn === null ||
      updatedCtn === "" || 
      (+updatedCtn === +row.attributes.ctn )
    ) {
      return true; 
    }
  }
  return false; 
};

const validateNewCtn = () => {
  for (const row of gridRows) {
    const updatedCtn = newCtnValues[row.id];
    if (updatedCtn && +updatedCtn === +row.attributes.ctn) {
      return false;
    }
  }
  return true;
};

useEffect(()=>{
  shouldDisableSaveButton()
},[newCtnValues])


// console.log("NEWCTn",newCtnValues)
  return (
    <StyledDialog
      disableEnforceFocus
      // maxWidth="xs"
      fullWidth={true}
      open={true}
      PaperProps={{
        style: isMobile
          ? {}
          : {
              minWidth: 750,

              maxWidth: 850,

              minHeight: 500,

              maxHeight: 550,
            },
      }}
    >
      <StyledDialogContent>
        {loading && <OpaqueLoading/>}
        <ModalHeaderContainer>
          <Typography variant="h5">Shipping Order Split ({temp.id})</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </ModalHeaderContainer>
        <div style={{ height: 300, width: 800 }}>
          <DataGrid autoPageSize columns={gridColumns()} rows={gridRows} />
        </div>
        <Stack sx={{marginTop:"10px",justifyContent:"space-between"}} direction={"row"}  >
          <Stack direction={"column"} spacing={2} >
            <Typography><strong>Total Weight:</strong> {calculateTotalWeight()}</Typography>
            <Typography><strong>Total CBM:</strong> {calculateTotalCbm()}</Typography>
          </Stack>
          <Stack direction={"column"} spacing={3} >
          <div>
        </div>
       <Button variant="contained" onClick={handleSubmit} sx={{marginLeft:"-20px"}} disabled={disableCheck}  >Save Changes</Button>
        </Stack>
        </Stack>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ContainerSOSplit;
// disabled={shouldDisableSaveButton()} 