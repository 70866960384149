import React, { useState, useEffect, useRef } from "react";
import { Button, IconButton, Tooltip, Typography } from "@mui/material"; // Update import to "@mui/material"
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { DATE_FORMATS, PRIMARY_COLOR, SHADOW } from "../../constants";
import { DataGrid, GridToolbar } from "@mui/x-data-grid"; // Update import to "@mui/x-data-grid"
import moment from "moment";
import AddDistrictModal from "../../components/modals/addDistrictModal";
import { Delete } from "@mui/icons-material";
const isMobile = window.innerWidth < 900;

const ContentContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  flex-direction: column;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 145px);
  width: 100%;
  position: relative;
`;

const DataGridStyled = styled(DataGrid)`
  background: white;
  border-radius: 10px !important;
  box-shadow: ${SHADOW};
  .MuiDataGrid-overlay{
    .MuiCircularProgress-root{
      color: ${PRIMARY_COLOR}
    }
  }
`;

const Toolbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const DistrictsManager = (props) => {
  const [loading, setLoading] = useState(false);

  const [districts, setDistricts] = useState([]);

  const districtsRef = useRef();

  districtsRef.current = districts;

  const [selectedDistrict, setSelectedDistrict] = useState(null);


  const onDelete=async(val)=>{

    try{
        let results = await window.Platform.database.deleteDistrict({val})
        fetchDistricts();
    }
    catch(error)
    {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
  
  }
  
  
  
  
  const onClickDeleteEmployee = (params) => {
  
  
    window.confirmationModal(
  
      `Are you sure you want to deactivate ${params.districtName}
  
       (${params.id})?`,()=> onDelete(params.id)
  
    );
    }

const gridColumns =  () => {
  let results = [
    {
      field: "districtName",
      headerName: "District Name",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        let name = params.row.districtName;
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
    {
        field: "stateName",
        headerName: "State Name",
        minWidth: 200,
        flex: 0.22,
        editable: false,
        valueGetter: (params) => {
          return params.row.state.stateName
        },
        renderCell: (params) => {
          let name = params.row.state.stateName;
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
    {
      field: "createdAt",
      headerName: "Created at",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        let name = moment(params.row.createdAt).format(DATE_FORMATS.DATE_WITH_TIME);
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        let name = moment(params.row.updatedAt).format(DATE_FORMATS.DATE_WITH_TIME);
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
  ];
  results.push({

    field: '  ',

    headerName: 'Actions',

    editable: false,

    minWidth: 150,

    flex: 0.22,

    maxWidth:160,

    disableColumnMenu: true,

    disableExport: true,

    disableReorder: true,

    hideSortIcons: true,

    renderCell: (params) => {

      return (

        <>

          <IconButton

            size="large"

            color="primary"

            onClick={(e) => {

              e.stopPropagation();

              onClickDeleteEmployee(params.row)

            }}

          >

           <Delete/>

          </IconButton>

         

        </>

      );

    },

  });
return results
}

  const fetchDistricts = async () => {
    try {
      setLoading(true);
      let response = await window.Platform.database.fetchDistricts();
      setDistricts(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching districts");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDistricts();
  }, []);

  const onCloseModal = (update=null) => {
    if (update) {
      let districtsCopy = [...districtsRef.current];
      let index = districtsCopy.findIndex(
        (district) => district.id === update.id
      );
      if (index !== -1) {
        districtsCopy[index] = update;
      } else {
        districtsCopy.unshift(update);
      }
      setDistricts(districtsCopy);
    }
    setSelectedDistrict(null);
  };

  const renderAddDistrictModal = () => {
    return (
      <AddDistrictModal
        closeModal={onCloseModal}
        selectedDistrict={selectedDistrict || {}}
      />
    );
  };

  const renderHeader = () => {
    return (
      <Toolbar>
        <Typography variant="h5">Districts Manager</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => setSelectedDistrict({})}
          >
            Add District
          </Button>
        </div>
      </Toolbar>
    );
  };

  const renderData = () => {
    return (
      <DataGridContainer>
        <DataGridStyled
          rows={districts}
          loading={loading}
          columns={gridColumns()}
          disableSelectionOnClick
          autoPageSize
          checkboxSelection
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              csvOptions: {
                fileName: `districtsList`,
              },
            },
          }}
          onRowClick={(rowParams, event) => {
            if (event.target.tagName !== "INPUT") {
              setSelectedDistrict(rowParams.row);
            }
          }}
        />
      </DataGridContainer>
    );
  };

  return (
    <>
      {isMobile && (
        <Drawer
          setShowNotifications={props.setShowNotifications}
          onAuthUserChanged={props.onAuthUserChanged}
          user={props.user}
          props = {props}
        />
      )}
      <ContentContainer>
        {renderHeader()}
        {renderData()}
        {selectedDistrict && renderAddDistrictModal()}
      </ContentContainer>
    </>
  );
};

export default DistrictsManager;
