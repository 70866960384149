import React, { useEffect, useState } from "react";
import {
    Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const RejectionReasonModal = ({ onCancel, items ,loading, onSubmit }) => {
    const [form, setForm] = useState({
     reason: ''
    });
// const [loading,setLoading] = useState(false)
     // useEffect(()=>{
    //   console.log("Customer Name",customerData)
    // },[customerData])
    // console.log("customerdata", initialData)
  
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    };
  
    const submitHandler = () => {

        if(!form.reason.length)
        window.NotificationUtils.showError("Rejection reason is required")
        else{
        onSubmit({reason: form.reason,id: items.id})
    }
    //   console.log({...form, supplierName: initialData.SupplierName});
    // if(form.remark && form.typeOfShipment)
    //   onSubmit({...form})
    //   else
    //   window.NotificationUtils.showError("Please fill all the fields")
    };
    const options = [ { value: 'Air', label: 'Air' },
    { value: 'Sea', label: 'Sea' },
  ]
  
    return (
      <StyledDialog disableEnforceFocus maxWidth="xs" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Please provide the reason to reject</Typography>

          <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack>
              <TextField
              required

                variant="outlined"
                label="Remarks"
                // defaultValue={form["name"]}
                onChange={(e) =>
                  changeHandler("reason", e.target.value)
                }
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              gap="10px"
              align-items="center"
            >
              <Button color="error" onClick={onCancel}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                Reject
              </Button>
            </Stack>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default RejectionReasonModal;
  