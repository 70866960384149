import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";

import { ROLES } from "../../constants";

const StyledCard = styled(Card)`
  position: relative;
  width: ${window.innerWidth < 500 ? "100%" : "500px"};
`;

const StyledCardContent = styled(CardContent)`
  padding: 16px !important;
`;

const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
`;

const FieldsContainer = styled.div``;

const CardHeaderContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputField = styled(TextField)`
  margin-bottom: 10px !important;
`;

const OrganisationHeading = styled(Typography)`
  font-weight: 500;
`;

const RoleHeading = styled(Typography)`
  font-weight: 500;
  text-transform: none;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledImage = styled.img`
  height: 120px;
  position: absolute;
  top: 30px;
`;

const Invite = (props) => {
  const [loading, setLoading] = useState(true);
  const [inviteDetails, setInviteDetails] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [registering, setRegistering] = useState(false);
  const [touched, setTouched] = useState(false);

  const getInviteId = () => {
    let queryParams = "";
    try {
      const url = new URL(window.location.href);

      queryParams = url.search.toString().slice(1);
    } catch (e) {
      console.error(e);
    }
    return queryParams;
  };

  const inviteId = getInviteId();
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        if (inviteId) {
          let results = await window.Platform.database.getInviteData(inviteId);
          if (
            !results ||
            [ROLES.BD_FOS, ROLES.RETENTION_FOS, ROLES.FOS_SPECIAL].includes(
              results.role
            )
          ) {
            window.NotificationUtils.showError("Invalid or expired invite link");
            routeToLogin();
          }
          setInviteDetails(results);
        } else {
          routeToLogin();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [inviteId]);

  useEffect(() => {
    // if (password !== confirmPassword) {
    //   setPasswordError("Passwords do not match");
    // } else if (touched && !strongPasswordRegex.test(password)) {
    //   setPasswordError(
    //     "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character (@, $, !, %, *, ?, &)"
    //   );
    // } else {
    //   setPasswordError("");
    // }
  }, [password, confirmPassword, touched]);

  const routeToLogin = () => {
    props.history.replace("/login");
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setTouched(true);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setTouched(true);

    if (password === value) {
      setPasswordError("");
    }
  };

  const onClickRegister = async () => {
    if (!password || !confirmPassword) {
      window.NotificationUtils.showError("Please enter and confirm the password");
      return;
    }

    if (password !== confirmPassword) {
      window.NotificationUtils.showError("Passwords do not match");
      return;
    }

    if (passwordError) {
      window.NotificationUtils.showError("Invalid password");
      return;
    }

    try {
      setRegistering(true);
      let data = {
        ...inviteDetails,
        password: password,
      };
      await window.Platform.database.confirmMemberInvite(data);
      window.NotificationUtils.showSuccess("Successfully registered");
      setRegistering(false);
      routeToLogin();
    } catch (error) {
      window.NotificationUtils.showError("Error trying to register");
      setRegistering(false);
    }
  };

  const renderLoading = () => {
    return (
      <div className="loadingCentered">
        <CircularProgress color="primary" />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <PageContainer>
        <StyledImage src="logo1.png" />
        <StyledCard raised>
          {registering && <OpaqueLoading />}
          <StyledCardContent>
            <CardHeaderContainer>
              <OrganisationHeading variant="h5">Maxx</OrganisationHeading>
              <RoleHeading variant="h6">
                Role: {inviteDetails?.role || ""}
              </RoleHeading>
            </CardHeaderContainer>
            <FieldsContainer>
              <InputField
                value={inviteDetails?.email || ""}
                disabled
                fullWidth
                variant="outlined"
                label="Email"
                required
              />
              <InputField
                value={password || ""}
                type="password"
                fullWidth
                onChange={handlePasswordChange}
                variant="outlined"
                label="Password"
                required
                disabled={registering}
                error={!!passwordError}
                helperText={touched && passwordError}
              />
              <InputField
                value={confirmPassword || ""}
                type="password"
                fullWidth
                onChange={handleConfirmPasswordChange}
                variant="outlined"
                label="Confirm Password"
                required
                disabled={registering}
                error={!!passwordError}
                helperText={touched && passwordError}
              />
            </FieldsContainer>
            <ActionButtonsContainer>
              <Button
                disabled={
                  registering ||
                  !password ||
                  !confirmPassword ||
                  !!passwordError
                }
                onClick={onClickRegister}
                variant="contained"
                color="primary"
              >
                Register
              </Button>
            </ActionButtonsContainer>
          </StyledCardContent>
        </StyledCard>
      </PageContainer>
    );
  };

  return (
    <div className="AppContainer">
      {loading ? renderLoading() : renderContent()}
    </div>
  );
};

export default Invite;