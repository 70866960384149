import { Button, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
// import { Button } from "@mui/base";

const isMobile = window.innerWidth < 900;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;

  // max-width: 700px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;
`;

const RecieveModal = ({ temp, onClose,fetchData }) => {
  // console.log("TEMP", temp);

  const [newCtnValues, setNewCtnValues] = React.useState({});
  const [formvalue,setFormValue]=useState([])
  const [gridRows,setGridRows]=useState(temp.order)
  const [loading,setLoading]=useState(false)
  
const calculateNewWeight=(wt,ctn,newCtn)=>{
  let weightPerCtn = wt / ctn;
  let newWeight = weightPerCtn * newCtn;
  return newWeight.toFixed(2);
}
const calculateNewVol=(wt,ctn,newCtn)=>{
  let weightPerCtn = wt / ctn;
  let newWeight = weightPerCtn * newCtn;
  return newWeight.toFixed(5);
}

const calculateNewCbm=(cbm,ctn,newCtn)=>{
  let weightPerCtn = cbm / ctn;
  let newWeight = weightPerCtn * newCtn;
  return newWeight.toFixed(2);
}
const calculateNewQTY=(cbm,ctn,newCtn)=>{
  let weightPerCtn = cbm / ctn;
  let newWeight = weightPerCtn * newCtn;
  return newWeight.toFixed(0);
}

  const handleNewCtnChange = (params, newValue) => {
    if (newValue > +params.row.weight) {
   
      newValue = params.row.weight;
    }
    setNewCtnValues((prevValues) => ({
      ...prevValues,
      [params.id]: newValue,
    }));
   
  };
  const handleNewCbmChange = (params, newValue) => {
    if (newValue > +params.row.cbm) {
   
      newValue = params.row.cbm;
    }
    setNewCtnValues((prevValues) => ({
      ...prevValues,
      [params.id + 'cbm']: newValue,
    }));
   
  };
const handleNewQtyChange = (params, newValue) => {
    if (newValue > +params.row.qty) {
   
      newValue = params.row.qty;
    }
    setNewCtnValues((prevValues) => ({
      ...prevValues,
      [params.id+'qty']: newValue,
    }));
   
  };
  const handleSubmit = async() => {
    try{
    //   if (!validateNewCtn()) {
    //     window.NotificationUtils.showError(
    //       "Please enter different ctn for SO splitting"
    //     );
    //     return;
    //   }
      setLoading(true)
    // Collect updated form data and update the gridRows
    const updatedRows = gridRows.map((row) => {
      const updatedFormValue = newCtnValues[row.id];
      // console.log("ROWSS",row)
      // console.log("rooww id",updatedFormValue)
      delete row.quotation
      delete row.shippingOrder
      
      // console.log("ROWSS",row)

      if (updatedFormValue || +newCtnValues[row.id+'cbm'] || +newCtnValues[row.id+ 'qty'] ) {
        // console
        return {
          ...row,
        //   ctn: +calculateNewWeight(row.ctn, row.weight, updatedFormValue), // Set new CTN directly
          weight: +updatedFormValue || row.weight,
          cbm: +newCtnValues[row.id+'cbm'] || row.cbm, 
        //   volume:+calculateNewVol(row.volume, row.weight, updatedFormValue),
        //   amount:+calculateNewWeight(row.amount, row.weight, updatedFormValue),
          qty:+newCtnValues[row.id+ 'qty'] || row.qty,
        };
      }
      else{
        return null;
      }
      // return row;
    }).filter((row) => row !== null||undefined);
    
// console.log("currentData: " , temp)
    // console.log('Updated',updatedRows);
    let totalWeight=calculateTotalWeight()
    let totalCBM=calculateTotalCbm()
    // let totalVolume=calculateTotalVolume()
    // let totalAmount=calculateTotalAmount()
    // let totalCtn=calculateTotalCTN()
    // console.log("TOTAL",totalCtn)
    // console.log("WEIGHTTT",totalVolume)
    // console.log("CBM",totalAmount)
    
   

    setGridRows(updatedRows)
    let results = await window.Platform.database.recievedItems({item:updatedRows, id:temp.id,totalCbm:totalCBM, totalWeight:totalWeight});
    // Perform additional actions like sending data to the server
    onClose();
    fetchData()
    // Close the modal
    // onClose();
    setLoading(false);
  }
    catch(err){
      console.log(err);
      setLoading(false);
    }
  };

// console.log('setNewCtnValues',gridRows)


  const gridColumns = () => {
    let columns = [
      {
        field: "id",
        headerName: "ID",
        sortable: false,
        width: 150,
        editable: false,
        renderCell: (params) => {
          // console.log(params)
        return params.row.id||"N/A"
        }
      },
      {
        field: "item",
        headerName: "Item Name",
        sortable: false,
        width: 150,
        editable: false,
        renderCell: (params) => {
          // console.log(params)
        return params.row.item||"N/A"
        }
      },
      {
        field: "ctn",
        headerName: "CTN",
        sortable: false,
        width: 150,
        // valueGetter: (params) =>{
        //     if(newCtnValues[params.id]){
        //      const weightPerCtn = params.row.ctn / params.row.weight;
        //      const newWeight = weightPerCtn * newCtnValues[params.id];
        //      return newWeight.toFixed(2);
        //    }
        //    else{
        //      return params.row.weight
        //    }
        //  }
        },
    //   {
    //     field: "New CTN",
    //     headerName: "Deliverable CTN",
    //     sortable: false,
    //     width: 150,
        
    //     renderCell: (params) => {
    //       // console.log(params)

    //       // const value = params.row.label || "N/A";

    //       return <TextField type="number" style={{ height: "30px",marginTop: "-10px",marginLeft:"-5px"}}
    //       inputProps={{
    //         style: { height: "10px" },
    //         min: 0, // Set the minimum value
    //         max: params.row.ctn, // Set the maximum value
    //         type: "number",
    //       }}
    //       // defaultValue={params.row.ctn}
    //       fullWidth
    //       defaultValue={params.row.ctn}
    //       // value={newCtnValues[params.id] ||""}
    //       value={newCtnValues[params.id] !== undefined ? newCtnValues[params.id] : params.row.ctn}
    //       onChange={(e)=>handleNewCtnChange(params, e.target.value)}

    //       />
    //   }
    //   },
      {
        field: "weight",
        headerName: "Warehouse Weight",
        sortable: false,
        width: 150,
    //     valueGetter: (params) =>{
    //      if(newCtnValues[params.id]){
    //       const weightPerCtn = params.row.weight / params.row.ctn;
    //       const newWeight = weightPerCtn * newCtnValues[params.id];
    //       return newWeight.toFixed(2);
    //     }
    //     else{
    //       return params.row.weight
    //     }

    //   }
    renderCell: (params) => {
              // console.log(params)
    
              // const value = params.row.label || "N/A";
    
              return <TextField type="number" style={{ height: "30px",marginTop: "-10px",marginLeft:"-5px"}}
              inputProps={{
                style: { height: "10px" },
                min: 0, // Set the minimum value
                max: params.row.weight, // Set the maximum value
                type: "number",
              }}
              // defaultValue={params.row.ctn}
              fullWidth
              defaultValue={params.row.weight}
              // value={newCtnValues[params.id] ||""}
              value={newCtnValues[params.id] !== undefined ? newCtnValues[params.id] : params.row.weight}
              onChange={(e)=>handleNewCtnChange(params, e.target.value)}
    
              />
          }
          },
        // (newCtnValues[params.id] || params.row.ctn) ,

      {
        field: "cbm",
        headerName: "Warehouse CBM",
        sortable: false,
        width: 150,
        renderCell: (params) => {
            // console.log(params)
  
            // const value = params.row.label || "N/A";
  
            return <TextField type="number" style={{ height: "30px",marginTop: "-10px",marginLeft:"-5px"}}
            inputProps={{
              style: { height: "10px" },
              min: 0, // Set the minimum value
              max: params.row.cbm, // Set the maximum value
              type: "number",
            }}
            // defaultValue={params.row.ctn}
            fullWidth
            defaultValue={params.row.cbm}
            // value={newCtnValues[params.id] ||""}
            value={newCtnValues[params.row.id + 'cbm'] !== undefined ? newCtnValues[params.row.id + 'cbm'] : params.row.cbm}
            onChange={(e)=>handleNewCbmChange(params, e.target.value)}
  
            />
        }
        },
        {
            field: "qty",
            headerName: "Warehouse Quantity",
            sortable: false,
            width: 150,
            renderCell: (params) => {
                // console.log(params)
      
                // const value = params.row.label || "N/A";
      
                return <TextField type="number" style={{ height: "30px",marginTop: "-10px",marginLeft:"-5px"}}
                inputProps={{
                  style: { height: "10px" },
                  min: 0, // Set the minimum value
                  max: params.row.qty, // Set the maximum value
                  type: "number",
                }}
                // defaultValue={params.row.ctn}
                fullWidth
                defaultValue={params.row.qty}
                // value={newCtnValues[params.id] ||""}
                value={newCtnValues[params.row.id+'qty'] !== undefined ? newCtnValues[params.row.id+'qty'] : params.row.qty}
                onChange={(e)=>handleNewQtyChange(params, e.target.value)}
      
                />
            }
            
          },
    
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        width: 150,
        // valueGetter: (params) =>{
        //   if(newCtnValues[params.id]){
        //     const cbmPerCtn = params.row.amount / params.row.weight;
        //     const newCbm = cbmPerCtn * newCtnValues[params.id];
        //     return newCbm.toFixed(2);
        //  }
        //  else{
        //    return params.row.amount
        //  }
    //    }
    //   },
      },
    
      {
        field: "volume",
        headerName: "Volume",
        sortable: false,
        width: 150,
    //     valueGetter: (params) =>{
    //       if(newCtnValues[params.id]){
    //         const cbmPerCtn = params.row.volume / params.row.weight;
    //         const newCbm = cbmPerCtn * newCtnValues[params.id];
    //         return newCbm.toFixed(4);
    //      }
    //      else{
    //        return params.row.volume
    //      }
    //    }
      },
    ];
    return columns;
  };

  // console.log("Form Value",formvalue)

const calculateTotalWeight = () => {
  let totalWeight = 0;
  gridRows.forEach((row) => {
    const updatedFormValue = newCtnValues[row.id];
    if (updatedFormValue) {
        // console.log('rrrrrrr',row.weight)
      let wt = newCtnValues[row.id]
      totalWeight = totalWeight + parseFloat(wt); // Convert string to number
    } else {
      totalWeight = totalWeight + parseFloat(row.weight); // Convert string to number
    }
  });
  // console.log("TOTLA",totalWeight)
  return totalWeight.toFixed(2);
};
// const calculateTotalVolume = () => {
//   let totalWeight = 0;
//   gridRows.forEach((row) => {
//     const updatedFormValue = newCtnValues[row.id];
//     if (updatedFormValue) {
//       let wt = calculateNewWeight(row.volume, row.ctn, updatedFormValue);
//       totalWeight = totalWeight + parseFloat(wt); // Convert string to number
//     } else {
//       totalWeight = totalWeight + parseFloat(row.volume); // Convert string to number
//     }
//   });
//   console.log("TOTLA",totalWeight)
//   return totalWeight.toFixed(5);
// };
// const calculateTotalAmount = () => {
//   let totalWeight = 0;
//   gridRows.forEach((row) => {
//     const updatedFormValue = newCtnValues[row.id];
//     if (updatedFormValue) {
//       let wt = calculateNewWeight(row.amount, row.ctn, updatedFormValue);
//       totalWeight = totalWeight + parseFloat(wt); // Convert string to number
//     } else {
//       totalWeight = totalWeight + parseFloat(row.amount); // Convert string to number
//     }
//   });
//   console.log("TOTLA",totalWeight)
//   return totalWeight.toFixed(2);
// };

// const calculateTotalCTN = () => {
//   let totalWeight = 0;
//   gridRows.forEach((row) => {
//     const updatedFormValue = newCtnValues[row.id];
//     if (updatedFormValue) {
//       let wt = updatedFormValue
//       totalWeight = totalWeight + parseFloat(wt); // Convert string to number
//     } else {
//       totalWeight = totalWeight + parseFloat(row.ctn); // Convert string to number
//     }
//   });
//   console.log("TOTLA",totalWeight)
//   return totalWeight.toFixed(2);
// };

const calculateTotalCbm = () => {
  let totalCbm = 0;
  gridRows.forEach((row) => {
    const updatedFormValue = newCtnValues[row.id + 'cbm'];
    if (updatedFormValue) {
      let cbm = newCtnValues[row.id + 'cbm']
      totalCbm = totalCbm + parseFloat(cbm); // Convert string to number
    } else {
      totalCbm = totalCbm + parseFloat(row.cbm); // Convert string to number
    }
  });
  // console.log("TOTLCBMA",totalCbm);

  return totalCbm.toFixed(2);
};

const shouldDisableSaveButton = () => {
  for (const row of gridRows) {

    const updatedCtn = newCtnValues[row.id];
    const updatedCbm = newCtnValues[row.id+'cbm'];

    const updatedQty = newCtnValues[row.id+'qty'];

    if (
      updatedCtn === undefined ||
      updatedCtn === null ||
      updatedCtn === "" || 
      updatedCbm === undefined ||
      updatedCbm === null ||
      updatedCbm === "" || 
      updatedQty === undefined ||
      updatedQty === null ||
      updatedQty === "" 
      // || 
      // (+updatedCtn === +row.attributes.weight ) ||
      // (+updatedCbm === +row.attributes.cbm ) ||
      // (+updatedQty === +row.attributes.qty )

    ) {
      return true; 
    }
  }
  return false; 
};

const validateNewCtn = () => {
  for (const row of gridRows) {
    const updatedCtn = newCtnValues[row.id];
    if (updatedCtn && +updatedCtn === +row.attributes.ctn) {
      return false;
    }
  }
  return true;
};

useEffect(()=>{
  shouldDisableSaveButton()
},[newCtnValues])


// console.log("NEWCTn",newCtnValues)
  return (
    <StyledDialog
      disableEnforceFocus
      // maxWidth="xs"
      fullWidth={true}
      open={true}
      PaperProps={{
        style: isMobile
          ? {}
          : {
              minWidth: 750,

              maxWidth: 850,

              minHeight: 500,

              maxHeight: 550,
            },
      }}
    >
      <StyledDialogContent>
        {loading && <OpaqueLoading/>}
        <ModalHeaderContainer>
          <Typography variant="h5">Update Warehouse Weight for each item (SO ID-{temp.id})</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </ModalHeaderContainer>
        <div style={{ height: 300 }}>
          <DataGrid autoPageSize columns={gridColumns()} rows={temp.order} />
        </div>
        <Stack sx={{marginTop:"10px",justifyContent:"space-between"}} direction={"row"}  >
          <Stack direction={"column"} spacing={2} >
            <Typography><strong>Total Weight:</strong> {calculateTotalWeight()}</Typography>
            <Typography><strong>Total CBM:</strong> {calculateTotalCbm()}</Typography>
          </Stack>
          <Stack direction={"column"} spacing={3} >
          <div>
        </div>
       <Button variant="contained" onClick={handleSubmit} sx={{marginLeft:"-20px"}}>Recieve</Button>
        </Stack>
        </Stack>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default RecieveModal;
// disabled={shouldDisableSaveButton()} 