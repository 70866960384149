import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import {
  Typography,
  Button,
  Fab,
  IconButton,
  Tabs,
  Tab,
  Icon,
  Stack,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { SaveAlt } from "@mui/icons-material";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import { DATE_FORMATS } from "../../constants";
import moment from "moment";
import QuotationAdmin from "../../components/modals/QuotationAdmin";
import RejectionReasonModal from "../../components/modals/rejectionReason";
import ApprovalRemarkModal from "../../components/modals/ApprovalRemarkModal";
import EditQuotationModal from "../../components/modals/EditQuotationModal";
import BulkApprove from "../../components/modals/BulkApprove";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewComfyAltIcon from "@mui/icons-material/ViewComfyAlt";
import CostingModal from "../../components/modals/CostingModal";
import PreviewIcon from '@mui/icons-material/Preview';
import PlannedSoModal from "../../components/modals/plannedSoModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 80px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;
const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: calc(100vh - 251px);
  && .highlighted-row {
    background-color: #ffcccb !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "140px - 20px - 41.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  overflow-x: ${isMobile
    ? "auto"
    : "hidden"}; /* Add horizontal scroll for mobile */
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
const StyledCardHeading = styled(Typography)`
  text-transform: none;
  letter-spacing: 0.5px;
`;

const StyledCardHeading1 = ({ icon, children, value, sx, bgColor }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      ...sx,
      transition: "transform 0.2s", // Add transition property for smooth effect
      ":hover": {
        transform: "scale(1.1)", // Scale up on hover
      },
    }}
  >
    <Icon
      sx={{
        color: "#ffffff",
        background: bgColor,
        width: "38px",
        height: "38px",
        fontSize: 24,
        marginRight: 2,
        padding: "7px",
        borderRadius: "5px",
      }}
    >
      {icon}
    </Icon>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* <Typography variant="h6" sx={{ fontSize:"12px" }}>
        {children}
      </Typography> */}
      <StyledCardHeading variant="h6" align="center" sx={{ fontSize: "12px" }}>
        {children}
      </StyledCardHeading>
      <Typography variant="h6" align="left" sx={{ marginTop: "0px" }}>
        <strong>{value}</strong>
      </Typography>
    </Box>
  </Box>
);

export default function QuotationHistory(props) {
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showItemsModal, setShowItemsModal] = useState();
  const [rejectionReason, setRejectionReason] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  //
  const [bulkApprovalModal, setBulkApprovalModal] = useState(false);
  const [isDataOnReasonModal, setDataOnReasonModal] = useState();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [costingModal,setCostingModal]=useState(false)
  const [containerDetails,setContainerDetails]=useState()
  const [modal,setModal]=useState();
  const [rowCount, setRowCount] = useState(0);
const [currentPage, setCurrentPage] = useState(1);

const [startDate, setStartDate] = useState(false);
const [endDate, setEndDate] = useState();
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 100,
});

useEffect(()=>{
  if(!startDate && !endDate)
  fetchData(activeTab);
else{
if(activeTab ==0)
  onFilterChange3();
else
onFilterChange4();
}
},[endDate, paginationModel.page])

useEffect(() => {
  // console.log('check page', paginationModel)
  if(!queryOptions && !endDate)
  {
    // console.log("heey")
  fetchData(activeTab);
  }
  else{
if(activeTab === 0 && queryOptions){
// {console.log("fff", queryOptions)
    onFilterChange1(queryOptions, paginationModel.page, paginationModel.pageSize)}
  // console.log('Fetching details with pagination',paginationModel.page);
  else if(activeTab === 1 && queryOptions)
  {
    // console.log("hhhelo")
  onFilterChange2(queryOptions, paginationModel.page, paginationModel.pageSize)
  }
  }

}, [paginationModel.page]);

useEffect(() => {
  // console.log('check page', paginationModel)

  // if(!queryOptions)
  fetchData(activeTab);
}, [activeTab]);

const [queryOptions, setQueryOptions] = React.useState(null);

  const [dashboardData, setDashboardData] = useState({
    adminApproved: 0,
    customerApproved: 0,
    pending: 0,
    totalQuotation: 0,
    totalSOGenerated: 0,
    totalDeliveredSo:0
  });
  // console.log("ATIVE", activeTab);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setStartDate(null);
    setEndDate(null)
  };

  const bulkApprove = (val) => {
    // console.log("val", val);
  };

  const bulkReject = (val) => {
    // console.log("val", val);
  };

  const fetchData = async (activeStep) => {
    try {
      setSubLoading(true);
      setData([]);
      let results = await window.Platform.database.fetchSOorQuotation({
        page: paginationModel.page, // Pass the current page
        pageSize: paginationModel.pageSize, // Set your desired page size
        filterModel: { items: [] }, // Pass your filterModel if needed
        params: activeStep,
      });
      // console.log("RESPONSE", results);
      if (activeTab === 1) {
        setDashboardData({
          adminApproved: results.adminApproved,
          customerApproved: results.customerApproved,
          pending: results.pending,
          totalQuotation: results.totalQuotation,
          totalSOGenerated: results.totalSOGenerated,
          totalDeliveredSo:results.totalDeliveredSo
        });
        setData(results.quotations);
        setRowCount(results.totalCount)
      } else {
        setDashboardData({
          adminApproved: results.adminApproved,
          customerApproved: results.customerApproved,
          pending: results.pending,
          totalQuotation: results.totalQuotation,
          totalSOGenerated: results.totalSOGenerated,
          totalDeliveredSo:results.totalDeliveredSo
        });
        setData1(results.quotations);
        setRowCount(results.totalCount)
      }
      setSubLoading(false);
    } catch (e) {
      console.log(e);
      setSubLoading(false);
    }
  };

  const onFilterChange1 = async(filterModel, page, pageSize) => {

    if(!filterModel)
    {
      // console.log("queryyyyy",queryOptions.filterModel)
      filterModel = queryOptions.filterModel
  }
    try{  
      setSubLoading(true);
      // console.log(filterModel,'................................................................')
      const response = await window.Platform.database.filterQuotation({ ...filterModel,page:page || paginationModel.page, // Pass the current page
      pageSize: pageSize || paginationModel.pageSize })
      // console.log("respponse",response);
      setData1(response.items);
      setRowCount(response.totalCount)
      setSubLoading(false);
    }
    catch(err){
  console.error(err)
  setSubLoading(false);
  
    }
    // setQueryOptions({ filterModel: { ...filterModel } });
  };
  const onFilterChange2 = async(filterModel, page, pageSize) => {

    if(!filterModel)
    {
      // console.log("queryyyyy",queryOptions.filterModel)
      filterModel = queryOptions.filterModel
  }
    try{  
      setSubLoading(true);
      // console.log(paginationModel,'................................................................')
      const response = await window.Platform.database.filterSO({ ...filterModel,page:page || paginationModel.page, // Pass the current page
      pageSize: pageSize || paginationModel.pageSize })
      // console.log("respponse",response);
      setData(response.items);
      setRowCount(response.totalCount)
      setSubLoading(false);
    }
    catch(err){
  console.error(err)
  setSubLoading(false);
  
    }
    // setQueryOptions({ filterModel: { ...filterModel } });
  };

  const onFilterChange3 = async(filterModel, page, pageSize) => {

    
    try{  
      setLoading(true);
      // console.log(filterModel,'................................................................')
      const response = await window.Platform.database.filterQuotationByDateRange({ startDate, endDate,page:page || paginationModel.page, // Pass the current page
      pageSize: pageSize || paginationModel.pageSize })
      console.log("respponse",response);
      setData1(response.items);
      setRowCount(response.totalCount)
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  
    }
    // setQueryOptions({ filterModel: { ...filterModel } });
  };
  const onFilterChange4 = async(filterModel, page, pageSize) => {

    try{  
      setLoading(true);
      // console.log(filterModel,'................................................................')
      const response = await window.Platform.database.filterSoByDateRange({ startDate, endDate,page:page || paginationModel.page, // Pass the current page
      pageSize: pageSize || paginationModel.pageSize })
      console.log("respponse",response);
      setData(response.items);
      setRowCount(response.totalCount)
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
    }
    // setQueryOptions({ filterModel: { ...filterModel } });
  };
  // useEffect(() => {
  //   fetchData(activeTab);
  // }, [activeTab]);

  // const currentDate = new Date();
  // const currentTimestamp = new Date().getTime();
  // console.log("currentDate",currentTimestamp)

  const getEmployeeColumns = () => {
    let result = [
      //       {
      //         field: "objectId",
      //         headerName: "Quotation ID",
      //         sortable: false,
      //         width: 150,
      //         // editable: true,
      //         valueGetter: (params) =>params.row.id,
      //         renderCell: (params) => {
      //           // console.log("DATAGETTERVALUE",params)
      //           let val = params.row.id;
      //           return <Tooltip title={val}>{val}</Tooltip>;
      //         },
      // // filterable: false, 

      //       },
            {
              field: "quotationId",
              headerName: "Quotation Id",
              sortable: false,
              width: 150,
              valueGetter: (params) =>params.row?.quotationId|| 'N/A',
              renderCell: (params) => {
                // console.log("DATAGETTERVALUE",params)
                let value = params.row?.quotationId || 'N/A';
                return <Tooltip title={value}>{value}</Tooltip>;
              },
              // editable: true,
      filterable: true, 
              
            },
            {
              field: "createdByRole",
              headerName: "Creater Role",
              sortable: false,
              width: 150,
              valueGetter: (params) =>`${params.row.createdBy.role}`,
              renderCell: (params) => {
                // console.log("DATAGETTERVALUE",params)
                let value = `${params.row.createdBy.role}`;
                return <Tooltip title={value}>{value}</Tooltip>;
              },
              // editable: true,
      filterable: true, 
              
            },
            {
              field: "createdByName",
              headerName: "Creater Name",
              sortable: false,
              width: 150,
              valueGetter: (params) =>params.row.createdBy?.firstName,
              renderCell: (params) => {
                // console.log("DATAGETTERVALUE",params)
                let value = params.row.createdBy.firstName;
                return <Tooltip title={value}>{value}</Tooltip>;
              },
              // editable: true,
      filterable: true, 
              
            },
           

            {
              field: "createdAt",
              headerName: "Created At",
              sortable: false,
              width: 200,
              filterable:false,
              valueGetter: (params) =>moment
              (params.row.createdAt)
              .format(DATE_FORMATS.DEFAULT) || 'N/A',
              renderCell: (params) => {
                // console.log("DATAGETTERVALUE",params)
                return moment
                (params.row.createdAt)
                .format(DATE_FORMATS.DEFAULT) || 'N/A';
              },
            },
            {
              field: "customerName",
              headerName: "Customer Name",
              sortable: false,
              width: 200,
              valueGetter: (params) =>params.row.customerName,
              renderCell: (params) => {
                let val = params.row.customerName;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "supplierName",
              headerName: "Supplier Name",
              sortable: false,
              width: 200,
              valueGetter: (params) =>params.row.supplierName,
              renderCell: (params) => {
                let val =  params.row.supplierName;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            // {
            //   field: "shippingMark",
            //   headerName: "Shipping Mark",
            //   sortable: false,
            //   width: 200,
            //   valueGetter: (params) =>params.row.shippingMark || 'N/A',
            //   renderCell: (params) => {
            //     let val =  params.row.shippingMark || 'N/A';
            //     return <Tooltip title={val}>{val}</Tooltip>;

            //   },
            // },
            {
              field: "deliveryDate",
              headerName: "Expected Delivery Date",
              sortable: false,
              width: 200,
              filterable: false,
              valueGetter: (params) =>moment
              .unix(params.row.deliveryDate)
              .format(DATE_FORMATS.DEFAULT),
              valueGetter: (params) =>
                moment
                  .unix(params.row.deliveryDate)
                  .format(DATE_FORMATS.DEFAULT),
            },
            {
              field: "deliveryPlace",
              headerName: "Delivery Place",
              sortable: false,
              width: 200,
              valueGetter:(params) => params.row.deliveryPlace || 'N/A',
              renderCell: (params) => {
                let val =  params.row.deliveryPlace || 'N/A';
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "status",
              headerName: "Current Status",
              sortable: false,
              width: 200,
              valueGetter:(params)=>{ if(params.row.status==="SOGENERATED"){
                return "SO Generated"
             }
           else if(params.row.status==="adminApproved"){
             return "Admin Approved"
           }
           else if(params.row.status==="customerApproved"){
           return "Customer Approved"
           }
           else{
             return params.row.status
           }
           },
              renderCell:(params) =>{ if(params.row.status==="SOGENERATED"){
                 return "SO Generated"
              }
            else if(params.row.status==="adminApproved"){
              return "Admin Approved"
            }
            else if(params.row.status==="customerApproved"){
            return "Customer Approved"
            }
            else{
              return params.row.status
            }
            },
              // renderCell: (params) => {
              //   return params.row.status;
              // },
            },
            {
              field: "warehouseName",
              headerName: "Initial Warehouse Destination",
              sortable: false,
              width: 200,
              valueGetter:(params) => params.row.warehouseName,
              renderCell: (params) => {
                let val = params.row.warehouseName;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "totalAmount",
              headerName: "Total Amount",
              sortable: false,
              width: 200,
              valueGetter:(params)=>params.row.totalAmount,
              renderCell: (params) => {
                let val =  params.row.totalAmount;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "totalWeight",
              headerName: "Total Weight",
              sortable: false,
              width: 200,
              valueGetter:(params)=>params.row.totalWeight,
              renderCell: (params) => {
                let val = params.row.totalWeight;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "totalCbm",
              headerName: "Total CBM",
              sortable: false,
              width: 200,
              valueGetter:(params)=>params.row.totalCbm,
              renderCell: (params) => {
                let val = params.row.totalCbm;
                return <Tooltip title={val}>{val}</Tooltip>;
              },
            },
            {
              field: "typeOfShipment",
              headerName: "Type of shipment",
              sortable: false,
              width: 200,
              valueGetter:(params)=>params.row.typeOfShipment,
              renderCell: (params) => {
                let val = params.row.typeOfShipment;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "remark",
              headerName: "Remark",
              sortable: false,
              width: 200,
              valueGetter:(params)=>params.row.remark || 'N/A',
              renderCell: (params) => {
                let val = params.row.remark || 'N/A';
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "items",
              headerName: "Items",
              width: 200,
              headerAlign: "center", // Align the header text to the center
              align: "center",
              sortable: false,
              disableExport: true,
                  renderCell: params => {
                    const value = params.row.items || []
                   return( <Tooltip title="Click to view items"><IconButton
                    size="large"
                    variant="extended"
                    color="primary"
                    onClick={() => setShowItemsModal(value)}
                  >
                    <ViewComfyAltIcon fontSize="inherit" />
                  </IconButton></Tooltip>
                )}
            },
            {
              field: "Packaginglist",
              headerName: "Packaging list",
              width: 200,
              headerAlign: "center", // Align the header text to the center
              align: "center",
              sortable: false,
              disableExport: true,
              renderCell: (params) => {
                const value = params.row.packagingList || [];
                // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
                return (
                  <Tooltip title="Click to view items">
                  <IconButton
                  disabled= {!params.row.packagingList}
                    size="large"
                    variant="extended"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      openPackaging(value);
                    }}
                  >
                    <RemoveRedEyeIcon fontSize="inherit" />
                  </IconButton></Tooltip>
                );
              },
            },
          ]
          return result;
        };
        const getColumns1=()=>{
        let result= [
            {
              field: "objectId",
              headerName: "SO ID",
              sortable: false,
              width: 150,
              // editable: true,
              valueGetter:(params)=>params.row?.id,
              renderCell: (params) => {
                let val = params.row.id;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "quotationId",
              headerName: "Quotation ID",
              sortable: false,
              width: 200,
              valueGetter:(params)=>params.row.quotation?.attributes?.quotationId,
              renderCell: (params) => {
                let val = params.row.quotation.attributes?.quotationId;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
      filterable: false, 

            },
            {
              field: "createdAt",
              headerName: "Created At",
              sortable: false,
              width: 200,
              filterable:false,

              valueGetter: (params) =>moment
              (params.row.createdAt)
              .format(DATE_FORMATS.DEFAULT) || 'N/A',
              renderCell: (params) => {
                // console.log("DATAGETTERVALUE",params)
                return moment
                (params.row.createdAt)
                .format(DATE_FORMATS.DEFAULT) || 'N/A';
              },
            },
            {
              field: "containerId",
              headerName: "Container Id",
              width: 200,
      filterable: false, 

              sortable: false,
              valueGetter: params => params.row?.container?.id || 'N/A',
              renderCell: params => {
                // console.log(params.row?.container?.id)
                const value = params.row?.container?.id || 'N/A'
                return <Tooltip title={value}>{value}</Tooltip>
              }},
              {
                field: "containerName",
                headerName: "Container Name",
                width: 200,
      filterable: false, 

                sortable: false,
                valueGetter: params => params.row?.container?.attributes?.containerName|| 'N/A',
                renderCell: params => {
                  // console.log(params.row?.container?.id)
                  const value = params.row?.container?.attributes?.containerName|| 'N/A'
                  return <Tooltip title={value}>{value}</Tooltip>
                }},
           
            {
              field: "customerName",
              headerName: "Customer Name",
              sortable: false,
              width: 200,
      filterable: false, 

              valueGetter:(params)=>params.row.quotation?.attributes?.customerName,
              renderCell: (params) => {
                let val = params.row.quotation.attributes.customerName;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "supplierName",
              headerName: "Supplier Name",
              sortable: false,
              width: 200,
      filterable: false, 

              valueGetter:(params)=>params.row.quotation?.attributes?.supplierName,
              renderCell: (params) => {
                let val = params.row.quotation.attributes.supplierName;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "deliveryDate",
              headerName: "Expected Delivery Date",
              sortable: false,
              width: 200,
      filterable: false, 

              valueGetter: (params) =>
                moment
                  .unix(params.row.deliveryDate)
                  .format(DATE_FORMATS.DEFAULT),
            },
            {
              field: "deliveryPlace",
              headerName: "Delivery Place",
              sortable: false,
      filterable: false, 

              width: 200,
              valueGetter:(params)=>params.row.quotation?.attributes?.deliveryPlace || 'N/A',
              renderCell: (params) => {
                let val = params.row.quotation.attributes.deliveryPlace || 'N/A';
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "warehouseName",
              headerName: "Initial Warehouse Destination",
              sortable: false,
      filterable: false, 

              width: 200,
              valueGetter:(params)=>params.row.quotation?.attributes?.warehouseName,
              renderCell: (params) => {
                let val =  params.row.quotation.attributes.warehouseName;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },  {
              field: "Costing Details Update",
              headerName: "Costing Details Update",
              width: 200,
              sortable: false,
              filterable: false, 
              valueGetter: params => params.row?.costing? 'Updated' : 'Pending' || 'N/A',
              renderCell: params => {
                const value = params.row?.costing? 'Updated' : 'Pending' || 'N/A'
                return <Tooltip title={value}>{value}</Tooltip>
              }
        
            },
            {
              field: "customClearanceTime",
              headerName: "Custom clearance time",
              width: 200,
              sortable: false,
              filterable: false, 
              valueGetter: params => params.row?.customClearanceTime ? moment(params.row?.customClearanceTime).format(DATE_FORMATS.DATE_WITH_TIME) : 'N/A',
              renderCell: params => {
                const value =params.row?.customClearanceTime? moment(params.row?.customClearanceTime).format(DATE_FORMATS.DATE_WITH_TIME): 'N/A'
                return <Tooltip title={value}>{value}</Tooltip>
              }
        
            },
            {
              field: "Tally Status ",
              headerName: "Tally Status ",
              width: 200,
              sortable: false,
              filterable: false, 
              valueGetter: params => params.row?.tallyStatus? 'Updated' : 'Pending' || 'N/A',
              renderCell: params => {
                const value = params.row?.tallyStatus? 'Updated' : 'Pending' || 'N/A'
                return <Tooltip title={value}>{value}</Tooltip>
              }
        
            },{
              field: "clearanceStatus",
              headerName: "Clearance Status",
              width: 200,
              sortable: false,
              filterable: false, 
              valueGetter: params => {
                  const value =  params.row.invoiceNumber? 'Updated': 'Pending'
                  return value
              },
              renderCell: params => {
                // console.log(params.row.quotation.attributes.shippingOrders.parent.id)
                const value =  params.row.invoiceNumber? 'Updated': 'Pending'
                return <Tooltip title={value}>{value}</Tooltip>
              }
          },{
            field: "invoiceNumber",
            headerName: "Invoice Number",
            width: 200,
            sortable: false,
            valueGetter: params => {
                const value =  params.row?.invoiceNumber
                return value
            },
            renderCell: params => {
              // console.log(params.row.quotation.attributes.shippingOrders.parent.id)
              const value =  params.row?.invoiceNumber||"N/A"
              return <Tooltip title={value}>{value}</Tooltip>
            }
        }
        ,{
          field: "invoice",
          headerName: "INV USD / HKD",
          width: 200,
          sortable: false,
          valueGetter: params => {
              const value =  params.row?.invoice
              return value
          },
          renderCell: params => {
            // console.log(params.row.quotation.attributes.shippingOrders.parent.id)
            const value =  params.row?.invoice||"N/A"
            return <Tooltip title={value}>{value}</Tooltip>
          }
      }
        ,{
            field: "pklStatus",
            headerName: "PKL Status",
            width: 200,
            sortable: false,
            filterable: false, 
            valueGetter: params => {
                const value =  params.row.pklStatus? 'Updated': 'Pending'
                return value
            },
            renderCell: params => {
              // console.log(params.row.quotation.attributes.shippingOrders.parent.id)
              const value =  params.row.pklStatus? 'Updated': 'Pending'
              return <Tooltip title={value}>{value}</Tooltip>
            }
        },

            {
              field: "totalAmount",
              headerName: "Total Amount",
              sortable: false,
              width: 200,
              valueGetter:(params)=>params.row.totalAmount,
              filterable: false, 
              renderCell: (params) => {
                let val = params.row.totalAmount;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "totalWeight",
              headerName: "Total Weight",
              sortable: false,
              width: 200,
              filterable: false, 
              valueGetter:(params)=>params.row.totalWeight,
              renderCell: (params) => {
                let val = params.row.totalWeight;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "totalCbm",
              headerName: "Total CBM",
              sortable: false,
              filterable: false, 
              width: 200,
              valueGetter:(params)=>params.row.totalCbm,
              renderCell: (params) => {
                let val = params.row.totalCbm;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },
            {
              field: "typeOfShipment",
              headerName: "Type of shipment",
              sortable: false,
              width: 200,
              valueGetter:(params)=>params.row.typeOfShipment,
              renderCell: (params) => {
                let val = params.row.typeOfShipment;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
            },{
              field: "costingDetails",
              headerName: "Costing Details",
              width: 200,
              headerAlign: "center", // Align the header text to the center
              align: "center",
              sortable: false,
      filterable: false, 

              disableExport: true,
                  renderCell: params => {
                    const value = params.row 
                    return(
                      <Tooltip title="Click to view Costing Details">
                    <IconButton
                    disabled={!params.row.costing}
                size="large"
                variant="extended"
                color="primary"
                onClick={() =>{ setCostingModal(true)
                setModal(value)}}
              >
                <RequestQuoteIcon fontSize="inherit" />
              </IconButton></Tooltip>
              )  }
            },
            {
              field: "containerDetails",
              headerName: "Container Details",
              width: 200,
              sortable: false,
      filterable: false, 

              disableExport: true,
              renderCell: params => {
                const value = params?.row?.container?.attributes || []
                // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
                return <Tooltip title="Click to view Container Details"><IconButton variant="extended" disabled={!params.row.container} color="primary" onClick={() => setContainerDetails(value)} >
                  <PreviewIcon/>
                </IconButton></Tooltip>
              }
            },
            {
              field: "items",
              headerName: "Items",
              width: 200,
              headerAlign: "center", // Align the header text to the center
              align: "center",
              sortable: false,
      filterable: false, 

              disableExport: true,
                  renderCell: params => {
                    const value = params.row.orderLineItems
                    console.log(value);

                    return(
                      <Tooltip title="Click to view items">
                    <IconButton
                size="large"
                variant="extended"
                color="primary"
                onClick={() => setShowItemsModal(value)}
              >
                <ViewComfyAltIcon fontSize="inherit" />
              </IconButton></Tooltip>
              )  }
            },
            {
              field: "Packaginglist",
              headerName: "Packaging list",
              width: 200,
              headerAlign: "center", // Align the header text to the center
              align: "center",
              sortable: false,
      filterable: false, 

              disableExport: true,
              renderCell: (params) => {
                const value =
                  params.row.quotation.attributes.packagingList || [];
                // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
                return (
                  <Tooltip title="Cick to view packagingList">
                  <IconButton
                  disabled={!params.row.quotation.attributes.packagingList}
                    size="large"
                    variant="extended"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      openPackaging(value);
                    }}
                  >
                    <RemoveRedEyeIcon fontSize="inherit" />
                  </IconButton></Tooltip>
                );
              },
            },
          ];

    return result;
  };

  const openPackaging = (val) => {
    window.open(val, "_blank");
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName:activeTab===0? "QuotationHistory":"SOHistory",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
   

    // console.log("================================", dashboardData);
// 
    // const columns = getEmployeeColumns().map((column) => {
    //   if (column.field === "items") {
    //     return {
    //       ...column,
    //       // renderCell: (params) => (
    //       //   <FabStyle
    //       //     variant="extended"
    //       //     color="primary"
    //       //     onClick={() => handleRowClick(params)}
    //       //   >
    //       //     View Items
    //       //   </FabStyle>
    //       // ),
    //       renderCell: (params) => {
    //         const value = params || [];
    //         // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
    //         return (
    //           <IconButton
    //             size="large"
    //             variant="extended"
    //             color="primary"
    //             onClick={() => handleRowClick(value)}
    //           >
    //             <ViewComfyAltIcon fontSize="inherit" />
    //           </IconButton>
    //         );
    //         // }
    //       },
    //     };
    //   }
    //   return column;
    // });

    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          disableSelectionOnClick={true}
          disableRowSelectionOnClick
          rows={activeTab === 1 ? data : data1}
          columns={activeTab === 0?getEmployeeColumns():getColumns1()}
          rowHeight={40}
          checkboxSelection={true}
          onRowSelectionModelChange={(selectedRowIndices) => {
            // console.log("Selected Row Indices:", selectedRowIndices);

            // Get the selected rows' data using the selected indices

            // console.log("Selected Row Data:", selectedRowData);

            setSelectedRowIds(selectedRowIndices);
          }}
        

          filterMode="server"
          onFilterModelChange={(val)=>
            {
              // console.log("vvvv",val);
              setQueryOptions(val);
              if(activeTab === 0)
            onFilterChange1(val)
          else
          onFilterChange2(val)

          }
          }
          rowCount={rowCount}
        
          // pageSizeOptions={[10]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          // autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
          
          // onRowClick={handleRowClick}
          //   getRowClassName={getRowClassName}
        />
      </DataGridContainer>
    );
  };

  return (
    <>
      {isMobile && <Drawer props={props} />}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Quotation/Shipping Order History</Typography>
          <div style={{display: 'flex', justifyContent: 'space-between', gap:"10px"}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    fullWidth
    disableFuture
    // shouldDisableDate={shouldDisableDate}
    required
    size='medium'
    // onError={dateErrorUpdate}
    allowKeyboardControl={false}
    autoOk
    label="Start Date"
    value={startDate 
      ? moment.unix(startDate) : null
    }
    onChange={(date) => {
      // console.log(date.unix())
      const value = date.unix();
      // console.log("chjeclDatae",value)
      setEndDate(null)
      setStartDate(value)
      console.log(value)
      // updateDateValue(date, value);
      // props.setData("terminationDate", value);
    }}
    renderInput={(params) => <TextField {...params} />}
    PopoverProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    }}
    // openTo="day"
  />
  </LocalizationProvider>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    fullWidth
    disableFuture
    // shouldDisableDate={shouldDisableDate}
    required
    size='medium'
    // onError={dateErrorUpdate}
    allowKeyboardControl={false}
    autoOk
    label="End Date"
    disabled={startDate? false: true}
    value={endDate ? moment.unix(endDate) : null}
    onChange={(date) => {
      // console.log(date.unix())
      const value = date.unix();
      // console.log("chjeclDatae",value)
      setEndDate(value);

      
      
      // props.setData("terminationDate", value);
    }}
    renderInput={(params) => <TextField {...params} />}
    PopoverProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    }}
    // openTo="day"
  />
  </LocalizationProvider>
  <Button variant="contained" 
  disabled={!endDate}
  onClick={async()=> {
    setStartDate(null)
    setEndDate(null)
    // await fetchData()
    // fetchData()
  }}>
    Clear Filter
  </Button>
  
          </div>
        </HeaderContainer>

        {/* <div
          style={{
            padding: "10px",
            marginLeft: "10px",
            display: isMobile ? "-ms-grid" : "flex",

            gap: "30%",
            maxHeight: "500px",
          }}
        > */}
        <Stack direction={isMobile?"column":"row"} gap={"27%"} mb={1} sx={{marginLeft:"1%"}} spacing={isMobile&&1}>
          {/* First Column */}

          {/* <div style={{ flex: !isMobile && "1 1 20%",flexDirection:"column" }}> */}
          <Stack direction={"column"} spacing={1}>
            <Typography align="left" variant={isMobile&&"body1"}>
              <strong>Pending Quotation:</strong> {dashboardData.pending}{" "}
            </Typography>
            <Typography align="left" variant={isMobile&&"body1"}>
              <strong>Admin Approved Quotation :</strong> {dashboardData.adminApproved}{" "}
            </Typography>
            </Stack>
          {/* </div> */}
          <Stack direction={"column"} spacing={1}>
            <Typography align="left" variant={isMobile&&"body1"}>
              <strong>Customer Approved Quotation :</strong> {dashboardData.customerApproved}{" "}
            </Typography>

            <Typography align="left" variant={isMobile&&"body1"}>
              <strong>Total Quotation :</strong> {dashboardData.totalQuotation}{" "}
            </Typography>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Typography align="left" variant={isMobile&&"body1"}>
              <strong>Total SO Generated :</strong>{" "} {dashboardData.totalSOGenerated}
            </Typography>
            <Typography align="left" variant={isMobile&&"body1"}> <strong>Total SO Delivered :</strong> {dashboardData.totalDeliveredSo}
            </Typography>
          </Stack>
          </Stack>
        {/* </StyledCardHeading1> */}

        <TableContainer>
          <Tabs
            value={activeTab}
            variant="fullWidth"
            onChange={handleTabChange}
          >
            <Tab label="Quotation" />
            <Tab label="Shipping Order" />
          </Tabs>
          {loading && <OpaqueLoading />}
          {subLoading && <OpaqueLoading />}

          {renderContent()}
        </TableContainer>
        {showItemsModal && <InventoryItemsModal
        onClose={() => setShowItemsModal(null)}
        items={showItemsModal}
      />}
      {costingModal && <CostingModal items={modal} onCancel={()=>setCostingModal(false)} onSubmit={null}/>}
      {
        containerDetails && <PlannedSoModal mode={null} temp={containerDetails}  onClose={()=>setContainerDetails(null)}/>
      }
      </StaffEditorPageContainer>
    </>
  );
}
