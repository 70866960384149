import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Fab, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { SaveAlt } from "@mui/icons-material";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import { DATE_FORMATS } from "../../constants";
import moment from "moment";
import QuotationAdmin from "../../components/modals/QuotationAdmin";
import RejectionReasonModal from "../../components/modals/rejectionReason";
import ApprovalRemarkModal from "../../components/modals/ApprovalRemarkModal";
import EditQuotationModal from "../../components/modals/EditQuotationModal";
import BulkApprove from "../../components/modals/BulkApprove";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;
const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: calc(100vh - 156px);
  && .highlighted-row {
    background-color: #FFCCCB !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  overflow-x: ${isMobile ? "auto" : "hidden"}; /* Add horizontal scroll for mobile */
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;

export default function QuotationGeneration(props) {
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showItemsModal, setShowItemsModal] = useState();
  const [rejectionReason, setRejectionReason] = useState(false)
  const [approvalModal, setApprovalModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  //
  const [bulkApprovalModal, setBulkApprovalModal] = useState(false)
  const [isDataOnReasonModal,setDataOnReasonModal] = useState();
const [selectedRowIds ,setSelectedRowIds] = useState([])
  const approveQuotation = async (val) => {
    
    try {
      setLoading(true)
      let results = await window.Platform.database.approveAdminQuotation(val.id)
      // return results
      fetchPendingQuotations()
      window.NotificationUtils.showSuccess("Quotation Approved")
    }
    catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      setLoading(false)
      setApprovalModal(false);
      setShowItemsModal(null)
    }
  }

  const bulkApprove = (val) => {
    // console.log("val",val)
  }

  const bulkReject = (val) => {
    // console.log("val",val)
  }

  const updateQuotation=async(val)=>{
    try {
      setLoading(true)
      // console.log("val.id" + val.id)
      let results = await window.Platform.database.updateQuotation(val)
      window.NotificationUtils.showSuccess("Quotation Saved SuccessFully");
      // return results
      fetchPendingQuotations()
      setEditModal(null)
    }
    catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      setLoading(false)
      
    }
  }

  const rejectQuotation = async (val) => {
    try {
      setLoading(true)
      let results = await window.Platform.database.rejectAdminQuotation({ id: val.id, reason: val.reason })
      // window.NotificationUtils.showSuccess("Quotation Approved",val.id)
      // return results
      fetchPendingQuotations()
    }
    catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      setLoading(false)
      setRejectionReason(null)
    }
  }

  const rows = [
    // {
    //   id: 1,
    //   role: "client 1",
    //   component1: { write: true, read: true },
    //   usermanagement: { write: true, read: true },
    //   tracking: { write: true, read: true },
    //   changeable: false,
    //   date: "2023-07-12",
    //   time: "10:00 AM",
    //   customercode: "C001",
    //   goodscategory: "Electronics",
    //   city: "New York",
    //   ctns: 5,
    //   weight: "10 kg",
    //   ctnsize: "20x20x20",
    //   cbm: "0.5",
    //   "shippingmark/ctnno": "ABC123",
    //   suppliername: "Supplier 1",
    //   customername: "Customer 1",
    // },
    // {
    //   id: 2,
    //   role: "client 2",
    //   component1: { write: false, read: true },
    //   usermanagement: { write: true, read: true },
    //   tracking: { write: true, read: true },
    //   changeable: true,
    //   date: "2023-07-13",
    //   time: "11:00 AM",
    //   customercode: "C002",
    //   goodscategory: "Clothing",
    //   city: "London",
    //   ctns: 3,
    //   weight: "5 kg",
    //   ctnsize: "15x15x15",
    //   cbm: "0.3",
    //   "shippingmark/ctnno": "DEF456",
    //   suppliername: "Supplier 2",
    //   customername: "Customer 2",
    // },
    // Add more rows with data...
  ];

  useEffect(()=>{
    
  },[selectedRowIds])
  useEffect(() => {
    // setAccessChanges(rows);
    // console.log("ssss",selectedRowIds)
    fetchPendingQuotations()
  }, [

  ]);


  const fetchPendingQuotations = async () => {
    try {
      setLoading(true)
      let results = await window.Platform.database.fetchPendingQuotations();
      setAccessChanges(results)
    }
    catch (error) {
      // window.NotificationUtils.showError(error)
      console.error(error.message)
    }
    finally {
      setLoading(false)
    }
  }
  // const currentDate = new Date(); 
  // const currentTimestamp = new Date().getTime();
  // console.log("currentDate",currentTimestamp)
  const getRowClassName = (params) => {
    const deliveryDate = moment.unix(params.row.deliveryDate).startOf("day"); 
    const currentDate = moment().startOf("day"); 
    const isTodayOrPast = deliveryDate.isSameOrBefore(currentDate, "day");
    return isTodayOrPast ? "highlighted-row" : "";
  };
  const getEmployeeColumns = () => {
    let result = [
      {
        field: "id",
        headerName: "Quotation ID",
        sortable: false,
        width: 150,
        // editable: true,
        valueGetter: params => params.row.quotationId,

      },
      {
        field: "createdAt",
        headerName: "Created At",
        sortable: false,
        width: 200,
        valueGetter: params => moment(params.row.createdAt).format(DATE_FORMATS.DEFAULT),
      },
      {
        field: "createdBy",
        headerName: "Created By",
        sortable: false,
        width: 200,
        valueGetter: params => params.row.createdBy?params.row.createdBy?.firstName + '(' +  params.row.createdBy?.role + ')' : 'N/A',
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        sortable: false,
        width: 200,
      },
      {
        field: "supplierName",
        headerName: "Supplier Name",
        sortable: false,
        width: 200,
      },
      {
        field: "deliveryDate",
        headerName: "Expected Delivery Date",
        sortable: false,
        width: 200,
        valueGetter: params => moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT),
       
      },
      {
        field: "deliveryPlace",
        headerName: "Delivery Place",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.deliveryPlace || 'N/A',
        renderCell: params => {
          const value = params.row.deliveryPlace || 'N/A' 
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "warehouseName",
        headerName: "Initial Warehouse Destination",
        sortable: false,
        width: 200,
      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        sortable: false,
        width: 200,
      },
      {
        field: "totalWeight",
        headerName: "Total Weight",
        sortable: false,
        width: 200,
      },
      {
        field: "totalCbm",
        headerName: "Total CBM",
        sortable: false,
        width: 200,
      },
      {
        field: "typeOfShipment",
        headerName: "Type of shipment",
        sortable: false,
        width: 200,
      },
      {
        field: "items",
        headerName: "Items",
        width: 200,
        headerAlign: "center", // Align the header text to the center
        align: "center",
        sortable: false,
        disableExport: true,
      //   renderCell: params => {
      //     const value = params.row || []
      //     // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
      //   //   return <FabStyle variant="extended" color="primary" onClick={() => setShowItemsModal(value)} >
      //   //     <ViewInArIcon/>
      //   //   </FabStyle>
      //   // }
      // }
    },
      {
        field: "Packaginglist",
        headerName: "Packaging list",
        width: 200,
        headerAlign: "center", // Align the header text to the center
        align: "center",
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row.packagingList || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <IconButton size="large" variant="extended" color="primary"
          disabled={!params.row.packagingList}
          onClick={(e) => {
            e.stopPropagation();
          openPackaging(value);}} >
            <RemoveRedEyeIcon fontSize="inherit"/>
          </IconButton>
        }
      }
    ];

    return result;
  };

  const openPackaging = (val) => {
    window.open(val, '_blank');
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "QuotationApprovals",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    const handleRowClick = (params) => {
      const rowData = params.row;
      setShowItemsModal(rowData);
    };

    const columns = getEmployeeColumns().map((column) => {
      if (column.field === "items") {
        return {
          ...column,
          // renderCell: (params) => (
          //   <FabStyle
          //     variant="extended"
          //     color="primary"
          //     onClick={() => handleRowClick(params)}
          //   >
          //     View Items
          //   </FabStyle>
          // ),
          renderCell: params => {
                const value = params || []
                // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
                return <IconButton size="large"  variant="extended" color="primary" onClick={() => handleRowClick(value)} >
                  <ViewComfyAltIcon fontSize="inherit" />
                </IconButton >
              // }
            }
        };
      }
      return column;
    });

    return (
      <DataGridContainer>
       <DataGrid
  className="payrollGrid"
  disableSelectionOnClick={true}
  disableRowSelectionOnClick
  rows={accessChanges}
  columns={columns}
  rowHeight={70}
  checkboxSelection={true}
  onRowSelectionModelChange={(selectedRowIndices) => {
    console.log("Selected Row Indices:", selectedRowIndices);

    // Get the selected rows' data using the selected indices

    // console.log("Selected Row Data:", selectedRowData);

    setSelectedRowIds(selectedRowIndices);
  }}
  autoPageSize
  components={{
    Toolbar: CustomToolbar,
  }}
  onRowClick={handleRowClick}
  getRowClassName={getRowClassName}
/>
      </DataGridContainer>
    );
  };

  return (
    <>
      {isMobile && <Drawer  props={props}/>}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Quotation Approval(s)</Typography>
          {!isMobile  && (
              <Button
                onClick={()=>{
                  setBulkApprovalModal(true)
                  // console.log("ssss",selectedRowIds)
                }}
                color="primary"
                variant="contained"
                disabled = {loading||accessChanges.length===0}
                
              >
                Bulk Approval/Rejection
              </Button>
            )}
        </HeaderContainer>
       
        <TableContainer>
          {loading && <OpaqueLoading />}
          {renderContent()}</TableContainer>
      </StaffEditorPageContainer>

      {showItemsModal && <QuotationAdmin
        onClose={() => {
          setDataOnReasonModal(showItemsModal);
          setShowItemsModal(null);
        }}
        onOpenEdit={()=>{
          setEditModal(showItemsModal)
        }}
        items={showItemsModal}
        onSubmit={setApprovalModal}
        onReject={setRejectionReason}
      />}
      { editModal && <EditQuotationModal
        items={editModal}
        onCancel={()=>setEditModal(false)}
        onSubmit={updateQuotation}
        
      />}

      {approvalModal && <ApprovalRemarkModal
        onCancel={() => setApprovalModal(null)}
        items={isDataOnReasonModal}
        onSubmit={approveQuotation} />}

      {rejectionReason && <RejectionReasonModal
        onCancel={() => setRejectionReason(null)}
        items={rejectionReason}
        onSubmit={rejectQuotation}
      />}

{bulkApprovalModal && 
    <BulkApprove
    onClose={() => {setBulkApprovalModal(false)
    fetchPendingQuotations()
    }}
    data={accessChanges}
    onApprove= {bulkApprove}
    onReject= {bulkReject}
    />
}
    </>
  );
}
