import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, Tab, Tabs, CircularProgress, FormControl, MenuItem, Select, InputLabel, Switch, Stack, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DATE_FORMATS, ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";
import { Add, Done, Inventory, Save, SaveAlt, Send } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/WarehouseModal";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import PackagingListModal from "../../components/modals/packagingListModal";
import { Link, useParams } from "react-router-dom";
import Calendar from "../CalenderTimeline/Calendar";
import CustomToggle from "./customToggle";
import moment from "moment";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import ViewPlannedSOModal from "../../components/modals/viewPlannedSOModal";

const isMobile = window.innerWidth < 900;


const TABS = {
  SEA:0,
  AIR:1
}



const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 205px);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const TabsContainer = styled.div`
//   ${isMobile &&
//   `
//     height: calc(100vh - 56px - 20px);
//   `}
// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ContainerPlanning(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [shippingOrdersSea, setShippingOrdersSea] = useState();
  const [shippingOrdersAir, setShippingOrdersAir] = useState();
  const [showPackagingListModal, setShowPackagingListModal] = useState();
  const [showItemsModal, setShowItemsModal] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
const [statusInventory, setStatusInventory] = useState(true)
const [modeTransport,setModeTransport] = useState(true)
const [itemModal,setItemModal]=useState(false)
// const { auth, setAuth } = useContext(AuthContext);

// console.log("AUTHROLE",props?.user?.role)


const onRecievedButton = (val) => {

}

const onSentButton = (val) => {

}

useEffect(()=>{
  fetchContainer()
}, [statusInventory])

const fetchContainer = async () => {
  try {
    setLoading(true)
    // Fetch the container data
    let results = await window.Platform.database.fetchPlannedContainer(id==="undefined"?{id:props.user.warehouse.objectId}:{id:id});
    // console.log("RESULTS", results);

    // Filter data based on shipment type (sea or air)
    const seaShippingOrders = results.filter(
      (order) => order.typeOfShipment === "Sea"
    );
    const airShippingOrders = results.filter(
      (order) => order.typeOfShipment === "Air"
    );

    // Store the filtered data in the state variables
    setShippingOrdersSea(seaShippingOrders);
    setShippingOrdersAir(airShippingOrders);
    setLoading(false)
  } catch (err) {
    setLoading(false)
    console.log("ERROR", err);
  }
};


  const getInventoryColumns = () => {
    
  

    let result = [
      {
        field: "id",
        headerName: "Container ID",
        sortable: false,
        width: 150,

        // editable: true,
      },
      {
        field: "containerName",
        headerName: "Container Name",
        width: 250,
        sortable: false,
        valueGetter: params => params.row?.containerName|| 'N/A',
        renderCell: params => {
          // console.log(params.row?.container?.id)
          const value = params.row?.containerName|| 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }},
      {
        field: "consigneeName",
        headerName: "Consignee Name",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.consigneeName || 'N/A',
        renderCell: params => {
          const value = params.row.consigneeName || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "forwarder",
        headerName: "Forwarder",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.forwarder|| 'N/A',
        renderCell: params => {
          const value = params.row.forwarder|| 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "shipperName",
        headerName: "Shipper Name",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.shipperName || 'N/A',
        renderCell: params => {
          const value = params.row.shipperName|| 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "shippingLine",
        headerName: "Shipper Line",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.shippingLine || 'N/A',
        renderCell: params => {
          const value = params.row.shippingLine|| 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },

      {
        field: "portLoading",
        headerName: "Port Loading",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.portLoading || 'N/A',
        renderCell: params => {
          const value = params.row.portLoading || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "portOfDestination",
        headerName: "Port Of Destination",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.portOfDestination || 'N/A',
        renderCell: params => {
          const value = params.row.portOfDestination || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "deliveryDate",
        headerName: "Delivery Date",
        width: 200,
        sortable: false,
        valueGetter: params => moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A',
        renderCell: params => {
          const value = moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "awbBlNumber",
        headerName: "AWB/BlNumber",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.awbBlNumber || 'N/A',
        renderCell: params => {
          const value = params.row.awbBlNumber || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "containerNoSealNo",
        headerName: "ContainerNo/SealNo",
        width: 200,
        sortable: false,
        valueGetter: params => params.row.containerNoSealNo || 'N/A',
        renderCell: params => {
          const value = params.row.containerNoSealNo || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "freightAmount",
        headerName: "Freight Amount",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row.freightAmount || "N/A"
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "totalCBM ",
        headerName: "Total CBM",
        width: 200,
        sortable: false,
        disableExport: true,
        valueGetter:params=>params.row.totalCBM,
        renderCell: params => {
          const value = params.row.totalCBM || "N/A"
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "totalCtn",
        headerName: "Total CTN",
        width: 200,
        sortable: false,
        disableExport: true,
        valueGetter:params=>params.row.totalCtn,
        renderCell: params => {
          const value = params.row.totalCtn|| "N/A"
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "totalWeight",
        headerName: "Total Weight",
        width: 200,
        sortable: false,
        disableExport: true,
        valueGetter:params=>params.row.totalWeight,
        renderCell: params => {
          const value = params.row.totalWeight|| "N/A"
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "typeOfGoods",
        headerName: "Type of Goods",
        width: 200,
        sortable: false,
        disableExport: true,
        valueGetter:params=>params.row.typeOfGoods,
        renderCell: params => {
          const value = params.row.typeOfGoods|| "N/A"
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "warehouse",
        headerName: "Warehouse Name",
        width: 200,
        sortable: false,
        disableExport: true,
        valueGetter:params=>params.row.warehouse,
        renderCell: params => {
          const value = params.row.warehouse|| "N/A"
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
    ];
    result.push({
      field:"  ",
      headerName: 'Actions',
      headerAlign: 'center',
      editable: false,
      minWidth:250,
      disableColumnMenu:true,
      disableExport:true,
      disableReorder:true,
      hideSortIcons:true,
      renderCell: (params) => {
        return (
          // <PinCodeChangeButtonContainer>
          <div style={{margin: '0 auto', width:'auto'}}>
          <Tooltip title="Click to view SOs in Container">
          <IconButton color="primary" onClick={()=>setModalData(params.row)}>
           <RemoveRedEyeIcon/>
          </IconButton>
          </Tooltip>
          {(props.user.role==="Super Admin"||props.user.role==="SCM Sr Manager")&& 
          <Tooltip title="Click to Edit Container">
          <Link to={`/containerplanning/${params.row.id}`}>
          <IconButton color="primary" onClick={()=>setLocalStorage(params.row)}>
           <EditIcon/>
          </IconButton>
          </Link>
          </Tooltip>}
          {(props.user.role==="Super Admin"||props.user.role==="SCM Sr Manager")&& 
          <IconButton color="primary" onClick={()=>setDeleteData(params.row)}>
           <DeleteIcon/>
          </IconButton>}
        
                     
              </div>
  
          // </PinCodeChangeButtonContainer>
        );
      },
    })
  

    return result
  }

const setDeleteData=(val)=>{
// console.log(val);
let temp={soId:val.soId,containerId:val.id}
// console.log(temp);
window.confirmationModal(
  `Are you sure you want to deactivate this Container 



   (${temp.containerId})?`,
  () => onDelete(temp)
);
}

const onDelete=async(temp) => {
  try{setLoading(true)
    let results = await window.Platform.database.deletePlanContainer(temp);
setLoading(false);
fetchContainer()

  }
  catch(err){
    console.log(err);
    setLoading(false)

  }

}


  const setLocalStorage=(params)=>{
    // console.log('Paramsss', params)
    localStorage.setItem('editTime',params.deliveryDate)
    localStorage.setItem('wid',params.wid)
    localStorage.setItem('typeShip',params.typeOfShipment)
    localStorage.setItem('phase',"edit")

  }
  const setModalData=(params)=>{
    setItemModal(params)
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: selectedTab===1?"plannedContainer Air":"plannedContainer Sea",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleTabChange = async (value) => {
    // setShippingOrders([]);
    // console.log("Setting",value)
    setSelectedTab(value);
    // if(value === TABS.SEA){
      // setShippingOrders(SHIPPING_ORDERS)
    // }
    // else if(value === TABS.AIR)
    // {
    //   setShippingOrders(AIR_SHIPPING_ORDERS)
    // }
  }

  // const renderLoading = () => {
  //   return(
  //     <LoadingContainer>
  //       <CircularProgress color='primary'/>
  //     </LoadingContainer>
  //   )
  // }

  const renderTabContent = () => {
   
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={selectedTab===1?shippingOrdersAir:shippingOrdersSea||[]}
          columns={getInventoryColumns()}
          rowHeight={70}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const renderModals = () => {
    return <>
      {showItemsModal && <InventoryItemsModal
        onClose={() => setShowItemsModal(null)}
        items={showItemsModal}
      />}
      {showPackagingListModal && <PackagingListModal
        onClose={() => setShowPackagingListModal(null)}
        list={showPackagingListModal}
      />}
      {itemModal && <ViewPlannedSOModal temp={itemModal} onClose={()=>setItemModal(null)}/>}
    </>
  }

    // const handleToggleChange1=(e,value)=> {
    // //  setShippingOrders([])
    //   console.log("value",modeTransport)
    //   setModeTransport(value)}

    // console.log("valueDATA",modeTransport)


  return (
    <>
      {isMobile && (
        <Drawer props = {props}/>
      )}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Stack spacing={1} direction={"row"}>
          <Typography variant="h5">Container Planning</Typography>
         

          </Stack>
           <CustomToggle
        selected={statusInventory}
        optionOne={{ value: true, label: "Calender" }}
        optionTwo={{ value: false, label: "List" }}
        onChange={(e,value)=> {
          if(value===null){
            return;
          }
          else{
          // console.log("valueTesting",value)
          setStatusInventory(value)}
      }}
      />
    
 {/* <Stack direction="row" spacing={1} alignItems="center">
<Typography>List</Typography>
<Tooltip title="Toggle to change view">
<Switch   checked={statusInventory}
onChange={(e,value)=> {
    console.log("value",statusInventory)
    setStatusInventory(value)
}}
/></Tooltip>
<Typography>Calender</Typography>
</Stack> */}

        </HeaderContainer>
        {loading&&<OpaqueLoading/>}

{statusInventory ?
            <Calendar props = {modeTransport} tempAir={shippingOrdersAir} tempSea={shippingOrdersSea}/> :
            <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
              >
              <Tab label="Sea Inventories" />
              <Tab label="Air Inventories" />
            </StyledTabs> 
            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
            }
        {renderModals()}
      </StaffEditorPageContainer>

    </>
  );
}
