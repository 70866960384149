import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ItemModal = ({ onCancel, initialData, loading, onSubmit }) => {

  const [form, setForm] = useState({});
  const [itemAlready, setItemAlready] = useState([]);

  const [checkExisting, setCheckExisting] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);

 

  const changeHandler = (fieldName, value) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const submitHandler = () => {

    if(!form.category || !form.itemName){
      window.NotificationUtils.showError('Enter all details')
      return;
    }
   
    onSubmit({ ...form, supplierId: initialData.supplierId,supplierName: initialData.supplierName });
  };

  const updateSupplier = () => {
    setCheckExisting(!checkExisting);
    setForm({});
  };
  const fetchItemsVal = async (val) => {
    try {
      setApiLoading(true);

      let result = await window.Platform.database.getAllItems(val);
      setItemAlready(result);
      return;
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
    } finally {
      setApiLoading(false);
    }
    // console.log(results)
  };
  const options = [
    { value: "Regular", label: "Regular" },
    { value: "Managable", label: "Managable" },
    { value: "Special", label: "Special" },
  ];
  useEffect(() => {
    fetchItemsVal(initialData);
  }, []);

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="xs"
      fullWidth={true}
      open={true}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add New Item</Typography>

          <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {/* <Typography variant="h10">Supplier Name: {initialData.supplierName}</Typography> */}
        <ModalHeaderContainer>
          <Typography variant="h10">
            Supplier Name: {initialData?.supplierName}
          </Typography>
          {/* <Button variant="contained" size="small" onClick={updateSupplier}>{!checkExisting ? "Add New Item" : "Add From Existing"}</Button> */}
        </ModalHeaderContainer>
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}

        {/* {loading && <OpaqueLoading />} */}

        {/* {!checkExisting &&
          <>
            <Stack gap="20px" style={{ padding: "10px" }}>

              <Stack>

                <Autocomplete
                  // size="small"
                  options={itemAlready}
                  // style={{ width: !isMobile ? "200px" : "90%" }}
                  // defaultValue={null}
                  // value={categoryValue || ""}
                  // disabled={supplierChoosed}
                  onChange={(e, value) =>
                    changeHandler("items", itemAlready.filter((val) => val.value == value?.value)[0])
                  }
                  noOptionsText={apiLoading ? 'Loading...' : 'No option'}
                  // onChange={(e,value) => {
                  // changeHandlerForItem("category", value?.value);
                  // setCategoryValue(value?.value)
                  // }}
                  // style={{ width: !isMobile ? "400px" : "90%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Existing Items"
                      variant="outlined"
                      required
                    // defaultValue={null}
                    // style={{ width: !isMobile ? "200px" : "100%" }}
                    // inputRef={fields[3]}
                    // onKeyUp={(e) => {
                    //   if (e.key === "Enter") {
                    //     if(e.target.value)
                    //       {
                    //     setCategoryValue(e.target.value)
                    //     e.preventDefault();
                    //     console.log(e.target.value);
                    //     changeHandlerForItem("category", e.target.value);
                    //     fields[4].current.focus();
                    //       }
                    //   }
                    // }}
                    />
                  )}
                />
              </Stack>

              <Stack
                direction="row"
                justifyContent="flex-end"
                gap="10px"
                align-items="center"
              >

                <Button variant="contained" onClick={submitHandler} >Link this Item</Button>
              </Stack>
            </Stack>

          </>
        } */}
        {checkExisting && (
          <Stack gap="20px" style={{ padding: "10px" }}>
            <Stack>
              <TextField
              required
                variant="outlined"
                label="Item Name"
                // defaultValue={form["name"]}
                onChange={(e) => changeHandler("itemName", e.target.value)}
              />
            </Stack>
            <Stack>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="cityLabel">Category</InputLabel>
                <Select
                required
                  // variant="contained"
                  label="Category"
                  // defaultValue={params.value}
                  onChange={(e, value) => {
                    changeHandler("category", value?.props.value);
                  }}
                >
                  <MenuItem value="Regular">Regular</MenuItem>
                  <MenuItem value="Managable">Managable</MenuItem>
                  <MenuItem value="Special">Special</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap="10px"
              align-items="center"
            >
              <Button color="error" onClick={onCancel}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                Create
              </Button>
            </Stack>
          </Stack>
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ItemModal;
