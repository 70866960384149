import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid"; // Assuming the library import has changed
import moment from "moment";
import styled from "styled-components";
import {
  BULK_DELETE_FAILURE_CODES,
  DATE_FORMATS,
  ROLES,
} from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { MuiPickersUtilsProvider } from "@mui/lab"; // Updated import for date picker utils
import MomentUtils from "@mui/lab/AdapterMoment"; // Updated import for date picker adapter

// The rest of your code...

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 70vh;
  .payrollGrid
    .MuiDataGrid-row
    .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    outline: none;
  }
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const BulkApprove = ({ onClose, data, onApprove, onReject }) => {
  // console.log(`Approve`, data);
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [fosUsers, setFosUsers] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [reasonObj, setReasonObj] = useState({});

  useEffect(() => {
    setFosUsers(data);
  }, []);

  const handleChange = (name, value, id) => {
    setReasonObj((prev) => {
      let reasonObj = prev[id] ? prev[id] : {};

      return {
        ...prev,

        [id]: {
          ...reasonObj,

          [name]: value,
        },
      };
    });
  };

  const getReason = (id) => {
    if (reasonObj[id]) {
      return reasonObj[id]?.reason || "";
    }

    return "";
  };

  const dataRowCheck = (rowDetails) => {
    try {
      if (!rowDetails.reason) {
        return BULK_DELETE_FAILURE_CODES.MISSING_REASON;
      }
      // else if (!rowDetails.terminationDate) {
      //   return BULK_DELETE_FAILURE_CODES.MISSING_TERMINATION_DATE;
      // }
      return false;
    } catch (error) {
      throw error;
    }
  };

  

  const bulkReject = async () => {
    try {
      // check for errors

      let users = [...fosUsers];
      let usersToBeDeleted = [];
      if (selectedRowIds.length > 0) {
        // take those users from the users array for deletion
        selectedRowIds.map((id) => {
          let index = users.findIndex((member) => member.id === id);

          if (index !== -1) {
            users[index]["reason"] = reasonObj[id]?.reason || "N/A";
            // users[index]['terminationDate'] = reasonObj[id]?.terminationDate || moment(new Date()).startOf("day").unix();
          } else {
            users[index]["reason"] = "N/A";
            // users[index]['terminationDate'] = "";
          }
          let indexdata = users[index];
          // console.log("indexiii", indexdata);
          let sent_data = {
            id: indexdata.id,
            remark: indexdata.reason,
          };
          usersToBeDeleted.push(sent_data);
          // console.log("usersToBeDeleted", usersToBeDeleted);
        });
      }
      // console.log("usersToBeDeleted1", usersToBeDeleted);
      let newRows = [...users];

      let i = 0;
      // while (i !== usersToBeDeleted.length) {

      //   let hasError = dataRowCheck(usersToBeDeleted[i]);
      //   if (hasError) {
      //     let index = newRows.findIndex((row) => row.id === usersToBeDeleted[i].id);
      //     if (index !== -1) {
      //       newRows[index] = {
      //         ...newRows[index],
      //         deleteStatus: "failure",
      //         failureReason: hasError,
      //       };
      //     }
      //     usersToBeDeleted.splice(i, 1);
      //   } else if (usersToBeDeleted[i].deleteStatus === 'success') {
      //     usersToBeDeleted.splice(i, 1);
      //   }
      //   else {
      //     i++;
      //   }
      // }
      // setFosUsers(newRows);
      // console.log("usersToBeDeleted", usersToBeDeleted);
      setLoading(true);
      let response = await window.Platform.database.bulkReject(
        usersToBeDeleted
      );
      // console.log("RESPONSES", response);

      let newFosRows = [...newRows];
      for (let responseEntry of response) {
        let rowIndex = newFosRows.findIndex(
          (row) => row.id === responseEntry.id
        );
        if (rowIndex !== -1) {
          newFosRows[rowIndex].deleteStatus = responseEntry.deleteStatus;
          newFosRows[rowIndex].failureReason = "";
          if (responseEntry.deleteStatus === "failure") {
            newFosRows[rowIndex].failureReason = responseEntry.failureReason;
          }
        }
      }

      if (!deleted) {
        setDeleted(true);
      }

      setFosUsers(newFosRows);
      removeDeletedEmployees();
      setLoading(false);
      // window.NotificationUtils.showSuccess("Successfully deleted employee");
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Something went wrong");
      setLoading(false);
    }
  };
  const bulkApprove = async () => {
    try {
      // check for errors

      let users = [...fosUsers];
      let usersToBeDeleted = [];
      if (selectedRowIds.length > 0) {
        // take those users from the users array for deletion
        selectedRowIds.map((id) => {
          let index = users.findIndex((member) => member.id === id);

          if (index !== -1) {
            users[index]["reason"] = reasonObj[id]?.reason || "N/A";
            // users[index]['terminationDate'] = reasonObj[id]?.terminationDate || moment(new Date()).startOf("day").unix();
          } else {
            users[index]["reason"] = "N/A";
            // users[index]['terminationDate'] = "";
          }
          let indexdata = users[index];
          // console.log("indexiii", indexdata);
          let sent_data = {
            id: indexdata.id,
            remark: indexdata.reason,
          };
          usersToBeDeleted.push(sent_data);
          // console.log("usersToBeDeleted", usersToBeDeleted);
        });
      }
      // console.log("usersToBeDeleted1", usersToBeDeleted);
      let newRows = [...users];

      let i = 0;
      // while (i !== usersToBeDeleted.length) {

      //   let hasError = dataRowCheck(usersToBeDeleted[i]);
      //   if (hasError) {
      //     let index = newRows.findIndex((row) => row.id === usersToBeDeleted[i].id);
      //     if (index !== -1) {
      //       newRows[index] = {
      //         ...newRows[index],
      //         deleteStatus: "failure",
      //         failureReason: hasError,
      //       };
      //     }
      //     usersToBeDeleted.splice(i, 1);
      //   } else if (usersToBeDeleted[i].deleteStatus === 'success') {
      //     usersToBeDeleted.splice(i, 1);
      //   }
      //   else {
      //     i++;
      //   }
      // }
      // setFosUsers(newRows);
      // console.log("usersToBeDeleted", usersToBeDeleted);
      setLoading(true);
      let response = await window.Platform.database.bulkApproval(
        usersToBeDeleted
      );
      // console.log("RESPONSES", response);

      let newFosRows = [...newRows];
      for (let responseEntry of response) {
        let rowIndex = newFosRows.findIndex(
          (row) => row.id === responseEntry.id
        );
        if (rowIndex !== -1) {
          newFosRows[rowIndex].deleteStatus = responseEntry.deleteStatus;
          newFosRows[rowIndex].failureReason = "";
          if (responseEntry.deleteStatus === "failure") {
            newFosRows[rowIndex].failureReason = responseEntry.failureReason;
          }
        }
      }

      if (!deleted) {
        setDeleted(true);
      }

      setFosUsers(newFosRows);
      removeDeletedEmployees();
      setLoading(false);
      // window.NotificationUtils.showSuccess("Successfully deleted employee");
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Something went wrong");
      setLoading(false);
    }
  };

  const getRowClassName = (params) => {
    let deleteStatus = params.row.deleteStatus || "";
    if (deleteStatus === "success") {
      return "successRow";
    } else if (deleteStatus === "failure") {
      return "failureRow";
    } else {
      return "";
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "fos-report",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const removeDeletedEmployees = () => {
    let employeeToBeRemoved = [...fosUsers];
    // let allEmployees = [...props.visibleEmployees];

    employeeToBeRemoved.map((employee) => {
      if (employee.deleteStatus === "success") {
        // let index = allEmployees.findIndex(member => member.id === employee.id);
        // if(index !== -1){
        //   // allEmployees.splice(index, 1);
        // }
      }
    });

    // props.setVisibleEmployees(allEmployees);
  };

  const dateErrorUpdate = (message) => {
    // if(message){
    //   if(!this.dateError){
    //     this.dateError = true;
    //   }
    // }
    // else{
    //   if(this.dateError){
    //     this.dateError = false;
    //   }
    // }
  };

  const updateDateValue = (value, stringValue = "", id) => {
    if (value && value._isAMomentObject) {
      let obj = { ...reasonObj };
      obj[id] = {
        ...obj[id],
        terminationDate: value.startOf("day").unix(),
        terminationDateString: stringValue,
      };
      setReasonObj(obj);
    }
  };

  const getTerminationDate = (id) => {
    let obj = { ...reasonObj };
    if (obj[id]?.terminationDate) {
      return obj[id]["terminationDate"];
    }
    return moment(new Date()).startOf("day").unix();
  };

  const getTerminationDateString = (id) => {
    let obj = { ...reasonObj };
    if (obj[id]?.terminationDate) {
      return obj[id]["terminationDateString"];
    }
    return null;
  };

  const getGridColumns = () => {
    let result = [
      {
        field: "id",
        headerName: "Quotation ID",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "customerId",
        headerName: "Customer Code",
        sortable: false,
        width: 200,
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        sortable: false,
        width: 200,
      },
      {
        field: "supplierName",
        headerName: "Supplier Name",
        sortable: false,
        width: 200,
      },
      {
        field: "reason",

        headerName: "Remark",

        minWidth: 200,

        flex: 0.35,

        renderCell: (params) => {
          return params.row?.deleteStatus === "success" ? (
            <Tooltip title={params.row.reason}>
              <Typography variant="inherit">{params.row.reason}</Typography>
            </Tooltip>
          ) : (
            <TextField
              // style={{ height: "50px" }}
              // inputProps={{ style: { height: "50px" } }}
              onKeyDown={(event) => {
                // console.log("Key down: ", event.key);
                event.stopPropagation();
              }}
              onKeyUp={(event) => {
                // console.log("Key up: ", event.key);
              }}
              onKeyPress={(event) => {
                // console.log("Key press: ", event.key);
              }}
              fullWidth={true}
              label="Enter Remark"
              required
              type="text"
              size="medium"
              variant="outlined"
              value={getReason(params.row.id) || ""}
              onChange={(e) => {
                // console.log(e.target.value)
                handleChange(
                  "reason",
                  e.target.value.toString("") || "",
                  params.row.id
                );
              }}
            />
          );
        },

        editable: false,
      },
      {
        field: "deliveryDate",
        headerName: "Expected Delivery Date",
        sortable: false,
        width: 200,
        valueGetter: (params) =>
          moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT),
      },
      {
        field: "deliveryPlace",
        headerName: "Delivery Place",
        sortable: false,
        width: 200,
      },
      {
        field: "warehouseName",
        headerName: "Initial Warehouse Destination",
        sortable: false,
        width: 200,
      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        sortable: false,
        width: 200,
      },
      {
        field: "totalWeight",
        headerName: "Total Weight",
        sortable: false,
        width: 200,
      },
      {
        field: "totalCbm",
        headerName: "Total CBM",
        sortable: false,
        width: 200,
      },
      {
        field: "typeOfShipment",
        headerName: "Type of shipment",
        sortable: false,
        width: 200,
      },
    ];
    if (deleted) {
      result.unshift(
        {
          field: "deleteStatus",

          headerName: "Status",

          width: 100,

          renderCell: (params) => {
            let name = "";

            if (!params.row.deleteStatus) {
              name = "N/A";
            } else {
              name =
                params.row.deleteStatus === "failure" ? "failure" : "Success";
            }

            return (
              <Tooltip title={name}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },

          editable: false,
        },
        {
          field: "failureReason",

          headerName: "Failure Reason",

          width: 200,

          renderCell: (params) => {
            let name = params.row.failureReason || "N/A";

            return (
              <Tooltip title={name}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },

          editable: false,
        }
      );
    }
    return result;
  };

  const renderModalContent = () => {
    return (
      <>
        <>
          <DataGridContainer>
            <DataGrid
              className="payrollGrid"
              disableSelectionOnClick={false}
              disableRowSelectionOnClick
              isRowSelectable={(params) => params.row.deleteStatus === 'success' ? false : true}

              rows={fosUsers}
              columns={getGridColumns()}
              checkboxSelection={true}
              onRowSelectionModelChange={(selectedValues) => {
                // console.log("sss", selectedValues);
                setSelectedRowIds(selectedValues);
              }}
              getRowClassName={getRowClassName}
              autoPageSize
              // disableClickEventBubbling={false}
              // components={{
              //   Toolbar: CustomToolbar,
              // }}
              density="comfortable"
            />
          </DataGridContainer>
        </>

        <ModalActionsContainer>
          <FirstButton
            disabled={loading || selectedRowIds.length === 0}
            // onClick={onClose}
            onClick={bulkReject}
            variant="contained"
            color="primary"
          >
            Reject
          </FirstButton>
          <Button
          onClick={bulkApprove}
            disabled={loading || selectedRowIds.length === 0}
            variant="contained"
            color="primary"
          >
            Approve
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="lg"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Bulk Approve/Reject</Typography>
          <IconButton disabled={loading} onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkApprove;
