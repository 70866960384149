import React, { useState, useRef } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
// import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    Button,
    Tooltip,
  } from "@mui/material";
// import { BULK_UPLOAD_FAILURE_CODES } from "../../constants";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom:0px;
  }
`;


const BulkItemAdd = ({onClose,fetchdata,id}) => {
    // console.log('BulkItemAdd',id)
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [targetRows, setTargetRows] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const hiddenFileInput = useRef(null);
    const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const fileInputRef = useRef(null);
  const [failedData, setFailedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileKey, setFileKey] = useState(0);
  const [display, setDisplay] = useState(false);
  const [isloader, setIsloader] = React.useState(false);
  const [dataId, setDataId] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);


  const getRowClassName = (params) => {
    let uploadStatus = params.row.status || "";
    if (uploadStatus === "Success") {
      return "successRow";
    } else if (uploadStatus === "Failure") {
      return "failureRow";
    } else {
      return "";
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport 
          csvOptions={{
            fileName: 'bulk-upload-Items',
          }}
        />
      </GridToolbarContainer>
    );
  }

  // const getEmployeeName = (
  //   employeeCode,
  //   reportingManagerStringNeeded = false
  // ) => {
  //   let result = "Admin";
  //   let employee = props.staffMembers.find(
  //     (member) => member.employeeCode === employeeCode
  //   );
  //   if (reportingManagerStringNeeded && employee) {
  //     employee = props.staffMembers.find(
  //       (member) => member.employeeCode === employee.reportingManager
  //     );
  //   }
  //   if (employee) {
  //     let name = `${employee.firstName} ${
  //       employee.lastName ? employee.lastName : ""
  //     }`;
  //     if (!reportingManagerStringNeeded) {
  //       result = name;
  //     } else {
  //       result = `${name}-${employee.employeeCode} (${employee.role})`;
  //     }
  //   }
  //   return result;
  // };
    const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // setView(true);
    // setFailedData([]);
    // setSearchTerm("");
    // setData([]);
    // setDisableButtons(true);
    setUploaded(false);
    handleImport(file);
    setFileKey((prevKey) => prevKey + 1);
  };
    const handleImport = (file) => {
      try {
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const formattedData = jsonData.slice(1).map((row,index) => ({
          name: row[1],
          category: row[2],
          id:index+1,
          supplierId:id
          
        }));

        setTargetRows(formattedData)
        setLoading(false);
        // setDisableButtons(false);
      };
      reader.readAsArrayBuffer(file);
    }}
    catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retreiving targets data");
      setLoading(false);
    }
  };




  const getGridColumns = () => {
      let columns = [
        {
            field: "id",
            headerName: "S.no",
            minWidth: 100,
            valueGetter: (params) => {
              let name = params.row.id || "";
              return name;
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              let name = params.row.id || "";
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "name",
            headerName: "Name",
            minWidth: 150,
            valueGetter: (params) => {
             let name=params.row.name
             return name
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
              // console.log("RESPONSE",params)
              let name=params.row.name
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
          },
          {
            field: "category",
            headerName: "Category",
            minWidth: 200,
            valueGetter: (params) => {
              let name = params.row.category;
              return name;
            },
            flex: 0.22,
            editable: false,
            renderCell: (params) => {
                let name=params.row.category
                return (
                  <Tooltip title={name}>
                    <Typography variant="inherit">{name}</Typography>
                  </Tooltip>
                );
            },
          },
          
         
      ];
      if (uploaded) {
        columns.splice(
          0,
          0,
          {
            field: "status",
            headerName: "Upload Status",
            width: 100,
            renderCell: (params) => {
              let name = "";
              if (params.row.status) {
                name =
                  params.row.status === "Success" ? "Success" : "Failure";
              }
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
            editable: false,
          },
          {
            field: "reason",
            headerName: "Reason",
            width: 200,
            renderCell: (params) => {
              let name = params.row.reason || "";
              return (
                <Tooltip title={name}>
                  <Typography variant="inherit">{name}</Typography>
                </Tooltip>
              );
            },
            editable: false,
          }
        );
      }
      return columns;
  }


    const handleRowSelection = (selection) => {
    // console.log("SELECT",selection)
    let array=[]
    let data=targetRows.filter((row) => selection.includes(row.id));
    let finalData=data.map((row) => {
      let sent={
        name:row.name,category:row.category,id:row.id,supplierId:row.supplierId
      }
       array.push(sent)
    })
  
    // console.log("selection1",finalData)
   return array
  };

  const onClickUpload = async () => {
    try {
    let data=handleRowSelection(selectedRowIds)
    // console.log("onClickUpload",data)

    let finalData =[...targetRows]
    // console.log("SELECTED ROWS",selectedRows);
      setLoading(true);
      let response = await window.Platform.database.bulkAddItem(data);
      // console.log("SUCCESS",response)
    //   setTargetRows(response.success)
       
    //   let newTargetRows = [...newRows];
    // console.log("FINAL",finalData)
    setSelectedRowIds([])
      for(let responseEntry of response.success){
        let rowIndex = finalData.findIndex(row => row.id === responseEntry.id);
        // console.log("ROWINDEX",rowIndex)
        if(rowIndex !== -1){
            finalData[rowIndex].status = responseEntry.status;
            finalData[rowIndex].reason = 'N/A';
            // console.log("CHECK!")
          if(responseEntry.status === 'Failure'){
            finalData[rowIndex].reason = responseEntry.reason;
          }
        }
      }
      setTargetRows(finalData);

      if (!uploaded) {
        setUploaded(true);
      }
      setLoading(false);
      window.NotificationUtils.showSuccess('Details Updated succesfully')

      // window.NotificationUtils.showSuccess("Details Uploaded Please verify your upload");
      fetchdata()
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error in Uploading");
      setLoading(false);
    }
  };

  const renderModalContent = () => {
    return (
      <>
        {targetRows.length > 0 && (
          <>
            <DataGridContainer>
              <DataGrid
                disableSelectionOnClick={true}
                checkboxSelection={true}
                rows={targetRows}
                columns={getGridColumns()}
                // onRowSelectionModelChange={handleRowSelection}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowIds(newRowSelectionModel);
                }}
        
                rowSelectionModel={selectedRowIds}
                density="compact"
                autoPageSize
                getRowClassName={getRowClassName}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </DataGridContainer>
            <NoteText variant="body2">
              Note: If selected, only the selected rows will be uploaded
            </NoteText>
          </>
        )}
        <InputFieldContainer>
          <a target="_blank" href="/assets/BulkItemAddSample.xlsx" download><Button variant="contained" color="primary">Download Template</Button></a>
          {/* <label htmlFor="filePickerButton">
            <Input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="filePickerButton"
              type="file"
              ref={hiddenFileInput}
              onChange={handleUpload}
            />
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              component="span"
            > */}
               <input
                    key={fileKey}
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <Button
                    // style={{ color: '#003974', borderColor: '#003974' }}
                    variant="contained"
                    sx={{
                      background: "primary",
                      borderColor: "#003974",
                      // borderRadius: "40px",
                      padding: "5px 10px",
                      marginRight: "20px",
                    }}
                    // startIcon={<Iconify icon="eva:attach-outline" />}
                    onClick={handleButtonClick}
                    // disabled={disableButtons}
                  >
                    Select File
                  </Button>
              {/* Select File
            </Button>
          </label> */}
        </InputFieldContainer>
        <ModalActionsContainer>
          <FirstButton
            disabled={loading}
            onClick={()=>onClose()}
            variant="contained"
            color="primary"
          >
            Cancel
          </FirstButton>
          <Button
            disabled={loading || selectedRowIds.length === 0}
            onClick={onClickUpload}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="md"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add Item Bulk</Typography>
          <IconButton disabled={loading} onClick={()=>onClose()}>
            {/* <CloseOutlinedIcon /> */}
          </IconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkItemAdd;

