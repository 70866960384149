import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"; // Update import to "@mui/material" for Material-UI v5
import styled from "styled-components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"; // Update import to "@mui/icons-material" for Material-UI v5
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
  position: relative;
  z-index: 10000;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  position: relative !important;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const CloseIconButton = styled(IconButton)`
  position: relative;
  z-index: 10000;
`;

const SelectControl = styled(FormControl)`
  .MuiInputLabel-root {
    top: -7px !important;
    left: 14px !important;
  }
`;

const AddDistrictModal = (props) => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [hasChange, setHasChange] = useState(false);

  const [state, setState] = useState(
    props.selectedDistrict.state ? props.selectedDistrict.state : {}
  );

  const [statesList, setStatesList] = useState([]);

  const [districtName, setDistrictName] = useState(
    props.selectedDistrict.id ? props.selectedDistrict.districtName : ""
  );
// console.log(props);
  const isNewDistrict = props.selectedDistrict.id ? false : true;

  const fetchStates = async() => {
    try {
      setLoading(true);
      let response = await window.Platform.database.fetchStatesModal();
      if (response && response.length > 0) {
        setStatesList(response);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching states");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    let change = false;
    if (isNewDistrict && (districtName && state)) {
      change = true;
    } else if (
      !isNewDistrict &&
      (districtName !== props.selectedDistrict.districtName ||
        state.id !== props.selectedDistrict.state.id)
    ) {
      change = true;
    }
    if (change !== hasChange) {
      setHasChange(change);
    }
  }, [districtName, state]);

  const checkErrors = async () => {
    if (!districtName) {
      window.NotificationUtils.showError("Please input a valid district name");
      return true;
    }
    if (!state.id) {
      window.NotificationUtils.showError("Please select a valid state");
      return true;
    }
    let districtNameAvailability =
      await window.Platform.database.checkDistrictNameAvailability(
        districtName,
        state.id
      );
    if (!districtNameAvailability) {
      window.NotificationUtils.showError("District name is already in use");
      return true;
    }
    return false;
  };

  const onClickSave = async () => {
    try {
      setLoading(true);
      let hasErrors = await checkErrors();
      if (hasErrors) {
        setLoading(false);
        return;
      }
      let response = await window.Platform.database.updateDistrict({
        ...props.selectedDistrict,
        districtName,
        state,
      });
      window.NotificationUtils.showSuccess("District updated successfully");
      props.closeModal(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error trying to save district");
      setLoading(false);
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <InputFieldContainer>
          <TextField
          required
            fullWidth={true}
            label="District Name"
            placeholder="Enter district name"
            variant="outlined"
            value={districtName}
            disabled={loading}
            onChange={(e) => setDistrictName(e.target.value.toString())}
          />
        </InputFieldContainer>
        <InputFieldContainer>
        <FormControl fullWidth variant="outlined" required>
  <InputLabel id="districtStateLabel">Select District State</InputLabel>
  <Select
  required
    labelId="districtStateLabel"
    id="districtStateSelect"
    value={state.id || ""}
    label="Select District State"
    onChange={(e) =>
      setState(
        statesList.find((state) => state.id === e.target.value)
      )
    }
  >
    {statesList.map((stateEntry) => (
      <MenuItem value={stateEntry.id} key={stateEntry.id}>
        {stateEntry.stateName}
      </MenuItem>
    ))}
  </Select>
</FormControl>
        </InputFieldContainer>
        <ModalActionsContainer>
          <FirstButton
            disabled={loading}
            onClick={() => props.closeModal()}
            variant="contained"
            color="primary"
          >
            Cancel
          </FirstButton>
          <Button
            disabled={loading || !hasChange}
            onClick={onClickSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog maxWidth="xs" fullWidth={true} open={showModal}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">
            {!isNewDistrict
              ? props.selectedDistrict.districtName || ""
              : "New District"}
          </Typography>
          <CloseIconButton
            disabled={loading}
            onClick={() => props.closeModal()}
          >
            <CloseOutlinedIcon />
          </CloseIconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default AddDistrictModal;
