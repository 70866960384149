import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  TextField,
  FormControl, // Import FormControl
  InputLabel, // Import InputLabel
  Select, // Import Select
  MenuItem, // Import MenuItem
  Autocomplete,
  Stack
} from "@mui/material"; // Update import to "@mui/material"
import styled from "styled-components";
// import OpaqueLoading from "../opaqueLoading/OpaqueLoading"; // Update component name to "OpaqueLoading" with capital letters
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"; // Update import to "@mui/icons-material"
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const isMobile = window.innerWidth < 900;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
  position: relative;
  z-index: 10000;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  position: relative !important;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const CloseIconButton = styled(IconButton)`
  position: relative;
  z-index: 10000;
`;

const SelectControl = styled(FormControl)`
  .MuiInputLabel-root {
    top: -7px !important;
    left: 14px !important;
  }
`;
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const SendToNextWarehouse = ({id,onClose,data,fetchData, items,selectedId}) => {
  // console.log("ONCLOSE",items);
  // console.log("ONCLOSE",selectedId)
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [hasChange, setHasChange] = useState(false);
  const [selectedCity,setSelectedCity] = useState();

  const [district, setDistrict] = useState(
    // props.selectedCity.district ? props.selectedCity.district : {}
  );

  const [disableTransfer, setDisableTransfer] = useState(true);
  const [warehouseData, setWarehouseData] = useState()

  const [districtsList, setDistrictsList] = useState([]);
  const [form,setForm]=useState({
    warehouseId:id,soId:data.id
  });

 const [note,setNote]=useState(false);
 const [currentDistrict,setCurrentDistrict]=useState();

  const changeHandler = (fieldName, value) => {
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const fetchDistricts = async() => {
    try {
      setLoading(true);
      let response = await window.Platform.database.fetchWarehouses();
    //  console.log("RESPONSE",response)
      if (response && response.length > 0) {
        const filteredDistricts = response.filter(
            (district) =>district.id!==id)
              
        
          // console.log("FETCHH",filteredDistricts);
          const filteredCurrentDistrict = response.filter(
            (district) =>district.id==id)
               setCurrentDistrict(filteredCurrentDistrict)
              //  console.log("FILTEREDCURRENTDISTRICT", filteredCurrentDistrict.warehouseCountry)
               if(filteredCurrentDistrict[0].warehouseCountry ==="India" ){
                const newFilteredDistrictIndia=filteredDistricts.filter((district)=>district.warehouseCountry ==="India")
                setDistrictsList(newFilteredDistrictIndia)
                // console.log("FILTEREDCURRENTDISTRICT", newFilteredDistrictIndia)
               }
               else{
               setDistrictsList(filteredDistricts);
               }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching Warehouse");
      setLoading(false);
    }
  };

  const arraysAreEqual=(arr1, arr2)=> {
    if (arr1.length !== arr2.length) {
      return false; 
    }
  
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
  
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false; 
      }
    }
  
    return true; 
  }

  const fetchWarehouse = async (id) => {
    // console.log(statusInventory, selectedTab)
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchWarehouse(id);
      setWarehouseData(results);
      // console.log("rrr", results);
      return results
      // setContainerPlanned(results.attributes.containerPlanned)
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWarehouse(id)
    fetchDistricts();
  }, []);

const onSubmit = async() => {
  try{
    if(!form.nextWarehouseId){
      // window.NotificationUtils.showError("Select Next Warehouse");
      return window.NotificationUtils.showError("Select Next Warehouse"); ;
    }
    else{
      // if(items.containerStatus === 'planned'&& selectedCity.warehouseCountry === 'India'){
      //    let slecetd=[
      //     "1MLOxOyFrT",
      //     "OYRNE6sjDL",
      //     "07OOCzuEdz"
      // ]
        // for( const result of selectedId ){ 
          setLoading(true);
          //  console.log("RESULTSSSOFFORLOOP", result)
         let temp={
          nextWarehouseId:form.nextWarehouseId,warehouseId:form.warehouseId,soId:form.soId
         }
        //  console.log("TEMPPP",temp)
          let response = await window.Platform.database.nextWarehouse(temp);
        
    //   }
    // }
    //   else{
    //   setLoading(true);
    // let response = await window.Platform.database.nextWarehouse(form);
    
    //   }
    // console.log("onSubmit",form)
    // console.log("onSubmit",response)
    // setLoading(false)
  }
  onClose()
  fetchData()
  window.NotificationUtils.showSuccess('Sent to Next Warehouse')
setLoading(false);
}catch(err){
// console.log("Error",err)
setLoading(false);
}}
// console.log("SELECTED City",selectedCity)
// console.log("FORM",form)
// console.log("CURRENTDISTRICT",currentDistrict)

  return (
    <StyledDialog maxWidth="xs" fullWidth={true} open={true} >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">
            Next Warehouse details
          </Typography>
          <CloseIconButton
            disabled={loading}
            onClick={()=>onClose()}
          >
            <CloseOutlinedIcon />
          </CloseIconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        <Stack direction={"column"} spacing={2} margin={2} mt={3}>
        <Autocomplete
              // value={cityVal.find((city) => city.id === deliveryCity) || ''}
            //   value={deliveryCity || null}
            clearOnBlur={false}
  disableClearable
              options={districtsList}
              getOptionLabel={(option) => `${option?.name} (${option?.warehouseCountry})`}
              getOptionValue={(option) => option?.id || ''}
              // style={{ width: isMobile ? "90%" : "70%",marginLeft:"45px" }}
              noOptionsText={loading ? "Loading..." : "No option"}
              onChange={async(event, value) => {
                const selectedCity = districtsList.find((city) => city?.id === value?.id);
                changeHandler("nextWarehouseId", selectedCity?.id || null);
                //  let warehouseDataa = fetchWarehouse(data)
                setSelectedCity(selectedCity)
                
                // console.log("selectedCity",warehouseData)
                
                if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                {
                    setDisableTransfer(false)
                }
                else
                setDisableTransfer(true)

                if((items.containerStatus === 'planned' && selectedCity.warehouseCountry === 'India') || selectedCity.warehouseCountry === 'China')
                {
                  // console.log("ssstatus",items.containerStatus)
                setDisableTransfer(false)
              
                }
                else{
                  if(items.containerStatus !== 'planned'){
                    window.NotificationUtils.showError("Please get the container planned before proceeding")
                  }
                setDisableTransfer(true)
                }

                // if(items.containerStatus === 'planned'&& selectedCity.warehouseCountry === 'India'){
                //   if(arraysAreEqual(selectedId, items.container.attributes.soId)===false && !currentDistrict.warehouseCountry==="India"){
                //     window.NotificationUtils.showError("SO(s) Missing for container")
                //     setDisableTransfer(true)
                //   }
                //   else{
                //     setDisableTransfer(false)
                //     setNote(true)
                //   }
                // }
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = districtsList.find((option) => option.name === value);

                if (!matchingOption ) {
                  // If there's no matching option, set the otherValue to null
                //   setDeliveryCity(null);
                return;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Next Warehouse Name"
                  variant="outlined"
                //   inputRef={fields[12]}
                  
                  required
                />
              )}
            />
           {note&& <NoteText variant="caption">Note: ({selectedId.length}) {selectedId.length>1?  'SO(s)': 'SO'} contained in container({items.container.id}) will be sent </NoteText>}
           <Stack
            // direction="row"
            // justifyContent="flex-end"
            gap="10px"
            alignItems={"center"}
            // textAlign={"center"}
           >

            <Button 
              disabled={!form.nextWarehouseId||loading||disableTransfer}
              // style={{paddingBottom :'10px'}}
              
              onClick={()=>onSubmit()} variant="contained" >Submit</Button>
              </Stack>
 </Stack>
      
        
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default SendToNextWarehouse;
