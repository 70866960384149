import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Fab, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { SaveAlt } from "@mui/icons-material";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import { DATE_FORMATS } from "../../constants";
import moment from "moment";
import SoModal from "../../components/modals/SoModal";
import QuotationAdmin from "../../components/modals/QuotationAdmin";
import ShowMultipleSoModal from "../../components/modals/showMultipleSoModal";
import TrackingModal from "../../components/modals/trackingModal";
import ViewListIcon from '@mui/icons-material/ViewList';
const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  overflow-x: ${isMobile ? "auto" : "hidden"}; /* Add horizontal scroll for mobile */
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;

export default function SoGeneration(props) {
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState([]);
  const [showItemsModal, setShowItemsModal] = useState();
  const [showTrackModal,setShowTrackModal]=useState();

  useEffect(() => {
    fetchPendingQuotations();
    // fetchSOById()
  }, []);

  const fetchPendingQuotations = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchTracking();
      setAccessChanges(results);
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    } finally {
      setLoading(false);
    }
  };
  // const fetchSOById = async () => {
  //   try {
  //     setLoading(true);
  //     let results = await window.Platform.database.fetchSoById({id:"Ufwvnddhkm"});
  //     console.log("RESPONSEFETCHSOBYID",results);
  //     // setData(results)
  //     // setAccessChanges(results);
  //   } catch (error) {
  //     window.NotificationUtils.showError("Something went wrong");
  //     console.log(error.message)
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getEmployeeColumns = () => {
    let result = [
      // {
      //   field: "id",
      //   headerName: "Tracking ID",
      //   sortable: false,
      //   width: 150,
      //   editable: false,
      // },
      // {
      //   field: "quotationId",
      //   headerName: "Quotation ID",
      //   sortable: false,
      //   width: 200,
      //   valueGetter: (params) => params.row.attributes?.quotation?.id ,
      // },
      {
        field: "soid",
        headerName: "SO ID",
        sortable: false,
        width: 200,
        valueGetter: (params) => params.row.attributes?.SO?.id,
      },
      // {
      //   field: "warehouseId",
      //   headerName: "Current Warehouse ID",
      //   sortable: false,
      //   width: 200,
      //   renderCell: params => {

      //     const value = params.row.attributes.warehouses.length 
      //     const returnValue = params.row.attributes.warehouses[value-1].id;

      //     return returnValue;
      //   }
      // },
   
      {
        field: "Packaginglist",
        headerName: "Packaging list",
        width: 200,
        headerAlign: "center", // Align the header text to the center
        align: "center",
        sortable: false,
        disableExport: true,
        renderCell: params => {

          const value = params.row.attributes?.quotation?.attributes?.packagingList || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title="View Packaging List"><IconButton
          disabled={!params.row.attributes?.quotation?.attributes?.packagingList}
          variant="extended" color="primary" onClick={(e) => {
            e.stopPropagation();
            openPackaging(value)
          }} >
            <RemoveRedEyeIcon/>&nbsp; 
            
          </IconButton></Tooltip> 
        }
      },
      {
        field: "viewitems",
        headerName: "Details",
        width: 200,
        headerAlign: "center", // Align the header text to the center
        align: "center",
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value =  params.row.attributes?.SO?.id
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title="View Order Details"><IconButton variant="extended" color="primary" onClick={(e) => {
            e.stopPropagation();
            setShowItemsModal(value)
          }} >
            <ViewListIcon/>&nbsp; 
           
          </IconButton>
          </Tooltip>
        }
      }
      ,
      {
        field: "track",
        headerName: "Track order",
        width: 200,
        headerAlign: "center", // Align the header text to the center
        align: "center",
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title="View Tracking details"> <IconButton variant="extended" color="primary" onClick={(e) => {
            e.stopPropagation();
            setShowTrackModal(value)
          }} >
            <QueryStatsIcon/>&nbsp;
           
          </IconButton>
          </Tooltip>
        }
      }
    ];

    return result;
  };

  const openPackaging = (val) => {
    window.open(val, '_blank');
  };


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "OrderTracking",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    // const handleRowClick = (params) => {
    //   const rowData = params.row;
    //   setShowItemsModal(rowData);
    // };

    const columns = getEmployeeColumns().map((column) => {
      if (column.field === "items") {
        return {
          ...column,
          renderCell: (params) => (
            <FabStyle
              variant="extended"
              color="primary"
              // onClick={() => handleRowClick(params)}
            >
              View Items
            </FabStyle>
          ),
        };
      }
      return column;
    });
    return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          className="payrollGrid"              
          checkboxSelection={true}
          rows={accessChanges || []}
          columns={columns}
          rowHeight={70}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
          // onRowClick={handleRowClick}
        />
      </Box>
    );
  };

  return (
    <>
      {isMobile && <Drawer props={props}/>}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Order Tracking</Typography>
        </HeaderContainer>
        <TableContainer>
          {loading && <OpaqueLoading />}
          {renderContent()}
        </TableContainer>
      </StaffEditorPageContainer>
      {showItemsModal && (
        <SoModal
          onClose={() => setShowItemsModal(null)}
          items={showItemsModal}
          mode={true}
          // onSubmit = {onClickApprove}
          loading={loading}
        />
      )}
      {showTrackModal && <TrackingModal role={props} items={showTrackModal} onCancel={()=>setShowTrackModal(false)}/>}
  
    </>
  );
}
