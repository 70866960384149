import React, { useState, useEffect } from "react";
import {
    Autocomplete,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid"; // Assuming the library import has changed
import moment from "moment";
import styled from "styled-components";
import {
  BULK_DELETE_FAILURE_CODES,
  DATE_FORMATS,
  ROLES,
} from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { MuiPickersUtilsProvider } from "@mui/lab"; // Updated import for date picker utils
import MomentUtils from "@mui/lab/AdapterMoment"; // Updated import for date picker adapter

// The rest of your code...

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
const isMobile = window.innerWidth < 900;

const ModalHeaderContainer = styled.div`
  display: flex;
  width:100%;
//   gap:50%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 65vh;
  .payrollGrid
    .MuiDataGrid-row
    .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    outline: none;
  }
`;

const ModalActionsContainer = styled.div`
  display: flex;
  
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const BulkTally = ({ onClose, data, fetchData }) => {
  // console.log(`Approve`, data);
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [fosUsers, setFosUsers] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [reasonObj, setReasonObj] = useState({});
  const [autocomplete,setAutocomplete] = useState();
  const [container,setContainer]=useState()
  const [selectedContainer, setSelectedContainer] = useState(null);

  useEffect(() => {
    setFosUsers(data);
}, []);
useEffect(()=>{
updateDataGrid(selectedContainer)
},[selectedContainer])

const updateDataGrid = (selectedContainer) => {
    if (!selectedContainer) {
      // If no container is selected, display all data
      setFosUsers(data);
    } else {
      // Filter the data based on the selected container
      const filteredData = data.filter(item => item.container?.id === selectedContainer.id);
      setFosUsers(filteredData);
    }
  };

  const removeDuplicates = () => {
    const filteredArray = fosUsers.filter(item => item.container !== undefined );
    // console.log("FILTEREARRAYTEST",filteredArray)
    const idAndNameArray = filteredArray.map(item => ({ id: item.container.id, name: item.container.attributes.containerName}));
    // console.log("FILTEREARRAYTEST1",idAndNameArray)


    // const idAndNameArray = dataArray.map(item => (if(){ id: item.containerid, name: item.name }));
    const uniqueData = idAndNameArray.reduce((accumulator, currentValue) => {
        // console.log("ACCUMLATOR",accumulator)
        // console.log("ACCUMLATOR1",currentValue)
      // Check if a similar entry (based on name and id) already exists in the accumulator
      const exists = accumulator.some(
        (item) => item.id === currentValue.id && item.name === currentValue.name
      );
  
      // If not, add it to the accumulator
      if (!exists) {
        accumulator.push(currentValue);
      }
  
      return accumulator;
    }, []);
  // console.log("ACCUMLATOR",uniqueData)
    return uniqueData;
  };
  useEffect(()=>{
    autoCompleteoptions()
  },[fosUsers])
  const autoCompleteoptions=()=>{
    const data=removeDuplicates()
    setAutocomplete(data)
}

//   const handleChange = (name, value, id) => {
//     setReasonObj((prev) => {
//       let reasonObj = prev[id] ? prev[id] : {};

//       return {
//         ...prev,

//         [id]: {
//           ...reasonObj,

//           [name]: value,
//         },
//       };
//     });
//   };

  const getReason = (id) => {
    if (reasonObj[id]) {
      return reasonObj[id]?.reason || "";
    }

    return "";
  };


  


  const bulkApprove = async () => {
    try {
      // check for errors

      let users = [...fosUsers];
      let usersToBeDeleted = [];
      if (selectedRowIds.length > 0) {
        // take those users from the users array for deletion
        selectedRowIds.map((id) => {
          let index = users.findIndex((member) => member.id === id);

        //   if (index !== -1) {
        //     users[index]["reason"] = reasonObj[id]?.reason || "N/A";
        //     // users[index]['terminationDate'] = reasonObj[id]?.terminationDate || moment(new Date()).startOf("day").unix();
        //   } else {
        //     users[index]["reason"] = "N/A";
        //     // users[index]['terminationDate'] = "";
        //   }
          let indexdata = users[index];
          // console.log("indexiii", indexdata);
          let sent_data = {
            id: indexdata.id,
          };
          usersToBeDeleted.push(indexdata.id);
          // console.log("usersToBeDeleted", usersToBeDeleted);
        });
      }
      // console.log("usersToBeDeleted1", usersToBeDeleted);
      let newRows = [...users];

      let i = 0;
      // while (i !== usersToBeDeleted.length) {

      //   let hasError = dataRowCheck(usersToBeDeleted[i]);
      //   if (hasError) {
      //     let index = newRows.findIndex((row) => row.id === usersToBeDeleted[i].id);
      //     if (index !== -1) {
      //       newRows[index] = {
      //         ...newRows[index],
      //         deleteStatus: "failure",
      //         failureReason: hasError,
      //       };
      //     }
      //     usersToBeDeleted.splice(i, 1);
      //   } else if (usersToBeDeleted[i].deleteStatus === 'success') {
      //     usersToBeDeleted.splice(i, 1);
      //   }
      //   else {
      //     i++;
      //   }
      // }
      // setFosUsers(newRows);
      // console.log("usersToBeDeleted", usersToBeDeleted);
      setLoading(true);
      let response = await window.Platform.database.BulkTallyUpdate(
        usersToBeDeleted
      );
      // console.log("RESPONSES", response);
    // let response=[]
     setSelectedRowIds([])
      let newFosRows = [...newRows];
      for (let responseEntry of response) {
        let rowIndex = newFosRows.findIndex(
          (row) => row.id === responseEntry.id
        );
        if (rowIndex !== -1) {
          newFosRows[rowIndex].deleteStatus = responseEntry.deleteStatus;
          newFosRows[rowIndex].failureReason = "";
          if (responseEntry.deleteStatus === "failure") {
            newFosRows[rowIndex].failureReason = responseEntry.failureReason;
          }
        }
      }

      if (!deleted) {
        setDeleted(true);
      }
      // closeModal()
    //   setFosUsers(newFosRows);
    //   removeDeletedEmployees();
      setLoading(false);
      window.NotificationUtils.showSuccess('Details Updated succesfully')
      // window.NotificationUtils.showSuccess("Successfully deleted employee");
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error While Recieving Please Wait and try again");
      setLoading(false);
    }
  };

  const getRowClassName = (params) => {
    let deleteStatus = params.row.deleteStatus || "";
    if (deleteStatus === "success") {
      return "successRow";
    } else if (deleteStatus === "failure") {
      return "failureRow";
    } else {
      return "";
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "bulk-tally",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const removeDeletedEmployees = () => {
    let employeeToBeRemoved = [...fosUsers];
    // let allEmployees = [...props.visibleEmployees];

    employeeToBeRemoved.map((employee) => {
      if (employee.deleteStatus === "success") {
        // let index = allEmployees.findIndex(member => member.id === employee.id);
        // if(index !== -1){
        //   // allEmployees.splice(index, 1);
        // }
      }
    });

    // props.setVisibleEmployees(allEmployees);
  };

  const closeModal=()=>{
    fetchData()
    onClose()
  }


  const getGridColumns = () => {
    let result = [
        {
            field: "id",
            headerName: "Quotation ID",
            sortable: false,
            width: 150,
            editable: false,
            valueGetter: (params) => params.row.id,
            
          },
          {
            field: "Costing Details Update",
            headerName: "Costing Details Update",
            width: 200,
            sortable: false,
            valueGetter: params => params.row?.costing? 'Updated' : 'Pending' || 'N/A',
            renderCell: params => {
              const value = params.row?.costing? 'Updated' : 'Pending' || 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
      
          },
          {
            field: "containerId",
            headerName: "Container Id",
            width: 200,
            sortable: false,
            valueGetter: params => params.row?.container?.id || 'N/A',
            renderCell: params => {
              // console.log(params.row?.container?.id)
              const value = params.row?.container?.id || 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }},
            {
              field: "containerName",
              headerName: "Container Name",
              width: 250,
              sortable: false,
              valueGetter: params => params.row?.container?.attributes?.containerName|| 'N/A',
              renderCell: params => {
                // console.log(params.row?.container?.id)
                const value = params.row?.container?.attributes?.containerName|| 'N/A'
                return <Tooltip title={value}>{value}</Tooltip>
              }},
          {
              field: "invoiceNumber",
              headerName: "Clearance Status",
              width: 200,
              sortable: false,
              valueGetter: params => {
                  const value =  params.row.invoiceNumber? 'Updated': 'Pending'
                  return value
              },
              renderCell: params => {
                // console.log(params.row.quotation.attributes.shippingOrders.parent.id)
                const value =  params.row.invoiceNumber? 'Updated': 'Pending'
                return <Tooltip title={value}>{value}</Tooltip>
              }
          },
        {
          field: "deliveryDate",
          headerName: "Expected Delivery Date(Customer)",
          width: 250,
          sortable: false,
          valueGetter: params => moment.unix(params.row.quotation.attributes.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A',
          renderCell: params => {
            const val=params.row.quotation.attributes.deliveryDate;
            // console.log("VALL",val);
            const value = moment.unix(val).format(DATE_FORMATS.DEFAULT) || 'N/A';
            // console.log("VALUE",params.row.quotation.attributes.deliveryDate);
            return <Tooltip title={value}>{value}</Tooltip>
          }
        },
      //   {
      //     field: "totalCbm",
      //     headerName: "Total CBM",
      //     width: 200,
      //     sortable: false,
      //     valueGetter: params => params.row.totalCbm || 'N/A',
      //     renderCell: params => {
      //       const value = params.row.totalCbm || 'N/A'
      //       return <Tooltip title={value}>{value}</Tooltip>
      //     }
      //   },
      //   {
      //     field: "totalWeight",
      //     headerName: "Total Weight",
      //     width: 200,
      //     sortable: false,
      //     valueGetter: params => params.row.totalWeight || 'N/A',
      //     renderCell: params => {
      //       const value = params.row.totalWeight || 'N/A'
      //       return <Tooltip title={value}>{value}</Tooltip>
      //     }
      //   },
      //   {
      //     field: "totalVolume",
      //     headerName: "totalVolume",
      //     width: 200,
      //     sortable: false,
      //     valueGetter: params => params.row.totalVolume || 'N/A',
      //     renderCell: params => {
      //       const value = params.row.totalVolume || 'N/A'
      //       return <Tooltip title={value}>{value}</Tooltip>
      //     }
      //   },
      //   {
      //     field: "totalAmount",
      //     headerName: "Total Amount",
      //     width: 200,
      //     sortable: false,
      //     valueGetter: params => params.row.totalAmount || 'N/A',
      //     renderCell: params => {
      //       const value = params.row.totalAmount || 'N/A'
      //       return <Tooltip title={value}>{value}</Tooltip>
      //     }
      //   },
      
        {
          field: "typeOfShipment",
          headerName: "Shipment Type",
          width: 200,
          sortable: false,
          valueGetter: params => params.row.quotation.attributes.typeOfShipment || 'N/A',
          renderCell: params => {
            const value = params.row.quotation.attributes.typeOfShipment || 'N/A'
            return <Tooltip title={value}>{value}</Tooltip>
          }
        },
          // {
          //   field: "packaginglist",
          //   headerName: "Packaging list",
          //   width: 200,
          //   headerAlign: "center", // Align the header text to the center
          //   align: "center",
          //   sortable: false,
          //   disableExport: true,
          //   valueGetter: (params) => params.row.quotation.attributes.packagingList,
    
          //   renderCell: params => {
          //     const value = params.row.quotation.attributes.packagingList || []
          //     // console.log(value)
          //     // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          //     return <IconButton variant="extended" color="primary" onClick={(e) => {
          //       e.stopPropagation();
          //     openPackaging(value);}} >
          //      <RemoveRedEyeRounded/>
          //     </IconButton>
          //   }
          // },
          {
            field: "invoiceNumber",
            headerName: "Invoice Number",
            sortable: false,
            width: 200,
            valueGetter: (params) => params.row.invoiceNumber || 'N/A',
    
          },
          {
            field: "pklStatus",
            headerName: "Pkl Status",
            sortable: false,
            width: 200,
            valueGetter: (params) => params.row.pklStatus? 'Updated' : 'Pending',
          },
    ];
    if (deleted) {
      result.unshift(
        {
          field: "deleteStatus",

          headerName: "Status",

          width: 100,

          renderCell: (params) => {
            let name = "";

            if (!params.row.deleteStatus) {
              name = "N/A";
            } else {
              name =
                params.row.deleteStatus === "failure" ? "failure" : "Success";
            }

            return (
              <Tooltip title={name}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },

          editable: false,
        },
        {
          field: "failureReason",

          headerName: "Failure Reason",

          width: 200,

          renderCell: (params) => {
            let name = params.row.failureReason || "N/A";

            return (
              <Tooltip title={name}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },

          editable: false,
        }
      );
    }
    return result;
  };

  const renderModalContent = () => {
    return (
      <>
        <>
          <DataGridContainer>
            <DataGrid
              className="payrollGrid"
              disableSelectionOnClick={false}
              disableRowSelectionOnClick
              rows={fosUsers}
              columns={getGridColumns()}
              checkboxSelection={true}
              onRowSelectionModelChange={(selectedValues) => {
                // console.log("sss", selectedValues);
                setSelectedRowIds(selectedValues);
              }}
              getRowClassName={getRowClassName}
              autoPageSize
              // disableClickEventBubbling={false}
              components={{
                Toolbar: CustomToolbar,
              }}
              density="comfortable"
            />
          </DataGridContainer>
        </>

        <ModalActionsContainer>
          <FirstButton
            disabled={loading || selectedRowIds.length === 0}
            // onClick={onClose}
            onClick={bulkApprove}
            variant="contained"
            color="primary"
          >
            Update
          </FirstButton>
          
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="lg"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Bulk Tally Update</Typography>
          
          <Autocomplete
              // value={cityVal.find((city) => city.id === deliveryCity) || ''}
            //   value={deliveryCity || null}
            clearOnBlur={false}
  disableClearable
              options={autocomplete}
              value={selectedContainer}
              getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
              style={{ width: isMobile ? "40%" : "30%",marginLeft:"40%"}}
              noOptionsText={loading ? "Loading..." : "No option"}
              onChange={async(event, value) => {
                setSelectedContainer(value);
                 updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = autocomplete.find((option) => option.name === value);

                if (!matchingOption ) {
                  // If there's no matching option, set the otherValue to null
                //   setDeliveryCity(null);
                setSelectedContainer(null);
                return;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter By Container"
                  variant="outlined"
                //   inputRef={fields[12]}
                  
                  required
                />
              )}
            />
          <IconButton disabled={loading} onClick={()=>closeModal()}>
            <CloseOutlinedIcon />
          </IconButton>
        
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkTally;
