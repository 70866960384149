import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, Tab, Tabs, CircularProgress, FormControl, MenuItem, Select, InputLabel, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DATE_FORMATS, ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";
import { Add, Done, Inventory, Save, SaveAlt, Send } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/WarehouseModal";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import PackagingListModal from "../../components/modals/packagingListModal";
import SendIcon from '@mui/icons-material/Send';
import { useParams } from "react-router-dom";
import SendToNextWarehouse from "../../components/modals/sendToNextWarehouse";
import moment from "moment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import BulkNextWarehouse from "../../components/modals/bulkDeliveryWarehouse";
import PreviewIcon from '@mui/icons-material/Preview';
import PlannedSoModal from "../../components/modals/plannedSoModal";

const isMobile = window.innerWidth < 900;

const SHIPPING_ORDERS = [
  {
    id: "1",
    soId: "abcd",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY, 
    shipmentType: SHIPMENT_TYPES.SEA
  }
]

const AIR_SHIPPING_ORDERS = [
  {
    id: "1",
    soId: "abcd",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY,
    shipmentType: SHIPMENT_TYPES.AIR
  }
]

const TABS = {
  SEA:0,
  AIR:1
}



const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 205px);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const TabsContainer = styled.div`
//   ${isMobile &&
//   `
//     height: calc(100vh - 56px - 20px);
//   `}
// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PendingDelivery(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [shippingOrders, setShippingOrders] = useState([]);
  const [showPackagingListModal, setShowPackagingListModal] = useState();
  const [showItemsModal, setShowItemsModal] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
const [statusInventory, setStatusInventory] = useState(10);
const [modal,setModal]=useState(false);
const [modalData,setModalData]=useState("")
const [onSentData, setOnSentData] = useState({})
const [selectedId,setSelctedId]=useState()
const [bulkData,setBulkData]=useState(false)
const [containerDetails,setContainerDetails] = useState();


// console.log("ID",id)
const onRecievedButton = (val) => {

}

const onSentButton = (val) => {
  let shippingOrderId=shippingOrders.map(val=>val.id)
  // console.log("SHIPPINGORDERFILTER",shippingOrderId)
  // setSelctedId(shippingOrderId)
//  const shippingOrderTest= [
//     "1MLOxOyFrT",
//     "OYRNE6sjDL",
//     "07OOCzuEdz"
// ]
if(val.container){
  const filteredArray2 = shippingOrderId.filter(item => val.container.attributes.soId.includes(item));
  // console.log("SHIPPINGORDERFILTER",filteredArray2)
  setSelctedId(filteredArray2)
}
  setModal(true);
  setModalData(val)
  // console.log("vvlll",val)
  setOnSentData(val)
}
// console.log("Sent",modalData);
useEffect(()=>{
  // console.log("id",id)
fetchData()


}, [statusInventory,selectedTab])

const fetchData = async()=> {

// console.log(statusInventory, selectedTab)
try{
  setLoading(true)
  let results = await window.Platform.database.fetchPendingDelivery(id)
  // console.log("DATA",results);

  if(selectedTab === 0)
  setShippingOrders(results)
  else if(selectedTab === 1)
  {
      // console.log("type",results[0].quotation.attributes.typeOfShipment)
      const filteredOrders = results.filter(order => order.quotation.attributes.typeOfShipment === 'Sea');
  setShippingOrders(filteredOrders)
  }
  else if(selectedTab === 2)
  {
      // console.log("type",results[0].quotation.attributes.typeOfShipment)
      const filteredOrders = results.filter(order => order.quotation.attributes.typeOfShipment === 'Air');

  setShippingOrders(filteredOrders)
  }
  setLoading(false)
 
}
catch(err){
    console.log(err)
     setLoading(false)
  }


}


// console.log("filteredOrders",shippingOrders)

const openPackaging = (val) => {
  window.open(val, '_blank');
};

  const getInventoryColumns = () => {
    let result
    if(statusInventory == 10)
    {
    
      result = [
      

        {
          field: "id",
          headerName: "SO Id",
          width: 200,
          sortable: false,
          valueGetter: params => params.row.id|| 'N/A',
          renderCell: params => {
            // console.log(params.row.id)
            const value =params.row.id|| 'N/A'
            return <Tooltip title={value}>{value}</Tooltip>
          }
        },
        {
          field: "containerId",
          headerName: "Container Id",
          width: 200,
          sortable: false,
          valueGetter: params => params.row?.container?.id || 'N/A',
          renderCell: params => {
            // console.log(params.row?.container?.id)
            const value = params?.row?.container?.id || 'N/A'
            return <Tooltip title={value}>{value}</Tooltip>
          }},
          {
            field: "containerName",
            headerName: "Container Name",
            width: 200,
            sortable: false,
            valueGetter: params => params.row?.container?.attributes?.containerName|| 'N/A',
            renderCell: params => {
              // console.log(params.row?.container?.id)
              const value = params.row?.container?.attributes?.containerName|| 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }},
        {
          field: "deliveryDate",
          headerName: "Expected Delivery Date(Customer)",
          width: 250,
          sortable: false,
          valueGetter: params => moment.unix(params.row.quotation.attributes.deliveryDate).format(DATE_FORMATS.DEFAULT)  || 'N/A',
          renderCell: params => {
            const value = moment.unix(params.row.quotation.attributes.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A'
            return <Tooltip title={value}>{value}</Tooltip>
          }
        },
      //   
        {
          field: "typeOfShipment",
          headerName: "Shipment Type",
          width: 200,
          sortable: false,
          valueGetter: params => params?.row?.quotation?.attributes?.typeOfShipment || 'N/A',
          renderCell: params => {
            // console.log("valueGetter",params.row)
            const value = params.row.quotation.attributes.typeOfShipment || 'N/A'
            return <Tooltip title={value}>{value}</Tooltip>
          }
        },
      {
        field: "items",
        headerName: "Items",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params?.row?.quotation?.attributes.items || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title="Click to view item details"><IconButton variant="extended" color="primary" onClick={() => setShowItemsModal(value)} >
            <ViewComfyAltIcon/>
          </IconButton></Tooltip>
        }
      },{
        field: "containerDetails",
        headerName: "Container Details",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params?.row?.container?.attributes || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title="Click to view container details"><IconButton variant="extended" disabled={!params.row.container} color="primary" onClick={() => setContainerDetails(value)} >
            <PreviewIcon/>
          </IconButton></Tooltip>
        }
      },
      {
        field: "packagingList",
        headerName: "Packaging List",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params?.row?.quotation?.attributes.packagingList || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title="Click to view packaging list"><IconButton variant="extended" color="primary" 
          disabled={!params?.row?.quotation?.attributes.packagingList}
          onClick={() => openPackaging(value)} >
            <VisibilityIcon/>
          </IconButton></Tooltip>
        }
      },

    ];
    result.push({
      field:"  ",
      headerName: 'Send To Next Warehouse',
      headerAlign: 'center',
      editable: false,
      minWidth:200,
      disableColumnMenu:true,
      disableExport:true,
      disableReorder:true,
      hideSortIcons:true,
      renderCell: (params) => {
        return (
          // <PinCodeChangeButtonContainer>
          <div style={{margin: '0 auto', width:'auto'}}>
  
          
          <Tooltip title="Click to generate">
  
            <IconButton  color="primary" 
               onClick={() => onSentButton(params.row)}
                   >
    <SendIcon  />

  </IconButton>
          </Tooltip>
                     
              </div>
  
          // </PinCodeChangeButtonContainer>
        );
      },
    },)
  }
  else{

  result = [
    {
      field: "soId",
      headerName: "SO Id",
      sortable: false,
      width: 150,

      editable: true,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.customer?.name || 'N/A',
      renderCell: params => {
        const value = params.row.customer?.name || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }

    },
    {
      field: "customerMobile",
      headerName: "Customer Mobile",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.customer?.mobile || 'N/A',
      renderCell: params => {
        const value = params.row.customer?.mobile || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "customerCity",
      headerName: "Customer City",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.customer?.city || 'N/A',
      renderCell: params => {
        const value = params.row.customer?.city || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "supplierName",
      headerName: "Supplier Name",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.supplier?.name || 'N/A',
      renderCell: params => {
        const value = params.row.supplier?.name || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "supplierWechatNumber",
      headerName: "Supplier Wechat Number",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.supplier?.wechatNumber || 'N/A',
      renderCell: params => {
        const value = params.row.supplier?.wechatNumber || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.supplier?.deliveryDate || 'N/A',
      renderCell: params => {
        const value = params.row.supplier?.deliveryDate || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.status || 'N/A',
      renderCell: params => {
        const value = params.row.status || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "shipmentType",
      headerName: "Shipment Type",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.shipmentType || 'N/A',
      renderCell: params => {
        const value = params.row.shipmentType || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "items",
      headerName: "Items",
      width: 200,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        const value = params.row.items || []
        // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
        return <FabStyle variant="extended" color="primary" onClick={() => setShowItemsModal(value)} >
          View Items
        </FabStyle>
      }
    },
    {
      field: "packagingList",
      headerName: "Packaging List",
      width: 200,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        const value = params.row.packagingList || []
        // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
        return <FabStyle variant="extended" color="primary" disabled={!params.row.packagingList} onClick={() => setShowPackagingListModal(value)} >
          View Packaging List
        </FabStyle>
      }
    },

  ];
  result.push({
    field:"  ",
    headerName: 'Actions',
    headerAlign: 'center',
    editable: false,
    minWidth:200,
    disableColumnMenu:true,
    disableExport:true,
    disableReorder:true,
    hideSortIcons:true,
    renderCell: (params) => {
      return (
        // <PinCodeChangeButtonContainer>
        <div style={{margin: '0 auto', width:'auto'}}>

        
        <Tooltip title="Click to generate">

          <FabStyle variant="extended" color="primary" 
           onClick={() => onSentButton(params)}
            //  onClick={() => {
               
            //    window.NotificationUtils.showSuccess(
            //      "Changes successfully made"
            //      );
            //     }}
                 >
  <Send sx={{ mr: 1 }} />
Send to next warehouse
</FabStyle>
        </Tooltip>
                   
            </div>

        // </PinCodeChangeButtonContainer>
      );
    },
  },)
}

    return result
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "pending Delivery",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleTabChange = async (value) => {
    // setShippingOrders([]);
    setSelectedTab(value);
    // if(value === TABS.SEA){
      // setShippingOrders()
    // }
    // else if(value === TABS.AIR)
    // {
    //   setShippingOrders(AIR_SHIPPING_ORDERS)
    // }
  }



  const renderTabContent = () => {
    
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={shippingOrders||[]}
          columns={getInventoryColumns()}
          rowHeight={70}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const renderModals = () => {
    return <>
      {showItemsModal && <InventoryItemsModal
        onClose={() => setShowItemsModal(null)}
        items={showItemsModal} 
      />}
      {showPackagingListModal && <PackagingListModal
        onClose={() => setShowPackagingListModal(null)}
        list={showPackagingListModal}
      />}
      {modal && <SendToNextWarehouse onClose={()=>setModal(false)} id={id} data={modalData} selectedId={selectedId} fetchData={()=>fetchData()} items={onSentData}/>}
      {bulkData && <BulkNextWarehouse data={shippingOrders} onClose={()=>setBulkData(false)} id={id}  fetchData={()=>fetchData()}/>}
      {
        containerDetails && <PlannedSoModal mode={null} temp={containerDetails}  onClose={()=>setContainerDetails(null)}/>
      }
    </>
  }

  return (
    <>
      {isMobile && (
        <Drawer props={props}/>
      )}
      <StaffEditorPageContainer>
      {loading && <OpaqueLoading />}
        <HeaderContainer>
          <Typography variant="h5"> Delivery To Warehouse</Typography>
          {!isMobile&&<Button variant="contained" onClick={()=>setBulkData(true)}>Bulk Delivery Warehouse</Button>
          }{/* <FormControl>
  <InputLabel id="demo-simple-select-label">Inventory Status</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    sx={{width: 150}}
    id="demo-simple-select"
    defaultValue={10}
    value={statusInventory || ''}
    label="Inventory Status"
    onChange={(e,value)=> setStatusInventory(e.target.value)}
  >
    <MenuItem value={10}>Pending SOs</MenuItem>
    <MenuItem value={20}>Recieved SOs</MenuItem>
  </Select>
</FormControl> */}
        </HeaderContainer>

        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="All Items" />
              <Tab label="Sea Inventories" />
              <Tab label="Air Inventories" />
            </StyledTabs>
            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
        {renderModals()}
      </StaffEditorPageContainer>

    </>
  );
}
