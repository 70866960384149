import React, { useContext, useState } from "react";
import { isEmail } from "validator";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Fab
} from "@mui/material";
import styled from "styled-components";
import { AuthContext } from "../../components/contextAPI/ContextAPI";
import { LOCAL_STORAGE_KEYS } from "../../constants";

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const CenterContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
`;

const CenterRow = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
`;

const InputFieldContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const LoginCard = styled(Card)`
  width: 100%;
  border-radius: 4px;
`;

const StyledCardContent = styled(CardContent)`
  padding: 20px !important;
`;

const Logo = styled.img`
  height: 100px;
  margin-bottom: 10px;
`;

const HeaderContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledForgotPassword = styled(Typography)`
  cursor: pointer;
  text-align: right; /* Add this line to align the text to the right */
`;

const StyledHeading = styled(Typography)`
  font-weight: bold;
`;

const Login = (props) => {
  const { auth, setAuth } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const isValid = true;
    // const isValid = strongPasswordRegex.test(newPassword);
    setIsPasswordValid(isValid);
    setPasswordErrorMessage(
      isValid
        ? ""
        : "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character (@ $ ! % * ? &)."
    );
  };

  const checkForErrors = () => {
    if (!email || !password) {
      window.NotificationUtils.showError(
        "Please enter a valid email and password"
      );
      return true;
    }
    return false;
  };

  let contextState = { ...auth };
  const onClickLogin = async () => {
    if (checkForErrors()) {
      return;
    }

    try {
      contextState.loading = true;

      let userResults = await window.Platform.database.login({
        email,
        password,
      });

      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.STORED_USER_DATA,
        JSON.stringify(userResults)
      );
      setAuth(userResults.attributes);
      contextState.loggedIn = true;
      contextState.token = userResults.attributes.sessionToken;
      contextState.user = userResults.attributes;
      contextState.email = userResults.attributes.email;
      setAuth(contextState);
      if (userResults) {
        props.onAuthUserChanged && props.onAuthUserChanged(userResults);
      }

      window.NotificationUtils.showSuccess("Logged In Successfully");
    } catch (error) {
      if (error && error.code === 101) {
        window.NotificationUtils.showError("Invalid Username/Password");
      } else {
        window.NotificationUtils.showError("Something Went Wrong");
      }
    } finally {
      contextState.loading = false;
    }
  };

  const onClickForgotPassword = async () => {
    try {
      if (!email || !isEmail(email)) {
        window.NotificationUtils.showError("Please enter a valid email address");
        return;
      }
      let response = await window.requestPasswordReset(email);
      window.NotificationUtils.showSuccess("Password reset email sent successfully");
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  const renderLoginCard = () => {
    return (
      <LoginCard raised>
        <StyledCardContent>
          <HeaderContainer>
            <StyledHeading variant="h5" align="left">
              Login
            </StyledHeading>
          </HeaderContainer>
          <InputFieldContainer>
            <TextField
              fullWidth={true}
              label="Email"
              placeholder="someone@example.com"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e && e.key && e.key === "Enter") {
                  onClickLogin();
                }
              }}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <TextField
              variant="outlined"
              fullWidth={true}
              label="Password"
              type="password"
              onChange={handlePasswordChange}
              onKeyDown={(e) => {
                if (e && e.key && e.key === "Enter") {
                  onClickLogin();
                }
              }}
              error={!isPasswordValid}
              helperText={passwordErrorMessage}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <StyledForgotPassword
              color="secondary"
              onClick={onClickForgotPassword}
              align="right"
              variant="subtitle2"
            >
              Forgot Password ?
            </StyledForgotPassword>
          </InputFieldContainer>
          <LoginButtonContainer>
            {/* Disable the login button if the password is not valid */}
            <FabStyle
              variant="extended"
              color="primary"
              onClick={onClickLogin}
              disabled={!isPasswordValid} // <-- Here is the change
            >
              Login
            </FabStyle>
          </LoginButtonContainer>
        </StyledCardContent>
      </LoginCard>
    );
  };

  const renderLogo = () => {
    return <Logo src="./logo1.png" />;
  };

  return (
    <CenterContent>
      <CenterRow>
        {renderLogo()}
        {renderLoginCard()}
      </CenterRow>
    </CenterContent>
  );
};

export default Login;
