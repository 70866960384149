import * as Parse from "parse";
import { ERROR_CODES } from "../../constants";

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID);

Parse.serverURL = process.env.REACT_APP_SERVER_URL;

async function signOut() {
  try {
    await Parse.User.logOut();
    const currentUser = Parse.User.current();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// const signUp = async (data) => {
//   try {
//     const user = new Parse.User();
//     user.set("username", "abhijit");
//     user.set("password", "123456");
//     user.set("email", "abhijit@maxx.com");
//     user.set("role", "Admin");
//     let result = await user.signUp();
//     return result;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

const login = async (data) => {
  try {
    const user = await Parse.User.logIn(data.email, data.password);
    return user;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getUserData = async (forced = false) => {
  try {
    const currentUser = Parse.User.current();
    if (currentUser && !forced) {
      return currentUser;
    } else {
      let user = await Parse.Cloud.run("getUserData");
      return user;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getInviteData = async (inviteId) => {
  try {
    let invite = new Parse.Query("invites");
    invite.equalTo("objectId", inviteId);
    let result = await invite.first();
    if (result) {
      return result.attributes;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const requestPasswordReset = async (email) => {
  try {
    // await Parse.User.requestPasswordReset(email);
    let response = await Parse.Cloud.run("requestCustomPasswordReset", {
      email,
    });

    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};
const createNewMember = async (details) => {
  try {
    let results = await Parse.Cloud.run("createNewMember", details);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createNewCustomer = async (details) => {
  try {
    let results = await Parse.Cloud.run("createNewCustomer", details);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const createNewSupplier = async (details) => {
  try {
    let results = await Parse.Cloud.run("createNewSupplier", details);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const createNewMasterSupplier = async (details) => {
  try {
    let results = await Parse.Cloud.run("createNewMasterSupplier", details);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createNewItem = async (details) => {
  try {
    let results = await Parse.Cloud.run("createNewItem", details);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const createNewItemMaster = async (details) => {
  try {
    let results = await Parse.Cloud.run("createNewItemMaster", details);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const createNewWarehouse = async (details) => {
  try {
    let results = await Parse.Cloud.run("createNewWarehouse", details);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchEmployees = async () => {
  try {
    let results = await Parse.Cloud.run("fetchEmployees");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchAllEmployeeBasics = async () => {
  try {
    let results = await Parse.Cloud.run("fetchAllEmployeeBasics");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchPendingEmployees = async () => {
  try {
    let results = await Parse.Cloud.run("fetchPendingEmployees");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getTemplates = async () => {
  try {
    let results = await Parse.Cloud.run("getTemplates");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const activateTemplate = async (val) => {
  try {
    let results = await Parse.Cloud.run("activateTemplate", {val});
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const saveTemplate = async (val) => {
  try {
    let results = await Parse.Cloud.run("saveTemplate", {val});
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const confirmMemberInvite = async (data) => {
  try {
    let results = await Parse.Cloud.run("confirmMemberInvite", data);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const resendInvite = async (details) => {
  try {
    await Parse.Cloud.run("resendInvite", details);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteEmployee = async (data, reason) => {
  try {
    // console.log("checking data", data, reportingManager, terminationDate, reason);
    await Parse.Cloud.run("deleteEmployee", {
      reason,
      employee: data,
      // replacementManager:reportingManager,
      // terminationDate,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const deletedUsers = async () => {
  try {
    let results = await Parse.Cloud.run("deletedUsers");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const profilePictureUpload = async (file) => {
  try {
    let userData = await getUserData();
    const timestamp = Date.now();
    const filename = `${userData.id}_${timestamp}.jpg`; 
    let parseFile = new Parse.File(filename, file);
    let response = await parseFile.save();
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const updateBasicDetails = async (details) => {
  try {
    let results = await Parse.Cloud.run("updateBasicDetails", details);
    if (results && results.message) {
      if (results.message === ERROR_CODES.EMAIL_IN_USE) {
        throw ERROR_CODES.EMAIL_IN_USE;
      } else if (results.message === ERROR_CODES.MOBILE_NUMBER_IN_USE) {
        throw ERROR_CODES.MOBILE_NUMBER_IN_USE;
      }
    }
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const getCustomer = async (details) => {
  try {
    let results = await Parse.Cloud.run("getCustomer", details);

    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const getSupplier = async (val) => {
  try {
    let results = await Parse.Cloud.run("getSupplier", { val });

    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const getAllSuppliers = async (val) => {
  try {
    let results = await Parse.Cloud.run("getAllSupplier", val);

    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAllItems = async (val) => {
  try {
    let results = await Parse.Cloud.run("getAllItems", val);

    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const getAllItemsMaster = async (val) => {
  try {
    let results = await Parse.Cloud.run("getAllItemsMaster", val);

    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const getItem = async (val) => {
  try {
    let results = await Parse.Cloud.run("getItem", { val });

    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchWarehouses = async () => {
  try {
    let results = await Parse.Cloud.run("fetchWarehouses");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchWarehouse = async (detail) => {
  try {
    let results = await Parse.Cloud.run("fetchWarehouse", { detail });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const createNewQuotation = async (details) => {
  try {
    // console.log(details);
    let results = await Parse.Cloud.run("createNewQuotation", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchPendingQuotations = async () => {
  try {
    let results = await Parse.Cloud.run("fetchPendingQuotations");
    return results;
  } catch (error) {
    throw error;
  }
};

const fetchCustomerApprovedQuotations = async () => {
  try {
    let results = await Parse.Cloud.run("fetchCustomerApprovedQuotations");
    return results;
  } catch (error) {
    throw error;
  }
};
const approveAdminQuotation = async (details) => {
  try {
    // console.log(details);
    let results = await Parse.Cloud.run("approveAdminQuotation", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const generateSO = async (details) => {
  try {
    // console.log(details);
    let results = await Parse.Cloud.run("generateSO", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchInventory = async (details) => {
  try {
    // console.log(details)
    let results = await Parse.Cloud.run("fetchInventory", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const checkStateNameAvailability = async (stateName) => {
  try {
    let response = await Parse.Cloud.run("checkStateNameAvailability", {
      stateName,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateState = async (update) => {
  try {
    let response = await Parse.Cloud.run("updateState", { update });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchStates = async (val) => {
  try {
    const response = await Parse.Cloud.run("fetchStates", val);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const checkDistrictNameAvailability = async (districtName, stateId) => {
  try {
    const response = await Parse.Cloud.run("checkDistrictNameAvailability", {
      districtName,
      stateId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateDistrict = async (update) => {
  try {
    let response = await Parse.Cloud.run("updateDistrict", { update });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchDistricts = async () => {
  try {
    const response = await Parse.Cloud.run("fetchDistricts");
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const checkCityNameAvailability = async (cityName, districtId) => {
  try {
    const response = await Parse.Cloud.run("checkCityNameAvailability", {
      cityName,
      districtId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateCity = async (update) => {
  try {
    let response = await Parse.Cloud.run("updateCity", { update });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchCities = async (districtId = null) => {
  try {
    const response = await Parse.Cloud.run("fetchCities", { districtId });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getRolesAccess = async () => {
  try {
    let results = await Parse.Cloud.run("getRolesAccess");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateRolesAccess = async (permissions) => {
  try {
    let results = await Parse.Cloud.run("updateRolesAccess", { permissions });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchAccessChangePermissions = async (permissions) => {
  try {
    let results = await Parse.Cloud.run("fetchAccessChangePermissions", {
      permissions,
    });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteCustomer = async (val) => {
  try {
    // console.log("sending", val);
    let results = await Parse.Cloud.run("deleteCustomer", val);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteItems = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("deleteItem", details);

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

const fetchMasterSuppliers = async () => {
  try {
    // console.log(details)

    let results = await Parse.Cloud.run("fetchMasterSuppliers");

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};
const deleteSupplier = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("deleteSupplier", details);

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

const rejectAdminQuotation = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("rejectAdminQuotation", details);

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};
const deleteDistrict = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("deleteDistrict", details);

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

const deleteCity = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("deleteCity", details);

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

const deleteState = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("deleteState", details);

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

const fetchQuotationbyID = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("fetchQuotationbyID", details);

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};
const fetchEditQuotationById = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("fetchEditQuotationById", details);

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

const approveCustomerQuotation = async (details) => {
  try {
    // console.log(details);
    let results = await Parse.Cloud.run("approveCustomerQuotation", {
      details,
    });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const rejectCustomerQuotation = async (details) => {
  try {
    // console.log(details);
    let results = await Parse.Cloud.run("rejectCustomerQuotation", details);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchQuotationPendingAndAdminpening = async () => {
  try {
    // console.log(details)
    let results = await Parse.Cloud.run("fetchQuotationPendingAndAdminpening");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const verifyUserSession = async (sessionToken) => {
  try {
    let response = await Parse.User.become(sessionToken);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const resendQuotationLink = async (details) => {
  try {
    let results = await Parse.Cloud.run("resendQuotationLink", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteWarehouse = async (details) => {
  try {
    // console.log(details);

    let results = await Parse.Cloud.run("deleteWarehouse", {details});

    return results;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

const updateQuotation = async (details) => {
  try {
    let results = await Parse.Cloud.run("updateQuotation", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchTracking = async () => {
  try {
    let results = await Parse.Cloud.run("fetchTracking");
    console.log("TRACKING", results);
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchNotifications = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchNotifications", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const markNotificationsRead = async (details) => {
  try {
    let results = await Parse.Cloud.run("markNotificationsRead", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const bulkApproval = async (details) => {
  try {
    let results = await Parse.Cloud.run("bulkApproval", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchExpectingDelivery = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchExpectingDelivery", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchSoInHouse = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchSoInHouse", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchSoNotRecieved = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchSoNotRecieved", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchDelivered = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchDelivered", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const fetchPendingDelivery = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchPendingDelivery", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchPendingDeliveryToCustomer = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchPendingDeliveryToCustomer", {
      details,
    });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const deliveredToCustomer = async (details) => {
  try {
    let results = await Parse.Cloud.run("deliveredToCustomer", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const recievedItems = async (details) => {
  try {
    let results = await Parse.Cloud.run("recievedItems", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const notRecievedItems = async (details) => {
  try {
    let results = await Parse.Cloud.run("notRecievedItems", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const bulkReject = async (details) => {
  try {
    let results = await Parse.Cloud.run("bulkReject", { details });
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchSoair = async () => {
  try {
    let results = await Parse.Cloud.run("fetchSoair");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchSoSea = async () => {
  try {
    let results = await Parse.Cloud.run("fetchSoSea");
    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const editQuotation = async (details) => {
  try {
    let results = await Parse.Cloud.run("editQuotation", { details });
    return results;
  } catch (error) {
    console.error(error);
  }
};
const bulkAddSuppliers = async (details) => {
  try {
    let results = await Parse.Cloud.run("bulkAddSuppliers", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const bulkAddCustomer = async (details) => {
  try {
    let results = await Parse.Cloud.run("bulkAddCustomer", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const filterState = async (details) => {
  try {
    let results = await Parse.Cloud.run("filterState", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const filterCustomers = async (details) => {
  try {
    let results = await Parse.Cloud.run("filterCustomers", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// bulkAddItem
const bulkAddItem = async (details) => {
  try {
    let results = await Parse.Cloud.run("bulkAddItem", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const nextWarehouse = async (details) => {
  try {
    let results = await Parse.Cloud.run("nextWarehouse", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const containerSoSplit = async (details) => {
  try {
    let results = await Parse.Cloud.run("containerSoSplit", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const fetchSoByExpectedDate = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchSoByExpectedDate", details);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const fetchSoByExpectedDateAll = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchSoByExpectedDateAll", details);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const fetchSOorQuotation = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchQuotationorso", {details});
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const filterQuotationByDateRange = async (details) => {
  try {
    let results = await Parse.Cloud.run("filterQuotationByDateRange", {details});
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const filterSoByDateRange = async (details) => {
  try {
    let results = await Parse.Cloud.run("filterSoByDateRange", {details});
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const planContainer = async (details) => {
  try {
    let results = await Parse.Cloud.run("planContainer", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const fetchPlannedContainer = async (details) => {
  try {
    // console.log("DETAILSSSSSS", details);
    let results = await Parse.Cloud.run("fetchPlannedContainer", details);
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const fetchPlannedContainerById = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchPlannedContainerById", details);
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const fetchCustomClearancePending = async () => {
  try {
    let results = await Parse.Cloud.run("fetchCustomClearancePending");
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const customClearanceUpdate = async (details) => {
  try {
    let results = await Parse.Cloud.run("customClearanceUpdate", { details });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const editPlanContainer = async (details) => {
  try {
    let results = await Parse.Cloud.run("editPlanContainer", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const deletePlanContainer = async (details) => {
  try {
    let results = await Parse.Cloud.run("deletePlanContainer", { details });
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
// const editPlanContainer=async(details)=>{
//   try{
//   let results=await Parse.Cloud.run("editPlanContainer",{details});
//   return results;
// }
// catch (error) {
//   console.log(error);
//   throw error;
// }
// }
// const deletePlanContainer=async(details)=>{
//   try{
//   let results=await Parse.Cloud.run("deletePlanContainer",{details});
//   return results;
// }
// catch (error) {
//   console.log(error);
//   throw error;
// }
// }

const fetchCostingAndBilling = async () => {
  try {
    let results = await Parse.Cloud.run("fetchCostingAndBilling");
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const costingAndBillingUpdate = async (details) => {
  try {
    let results = await Parse.Cloud.run("costingAndBillingUpdate", { details });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const tallyUpdate = async (details) => {
  try {
    let results = await Parse.Cloud.run("tallyUpdate", { details });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const fetchTally = async () => {
  try {
    let results = await Parse.Cloud.run("fetchTally");
    console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const fetchDeclaration = async () => {
  try {
    let results = await Parse.Cloud.run("fetchDeclaration");
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const declarationAndPaymentUpdate = async (details) => {
  try {
    let results = await Parse.Cloud.run("declarationAndPaymentUpdate", {
      details,
    });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const fetchStatesModal = async () => {
  try {
    let results = await Parse.Cloud.run("fetchStatesModal");
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const BulkRecievedItems = async (details) => {
  try {
    let results = await Parse.Cloud.run("BulkRecievedItems", { details });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const bulkNextWarehouse = async (details) => {
  try {
    let results = await Parse.Cloud.run("bulkNextWarehouse", { details });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const bulkPaymentAndDeclaration = async (details) => {
  try {
    let results = await Parse.Cloud.run("bulkPaymentAndDeclaration", { details });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const customClearanceUpdateBulk = async (details) => {
  try {
    let results = await Parse.Cloud.run("customClearanceUpdateBulk", { details });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const bulkCostingAndBillingUpdate = async (details) => {
  try {
    let results = await Parse.Cloud.run("bulkCostingAndBillingUpdate", { details });
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const fetchSoById = async (details) => {
  try {
    let results = await Parse.Cloud.run("fetchSoById",  details );
    // console.log("resultsAPI", results);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const BulkTallyUpdate=async(details)=>{
  try{
  let results=await Parse.Cloud.run("BulkTallyUpdate",{details});
  // console.log("resultsAPI",results)
  return results;
}
catch (error) {
  console.log(error);
  throw error;
}
}

const filterQuotation=async(details)=>{
  try{
  let results=await Parse.Cloud.run("filterQuotation",{details});
  // console.log("resultsAPI",results)
  return results;
}
catch (error) {
  console.log(error);
  throw error;
}
}
const filterSO=async(details)=>{
  try{
  let results=await Parse.Cloud.run("filterSO",{details});
  // console.log("resultsAPI",results)
  return results;
}
catch (error) {
  console.log(error);
  throw error;
}
}



let exportedFunctions = {
  signOut,
  // signUp,
  login,
  getUserData,
  requestPasswordReset,
  createNewMember,
  fetchEmployees,
  fetchAllEmployeeBasics,
  fetchPendingEmployees,
  getInviteData,
  confirmMemberInvite,
  resendInvite,
  deleteEmployee,
  deletedUsers,
  profilePictureUpload,
  updateBasicDetails,
  createNewCustomer,
  createNewSupplier,
  getCustomer,
  getSupplier,
  getItem,
  createNewItem,
  createNewWarehouse,
  fetchWarehouses,
  createNewQuotation,
  fetchPendingQuotations,
  approveAdminQuotation,
  getAllSuppliers,
  getAllItems,
  generateSO,
  fetchInventory,
  fetchCustomerApprovedQuotations,
  checkStateNameAvailability,
  updateState,
  fetchStates,
  fetchDistricts,
  checkDistrictNameAvailability,
  updateDistrict,
  checkCityNameAvailability,
  updateCity,
  fetchCities,
  getRolesAccess,
  updateRolesAccess,
  fetchAccessChangePermissions,
  deleteCustomer,
  deleteItems,
  fetchMasterSuppliers,
  deleteSupplier,
  rejectAdminQuotation,
  deleteState,
  deleteCity,
  deleteDistrict,
  createNewMasterSupplier,
  getAllItemsMaster,
  createNewItemMaster,
  fetchQuotationbyID,
  approveCustomerQuotation,
  rejectCustomerQuotation,
  fetchQuotationPendingAndAdminpening,
  verifyUserSession,
  rejectCustomerQuotation,
  resendQuotationLink,
  deleteWarehouse,
  updateQuotation,
  fetchNotifications,
  fetchTracking,
  bulkApproval,
  bulkReject,
  fetchWarehouse,
  fetchExpectingDelivery,
  recievedItems,
  fetchSoair,
  fetchSoInHouse,
  fetchEditQuotationById,
  editQuotation,
  fetchWarehouse,
  bulkAddSuppliers,
  fetchExpectingDelivery,
  recievedItems,
  fetchSoair,
  fetchEditQuotationById,
  editQuotation,
  bulkAddCustomer,
  filterState,
  filterCustomers,
  bulkAddItem,
  fetchSoSea,

  fetchSoSea,
  nextWarehouse,
  fetchPendingDelivery,
  fetchPendingDeliveryToCustomer,
  fetchDelivered,
  deliveredToCustomer,
  containerSoSplit,
  fetchSoByExpectedDate,
  fetchSoByExpectedDateAll,
  fetchSOorQuotation,
  planContainer,
  markNotificationsRead,
  fetchPlannedContainer,
  fetchPlannedContainerById,
  fetchCustomClearancePending,
  customClearanceUpdate,
  fetchCostingAndBilling,
  costingAndBillingUpdate,
  tallyUpdate,
  fetchTally,
  fetchDeclaration,
  editPlanContainer,
  deletePlanContainer,
  declarationAndPaymentUpdate,
  fetchStatesModal,
  BulkRecievedItems,
  bulkNextWarehouse,
  bulkPaymentAndDeclaration,
  customClearanceUpdateBulk,
  bulkCostingAndBillingUpdate,
  fetchSoById,
  BulkTallyUpdate,
  filterQuotation,
  filterSO,
  notRecievedItems,
  fetchSoNotRecieved,
  filterQuotationByDateRange,
  filterSoByDateRange,
  getTemplates,
  activateTemplate,
  saveTemplate
};

export default exportedFunctions;
