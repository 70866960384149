import React, { useState } from "react";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { CloseOutlined } from "@mui/icons-material";

import { Stack } from "@mui/material";

import styled from "styled-components";

import { ACTIVITY_STATUS, ERROR_CODES } from "../../constants";

import OpaqueLoading from "../opaqueLoading/opaqueLoading";

import { useEffect } from "react";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;

  // max-width: 700px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;
`;

const AddNewCustomer = ({
  template,
  onCancel,
  onSubmit,
  loading,
  setLoading,
}) => {
  // console.log("TEMPLATEE", template);
  // const [loading, setLoading] = useState()

  const [form, setForm] = useState({
    customerName: "",

    customerCity: "",

    customerMobile: "",
  });

  const [isEditMode, setIsEditMode] = useState(!!template);

  const [cityVal, setCityVal] = useState(["Delhi"]);

  // const [loading, setLoading] = useState(false)

  const fetchCities = async () => {
    try {
      setLoading(true);

      let response = await window.Platform.database.fetchCities();

      setCityVal(response);

      setLoading(false);
    } catch (error) {
      console.error(error);

      window.NotificationUtils.showError("Error fetching cities");

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    // If a template is provided (in "Edit" mode), pre-fill the form fields

    if (template) {
      setForm({
        customerName: template.value || "",

        customerCity: template.cityId || "",

        customerMobile: template.mobile || "",
      });
    }
  }, [template]);

  const changeHandler = (fieldName, value) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const isValidIndiaMobileNumber = (mobileNumber) => {
    const indiaRegex = /^(\+?91[-\s]?)?[789]\d{9}$/;

    return indiaRegex.test(mobileNumber);
  };

  const checkForErrors = () => {
    // if(!form.customerName &&!form.customerCity && !form.customerMobile){

    //   window.NotificationUtils.showError("Please enter Details");

    // }

    if (form && !form.customerName) {
      window.NotificationUtils.showError("Please enter a Customer name");

      return true;
    }

    // if (!details.employeeCode) {

    //   window.NotificationUtils.showError(

    //     "Please enter an employee code for the user"

    //   );

    //   return true;

    // }

    if (form && !form.customerCity) {
      window.NotificationUtils.showError("Please Select a Customer City");

      return true;
    }

    if (form && !form.customerMobile) {
      window.NotificationUtils.showError(ERROR_CODES.NO_MOBILE);

      return true;
    }

    if (
      !form.customerMobile ||
      isValidIndiaMobileNumber(form.customerMobile) == false
    ) {
      window.NotificationUtils.showError(ERROR_CODES.INVALID_MOBILE);

      return true;
    }

    return false;
  };

  const submitHandler = () => {
    if (checkForErrors()) {
      return;
    }

    // setLoading(true);

    onSubmit(form);

    // setLoading(false)
  };
//  console.log("FORM",form)
  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="xs"
      fullWidth={true}
      open={true}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">
            {isEditMode ? "Edit Customer" : "Add New Customer"}
          </Typography>

          <IconButton onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>

        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}

        <Stack gap="20px" style={{ padding: "10px" }}>
          {loading && <OpaqueLoading />}

          <Stack>
            <TextField
              variant="outlined"
              label="Customer Name"
              value={form.customerName}
              onChange={(e) => changeHandler("customerName", e.target.value)}
            />
          </Stack>

          <Stack>
            {/* <TextField

 

                variant="outlined"

 

                label="Customer City"

 

                value={form.customerCity}

 

                onChange={(e) =>

 

                  changeHandler("customerCity", e.target.value)

 

                }

 

              /> */}

            <FormControl fullWidth variant="outlined">
              <InputLabel id="cityLabel">Select City</InputLabel>

              <Select
                labelId="cityLabel"
                id="citySelect"
                // value={district.id || ""}

                // value={cityVal || ""}

                label="Select City"
                // disabled={loading}

                value={form.customerCity}
                onChange={(e) => changeHandler("customerCity", e.target.value)}
              >
                {cityVal.map((cityEntry) => (
                  <MenuItem value={cityEntry?.id} key={cityEntry?.id}>
                    {cityEntry?.cityName} ({cityEntry?.state?.stateName})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack>
            {/* <TextField
              variant="outlined"
              type="number"
              label="Customer Mobile Number"
              value={form.customerMobile}
              onChange={(e) =>
                changeHandler("customerMobile", e.target.value)
              }
            /> */}

            <TextField
              required
              value={form.customerMobile}
              onChange={(e) => {
                const input = e.target.value;
                // Custom validation to limit the input to a maximum of 10 digits
                if (input.length <= 15 && /^\d*$/.test(input)) {
                  changeHandler("customerMobile", input);
                }
              }}
              variant="outlined"
              label="Customer Mobile Number"
              fullWidth
              // disabled={loading || props.isManager || props.isDeleted}
              type="text" // Use type="text" for alphanumeric input
            />
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="10px"
            align-items="center"
          >
            <Button color="error" onClick={onCancel}>
              Close
            </Button>

            <Button color="primary" variant="contained" onClick={submitHandler}>
              {isEditMode ? "Save" : "Create"}
            </Button>
          </Stack>
        </Stack>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default AddNewCustomer;
