import React, { useRef, useState } from "react";

import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Upload } from "@mui/icons-material";

import { CloseOutlined } from "@mui/icons-material";
import dayjs from "dayjs";

import { Stack } from "@mui/material";

import styled from "styled-components";

import { ACTIVITY_STATUS, ERROR_CODES } from "../../constants";

import OpaqueLoading from "../opaqueLoading/opaqueLoading";

import { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const AutocompleteContainer = styled(Autocomplete)`
  width: 250px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;

const InputFieldContainer = styled.div`
  margin: 5px 0px;

  display: flex;

  justify-content: center;

  align-items: center;

  gap: 2px;

  label {
    margin-bottom: 0px;
  }
`;

const Input = styled.input`
  display: none;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;

  // max-width: 700px;
`;
const isMobile = window.innerWidth < 900;

const ModalHeaderContainer = styled.div`
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;
`;
const ResponsiveTextField = styled(TextField)`
  // flex: 1;
  width: 250px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const EditQuotationModal = ({
  onCancel,
  loading,
  onSubmit,
  setLoading,
  items,
}) => {

  const options = [
    { value: "Air", label: "Air" },
    { value: "Sea", label: "Sea" },
  ];
  const hiddenFileInput = useRef(null);
  const [packagingList, setPackagingList] = useState(items.packagingList);
  const [deliveryCity, setDeliveryCity] = useState(
    items.deliveryCity.attributes
  );

  const [downloadLink, setDownloadLink] = useState("");

  const [form, setForm] = useState(items);

  const [cityOption, setCityOptions] = useState();
  const [deliveryDate, setDeliveryDate] = useState(null);

  const [apiLoading, setApiLoading] = useState(false);
  const [warehouseAlready, setWarehouseAlready] = useState([]);

  const changeHandler = (fieldName, value) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };



  const [cityVal, setCityVal] = useState(["Delhi"]);
  const fetchWarehouseVal = async () => {
    try {
      setApiLoading(true);

      let results = await window.Platform.database.fetchWarehouses();
      let warehouseNames = results.map((result) => {
        let user = result.name;

        return user;
      });
      setWarehouseAlready(warehouseNames);
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError(error)
    } finally {
      setApiLoading(false);
    }
  };

  const fetchCities = async () => {
    try {
      setApiLoading(true);

      let response = await window.Platform.database.fetchCities();

      setCityVal(response);

      setApiLoading(false);
    } catch (error) {
      console.error(error);

      window.NotificationUtils.showError("Error fetching cities");

      setApiLoading(false);
    }
  };

  const isValidIndiaMobileNumber = (mobileNumber) => {
    const indiaRegex = /^(\+?91[-\s]?)?[789]\d{9}$/;

    return indiaRegex.test(mobileNumber);
  };

  useEffect(() => {
    fetchCities();
    fetchWarehouseVal();
  }, []);

  const handleUpload = async () => {
    try {
      //   setLoading(true);
      let uploadInput = document.getElementById("filePickerButton");
      if (uploadInput) {
        if (uploadInput.files.length > 0) {
          let file = uploadInput.files[0];
          const allowedExtensions = /\.(docx?|pdf|jpe?g|png|gif|xlsx|csv?)$/i;
          const fileExtension = file.name;
          if (!allowedExtensions.test(fileExtension)) {
            window.NotificationUtils.showError(
              "Please select a valid file (doc, docx, pdf, jpg, jpeg, png, gif, xlsx,csv) for upload"
            );
            setLoading(false);
            return;
          }
          let response = await window.Platform.database.profilePictureUpload(
            file
          );

          setPackagingList(response._url);
          //   props.onCancel();
          //   setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retrieving user data");
      //   setLoading(false);s\
    }
  };

  const checkErrors = () => {
    if (
      !form.deliveryCityId ||
      !form.deliveryDate ||(isNaN(form.deliveryDate) ) ||
      !form.deliveryPlace ||
      !form.typeOfShipment ||
      !form.warehouseName ||
      !packagingList
    ) {
      window.NotificationUtils.showError("Please Enter valid details");
      return true
    }
  };

  const submitHandler = () => {
    if (checkErrors()) {
      return;
    }

    let newForm = {
      quotationId: form.id,
      deliveryCityId: form.deliveryCityId,
      deliveryDate: form.deliveryDate,
      deliveryPlace: form.deliveryPlace,
      typeOfShipment: form.typeOfShipment,
      warehouseName: form.warehouseName,
      packagingList: packagingList,
    };


    onSubmit(newForm);
  };
  //   const handleCitySelection = (event, value) => {
  //     setSelectedCity(value);
  //     changeHandler("customerCity", value?.value || null);
  //   };

  useEffect(() => {

  }, [loading]);
  useEffect(() => {
    const deliveryDateValue = form.deliveryDate
      ? dayjs.unix(form.deliveryDate / 1000)
      : null;
    setDeliveryDate(deliveryDateValue);
  }, [form.deliveryDate]);

  // setSelectedCity(cityName)

  //   console.log("cityName", cityName);
  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="xs"
      fullWidth={true}
      open={true}
    >
      <StyledDialogContent>
        {apiLoading && <OpaqueLoading />}

        <ModalHeaderContainer>
          <Typography variant="h5">Edit Details <Tooltip title="Quotation ID">({form.id})</Tooltip> </Typography>

          <IconButton onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>

        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}

        <Stack gap="20px" style={{ padding: "10px" }}>
          <Stack>
            <InputFieldContainer>
              <a target="_blank" href={downloadLink} download="leads template">
                {/* {console.log('downloadLink', downloadLink)} */}

                {/* <Button variant="contained" color="primary" disabled={!downloadLink}>

 

              {!!downloadLink ? 'Download Template'}

 

            </Button> */}
              </a>

              <label htmlFor="filePickerButton">
                <Input
                  //   accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

                  id="filePickerButton"
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleUpload}
                  maxSize={5242880}
                />

                <FabStyle
                  variant="extended"
                  color="primary"
                  disabled={loading}
                  component="span"

                  //  onClick={}
                >
                  <Upload  />
                  {packagingList ? "Re-Upload PL" :"Upload PL"}
                </FabStyle>
              </label>
            </InputFieldContainer>
            {/* </InputFieldContainer> */}
          </Stack>

          <Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY/MM/DD"
                
                value={deliveryDate}
                sx={{ width: "100%" }}
                // renderInput={(props) => <TextField {...props} fullWidth />}
                InputLabelProps={{ shrink: true }}
                disablePast
                label="Expected Delivery Date*"
                // onChange={(newValue) => changeHandler("deliveryDate", newValue)}
                onChange={(newValue) => {
                  setDeliveryDate(newValue);
                  const newTimestamp = newValue ? newValue.unix() * 1000 : null;
                  changeHandler("deliveryDate", newTimestamp);
                }}
                // inputRef={fields[11]}
              />
            </LocalizationProvider>
          </Stack>

          <Stack>
            <Autocomplete
              value={deliveryCity || ""}
              options={cityVal}
              getOptionLabel={(option) =>
                `${option && option.cityName ? option?.cityName : ""} `
              }
              getOptionValue={(option) => option?.id}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = cityVal.find(
                  (option) => option.id === value?.id
                );

                if (!matchingOption) {
                  // If there's no matching option, set the otherValue to null
                  setDeliveryCity(null);
                }
              }}
              noOptionsText={"loading"}
              onChange={(event, value) => {
                setDeliveryCity(value || "");


                changeHandler("deliveryCityId", value?.id || null);

                // setDeliveryCity(selectedCity || null);

                // fields[13].current.focus();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Deivery City"
                  variant="outlined"
                  // inputRef={fields[12]}

                  required
                />
              )}
            />
          </Stack>

          <Stack>
            <TextField
            required
              value={form.deliveryPlace}
              variant="outlined"
              label="Delivery Place"
              //   defaultValue={form["name"]}
              onChange={(e) => changeHandler("deliveryPlace", e.target.value)}
            />
          </Stack>
          <Stack>
            <Autocomplete
              // size="small"
              value={form.typeOfShipment}
              options={options}
              onChange={(e, value) => {
                changeHandler("typeOfShipment", value?.value);
              }}
              // style={{ width: !isMobile ? "400px" : "90%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Shipment Type"
                  variant="outlined"
                  required
                  //   style={{ width: !isMobile?'200px':'100%' }}
                  //   inputRef={fields[3]}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      // e.preventDefault();
                      changeHandler("typeOfShipment", e.target.value);

                      e.preventDefault();
                    }
                  }}
                  
                />
              )}
            />
          </Stack>
          <Stack>
            <Autocomplete
              value={form.warehouseName}
              options={warehouseAlready}
              noOptionsText={apiLoading ? "Loading..." : "No option"}
              // size="small"
              onChange={(e, value) => {
                // setWarehouseValue(value)
                changeHandler("warehouseName", value);
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = warehouseAlready.find(
                  (option) => option.value === value
                );

                if (!matchingOption) {
                  // If there's no matching option, set the otherValue to null
                  //   setWarehouseValue(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  style={{ width: isMobile && "100%" }}
                  {...params}
                  label="Choose warehouse"
                  variant="outlined"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      if (e.target.value) {

                        changeHandler("warehouseName", e.target.value);
                      }
                      // setCurrent\F\ield(currentField + 1);
                    }
                  }}
                  required
                />
              )}
            />
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="10px"
            align-items="center"
          >
            <Button color="error" onClick={onCancel}>
              Close
            </Button>

            <Button color="primary" variant="contained" onClick={submitHandler}>
              Save
            </Button>
          </Stack>
        </Stack>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default EditQuotationModal;
