import react, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import styled from "styled-components";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import Drawer from "../../components/common/drawer/drawer";

import DeleteIcon from "@mui/icons-material/Delete";

import {
  Typography,
  Button,
  Checkbox,
  Fab,
  Tab,
  Tabs,
  CircularProgress,
  IconButton,
  Popover,
  MenuItem,
  Stack,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";

import {
  DATE_FORMATS,
  ERROR_COLOR,
  SHIPMENT_TYPES,
  SO_STATUS,
} from "../../constants";

import { Add, Inventory, Save, SaveAlt } from "@mui/icons-material";

import WarehouseModal from "../../components/modals/WarehouseModal";

import InventoryItemsModal from "../../components/modals/inventoryItemsModal";

import PackagingListModal from "../../components/modals/packagingListModal";

import { useParams } from "react-router-dom";

import AddNewSupplier from "../../components/modals/AddNewMasterSupplier";
import moment from "moment";
import BulkSupplierAdd from "../../components/modals/bulkSupplierAdd";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};

  width: 100%;

  background-color: white;

  padding: 10px 15px;

  ${isMobile &&
  `

      position: relative;




      `}
`;

const ContentContainer = styled.div`
  display: flex;

  gap: 10px;

  padding: 10px 15px;

  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: ${isMobile ? "flex-start" : "space-between"};

  margin-bottom: 10px;

  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );

  width: 100%;

  border: solid 1px lightGrey;

  border-radius: 8px;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const AvatarContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 145px);

  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);

  display: flex;

  justify-content: center;

  align-items: center;
`;

// const TabsContainer = styled.div`

//   ${isMobile &&

//   `

//     height: calc(100vh - 56px - 20px);

//   `}

// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;

const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;

const StyledHeader = styled.div`
  display: flex;

  justify-content: center;

  align-items: center;
`;

const Toolbar = styled.div`
  width: 100%;

  display: flex;

  align-items: center;

  justify-content: space-between;

  gap: 10px;
`;

export default function SupplierManager(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [Supplier, setSupplier] = useState(false);

  const [showPackagingListModal, setShowPackagingListModal] = useState();

  const [showItemsModal, setShowItemsModal] = useState();

  const [openPopover, setOpenPopover] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [row, setRow] = useState([]);

  const [subLoading, setSubLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [bulk,setBulk]=useState(false);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const fetchdata = async () => {
    try {
      setFetchLoading(true);
      let response = await window.Platform.database.fetchMasterSuppliers();


      setRow(response);
    } catch (error) {
      console.error(error);
    } finally {
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const onSubmitSupplier = async (form) => {
    if (selectedRow) form = { ...form, id: selectedRow.id };
    try {
      setSubLoading(true);

      // let sent_data={customerName: form.customerName, customerCity: form.customerCity, customerMobile:form.customerMobile}

      // let response_Data={customerDetails:sent_data}

      let response = await window.Platform.database.createNewMasterSupplier(
        form
      );
      window.NotificationUtils.showSuccess(
        "Supplier Details added to the system"
      );

      setShowModal(false);
      setSubLoading(false);
      fetchdata();
    } catch (error) {
      window.NotificationUtils.showError(error.message);
      console.log(error.message);
      setSubLoading(false);
    }
    // finally{
    //     setShowModal(false)
    //     setSubLoading(false)
    //     fetchdata()
    // }
  };

  const getSuppliercolumns = () => {
    let result = [
      {
        field: "customerName",

        headerName: "Supplier Name",

        minWidth: 200,

        flex: 0.22,

        sortable: false,

        // dataGetter: (params) => params.row.name || "N/A",
        valueGetter: (params) => params.row.name ||"N/A",
        renderCell: (params) => {
          const value = params.row.name || "N/A";

          return <Tooltip title={value}><div>{value}</div></Tooltip>;
        },
      },
      {
        field: "createdAt",

        headerName: "Updated At",

        minWidth: 200,

        flex: 0.22,

        sortable: false,

        // dataGetter: (params) => {
        //   // console.log("params in dataGetter:", params);
        //   return params.row.status || "N/A";
        // },
        valueGetter: (params) =>moment(params.row.updatedAt).format(DATE_FORMATS.DATE_WITH_TIME) || "N/A" ,
        renderCell: (params) => {
          const value = params.row.updatedAt || "N/A";

          return (
            <Tooltip title={moment(value).format(DATE_FORMATS.DATE_WITH_TIME)}>
              {moment(value).format(DATE_FORMATS.DATE_WITH_TIME)}
            </Tooltip>
          );
        },
      },

      {
        field: "customerMobile",

        headerName: "Supplier Mobile",

        minWidth: 200,

        flex: 0.22,

        sortable: false,

        // dataGetter: (params) => params.row?.mobile || "N/A",
        valueGetter: (params) => params.row.mobile ||"N/A",
        renderCell: (params) => {
          const value = params.row.mobile || "N/A";

          return <Tooltip title={value}>{value}</Tooltip>;
        },
      },

      {
        field: "customerCity",

        headerName: "Supplier City",

        minWidth: 200,

        flex: 0.22,

        sortable: false,

        // dataGetter: (params) => params.row.city.attributes.cityName || "N/A",
        valueGetter: (params) => params.row.city?.attributes?.cityName ||"N/A",
        renderCell: (params) => {
          // console.log("PARAMSE",params.row.city?.attributes?.cityName )
          const value = params.row.city?.attributes?.cityName || "N/A";

          return <Tooltip title={value}>{value}</Tooltip>;
        },
      },
      {
        field: "wechtid",

        headerName: "WeChat Id",

        minWidth: 200,

        flex: 0.22,

        sortable: false,

        // dataGetter: (params) => params.row.weChatId || "N/A",
        valueGetter: (params) => params.row.weChatId||"N/A",
        renderCell: (params) => {
          // console.log("PARAMSE",params.row.city?.attributes?.cityName )
          const value = params.row.weChatId || "N/A";

          return <Tooltip title={value}>{value}</Tooltip>;
        },
      },
    ];

    result.push({
      field: "  ",

      headerName: "Actions",

      editable: false,

      minWidth: 150,

      flex: 0.22,

      maxWidth: 160,

      disableColumnMenu: true,

      disableExport: true,

      disableReorder: true,

      hideSortIcons: true,

      renderCell: (params) => {
        return (
          <>
            <IconButton
              size="large"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();

                onClickDeleteEmployee(params.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    });

    return result;
  };

  const onDelete = async (val) => {
    try {
      setLoading(true);
      // console.log(val)
      let response = await window.Platform.database.deleteSupplier(val);

      window.NotificationUtils.showSuccess("Supplier deleted successfully");

      fetchdata();

      setLoading(false);
    } catch (e) {
      setLoading(false);

      window.NotificationUtils.showError("Something went wrong");
      console.log(e.message);
    }
  };

  const onClickDeleteEmployee = (params) => {
    // console.log(`onClickDeleteEmployee`,params)

    window.confirmationModal(
      `Are you sure you want to deactivate ${params.name}

       (${params.id})?`,
      () => onDelete({ ...params, city: params.id })
    );
  };

  const setModalData = (params) => {
    setSelectedRow(params.row);

    setShowModal(true);
  };

  const handleAddCustomer = () => {
    setShowModal(true);

    setSelectedRow(null); 
  };

  const createCustomDataGrid = (rows, columns) => {
    // Define the custom toolbar function here

    const CustomToolbar = () => {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />

          <GridToolbarFilterButton />

          <GridToolbarDensitySelector />

          <GridToolbarExport
            csvOptions={{
              fileName: "SupplierList",
            }}
          />
        </GridToolbarContainer>
      );
    };

    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          loading={fetchLoading}
          checkboxSelection={true}
          rows={rows}
          columns={columns}
          rowHeight={70}
          onRowClick={(params, e) => setModalData(params)}
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <LoadingContainer>
        <CircularProgress color="primary" />
      </LoadingContainer>
    );
  };

  const renderTabContent = () => {
    // if (loading) {

    //   return renderLoading();

    // } else {

    return createCustomDataGrid(row, getSuppliercolumns());
  };

  // console.log("CUSTSB",Supplier)

  const renderModals = () => {
    return (
      <>
        {selectedRow && showModal && (
          <AddNewSupplier
            template={selectedRow}
            onSubmit={onSubmitSupplier}
            onCancel={() => setShowModal(false)}
            loading={subLoading}
            setLoading={setSubLoading}
          />
        )}

        {!selectedRow && showModal && (
          <AddNewSupplier
            template={null}
            onSubmit={onSubmitSupplier}
            onCancel={() => setShowModal(false)}
            loading={subLoading}
            setLoading={setSubLoading}
          />
        )}
      {bulk && <BulkSupplierAdd onClose={()=>setBulk(false)} fetchdata={()=>fetchdata()}/>}
      </>
    );
  };

  const renderHeader = () => {
    return (
      <Toolbar>
        <Typography variant="h5">Supplier Manager</Typography>

        <Stack direction={"row"} spacing={1}>
        <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={()=>setBulk(true)}
          >
            Add Bulk Supplier
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleAddCustomer}
          >
            Add Supplier
          </Button>
        </Stack >
      </Toolbar>
    );
  };

  return (
    <>
      {isMobile && <Drawer props={props}/>}

      <ContentContainer>
        {renderHeader()}

        {renderTabContent()}

        {renderModals()}
      </ContentContainer>

      {/* <StaffEditorPageContainer>

        <HeaderContainer>

          <Typography variant="h5">Supplier Manager</Typography>

        </HeaderContainer>

        <TableContainer>{renderTabContent()}</TableContainer>

        {renderModals()}

      </StaffEditorPageContainer> */}
    </>
  );
}
