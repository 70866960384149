
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { DATE_FORMATS, PRIMARY_COLOR, SHADOW } from "../../constants";
import { Button, IconButton, Tooltip, Typography } from "@mui/material"; // Update import to "@mui/material"
import {
    DataGrid,
    GridColDef,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
  } from "@mui/x-data-grid";
//   import GridToolbar from "@mui/x-data-grid/GridToolbar"; // Import GridToolbar separately
import AddStateModal from "../../components/modals/addStateModal";
import moment from "moment";
import { Delete } from "@mui/icons-material";


const isMobile = window.innerWidth < 900;

const ContentContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  flex-direction: column;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 145px);
  width: 100%;
  position: relative;
`;

const DataGridStyled = styled(DataGrid)`
  background: white;
  border-radius: 10px !important;
  box-shadow: ${SHADOW};
  .MuiDataGrid-overlay{
    .MuiCircularProgress-root{
      color: ${PRIMARY_COLOR}
    }
  }
`;

const Toolbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const StatesManager = (props) => {
  const [loading, setLoading] = useState(false);

  const [states, setStates] = useState([]);

  const statesRef = useRef();

  statesRef.current = states;

  const [selectedState, setSelectedState] = useState(null);


  const gridColumns =()=>{
    const results= [
    {
      field: "stateName",
      headerName: "State Name",
      
      minWidth: 200,
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        let name = params.row.stateName;
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        let name = moment(params.row.createdAt).format(DATE_FORMATS.DATE_WITH_TIME);
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
      filterable: false, 
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      valueGetter: (params) => {

        let name = moment(params.row.updatedAt).format(DATE_FORMATS.DATE_WITH_TIME);
        // console.log(name)
        return name
      
      },
      filterable: false, 
      renderCell: (params) => {
        let name = moment(params.row.updatedAt).format(DATE_FORMATS.DATE_WITH_TIME);
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
  ];
  results.push({

    field: '  ',

    headerName: 'Actions',

    editable: false,

    minWidth: 150,

    flex: 0.22,

    maxWidth:160,

    disableColumnMenu: true,

    disableExport: true,

    disableReorder: true, filterable: false, 

    hideSortIcons: true,

    renderCell: (params) => {

      return (

        <>

          <IconButton

            size="large"

            color="primary"

            onClick={(e) => {

              e.stopPropagation();

              onClickDeleteEmployee(params.row)

            }}

          >

           <Delete/>

          </IconButton>

         

        </>

      );

    },

  });
  return results;
}

const [rowCount, setRowCount] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 5,
});

const [queryOptions, setQueryOptions] = React.useState(null);

const onFilterChange = async(filterModel, page, pageSize) => {

  if(!filterModel)
  {
    // console.log("queryyyyy",queryOptions.filterModel)
    filterModel = queryOptions.filterModel
}
  try{  
    setLoading(true);
    // console.log(paginationModel,'................................................................')
    const response = await window.Platform.database.filterState({ ...filterModel,page:page || paginationModel.page, // Pass the current page
    pageSize: pageSize || paginationModel.pageSize })
    // console.log("respponse",response);
    setStates(response.items);
    setRowCount(response.totalCount)
    setLoading(false);
  }
  catch(err){
console.error(err)
setLoading(false);

  }
  // setQueryOptions({ filterModel: { ...filterModel } });
};

// console.log("onFilterChange",queryOptions)
// console.log(`setPaginationModel`,paginationModel)
const fetchStates = async () => {
  try {
    // console.log("Fetching states with pagination:", paginationModel);
    setLoading(true);
    const response = await window.Platform.database.fetchStates({
      page: paginationModel.page, // Pass the current page
      pageSize: paginationModel.pageSize, // Set your desired page size
      filterModel: { items: [] }, // Pass your filterModel if needed
    });
    setStates(response.states);
    setRowCount(response.totalCount)
    setLoading(false);
  } catch (error) {
    console.error(error);
    window.NotificationUtils.showError("Error fetching states");
    setLoading(false);
  }
};

useEffect(() => {
  // fetchStates();
},[])
  useEffect(() => {
    // console.log('check page', paginationModel)
    if(!queryOptions)
    fetchStates();
    else{

      onFilterChange(queryOptions, paginationModel.page, paginationModel.pageSize)
    // console.log('Fetching states with pagination',paginationModel.page);
    }
  }, [paginationModel.page]);


  const onCloseModal = (update=null) => {
    if (update) {
      let statesCopy = [...statesRef.current];
      let index = statesCopy.findIndex(
        (state) => state.id === update.id
      );
      if (index !== -1) {
        statesCopy[index] = update;
      } else {
        statesCopy.unshift(update);
      }
      setStates(statesCopy);
    }
    setSelectedState(null);
  };

  const onDelete=async(val)=>{

    try{
        let results = await window.Platform.database.deleteState(val)
        fetchStates();
    }
    catch(error)
    {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }

  }




  const onClickDeleteEmployee = (params) => {


    window.confirmationModal(

      `Are you sure you want to deactivate ${params.stateName}

       (${params.id})?`,()=> onDelete(params)

    );
    }

  const renderAddStateModal = () => {
    return (
      <AddStateModal
        closeModal={onCloseModal}
        selectedState={selectedState || {}}
      />
    );
  };

  const renderHeader = () => {
    return (
      <Toolbar>
        <Typography variant="h5">States Manager</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => setSelectedState({})}
          >
            Add State
          </Button>
        </div>
      </Toolbar>
    );
  };


  
  const renderData = () => {
    return (
      <DataGridContainer>
        <DataGridStyled
          rows={states}
          loading={loading}
          columns={gridColumns()}
          disableSelectionOnClick
          // autoPageSize
          checkboxSelection
          filterMode="server"
        onFilterModelChange={(val)=>
          {
            // console.log("vvvv",val);
            setQueryOptions(val);
          onFilterChange(val)
        }
        }

          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              csvOptions: {
                fileName: `statesList`,
              },
            },
          }}
          onRowClick={(rowParams, event) => {
            if (event.target.tagName !== "INPUT") {
              setSelectedState(rowParams.row);
            }
          }}
          rowCount={rowCount}
        
          pageSizeOptions={[5]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
         
        />
      </DataGridContainer>
    );
  };

  return (
    <>
      {isMobile && (
        <Drawer
          setShowNotifications={props.setShowNotifications}
          onAuthUserChanged={props.onAuthUserChanged}
          user={props.user}
          props={props}
        />
      )}
      <ContentContainer>
        {renderHeader()}
        {renderData()}
        {selectedState && renderAddStateModal()}
      </ContentContainer>
    </>
  );
};

export default StatesManager;