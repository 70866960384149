import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, Tab, Tabs, CircularProgress, FormControl, MenuItem, Select, InputLabel, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DATE_FORMATS, ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";
import { Add, Assessment, AssignmentLate, Done, Inventory, RemoveRedEyeRounded, Save, SaveAlt, Send, Update, UpdateRounded } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/WarehouseModal";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import PackagingListModal from "../../components/modals/packagingListModal";
import { useParams } from "react-router-dom";
import moment from "moment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import InvoiceAndPkl from "../../components/modals/invoiceAndPkl";
import CostingModal from "../../components/modals/CostingModal";
import DeclarationPaymentModal from "../../components/modals/declarationPayment";
import DeclarationPendingModal from "../../components/modals/declarationPayment";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BulkPayment from "../../components/modals/bulkPayment";
import PreviewIcon from '@mui/icons-material/Preview';
import PlannedSoModal from "../../components/modals/plannedSoModal";

const isMobile = window.innerWidth < 900;

const SHIPPING_ORDERS = [

]
const AIR_SHIPPING_ORDERS = [
    
]
const openPackaging = (val) => {
    window.open(val, '_blank');
  };





const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 205px);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const TabsContainer = styled.div`
//   ${isMobile &&
//   `
//     height: calc(100vh - 56px - 20px);
//   `}
// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function DeclarationAndPayment(props) {
  const { id } = 'abc';

  const [loading, setLoading] = useState(false);
  const [shippingOrders, setShippingOrders] = useState(SHIPPING_ORDERS);
  const [showPackagingListModal, setShowPackagingListModal] = useState();
  const [invoiceModal, setInvoiceModal] = useState(false)
  const [showItemsModal, setShowItemsModal] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
const [statusInventory, setStatusInventory] = useState(10)
const [showCostingModal, setShowCostingModal] = useState(false)
const [showDeclarationModal, setShowDeclarationModal] = useState(false)
const [bulkData, setBulkData] = useState(false)
const [containerDetails,setContainerDetails] = useState();


const declarationAndPaymentUpdate = async(val) => {
// console.log(val);
try {
  setLoading(true)
    let results = await window.Platform.database.declarationAndPaymentUpdate(val);
    fetchData()
    window.NotificationUtils.showSuccess('Marked as recieved')
    setLoading(false)
} catch (error) {
    console.error(error);
    setLoading(false)
}
}

const onSentButton = (val) => {

}

const onClose= () => {
    setShowCostingModal(false)
}

const onSubmit = async(val) => {
    try {
        setLoading(true)
        // console.log("vvvlll",val)
        let response = await window.Platform.database.costingAndBillingUpdate(val)
        fetchData()
        setLoading(false)
        onClose();
        window.NotificationUtils.showSuccess("Details Updated Successfully");
    } catch (error) {
        console.error(error)
        setLoading(false)
    }
    // console.log(val)
}

const onCloseDeclare= () => {
    setShowDeclarationModal(false)
}

const onSubmitDeclare = async(val) => {
    try {
        setLoading(true)
        // console.log("vvvlll",val)
        let response = await window.Platform.database.declarationAndPaymentUpdate(val)
        fetchData()
        setLoading(false)
        onClose();
      window.NotificationUtils.showSuccess("Details Updated Successfully");
        
    } catch (error) {
        console.error(error)
        setLoading(false)
    }
    // console.log(val)
}



useEffect(()=>{
    // console.log("id",id)
 fetchData()
}, [statusInventory,selectedTab])


const fetchData = async () => {
  try {
    setLoading(true)
    let results = await window.Platform.database.fetchDeclaration();
    // console.log("RESULTS",results)
    let tabvalue;

    if (selectedTab === 1) {
      tabvalue = results.filter(order => order.quotation.attributes.typeOfShipment === "Sea");
    } else if (selectedTab === 2) {
      tabvalue = results.filter(order => order.quotation.attributes.typeOfShipment === "Air");
    } else {
      tabvalue = results;
    }

    // console.log("TABVALUE", tabvalue);
    setShippingOrders(tabvalue);
  
    setLoading(false)
  } catch (err) {
    console.log(err);
    setLoading(false)
  }
};
// console.log("SHIPPING",shippingOrders) 

  const getInventoryColumns = () => {
    let result
    if(statusInventory == 10)
    {
     result = [
      
      {
        field: "id",
        headerName: "SO ID",
        sortable: false,
        width: 150,
        editable: true,
        
      },
      {
        field: "Declaration and Payment Status",
        headerName: "Declaration and Payment Status",
        width: 300,
        sortable: false,
        valueGetter: params => params.row?.declaration && params.row?.payment ? 'Updated' : 'Pending' || 'N/A',
        renderCell: params => {
          const value = params.row?.declaration && params.row?.payment? 'Updated' : 'Pending' || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
  
      },
      {
        field: "Costing Details Update",
        headerName: "Costing Details Update",
        width: 200,
        sortable: false,
        valueGetter: params => params.row?.costing? 'Updated' : 'Pending' || 'N/A',
        renderCell: params => {
          const value = params.row?.costing? 'Updated' : 'Pending' || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
  
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        sortable: false,
        width: 200,
        valueGetter: (params) => params.row.quotation.attributes.customerName,

      },
      {
        field: "supplierName",
        headerName: "Supplier Name",
        sortable: false,
        width: 200,
        valueGetter: (params) => params.row.quotation.attributes.supplierName,

      },
      {
        field: "createdAt",
        headerName: "Created At",
        sortable: false,
        width: 200,
        valueGetter: (params) => moment(params.row.createdAt).format(DATE_FORMATS.DEFAULT),
      },
      
      {
        field: "deliveryDate",
        headerName: "Expected Delivery Date",
        sortable: false,
        width: 200,
        valueGetter: (params) => moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT),
      },
      {
        field: "deliveryPlace",
        headerName: "Delivery Place",
        sortable: false,
        width: 200,
        valueGetter: (params) => params.row.quotation.attributes.deliveryPlace,

      },
      {
        field: "warehouseName",
        headerName: "Initial Warehouse Destination",
        sortable: false,
        width: 200,
        valueGetter: (params) => params.row.quotation.attributes.warehouseName,

      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        sortable: false,
        width: 200,
      },
      {
        field: "totalWeight",
        headerName: "Total Weight",
        sortable: false,
        width: 200,
      },
      {
        field: "totalCbm",
        headerName: "Total CBM",
        sortable: false,
        width: 200,
      },
      {
        field: "typeOfShipment",
        headerName: "Type of shipment",
        sortable: false,
        width: 200,
      },
      {
        field: "invoiceNumber",
        headerName: "Invoice Number",
        sortable: false,
        width: 200,
        valueGetter: (params) => params.row.invoiceNumber || 'N/A',

      },
      {
        field: "pklStatus",
        headerName: "Pkl Status",
        sortable: false,
        width: 200,
        valueGetter: (params) => params.row.pklStatus? 'Updated' : 'Pending',
      },
      ,{
        field: "containerDetails",
        headerName: "Container Details",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row.container.attributes || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <IconButton variant="extended" color="primary" onClick={() => setContainerDetails(value)} >
            <PreviewIcon/>
          </IconButton>
        }
      },
      {
        field: "packaginglist",
        headerName: "Packaging list",
        width: 200,
        headerAlign: "center", // Align the header text to the center
        align: "center",
        sortable: false,
        disableExport: true,
        valueGetter: (params) => params.row.quotation.attributes.packagingList,

        renderCell: params => {
          const value = params.row.quotation.attributes.packagingList || []
          // console.log(value)
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <IconButton variant="extended" color="primary" 
          disabled={!params.row.quotation.attributes.packagingList}
          onClick={(e) => {
            e.stopPropagation();
          openPackaging(value);}} >
           <RemoveRedEyeRounded/>
          </IconButton>
        }
      }



    ];

    result.push({
      field:"  ",
      headerName: 'Costing Details',
      headerAlign: 'center',
      editable: false,
      minWidth:200,
      disableColumnMenu:true,
      disableExport:true,
      disableReorder:true,
      hideSortIcons:true,
      renderCell: (params) => {
        return (
          // <PinCodeChangeButtonContainer>
          <div style={{margin: '0 auto', width:'auto'}}>
  
          
          <Tooltip title="Add Costing Details">
  
            <IconButton variant="extended" color="primary" 
               onClick={() => setShowCostingModal(params.row)} 
                   >
<RemoveRedEyeRounded/>
  </IconButton>
          </Tooltip>
                     
              </div>
  
         
        );
      },
    },)
    result.push({
        field:"   ",
        headerName: 'Declaration and Payment Update',
        headerAlign: 'center',
        editable: false,
        minWidth:300,
        disableColumnMenu:true,
        disableExport:true,
        disableReorder:true,
        hideSortIcons:true,
        renderCell: (params) => {
          return (
            // <PinCodeChangeButtonContainer>
            <div style={{margin: '0 auto', width:'auto'}}>
    
            
            <Tooltip title="Click to update">
    
              <IconButton variant="extended" color="primary" disabled={params.row.declaration}
                 onClick={() => setShowDeclarationModal(params.row)}
                     >
      <Update  />
  
    </IconButton>
            </Tooltip>
                       
                </div>
    
            // </PinCodeChangeButtonContainer>
          );
        },
      },)
  }
  else{

  result = [
    {
      field: "soId",
      headerName: "SO Id",
      sortable: false,
      width: 150,

      editable: true,
    },
    {
      field: "Costing Details Update",
      headerName: "Customer Name",
      width: 200,
      sortable: false,
      valueGetter: params => params.row?.costing? 'Updated' : 'Pending' || 'N/A',
      renderCell: params => {
        const value = params.row?.costing? 'Updated' : 'Pending' || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }

    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.customer?.name || 'N/A',
      renderCell: params => {
        const value = params.row.customer?.name || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }

    },
    {
      field: "customerMobile",
      headerName: "Customer Mobile",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.customer?.mobile || 'N/A',
      renderCell: params => {
        const value = params.row.customer?.mobile || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "customerCity",
      headerName: "Customer City",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.customer?.city || 'N/A',
      renderCell: params => {
        const value = params.row.customer?.city || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "supplierName",
      headerName: "Supplier Name",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.supplier?.name || 'N/A',
      renderCell: params => {
        const value = params.row.supplier?.name || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "supplierWechatNumber",
      headerName: "Supplier Wechat Number",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.supplier?.wechatNumber || 'N/A',
      renderCell: params => {
        const value = params.row.supplier?.wechatNumber || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.supplier?.deliveryDate || 'N/A',
      renderCell: params => {
        const value = params.row.supplier?.deliveryDate || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.status || 'N/A',
      renderCell: params => {
        const value = params.row.status || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "shipmentType",
      headerName: "Shipment Type",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.shipmentType || 'N/A',
      renderCell: params => {
        const value = params.row.shipmentType || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "items",
      headerName: "Items",
      width: 200,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        const value = params.row.items || []
        // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
        return <FabStyle variant="extended" color="primary" onClick={() => setShowItemsModal(value)} >
          View Items
        </FabStyle>
      }
    },
    {
      field: "packagingList",
      headerName: "Packaging List",
      width: 200,
      sortable: false,
      disableExport: true,
      valueGetter: (params) => params.row.quotation.attributes.packagingList,

      renderCell: params => {
        const value = params.row.quotation.attributes.packagingList || []
        // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
        return <FabStyle variant="extended" color="primary" 
        disabled={!params.row.quotation.attributes.packagingList}
        onClick={() => setShowPackagingListModal(value)} >
          View Packaging List
        </FabStyle>
      }
    },


  ];
  result.push({
    field:"  ",
    headerName: 'Actions',
    headerAlign: 'center',
    editable: false,
    minWidth:200,
    disableColumnMenu:true,
    disableExport:true,
    disableReorder:true,
    hideSortIcons:true,
    renderCell: (params) => {
      return (
        // <PinCodeChangeButtonContainer>
        <div style={{margin: '0 auto', width:'auto'}}>

        
        <Tooltip title="Click to generate">

          <FabStyle variant="extended" color="primary" 
          //  onClick={() => setInvoiceModal(true)}
            //  onClick={() => {
               
            //    window.NotificationUtils.showSuccess(
            //      "Changes successfully made"
            //      );
            //     }}
                 >
  <Update sx={{ mr: 1 }} />
Send to next warehouse
</FabStyle>
        </Tooltip>
                   
            </div>

        // </PinCodeChangeButtonContainer>
      );
    },
  },)
}

    return result
  }

// console.log("SELECTED TAB",selectedTab)

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "Declaration and Payment",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleTabChange = async (value) => {
    setShippingOrders([]);
    setSelectedTab(value);
    // if(value === TABS.SEA){
      setShippingOrders(SHIPPING_ORDERS)
    // }
    // else if(value === TABS.AIR)
    // {
    //   setShippingOrders(AIR_SHIPPING_ORDERS)
    // }
  }



  const renderTabContent = () => {
    
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={shippingOrders||[]}
          columns={getInventoryColumns()}
          rowHeight={70}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const renderModals = () => {
    return <>
      {showItemsModal && <InventoryItemsModal
        onClose={() => setShowItemsModal(null)}
        items={showItemsModal}
      />}
      {showPackagingListModal && <PackagingListModal
        onClose={() => setShowPackagingListModal(null)}
        list={showPackagingListModal}
      />}
            {/* { invoiceModal && <InvoiceAndPkl onCancel={onClose} onSubmit={(val) => onSubmit(val)} items={invoiceModal} />} */}
        { showCostingModal && <CostingModal onCancel={onClose} onSubmit={(val) => onSubmit(val)} items={showCostingModal} />}
        { showDeclarationModal && <DeclarationPaymentModal onCancel={onCloseDeclare} onSubmit={(val) => onSubmitDeclare(val)} items={showDeclarationModal} /> }
        {bulkData && <BulkPayment onClose={()=>setBulkData(false)} fetchData={()=>fetchData()} data={shippingOrders} />}
        {
        containerDetails && <PlannedSoModal mode={null} temp={containerDetails}  onClose={()=>setContainerDetails(null)}/>
      }
    </>
  }

  return (
    <>
      {isMobile && (
        <Drawer props={props}/>
      )}
      <StaffEditorPageContainer>
      {loading && <OpaqueLoading />}
        <HeaderContainer>
          <Typography variant="h5">Declaration and Payments Pending</Typography>
         { !isMobile&&<Button variant="contained" onClick={()=>setBulkData(true)}>Bulk Declaration and Payment</Button>
          }{/* <FormControl>
  <InputLabel id="demo-simple-select-label">Inventory Status</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    sx={{width: 150}}
    id="demo-simple-select"
    defaultValue={10}
    value={statusInventory || ''}
    label="Inventory Status"
    onChange={(e,value)=> setStatusInventory(e.target.value)}
  >
    <MenuItem value={10}>Pending SOs</MenuItem>
    <MenuItem value={20}>Recieved SOs</MenuItem>
  </Select>
</FormControl> */}
        </HeaderContainer>

        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="All Items" />
              <Tab label="Sea Inventories" />
              <Tab label="Air Inventories" />
            </StyledTabs>
            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
        {renderModals()}
      </StaffEditorPageContainer>

    </>
  );
}
