import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ERROR_CODES } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { useEffect } from "react";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const AddCustomerModal = ({ onCancel, loading, onSubmit, setLoading }) => {
    // const [loading, setLoading] = useState(false);
    const [form, setForm] = useState( {});
    const [cityOption,setCityOptions] = useState()
    const [apiLoading, setApiLoading] = useState(false)
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    };
    const [deliveryCity, setDeliveryCity] = useState()
    const [cityVal, setCityVal] = useState(["Delhi"]);
    const fetchCities = async () => {
      try {
        setApiLoading(true);
        let response = await window.Platform.database.fetchCities();
        setCityVal(response);
        setApiLoading(false);
      } catch (error) {
        console.error(error);
        window.NotificationUtils.showError("Error fetching cities");
        setApiLoading(false);
      }
    };
  
    const isValidIndiaMobileNumber = (mobileNumber) => {
      const indiaRegex = /^(\+?91[-\s]?)?[789]\d{9}$/;
      return indiaRegex.test(mobileNumber);
    };

    const checkForErrors = () => {
      // if(!form.customerName &&!form.customerCity && !form.customerMobile){
      //   window.NotificationUtils.showError("Please enter Details");
      // }
     
      if (form && !form.customerName) {
        window.NotificationUtils.showError("Please enter a Customer name");
        return true;
      }
      // if (!details.employeeCode) {
      //   window.NotificationUtils.showError(
      //     "Please enter an employee code for the user"
      //   );
      //   return true;
      // }
      if ( form &&!form.customerCity) {
        window.NotificationUtils.showError("Please Select a Customer City");
        return true;
      }
      if (form && !form.customerMobile) {
        window.NotificationUtils.showError(ERROR_CODES.NO_MOBILE);
        return true;
      }
  
      if (!form.customerMobile || 
        form.customerMobile.length > 15
        // isValidIndiaMobileNumber(form.customerMobile) == false
        
        ) {
        window.NotificationUtils.showError(ERROR_CODES.INVALID_MOBILE);
        return true;
      }
    
      return false;
    };

    useEffect(()=>{
      fetchCities()
    },[])

    const submitHandler = () => {
      if(checkForErrors()){
        return
      }
      onSubmit(form)
    };

    useEffect(()=>{
  
    },[loading])
  
    return (
      <StyledDialog disableEnforceFocus maxWidth="xs" fullWidth={true} open={true}>
      <StyledDialogContent>
      {apiLoading && <OpaqueLoading />}

        <ModalHeaderContainer>
          <Typography variant="h5">Add New Customer</Typography>
          <IconButton  onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {/* {loading && <OpaqueLoading />} */}
            <Stack>
              <TextField
              required
                variant="outlined"
                label="Customer Name"
                defaultValue={form["name"]}
                onChange={(e) =>
                  changeHandler("customerName", e.target.value)
                }
              />
            </Stack>
            <Stack>
             {/* <FormControl fullWidth variant="outlined">
  <InputLabel id="cityLabel">Select City</InputLabel>
  <Select
    labelId="cityLabel"
    id="citySelect"
    // value={district.id || ""}
    // value={cityVal || ""}
    label="Select City"
    // disabled={loading}
    onChange={(e) =>
      changeHandler("customerCity", e.target.value)
    }
  >
    {cityVal.map((cityEntry) => (
      <MenuItem value={cityEntry?.id} key={cityEntry?.id}>
        {cityEntry?.cityName} ({cityEntry?.state?.stateName})
      </MenuItem>
    ))}
  </Select>
</FormControl> */}
   <Autocomplete
   
  value={deliveryCity || null}
  options={cityVal}
  getOptionLabel={(option) => `${option?.cityName} (${option?.state?.stateName})`}
  getOptionValue={(option) => option?.id}
  // style={{ width: isMobile ? "90%" : "190px" }}
  noOptionsText={loading ? "Loading..." : "No option"}
  onChange={(event, value) => {
    const selectedCity = cityVal.find((city) => city?.id === value?.id);
    changeHandler("customerCity", selectedCity?.id || null);
    setDeliveryCity(selectedCity || null);
    // fields[13].current.focus();
  }}
  renderInput={(params) => (
    <TextField
    
      {...params}
      label="Customer City"
      variant="outlined"
      // inputRef={fields[12]}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (e.target.value) {
            const selectedCity = cityVal.find((city) => city?.id === e.target.value);
            changeHandler("customerCity", selectedCity?.id || null);
            setDeliveryCity(selectedCity || null);
            // fields[13].current.focus();
          }
        }
      }}
      required
    />
  )}
/>
            </Stack>
            {/* <Stack>
              <TextField
                variant="outlined"
                label="Customer City"
                value={form["name"]}
                onChange={(e) =>
                  changeHandler("deliveryPlace", e.target.value)
                }
              />
            </Stack> */}
            <Stack>
              <TextField
              required
                variant="outlined"
                label="Customer Mobile Number"
                defaultValue={form["name"]}
                onChange={(e) =>
                  changeHandler("customerMobile", e.target.value)
                }
              />
               {/* <Stack>
              <TextField
                variant="outlined"
                label=""
                value={form["name"]}
                onChange={(e) =>
                  changeHandler("name", e.target.value)
                }
              />
            </Stack> */}
            </Stack>
            {/* <Stack>
              <TextField
                variant="outlined"
                label="Value"
                value={form["value"]}
                onChange={(e) =>
                  changeHandler("value", e.target.value)
                }
              />
            </Stack>
            <Stack>
              <TextField
                variant="outlined"
                label="Error Message"
                value={form["errorMessage"]}
                onChange={(e) =>
                  changeHandler("errorMessage", e.target.value)
                }
              />
            </Stack> */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap="10px"
              align-items="center"
            >
              <Button color="error" onClick={onCancel}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                Create
              </Button>
            </Stack>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default AddCustomerModal;
  