import React from "react";

import Drawer from "../../components/common/drawer/drawer";

import styled from "styled-components";

import Typography from "@mui/material/Typography";

import { NavLink } from "react-router-dom";

import { PRIMARY_COLOR } from "../../constants";
import { Accessibility } from "@mui/icons-material";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 82px)`};

  width: 100%;

  background: none;

  padding: 10px 15px;

  ${isMobile &&
  `

    position: relative;

    top:10px;

    `}
`;

const HeaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: space-between;

  margin-bottom: 10px;
`;

const ShortcutsContainer = styled.div`
  padding: 20px;

  overflow: auto;

  height: ${isMobile ? "calc(100vh - 125px)" : "calc(100% - 43px)"};

  width: 100%;

  border-radius: 8px;

  border: solid 1px lightGrey;

  display: flex;

  gap: 20px;

  ${isMobile &&
  `

    flex-direction:column;

    align-items:center;

    `}
`;

const ShortcutCard = styled.div`
  height: 200px;

  width: 200px;

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
  &:hover {
    box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 20%),
      0px 3px 5px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  }
`;

const CardIcon = styled.i`
  font-size: 112px;

  color: ${PRIMARY_COLOR};
`;

const StyledCardHeading = styled(Typography)`
  text-transform: none;

  letter-spacing: 0.5px;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
`;

const SHORTCUTS = [
  {
    name: "Customer Management ",

    icon: "groups",

    pathTo: "/master-manager-customer",
  },

  {
    name: "Suppliers Management ",

    icon: "people",

    pathTo: "/master-manager-supplier",
  },

  // {
  //   name: "Items Management ",

  //   icon: "category",

  //   pathTo: "/master-manager-items",
  // },

  // {
  //   name: "Templates Management",

  //   icon: "format_indent_decrease",

  //   pathTo: "/master-manager-templates",
  // },
  {
    name: "States Management",

    icon: "travel_explore",

    pathTo: "/master-manager-states",
  },

  {
    name: "City Management",

    icon: "room",

    pathTo: "/master-manager-cities",
  },

  {
    name: "Districts Management",

    icon: "explore",

    pathTo: "/master-manager-districts",
  },
  {
    name: "Template Management",
    icon: "send",
    pathTo: "/master-manager-templates",
    // role: test && test["master-manager-templates"]?.read ? [auth.user.role] : [],
  },
];

const MastersManager = (props) => {
  const renderShortcut = (shortcut) => {
    return (
      <ShortcutCard>
        <StyledNavLink to={shortcut.pathTo || "/dashboard"}>
          <CardIcon className="icon material-icons">{shortcut.icon}</CardIcon>

          <StyledCardHeading variant="h6" align="center">
            {shortcut.name}
          </StyledCardHeading>
        </StyledNavLink>
      </ShortcutCard>
    );
  };

  return (
    <>
      {isMobile && (
        <Drawer
          setShowNotifications={props.setShowNotifications}
          onAuthUserChanged={props.onAuthUserChanged}
          user={props.user}
          props = {props}
        />
      )}

      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Masters Manager</Typography>

          <div></div>
        </HeaderContainer>

        <ShortcutsContainer>
          {SHORTCUTS.map((shortcut) => renderShortcut(shortcut))}
        </ShortcutsContainer>
      </StaffEditorPageContainer>
    </>
  );
};

export default MastersManager;
