import React, { useEffect, useState } from "react";

import {

  Box,

  Button,

  Checkbox,

  CircularProgress,

  Dialog,

  DialogContent,

  FormControl,

  FormControlLabel,

  FormGroup,

  IconButton,

  InputLabel,

  MenuItem,

  Select,

  TextField,

  Typography,

  Fab,

  DialogActions

} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";

import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp } from "@mui/icons-material";

import { CloseOutlined } from "@mui/icons-material";

import { Stack } from "@mui/material";

import styled from "styled-components";

import { ACTIVITY_STATUS, DATE_FORMATS } from "../../constants";

import moment from "moment";

import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

import ApprovalRemarkModal from "../../components/modals/ApprovalRemarkModal";

import RejectionReasonModal from "../../components/modals/rejectionReason";

import { useParams } from "react-router-dom";




const isMobile = window.innerWidth < 900;







const ModalHeaderContainer = styled.div`

  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;

  padding: 10px;

`;




const InputFieldContainer = styled.div`

  margin: 20px 0px;

  display: flex;

  justify-content: center;

  align-items: center;

  gap: 10px;

  label {

    margin-bottom: 0px;

  }

`;




const ModalActionsContainer = styled.div`

  display: flex;

  align-items: center;

  justify-content: center;

  margin-top: 20px;

  margin-bottom: 20px;

`;




const FirstButton = styled(Button)`

  margin-right: 10px !important;

`;




const StyledDialogContent = styled(DialogContent)`

  padding: 16px;

`;




const StyledDialog = styled(Dialog)`

  position: relative;

`;




const FabStyle = styled(Fab)`

  && {

    z-index: 1;

  }

`;




const DataGridContainer = styled.div`

  width: 100%;

  margin-bottom: 20px;

 

//   height: 500px;

  padding:10px;

`;




const NoteText = styled(Typography)`

  margin-top: 10px !important;

`;




const Input = styled.input`

  display: none;

`;




const StaffEditorPageContainer = styled.div`

  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};

  width: 100%;

  background-color: white;

  padding: 10px 15px;

  ${isMobile &&

  `

      position: relative;

 

      `}

`;




const QuotationCustomerApproval = () => {




  const [approvalModal, setApprovalModal] = useState(false)
  const [loading, setLoading] = useState(false);
  // const [rejectionModal, setRejectionModal] = useState(false)

  const [rejectModal, setRejectModal] = useState(false)

  const [items, setItems] = useState()

  const [row, setRow] = useState([])
  const [ totalCTN, setTotalCTN] = useState(0)


  const TotalAmountTypography = styled(Typography)`

  position: absolute;

  // bottom: 10px;

  right: 22px;


  font-size: ${isMobile ? "14px" : "inherit"};

  bottom: ${items?.status === "customerApproved" || items?.status === "customerRejected"?"":"100px"};

  // right: ${isMobile ? "15px" : "50px"};

`;

  const getInviteId = () => {
    let queryParams = "";
    try {
      const url = new URL(window.location.href);
      queryParams = url.search.toString().slice(1);
    } catch (e) {
      console.error(e);
    }
    return queryParams;
  };

  const id = getInviteId();




  const onClose = () => {

    setRejectModal(null)

    setApprovalModal(null)

  }

  const onReject = () => {
  }

  const onSubmit = async (val) => {
    // Add your logic for form submission or any other actions here
    try {
      setLoading(true)
      let results = await window.Platform.database.rejectCustomerQuotation({ id: val.id, reason: val.reason })
      window.NotificationUtils.showSuccess("Quotation Rejected")
      // window.NotificationUtils.showSuccess("Quotation Approved",val.id)
      // return results
    }
    catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      setLoading(false)
      fetchQuotationbyID();
      setRejectModal(false)
    }
  };

  const submitApprove = async (val) => {

    try {
      setLoading(true)

      let results = await window.Platform.database.approveCustomerQuotation(val.id)
      // return results
      window.NotificationUtils.showSuccess("Quotation Approved")
    }
    catch (error) {
      console.log('error', error);
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      setLoading(false)
      fetchQuotationbyID();
      // setShowItemsModal(null)
    }
  }

  const fetchQuotationbyID = async () => {

    setLoading(true);

    try {

      const response = await window.Platform.database.fetchQuotationbyID({ id });
console.log('ctn',response.items)
let totalCTNS = 0;
response.items.map((val) => totalCTNS += +val.ctn)

setTotalCTN(totalCTNS)
      if(response)
      {
      setItems(response)

      setRow(response.items)


      }
      else
      window.NotificationUtils.showError('Link Expired')
    }

    catch (err) {

      console.log(err)

    }
    finally {
      setLoading(false);
    }

  }



  useEffect(() => {

    fetchQuotationbyID()

  }, [])




  const getItemColumns = () => {

    let result = [

      {

        field: "item",

        headerName: "Name",

        sortable: false,

        width: 160,

        editable: true,

      },
      {
        field: "shippingMark",
        headerName: "Shipping Mark",
        sortable: false,
        width: 150,
        valueGetter: (params) => 
        {
          console.log(params.row)
         return params.row?.shippingMark || 'N/A'
        }

        // editable: true,
      },

      {

        field: "category",

        headerName: "Category",

        sortable: false,

        width: 160,

        editable: true,

      },

      {

        field: "volume",

        headerName: "Volume",

        sortable: false,

        width: 130,

        editable: true,

      },

      {

        field: "qty",

        headerName: "Quantity",

        sortable: false,

        width: 130,

        editable: true,

      },

      {

        field: "ctn",

        headerName: "Carton",

        sortable: false,

        width: 130,

        editable: true,

      },

      {

        field: "weight",

        headerName: "Weight",

        sortable: false,

        width: 130,

        editable: true,

      },

      {

        field: "cbm",

        headerName: "Cubic Meter",

        sortable: false,

        width: 130,

        editable: true,

      },

      {

        field: "unitOfAmount",

        headerName: "Unit of Amount",

        sortable: false,

        width: 130,

        editable: true,

      },

      {

        field: "rate",

        headerName: "Rate",

        sortable: false,

        width: 130,

        editable: true,

      },

      {

        field: "amount",

        headerName: "Amount",

        sortable: false,

        width: 150,

        editable: true,

      },

    ];




    return result;

  };




  function CustomToolbar() {

    return (

      <GridToolbarContainer>

        <GridToolbarColumnsButton />

        <GridToolbarFilterButton />

        <GridToolbarDensitySelector />

        <GridToolbarExport

          csvOptions={{

            fileName: "Quatation CustomerApproval",

          }}

        />

      </GridToolbarContainer>

    );

  }




  const generateSO = async (val) => {


    // try {

    //   console.log(val);

    //   // let results = await window.fdatabase.approveAdminQuotation(val)

    //   window.NotificationUtils.showSuccess("Quotation Approved");

    //   // return results

    // } catch (error) {

    //   window.NotificationUtils.showError(error);

    // }

  }




  const renderContent = (params) => {

    return (

      <>

        <DataGridContainer>

          {/* <Box sx={{ height: "100%", width: "100%" }}> */}

          <DataGrid

            className="payrollGrid"

            sx={{ height: isMobile ? "300px" : "400px" }}

            checkboxSelection={true}

            rows={row || []}

            columns={getItemColumns()}

            rowHeight={30}

            disableSelectionOnClick

            disableRowSelectionOnClick

            autoPageSize

            components={{

              Toolbar: CustomToolbar,

            }}

          />

          {/* </Box> */}

        </DataGridContainer>




        <ModalActionsContainer>

          <TotalAmountTypography variant={isMobile ? "body2" : "button"}><strong>Total Amount:</strong> {items?.totalAmount}</TotalAmountTypography>

          {
            items?.status === "customerApproved" || items?.status === "customerRejected"?null:
            <div style={{ textAlign: "center", marginRight: 8, display: "flex", gap: "10px", marginTop: isMobile ? "35px" : "30px" }}>

            <Tooltip title="Click to reject">

              <FabStyle

                variant="extended"

                color="primary"

                onClick={() => {

                  onClose()

                  onReject(items?.[0])

                  setRejectModal(' ')



                }

                }

              >

                <Close sx={{ mr: 1 }} /> {/* Replace the SaveAlt icon with the CheckIcon */}

                Reject

              </FabStyle>

            </Tooltip>

            <Tooltip title="Click to Approve">

              <FabStyle

                variant="extended"

                color="primary"

                onClick={() => {

                  onClose()

                  // setApprovalModal(' ')

                  submitApprove(items);

                  // onSubmit(items)
                }}

              >

                <CheckIcon sx={{ mr: 1 }} /> {/* Replace the SaveAlt icon with the CheckIcon */}

                Approve

              </FabStyle>

            </Tooltip>

          </div>
          }

        </ModalActionsContainer>

      </>

    );

  };






  return (

    // <Dialog open={true} onClose={onClose} maxWidth="lg">

    <StaffEditorPageContainer>

      <ModalHeaderContainer style={{ marginBottom: 0, paddingBottom: 0 }}>

        <Typography variant="h5">

          {/* {items?.customerName} */}

          Please verify your quotation

          {/* <Tooltip title="Quotation Id">




            {"(" + id + ")"}

          </Tooltip> */}



        </Typography>



      </ModalHeaderContainer>




      <div style={{ padding: "10px", marginLeft: "10px", display: isMobile ? "-ms-grid" : "flex", gap: "10px", maxHeight: "500px" }}>

        {/* First Column */}

        <div style={{ flex: !isMobile && "1 1 40%", textAlign: "left" }}>



          <Typography variant={isMobile && "body2"}>

            <strong>Quotation Id:</strong> {items?.quotationId}

          </Typography>

          {!isMobile && <br />}

          <Typography variant={isMobile && "body2"}>

            <strong>Created At:</strong> {moment(items?.createdAt).format(DATE_FORMATS.DEFAULT)}

          </Typography>

          {!isMobile && <br />}

          <Typography variant={isMobile && "body2"}>

          <strong>Delivery City:</strong> {items?.quotationValue?.deliveryCity?.attributes?.cityName || "N/A"}

          </Typography>

          {!isMobile && <br />}




          {/* <Typography variant={isMobile && "body2"}>

            <strong>Customer ID:</strong> {items?.customerId}

          </Typography> */}

          {/* {!isMobile && <br />} */}




          <Typography variant={isMobile && "body2"}><strong>Type of shipment:</strong> {items?.typeOfShipment}</Typography>

          {!isMobile && <br />}

          <Typography variant={isMobile && "body2"}><strong>Initial Warehouse Destination:</strong> {items?.warehouseName}</Typography>
          {/* <strong></strong>  */}



          {/* 

           <strong>Expected Delivery Date:</strong> {items.deliveryDate}

         </Typography>

         <Typography>

           <strong>Delivery Place:</strong> {items.deliveryPlace}

         </Typography>

         <Typography>

           <strong>Initial Warehouse Destination:</strong> {items.warehouseName}

         </Typography>

         <Typography>

           <strong>Total Weight:</strong> {items.totalWeight}

         </Typography> */}

        </div>

        <div style={{ flex: !isMobile && "1 1 45%", textAlign: "left" }}>

          <Typography variant={isMobile && "body2"}>

            <strong>Expected Delivery Date:</strong> {moment.unix(items?.deliveryDate).format(DATE_FORMATS.DEFAULT)}

          </Typography>

          {!isMobile && <br />}




          <Typography variant={isMobile && "body2"}>

            <strong>Delivery Place:</strong> {items?.deliveryPlace || "N/A"}

          </Typography>
          

          {!isMobile && <br />}
          <Typography variant={isMobile && "body2"}><strong>Total CBM:</strong> {items?.totalCbm}</Typography>


          <Typography variant={isMobile && "body2"}>


</Typography>

{!isMobile && <br />}

          <Typography variant={isMobile && "body2"}>

            {/* {!isMobile && <br />} */}

<Typography variant={isMobile && "body2"}><strong>Total CTNS:</strong> {totalCTN}</Typography>

          </Typography>

          {!isMobile && <br />}




          <Typography variant={isMobile && "body2"}>

            <strong>Total Weight:</strong> {items?.totalWeight}

          </Typography>

          {!isMobile && <br />}




        </div>




        {/* Second Column */}

        <div style={{ flex: !isMobile && "1 1 20%", textAlign: "left" }}>

          <Typography variant={isMobile && "body2"}><strong>Supplier Name:</strong> {items?.supplierName}</Typography>

          {!isMobile && <br />}




          <Typography variant={isMobile && "body2"}><strong>Customer Name:</strong> {items?.customerName}</Typography>

          {/* {!isMobile && <br />} */}









          {/* <strong></strong>  */}

          {!isMobile && <br />}



        </div>

      </div>

      {/* <Typography variant={isMobile && "body2"} style={{ padding: "10px",marginLeft:"10px", display: isMobile ?"-ms-grid":"flex", gap: "10px", maxHeight:"500px" }}><strong>Remark:</strong> {items?.[0]?.remark}</Typography> */}

      {renderContent()}





      {approvalModal && <ApprovalRemarkModal onCancel={onClose} />}

      {rejectModal && <RejectionReasonModal onCancel={onClose} onSubmit={() => onSubmit(items)} items={items} />}


      {
        loading && <OpaqueLoading />
      }
    </StaffEditorPageContainer>

  );

};




export default QuotationCustomerApproval;