// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Checkbox,
//   CircularProgress,
//   Dialog,
//   DialogContent,
//   FormControl,
//   FormControlLabel,
//   FormGroup,
//   IconButton,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   Typography,
//   Fab,
//   DialogActions
// } from "@mui/material";
// import Tooltip from "@mui/material/Tooltip";
// import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp } from "@mui/icons-material";
// import { CloseOutlined } from "@mui/icons-material";
// import { Stack } from "@mui/material";
// import styled from "styled-components";
// import { ACTIVITY_STATUS, DATE_FORMATS } from "../../constants";
// import moment from "moment";
// import OpaqueLoading from "../opaqueLoading/opaqueLoading";
// import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

// const isMobile = window.innerWidth < 900;

// const ModalHeaderContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 15px;
//   padding: 10px;
// `;

// const InputFieldContainer = styled.div`
//   margin: 20px 0px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   label {
//     margin-bottom: 0px;
//   }
// `;

// const ModalActionsContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 20px;
//   margin-bottom: 20px;
// `;

// const FirstButton = styled(Button)`
//   margin-right: 10px !important;
// `;

// const StyledDialogContent = styled(DialogContent)`
//   padding: 16px;
// `;

// const StyledDialog = styled(Dialog)`
//   position: relative;
// `;

// const FabStyle = styled(Fab)`
//   && {
//     z-index: 1;
//   }
// `;

// const DataGridContainer = styled.div`
//   width: 100%;
//   margin-bottom: 20px;
  
// //   height: 500px;
//   padding:10px;
// `;

// const NoteText = styled(Typography)`
//   margin-top: 10px !important;
// `;

// const Input = styled.input`
//   display: none;
// `;

// const ShowMultipleSoModal = ({ onClose, items ,onSubmit, onReject}) => {
//   const getItemColumns = () => {
//     let result = [
//         {
//             field: "id",
//             headerName: "Quotation ID",
//             sortable: false,
//             width: 150,
//             editable: true,
//           },
//           {
//             field: "customerId",
//             headerName: "Customer Code",
//             sortable: false,
//             width: 200,
//           },
//           {
//             field: "customerName",
//             headerName: "Customer Name",
//             sortable: false,
//             width: 200,
//           },
//           {
//             field: "supplierName",
//             headerName: "Supplier Name",
//             sortable: false,
//             width: 200,
//           },
//           {
//             field: "deliveryDate",
//             headerName: "Expected Delivery Date",
//             sortable: false,
//             width: 200,
//             valueGetter: (params) => moment(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT),
//           },
//           {
//             field: "deliveryPlace",
//             headerName: "Delivery Place",
//             sortable: false,
//             width: 200,
//           },
//           {
//             field: "warehouseName",
//             headerName: "Initial Warehouse Destination",
//             sortable: false,
//             width: 200,
//           },
//           {
//             field: "totalAmount",
//             headerName: "Total Amount",
//             sortable: false,
//             width: 200,
//           },
//           {
//             field: "totalWeight",
//             headerName: "Total Weight",
//             sortable: false,
//             width: 200,
//           },
//           {
//             field: "totalCbm",
//             headerName: "Total CBM",
//             sortable: false,
//             width: 200,
//           },
//           {
//             field: "typeOfShipment",
//             headerName: "Type of shipment",
//             sortable: false,
//             width: 200,
//           },
//     ];

//     return result;
//   };

//   function CustomToolbar() {
//     return (
//       <GridToolbarContainer>
//         <GridToolbarColumnsButton />
//         <GridToolbarFilterButton />
//         <GridToolbarDensitySelector />
//         <GridToolbarExport
//           csvOptions={{
//             fileName: "items-list",
//           }}
//         />
//       </GridToolbarContainer>
//     );
//   }

//   const generateSO = async(val) => {
//     console.log(val)
//         // try {
//     //   console.log(val);
//     //   let results = await window.fdatabase.approveAdminQuotation(val)
//     //   window.NotificationUtils.showSuccess("Quotation Approved");
//     //   // return results
//     // } catch (error) {
//     //   window.NotificationUtils.showError(error);
//     // }
//   }

//   const renderContent = (params) => {
//     return (
//       <>
//         <DataGridContainer>
//           {/* <Box sx={{ height: "100%", width: "100%" }}> */}
//             <DataGrid
//               className="payrollGrid"
//               sx={{height:isMobile ? "300px":"300px"}}
//               checkboxSelection={true}
//               rows={items || []}
//               columns={getItemColumns()}
//               rowHeight={30}
//               disableSelectionOnClick
//               disableRowSelectionOnClick
//               autoPageSize
//               components={{
//                 Toolbar: CustomToolbar,
//               }}
//             />
//           {/* </Box> */}
//         </DataGridContainer>

//         <ModalActionsContainer>
//           <div style={{ textAlign: "center", marginRight: 8, display:"flex", gap: "10px" }}>
//             <Tooltip title="Click to generate">
//               <FabStyle
//                 variant="extended"
//                 color="primary"
//                 onClick={() => {
//                 //   onReject(items)
//                   onClose()
                  
//                 }
//                 }
//               >
//                 <Close sx={{ mr: 1 }} /> {/* Replace the SaveAlt icon with the CheckIcon */}
//                 Cancel
//               </FabStyle>
//             </Tooltip>
//             <Tooltip title="Click to generate">
//               <FabStyle
//                 variant="extended"
//                 color="primary"
//                 onClick={() => {
//                   onClose()
//                   onSubmit(items)}}
//               >
//                 <CheckIcon sx={{ mr: 1 }} /> {/* Replace the SaveAlt icon with the CheckIcon */}
//                 Continue
//               </FabStyle>
//             </Tooltip>
//           </div>

          
//         </ModalActionsContainer>
//       </>
//     );
//   };

//   return (
//     <Dialog open={true} onClose={onClose} maxWidth="lg">
//     <ModalHeaderContainer style={{marginBottom:0,paddingBottom:0}}>
//        <Typography  variant="h5">
//          {/* {items?.customerName} */}
//           Multiple SOs
        
//            </Typography>
//        <IconButton onClick={onClose}>
//          <CloseOutlined />
//        </IconButton>
//      </ModalHeaderContainer>

    
//      {renderContent()}

//    </Dialog>
//   );
// };

// export default ShowMultipleSoModal;
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Card
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

const isMobile = window.innerWidth < 900;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;

const DataGridContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  
//   height: 500px;
  padding:10px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const StyledCard = styled(Card)`
  position: relative;
//   width: 90%;
padding: 20px
margin-bottom:40px;
`;
const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 20px;
`;



const ShowMultipleModal = ({ onClose, items, onSubmit, loading, multipleData }) => {
  const getItemColumns = () => {
    let result = [
      {
        field: "item",
        headerName: "Name",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "category",
        headerName: "Category",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "volume",
        headerName: "Volume",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "qty",
        headerName: "Quantity",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "ctn",
        headerName: "Carton",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "weight",
        headerName: "Weight",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "cbm",
        headerName: "Cubic Meter",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "unitOfAmount",
        headerName: "Unit of Amount",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        width: 150,
        // editable: true,
      },
    ];

    return result;
  };
// useEffect(()=>{
//     console.log("dfdfdf",items)
// })
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "items-list",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const generateSO = async(val) => {
    // console.log("generating",val)
    onSubmit(val)
    // console.log(val)
        // try {
    //   console.log(val);
    //   // let results = await window.fdatabase.approveAdminQuotation(val)
    //   window.NotificationUtils.showSuccess("Quotation Approved");
    //   // return results
    // } catch (error) {
    //   window.NotificationUtils.showError(error);
    // }
  }

  const renderContent = (params) => {
    return (
      <>
        <DataGridContainer>
          {/* <Box sx={{ height: "100%", width: "100%" }}> */}
            <DataGrid
              className="payrollGrid"
              sx={{height:isMobile ? "300px":"300px"}}
              checkboxSelection={true}
              rows={params || []}
              columns={getItemColumns()}
              rowHeight={30}
              disableSelectionOnClick
              disableRowSelectionOnClick
              autoPageSize
             
            />
          {/* </Box> */}
        </DataGridContainer>
        {/* {loading && <OpaqueLoading />} */}
       
      </>
    );
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg" PaperProps={{

        style: isMobile

          ? {}

          : {

              minWidth: 1100,

              maxWidth: 640,

              minHeight: 500,

              maxHeight: 550,
              backgroundColor: "#f5f5f5"

            }, 

      }}>
    <ModalHeaderContainer style={{marginBottom:0,paddingBottom:0}}>
       <Typography  variant="h5">
          Multiple SO(s)
           
           </Typography>
          <IconButton disabled={loading} onClick={onClose}>
            <CloseOutlined />
          </IconButton>
     
     </ModalHeaderContainer>
<div style={{maxHeight:400,overflowY:"scroll"}}>
     {items.map((val) => (
        <>
        <ItemContainer>
<StyledCard > 
  <div
    key={val.id}
    style={{ padding: "10px", marginLeft: "10px", display: isMobile ? "-ms-grid" : "flex", gap: "10px", maxHeight: "500px" }}
  >
    {/* First Column */}
    <div style={{ flex: !isMobile && "1 1 40%" }}>
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Created At:</strong> {moment(val.createdAt).format("YYYY-MM-DD")}
      </Typography>
      {!isMobile && <br />}
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Customer Name:</strong> {val.customerName}
      </Typography>
      {!isMobile && <br />}
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Customer ID:</strong> {val.customerId}
      </Typography>

    </div>
    <div style={{ flex: !isMobile && "1 1 45%" }}>
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Expected Delivery Date:</strong> {moment.unix(val.deliveryDate).format("YYYY-MM-DD")}
      </Typography>
      {!isMobile && <br />}
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Delivery Place:</strong> {val.deliveryPlace}
      </Typography>
      {!isMobile && <br />}
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Initial Warehouse Destination:</strong> {val.warehouseName}
      </Typography>
      {!isMobile && <br />}
      {/* <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Total Weight:</strong> {val.totalWeight}
      </Typography>
      {!isMobile && <br />} */}
    </div>

    {/* Second Column */}
    <div style={{ flex: !isMobile && "1 1 20%" }}>
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Supplier Name:</strong> {val.supplierName}
      </Typography>
      {!isMobile && <br />}
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Supplier ID:</strong> {val.supplierId}
      </Typography>
      {!isMobile && <br />}
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Type of shipment:</strong> {val.typeOfShipment}
      </Typography>
      {/* <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Total Amount:</strong> {val.totalAmount}
      </Typography>
      {!isMobile && <br />}
      <Typography variant={isMobile ? "body2" : "inherit"}>
        <strong>Total CBM:</strong> {val.totalCbm}
      </Typography>
      {!isMobile && <br />} */}
      
    </div>
  </div>
{renderContent(val.items)}
</StyledCard>
</ItemContainer>
</>

))}
</div>

   
     {/* {renderContent()}
    })} */}
     

     <ModalActionsContainer>
          <div style={{ textAlign: "center", marginRight: 8 }}>
            <Tooltip title="Click to generate">
              <FabStyle
                variant="extended"
                color="primary"
                onClick={() => generateSO(multipleData)}
              >
                <CheckIcon sx={{ mr: 1 }} /> {/* Replace the SaveAlt icon with the CheckIcon */}
                Generate
              </FabStyle>
            </Tooltip>
          </div>

          
        </ModalActionsContainer>

     

   </Dialog>
  );
};

export default ShowMultipleModal;
