import react, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import styled from "styled-components";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import Drawer from "../../components/common/drawer/drawer";

import DeleteIcon from "@mui/icons-material/Delete";

import {
  Typography,
  Button,
  Checkbox,
  Fab,
  Tab,
  Tabs,
  CircularProgress,
  IconButton,
  Popover,
  MenuItem,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import {
  DATE_FORMATS,
  ERROR_COLOR,
  SHIPMENT_TYPES,
  SO_STATUS,
} from "../../constants";

import { Add, Inventory, Save, SaveAlt } from "@mui/icons-material";

import WarehouseModal from "../../components/modals/WarehouseModal";

import InventoryItemsModal from "../../components/modals/inventoryItemsModal";

import PackagingListModal from "../../components/modals/packagingListModal";

import { useParams } from "react-router-dom";

import AddNewSupplier from "../../components/modals/AddNewMasterSupplier";
import moment from "moment";
import QuotationAdmin from "../../components/modals/QuotationAdmin";
import QuotationCustomer from "../../components/modals/customerApprovalQuoation";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};

  width: 100%;

  background-color: white;

  padding: 10px 15px;

  ${isMobile &&
  `

      position: relative;




      `}
`;

const ContentContainer = styled.div`
  display: flex;

  gap: 10px;

  padding: 10px 15px;

  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: ${isMobile ? "flex-start" : "space-between"};

  margin-bottom: 10px;

  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );

  width: 100%;

  border: solid 1px lightGrey;

  border-radius: 8px;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const AvatarContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 145px);

  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);

  display: flex;

  justify-content: center;

  align-items: center;
`;

// const TabsContainer = styled.div`

//   ${isMobile &&

//   `

//     height: calc(100vh - 56px - 20px);

//   `}

// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;

const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;

const StyledHeader = styled.div`
  display: flex;

  justify-content: center;

  align-items: center;
`;

const Toolbar = styled.div`
  width: 100%;

  display: flex;

  align-items: center;

  justify-content: space-between;

  gap: 10px;
`;

export default function QuotationApprovalCustomer(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [Supplier, setSupplier] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalData, setModalData] = useState(null);

  const [showPackagingListModal, setShowPackagingListModal] = useState();

  const [showItemsModal, setShowItemsModal] = useState();

  const [openPopover, setOpenPopover] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [row, setRow] = useState([]);

  const [subLoading, setSubLoading] = useState(false);
  const [propData, setPropData] = useState([]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const fetchdata = async () => {
    try {
      setLoading(true);
      const response = await window.Platform.database.fetchQuotationPendingAndAdminpening();
      // let setrows = [];
      // let setrows=[
      //         {
      //           id:response.id,
      //           customerId:response.attributes.customerId ,
      //           customerName: response.attributes.customerName,
      //           supplierName: response.attributes.supplierName,
      //           deliveryDate: response.attributes.deliveryDate,
      //           deliveryPlace: response.attributes.deliveryPlace,
      //           warehouseName: response.attributes.warehouseName,
      //           totalAmount: response.attributes.totalAmount,
      //           totalWeight: response.attributes.totalWeight,
      //           totalCbm: response.attributes.totalCbm,
      //           typeOfShipment: response.attributes.typeOfShipment,
      //           data:response.attributes


      //         }]
      // setPropData(response.attributes)
      setRow(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const onSubmitSupplier = async (form) => {
    if (selectedRow) form = { ...form, id: selectedRow.id };
    try {
      setSubLoading(true);

      // let sent_data={customerName: form.customerName, customerCity: form.customerCity, customerMobile:form.customerMobile}

      // let response_Data={customerDetails:sent_data}

      let response = await window.Platform.database.createNewMasterSupplier(
        form
      );
      window.NotificationUtils.showSuccess("Supplier added to the system");

    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    } finally {
      setShowModal(false);
      setSubLoading(false);
      fetchdata();
    }
  };

  const getEmployeeColumns = () => {
    let result = [
      {

        field: "id",

        headerName: "Quotation ID",

        minWidth: 200,




        flex: 0.22,

        sortable: false,

        dataGetter: (params) => {
          // console.log("params in dataGetter:", params);
          return params.row.customer?.mobile || 'N/A';
        },
        renderCell: (params) => {
          // console.log("params in dataGetter:", params);
          const value = params.row?.quotationId || 'N/A'

          return <Tooltip title={value}>{value}</Tooltip>

        }

      },
      {
        field: "status",
        headerName: "Status",
        sortable: false,
        width: 200,
        dataGetter: (params) => {
          // console.log("params in dataGetter:", params);
          return params.row.status || 'N/A';
        },
        renderCell: (params) => {
          switch (params.row.status) {
            case "adminApproved":
              return "Pending at Customer end";
            case "pending":
              return "Pending at Admin end";
            default:
              return "N/A";
          }
        }
      },


      {
        field: "customerId",
        headerName: "Customer Code",
        sortable: false,
        width: 200,
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        sortable: false,
        width: 200,
      },
      {
        field: "supplierName",
        headerName: "Supplier Name",
        sortable: false,
        width: 200,
      },
      {

        field: "createdAt",

        headerName: "Created At",

        minWidth: 200,




        flex: 0.22,

        sortable: false,

        dataGetter: (params) => {
          // console.log("params in dataGetter:", params);
          return params.row.status || 'N/A';
        },
        renderCell: (params) => {
          // console.log("params in dataGetter:", params);
          const value = params.row.createdAt || 'N/A'

          return <Tooltip title={moment(value).format(DATE_FORMATS.DEFAULT)}>{moment(value).format(DATE_FORMATS.DEFAULT)}</Tooltip>

        }

      },
      {
        field: "deliveryDate",
        headerName: "Expected Delivery Date",
        sortable: false,
        width: 200,
        valueGetter: (params) =>
          moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT),
      },
      {

        field: "deliveryPlace",

        headerName: "Delivery Place",

        minWidth: 200,




        flex: 0.22,

        sortable: false,

        dataGetter: (params) => {
          // console.log("params in dataGetter:", params);
          return params.row.deliveryPlace || 'N/A';
        },
        renderCell: (params) => {
          // console.log("params in dataGetter:", params);
          const value = params.row.deliveryPlace || 'N/A'

          return <Tooltip title={value}>{value}</Tooltip>

        }

      },
      // {
      //   field: "deliveryPlace",
      //   headerName: "Delivery Place",
      //   sortable: false,
      //   width: 200,
      // },
      {
        field: "warehouseName",
        headerName: "Initial Warehouse Destination",
        sortable: false,
        width: 200,
      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        sortable: false,
        width: 200,
      },
      {
        field: "totalWeight",
        headerName: "Total Weight",
        sortable: false,
        width: 200,
      },
      {
        field: "totalCbm",
        headerName: "Total CBM",
        sortable: false,
        width: 200,
      },
      {
        field: "typeOfShipment",
        headerName: "Type of shipment",
        sortable: false,
        width: 200,
      }
    ];

    return result;
  };

  const onDelete = async (val) => {
    try {
      setLoading(true);
      // console.log(val)
      let response = await window.Platform.database.deleteSupplier(val);

      window.NotificationUtils.showSuccess("Supplier deleted successfully");



      setLoading(false);
    } catch (e) {
      setLoading(false);

      window.NotificationUtils.showError("Something went wrong");
      console.log(e.message)
    }
  };

  //   const onClickDeleteEmployee = (params) => {

  //     // console.log(`onClickDeleteEmployee`,params)

  //     window.confirmationModal(

  //       `Are you sure you want to deactivate ${params.name}

  //        (${params.id})?`,()=>onDelete({...params, city: params.city.attributes.id})

  //     );

  // };

  // const setModalData=(params)=>{

  //   setSelectedRow(params.row)

  //   setShowModal(true)

  // }

  //   const handleAddCustomer = () => {

  //     setShowModal(true);

  //     setSelectedRow(null); // Set selectedRow to null to indicate "Create" mode

  //   };
  const resendEmailQoatationPending = async (val) => {

    // console.log("Value from child:", JSON.stringify(value));
    try {
      let results = await window.Platform.database.resendQuotationLink(val.id);
      window.NotificationUtils.showSuccess("Quotation Approval Link Sent successfully");
      return results
    } catch (error) {
      window.NotificationUtils.showError(error);
    }
  };

  // const [filteredData, setFilteredData] = useState([]);
  const setModalData = (params, event) => {
    setSelectedRow(params);
    setShowModal(true);
    // Filter data based on the selected row's ID
    // const filteredData1 = propData.filter((item) => item.id === params.id);
    // setFilteredData(filteredData1);
  };


  // const setModalData = (params, event) => {
  //   // You can set the modal data here if needed
  //   setSelectedRow(params)

  //   setShowModal(true)
  // };

  const handleCloseModal = () => {
    setShowModal(false);
  };




  const createCustomDataGrid = (rows, columns) => {
    // Define the custom toolbar function here

    const CustomToolbar = () => {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />

          <GridToolbarFilterButton />

          <GridToolbarDensitySelector />

          <GridToolbarExport
            csvOptions={{
              fileName: "Pending Quotations",
            }}
          />
        </GridToolbarContainer>
      );
    };

    return (
      <DataGridContainer>
        {/* {loading && renderLoading()} */}
        <DataGrid
          loading={loading}
          className="payrollGrid"
          checkboxSelection={true}
          rows={rows || []}
          columns={columns}
          rowHeight={70}
          // onRowClick={handleRowClick}
          onRowClick={(params, e) => setModalData(params)}

          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <LoadingContainer>
        <CircularProgress color="primary" />
      </LoadingContainer>
    );
  };

  const renderTabContent = () => {

    return createCustomDataGrid(row, getEmployeeColumns());

  };

  // console.log("CUSTSB",Supplier)

  const renderModals = () => {
    return (
      showModal && (
        <QuotationCustomer
          open={isModalOpen}
          items={selectedRow}
          onSubmit={resendEmailQoatationPending}
          onClose={handleCloseModal}
        />
      )
      //       <>

      //          {selectedRow && showModal &&  <AddNewSupplier template={selectedRow}onSubmit={onSubmitSupplier}   onCancel={() => setShowModal(false)} loading={subLoading} setLoading={setSubLoading}  />}

      //         {!selectedRow && showModal && <AddNewSupplier template={null} onSubmit={onSubmitSupplier} onCancel={() =>setShowModal(false)} loading={subLoading} setLoading={setSubLoading}  />}

      //       </>
    );
  };

  const renderHeader = () => {
    return (
      <Toolbar>
        <Typography variant="h5">Pending Quotation(s)</Typography>

        <div>
          {/* <Button

            variant="contained"

            color="primary"

            disabled={loading}

            // onClick={handleAddCustomer}

          >

            Add Supplier

          </Button> */}
        </div>
      </Toolbar>
    );
  };

  return (
    <>
      {isMobile && <Drawer  props={props}/>}

      <ContentContainer>
        {renderHeader()}

        {renderTabContent()}

        {renderModals()}

      </ContentContainer>

      {/* <StaffEditorPageContainer>

        <HeaderContainer>

          <Typography variant="h5">Supplier Manager</Typography>

        </HeaderContainer>

        <TableContainer>{renderTabContent()}</TableContainer>

        // {renderModals()}

      </StaffEditorPageContainer> */}
    </>
  );
}
