import React, { useEffect, useState } from "react";
import {
    Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const InvoiceAndPkl = ({ onCancel, items , onSubmit }) => {
    // console.log("ssdasd",items)
    const [form, setForm] = useState({
      invoice: items.invoice || '',
     invoiceNo: items.invoiceNumber || '',
     pklStatus: items.pklStatus || false
    });
const [loading,setLoading] = useState(false)
     // useEffect(()=>{
    //   console.log("Customer Name",customerData)
    // },[customerData])
    // console.log("customerdata", initialData)
  
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
    };
  
    const submitHandler = () => {
        // setLoading(true);
        if(!form.invoiceNo.length)
        window.NotificationUtils.showError("Invoice number is required")
        else{
        onSubmit({invoiceNo: form.invoiceNo, invoice: form.invoice ,id: items.id, pklStatus: form.pklStatus})
        // setLoading(false)
        // onCancel()
    }
    //   console.log({...form, supplierName: initialData.SupplierName});
    // if(form.remark && form.typeOfShipment)
    //   onSubmit({...form})
    //   else
    //   window.NotificationUtils.showError("Please fill all the fields")
    };
    const options = [ { value: 'Air', label: 'Air' },
    { value: 'Sea', label: 'Sea' },
  ]
  
    return (
      <StyledDialog disableEnforceFocus maxWidth="xs" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Enter the invoice number and the PKL status</Typography>

          <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack>
              <TextField
    value={form.invoiceNo || ''}
                variant="outlined"
                label="Invoice Number"
                // defaultValue={form["name"]}
                onChange={(e) =>
                  changeHandler("invoiceNo", e.target.value)
                }
              />
            </Stack>
            <Stack>
              <TextField
    value={form.invoice || ''}
                variant="outlined"
                label="INV USD / HKD"
                // type="number"
                // defaultValue={form["name"]}
                onChange={(e) =>
                  changeHandler("invoice", e.target.value)
                }
              />
            </Stack>
                <Stack>
                   <Typography>
                    Pkl Status:
                    <Checkbox 
                    checked={form.pklStatus || false}
                    // onClick={(e,val)=> console.log(e.target.checked)}
                    onChange={(e) =>
                        changeHandler("pklStatus", e.target.checked)
                      }
                    />
                   </Typography>
                </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap="10px"
              align-items="center"
            >
              <Button color="error" onClick={onCancel}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default InvoiceAndPkl;
  