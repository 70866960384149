import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  TextField,
  FormControl, // Import FormControl
  InputLabel, // Import InputLabel
  Select, // Import Select
  MenuItem // Import MenuItem
} from "@mui/material"; // Update import to "@mui/material"
import styled from "styled-components";
// import OpaqueLoading from "../opaqueLoading/OpaqueLoading"; // Update component name to "OpaqueLoading" with capital letters
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"; // Update import to "@mui/icons-material"
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
  position: relative;
  z-index: 10000;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  position: relative !important;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const CloseIconButton = styled(IconButton)`
  position: relative;
  z-index: 10000;
`;

const SelectControl = styled(FormControl)`
  .MuiInputLabel-root {
    top: -7px !important;
    left: 14px !important;
  }
`;

const AddCityModal = (props) => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [hasChange, setHasChange] = useState(false);

  const [district, setDistrict] = useState(
    props.selectedCity.district ? props.selectedCity.district : {}
  );

  const [districtsList, setDistrictsList] = useState([]);

  const [cityName, setCityName] = useState(
    props.selectedCity.id ? props.selectedCity.cityName : ""
  );

  const isNewCity = props.selectedCity.id ? false : true;

  const fetchDistricts = async() => {
    try {
      setLoading(true);
      let response = await window.Platform.database.fetchDistricts();
      if (response && response.length > 0) {
        setDistrictsList(response);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching districts");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDistricts();
  }, []);

  useEffect(() => {
    let change = false;
    if (isNewCity && (cityName && district)) {
      change = true;
    } else if (
      !isNewCity &&
      (cityName !== props.selectedCity.cityName ||
        district.id !== props.selectedCity.district.id)
    ) {
      change = true;
    }
    if (change !== hasChange) {
      setHasChange(change);
    }
  }, [cityName, district]);

  const checkErrors = async () => {
    if (!cityName) {
      window.NotificationUtils.showError("Please input a valid city name");
      return true;
    }
    if (!district.id) {
      window.NotificationUtils.showError("Please select a valid district");
      return true;
    }
    let citynameAvailability =
      await window.Platform.database.checkCityNameAvailability(
        cityName,
        district.id
      );
    if (!citynameAvailability) {
      window.NotificationUtils.showError("City name is already in use");
      return true;
    }
    return false;
  };

  const onClickSave = async () => {
    try {
      setLoading(true);
      let hasErrors = await checkErrors();
      if (hasErrors) {
        setLoading(false);
        return;
      }
      let response = await window.Platform.database.updateCity({
        ...props.selectedCity,
        cityName,
        district: { id: district.id },
      });
      window.NotificationUtils.showSuccess("City updated successfully");
      props.closeModal(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error trying to save city");
      setLoading(false);
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <InputFieldContainer>
          <TextField
          required
            fullWidth={true}
            label="City Name"
            placeholder="Enter city name"
            variant="outlined"
            value={cityName}
            disabled={loading}
            onChange={(e) => setCityName(e.target.value.toString())}
          />
        </InputFieldContainer>
        <InputFieldContainer>
        <FormControl fullWidth variant="outlined" required>
  <InputLabel id="cityDistrictLabel">Select City District</InputLabel>
  <Select
  required
    labelId="cityDistrictLabel"
    id="districtStateSelect"
    value={district.id || ""}
    label="Select City District"
    disabled={loading}
    onChange={(e) =>
      setDistrict(
        districtsList.find((district) => district.id === e.target.value)
      )
    }
  >
    {districtsList.map((districtEntry) => (
      <MenuItem value={districtEntry.id} key={districtEntry.id}>
        {districtEntry.districtName} ({districtEntry.state.stateName})
      </MenuItem>
    ))}
  </Select>
</FormControl>
        </InputFieldContainer>
        <ModalActionsContainer>
          <FirstButton
            disabled={loading}
            onClick={() => props.closeModal()}
            variant="contained"
            color="primary"
          >
            Cancel
          </FirstButton>
          <Button
            disabled={loading || !hasChange}
            onClick={onClickSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog maxWidth="xs" fullWidth={true} open={showModal}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">
            {!isNewCity
              ? props.selectedCity.cityName || ""
              : "New City"}
          </Typography>
          <CloseIconButton
            disabled={loading}
            onClick={() => props.closeModal()}
          >
            <CloseOutlinedIcon />
          </CloseIconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default AddCityModal;
