import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DATE_FORMATS, ERROR_COLOR } from "../../constants";
import { Add, Edit, Inventory, Save, SaveAlt } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/WarehouseModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import { NavLink } from "reactstrap";
import { Link, Navigate, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100%);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function WarehouseManagement(props) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
const [selectedRowId, setSelectedRowId] = useState(null);

  const rows = [
    // {
    //   id: 1,
    //   role: "client 1",
    //   component1: { write: true, read: true },
    //   usermanagement: { write: true, read: true },
    //   tracking: { write: true, read: true },
    //   changeable: false,
    // },
    // {
    //   id: 2,
    //   role: "client 2",
    //   component1: { write: false, read: true },
    //   usermanagement: { write: true, read: true },
    //   tracking: { write: true, read: true },
    //   changeable: true,
    // },
    // {
    //   id: 3,
    //   role: "client 3",
    //   component1: { write: true, read: true },
    //   usermanagement: { write: false, read: true },
    //   tracking: { write: false, read: true },
    //   changeable: true,
    // },
    // {
    //   id: 4,
    //   role: "client 4",
    //   component1: { write: false, read: false },
    //   usermanagement: { write: false, read: true },
    //   tracking: { write: true, read: true },
    //   changeable: true,
    // },
  ];

  useEffect(() => {
    setAccessChanges(rows);
    fetchWarehouses()
  }, []);
  useEffect(() => {
  }, [accessChanges]);

  const fetchWarehouses = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchWarehouses();
      // if(auth?.user?.role === ROLES.ADMIN){
        setAccessChanges(results);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Warehouses");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const getEmployeeColumns = () => {
  let result = [
    {
      field: "name",
      headerName: "Warehouse Name",
      sortable: false,
      width: 150,
      
      editable: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
      sortable: false,
     
    },
    {
      field: "warehouseCountry",
      headerName: "Warehouse Country",
      width: 200,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
     
    },
    {
      field: "city",
      headerName: "City Name",
      sortable: false,
      width: 200,
      valueGetter: (params) =>params?.row?.city?.attributes?.cityName || "N/A" ,
      renderCell: (params) => {
        const value = params?.row?.city?.attributes?.cityName || "N/A";

        return (
          <Tooltip title={value}>
            {value}
          </Tooltip>
        );
      },
      // editable: true,
    },
    {
      field: "updatedAt",

      headerName: "Updated At",

      minWidth: 200,

      flex: 0.22,

      sortable: false,

      // dataGetter: (params) => {
      //   // console.log("params in dataGetter:", params);
      //   return params.row.status || "N/A";
      // },
      valueGetter: (params) =>moment(params.row.updatedAt).format(DATE_FORMATS.DATE_WITH_TIME) || "N/A" ,
      renderCell: (params) => {
        const value = params.row.updatedAt || "N/A";

        return (
          <Tooltip title={moment(value).format(DATE_FORMATS.DATE_WITH_TIME)}>
            {moment(value).format(DATE_FORMATS.DATE_WITH_TIME)}
          </Tooltip>
        );
      },
    },
    {
      field: "contactNumber",
      headerName: "Contact number",
      // headerAlign: 'center',
      sortable: false,
      width: 200,
       
          
    },
    {
      field: "workingHours",
      headerName: "Working Hours",
      // headerAlign: 'center',
      sortable: false,
      width: 200,
    }
  ];
  
  // result.push({
  //   field: "b  ",
  
  //   headerName: "Delete",
  
  //   editable: false,
  
  //   minWidth: 120,
  
  //   flex: 0.22,
  
  //   maxWidth: 140,
  
  //   disableColumnMenu: true,
  
  //   disableExport: true,
  
  //   disableReorder: true,
  
  //   hideSortIcons: true,
  
  //   renderCell: (params) => {
  //     return (
  //       <>
  //         <IconButton
  //           size="large"
  //           color="primary"
  //           onClick={(e) => {
  //             e.stopPropagation();
  
  //             onClickDeleteEmployee(params.row);
  //           }}
  //         >
  //           <DeleteIcon />
  //         </IconButton>
  //       </>
  //     );
  //   },
  // });
  result.push({
    field:"a  ",
    headerName: 'Actions',
    headerAlign: 'center',
    editable: false,
    minWidth:130,
    disableColumnMenu:true,
    disableExport:true,
    disableReorder:true,
    hideSortIcons:true,
    renderCell: (params) => {
      return (
        <>
        <Tooltip title="Edit warehouse">

        {/* <Link to={`/dashboard?warehouseId=`+ params.row.id} > */}

          {/* <FabStyle variant="extended" color="primary" 
            //  onClick={() => {
               
            //    window.NotificationUtils.showSuccess(
            //      "Changes successfully made"
            //      );
            //     }}
                 > */}
                   <IconButton
            size="large"
            color="primary"
            onClick={()=> {
              // console.log("ppp",params.row)
              setModalData(params)
            }}
          >

  <Edit   />
          </IconButton>
          </Tooltip>
        <Tooltip title="Delete warehouse">

  <IconButton
            size="large"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
  
              onClickDeleteEmployee(params.row);
            }}
          >
            <DeleteIcon />
          </IconButton>
{/* </FabStyle> */}
        </Tooltip>
                      {/* </Link> */}
          
        </>

      );
    },
  },)

  return result
}
// const history = useHistory();


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "WarehouseManagement",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const onDelete = async (val) => {
    try {

      setLoading(true);
      // console.log(val)
      let response = await window.Platform.database.deleteWarehouse(val.id);

      window.NotificationUtils.showSuccess("Supplier deleted successfully");

      fetchWarehouses();

      setLoading(false);
    } catch (e) {
      setLoading(false);

      window.NotificationUtils.showError(e.message);
    }
  };

  const onClickDeleteEmployee = (params) => {

    window.confirmationModal(
      `Are you sure you want to deactivate ${params.name}

       (${params.id})?`,
      () => onDelete({ ...params, id: params.id })
    );
  };

  const setModalData = (params) => {
    setSelectedRow(params.row);

    setShowModal(true);
  };

  const renderContent = () => {
    return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={accessChanges}
          columns={getEmployeeColumns()}
          rowHeight={70}
          // onRowClick={(params, e) => setModalData(params)}
          disableSelectionOnClick
          disableRowSelectionOnClick
          disable
          onRowClick={(params,event) => {
            if (event.target.tagName === 'BUTTON' || event.target.tagName === 'svg' || event.target.tagName === 'path') {
              return; // Ignore row click if a button is clicked
            }
            // console.log('eeevent',event.target.tagName)
            // Assuming `params` contains the necessary information to determine the new route
            // For example, if you have an 'id' field in your row data:
            const newRowId = params.row.id;
            // window.location.href = `/dashboard?warehouseId=`+ params.row.id;
setSelectedRowId(params.row.id)
            //navigate to /dashboard?warehouseId=`+ params.row.id 
            // return <Navigate to={`/dashboard?warehouseId=`+ params.row.id} />;
            // return (
            //   <Navigate to={`/dashboard?warehouseId=`+ params.row.id} />
            // );
          }}


          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
        {selectedRowId && <Navigate to={`/dashboard?warehouseId=${selectedRowId}`} />}
      </Box>
    );
  };

  // const updateWarehouseData = async (val) => {
  //   try {
  //     console.log("data entered",val)
  //       // setSubLoading(true)
  //       // const errorMessage = getRegexValidation(regex)
  //       // if (errorMessage) 
  //       // {
  //       //     setSubLoading(false)
  //       //     return window.NotificationUtils.showError(errorMessage);}
  //       await window.Platform.database.createNewWarehouse(val);
  //       // getRegex()
  //       // setShowCustomerFormModal(false)
  //       // setSubLoading(false)
  //       // window.NotificationUtils.showSuccess("Regex updated");
  //   } catch (error) {
  //       // setSubLoading(false)
  //       console.error(error);
  //       // let message = error.code === 141 ? error.message : "Something went wrong"
  //       // window.NotificationUtils.showError(message);
  //   }
  // }
  const updateWarehouseData = async (value) => {
    try {
      setLoading(true)
      let results = await window.Platform.database.createNewWarehouse(value);
      window.NotificationUtils.showSuccess("Warehouse added to the system")
      fetchWarehouses();
      setShowModal(false)
      // setLoading(false)
    } catch (error) {
      console.log(error)
      window.NotificationUtils.showError(error.message);
      console.log(error.message)
    }
    finally{
      fetchWarehouses();
      setLoading(false)
      setShowWarehouseFormModal(null)
    }
}

const handleAddCustomer = () => {
  setShowModal(true);

  setSelectedRow(null); 
};

  return (
    <>
      {isMobile && (
        <Drawer
        setShowNotifications={props.setShowNotifications}
        onAuthUserChanged={props.onAuthUserChanged}
        user={props.user}
        props={props}
        />
      )}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Warehouse Management</Typography>
          <div>
          {loading && <OpaqueLoading/>}
            {/* <FabStyle> */}
            <FabStyle variant="extended" color="primary" 
            onClick={handleAddCustomer}
  //  onClick={}
             >
  <Add   />
  Add New
</FabStyle> 

            {/*  */}
                  {/* </FabStyle> */}
            {/* )} */}
          </div>
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {
      !selectedRow && showModal &&
      <WarehouseModal
      onCancel={() => setShowModal(false)}
      template={null}
      loading={loading}
      onSubmit={updateWarehouseData}
      />
    }
     {
      selectedRow && showModal &&
      <WarehouseModal
      onCancel={() => setShowModal(false)}
      template={selectedRow}
      loading={loading}
      onSubmit={updateWarehouseData}
      />
    }
    </>
  );
}
