import React, { useEffect, useState } from "react";

import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  StepConnector,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, DATE_FORMATS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { Stepper, Step, StepLabel, Modal, Button } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { CloseOutlined } from "@mui/icons-material";

import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
// import moment from 'moment-timezone';


const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  //  background-color: white;
`;
const isMobile = window.innerWidth < 900;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
const StyledStepConnector = styled(StepConnector)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .MuiStepConnector-line {
    width: 2px;
    background-color: #00b894;
    border-color: nul;

    position: relative;
    top: -52px; /* Adjust this value to control the position of the line */
    // bottom: 175px; /* Adjust this value to control the position of the line */
    left: 3%;
    transform: translateX(-50%);
  }

  .MuiStepConnector-lineVertical {
    height: 80px;
  }

  .MuiStepConnector-active .MuiStepConnector-line {
    background-color: white; // Adjust this color to match the other lines
  }
`;

const TrackingModal = ({onCancel,items,role}) => {
  // const [activeStep, setActiveStep] = React.useState(orderStatus["warehouse Data"].length);
  const [country,setCountry]=useState(false)

    useEffect(()=>{
      console.log("items",role)
      if(role.user.role==="SCM Executive - China-Warehouse"){
        setCountry(true)
      }
    },[])
  

  const orderStatus = {
    "Quotation Generated": {
      label: "Quotation Generated",
      date: "2023-08-04",
      time: "10:00 AM",
    },
    "SO generated": {
      label: "Quotation Generated",
      date: "2023-08-04",
      time: "10:00 AM",
    },
    "warehouse Data": [
      {
        label: "China Warehouse 1",
        date: "2023-08-05",
        time: "11:30 AM",
      },
      {
        label: "China Warehouse 2",
        date: "2023-08-06",
        time: "01:15 PM",
      },
      {
        label: "India Warehouse 1",
        date: "2023-08-07",
        time: "09:45 AM",
      },
      {
        label: "India Warehouse 2",
        date: "2023-08-08",
        time: "02:00 PM",
      },
      {
        label: "Delivered",
        date: "2023-08-09",
        time: "03:30 PM",
      },
    ],
  };

 


  const filterCountry=items.attributes.warehouses.filter(item => item.country === 'China');
  const filterCountryIndia=items.attributes.warehouses.filter(item => item.country === 'India');
  // console.log("FILTERCOUNTRY",filterCountry)
  // console.log("filterCountryIndia",filterCountryIndia)

  const [activeStep1, setActiveStep1] = React.useState(filterCountry.length);
  const [activeStep, setActiveStep] = React.useState(filterCountryIndia.length-1);



  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="xs"
      fullWidth={true}
      open={true}
      PaperProps={{
        
        style:isMobile? {minWidth:350,minHeight:620,maxHeight:650,maxWidth:600} 
        :{
          minWidth: 850,

          maxWidth: 850,

          minHeight: 500,

          maxHeight: 550,
          backgroundColor: "white",
        },
      }}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h6">{isMobile?`Tracking(${items.id})`:`Order Tracking(${items.id})`}</Typography>
          <IconButton  onClick={onCancel}>

      <CloseOutlined />

    </IconButton>

        </ModalHeaderContainer>
        <div
          style={{
            padding: "10px",
            marginTop:"-15px",
            marginLeft: isMobile?"10%":"7%",
            display: isMobile ? "-ms-grid" : "flex",
            gap: !isMobile&&"25%",
            maxHeight: "500px",
          }}
        >
          <Box
            sx={{
              maxWidth: isMobile?"90%":"35%",
              minWidth:"33%",
              border: "1px solid lightGrey",
              // borderColor: "grey",
              borderRadius: "5px",
              paddingBottom: "5px",
              transition: "box-shadow 0.3s ease-in-out",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.6)",
              "&:hover": {
                boxShadow: "0px 0px 5px #00b894",
              },
            
            }}
          >
            <Typography variant={"body1"} sx={{ margin: "5px" }}>
              <strong>Quotation Generated Details</strong>{" "}
            </Typography>

            <Typography
              variant={"caption"}
              sx={{ color: "#333", margin: "5px" }}
            >
              <strong>Quotation ID:</strong> {items.attributes.quotation.id}
            </Typography>
            <br />
            <Typography
              variant={"caption"}
              sx={{ margin: "5px", color: "#333" }}
            >
              <strong>Created At:</strong>{" "}
              {moment(items.attributes.quotation.attributes.createdAt).format(DATE_FORMATS.DATE_WITH_TIME)}
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: isMobile?"90%":"35%",
              minWidth: "33%",
              border: "1px solid lightGrey",
              marginTop:isMobile && "10px",
              // borderColor: "grey",
              borderRadius: "5px",
              paddingBottom: "5px",
              transition: "box-shadow 0.3s ease-in-out",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.6)",
              "&:hover": {
                boxShadow: "0px 0px 5px #00b894",
              },
            
            }}
          >
            <Typography variant={"body1"} sx={{ margin: "5px" }}>
              <strong>SO Generated Details</strong>{" "}
            </Typography>
            <Typography
              variant={"caption"}
              sx={{ color: "#333", margin: "5px" }}
            >
              <strong>SO ID:</strong> {items.attributes.SO.id}
            </Typography>
            <br />
            <Typography
              variant={"caption"}
              sx={{ margin: "5px", color: "#333" }}
            >
              <strong>Created At:</strong> {moment(items.attributes.SO.attributes.createdAt).format(DATE_FORMATS.DATE_WITH_TIME)}
            </Typography>
          </Box>
        </div>
        <Card
          sx={{
            maxWidth: isMobile?"305px":"79%",
            minWidth:isMobile?"302px": "79%",
            overflowY: "scroll",
            // overflowX:"scroll",
            borderRadius: "10px",
            border: "2px solid lightGrey",
            marginTop: "10px",
            marginLeft:isMobile? "-3%":"10%",
            marginBottom:"10px",
            maxHeight: 320,
            minHeight: 320,
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.6)",
              "&:hover": {
                boxShadow: "0px 0px 5px #00b894",
              },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isMobile?"column":"row",
              justifyContent: "center",
              gap: "50px",
              marginTop: "10px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row",margin:isMobile&&"5px" }}>
            <div style={{marginLeft:isMobile&&"10%"}}>
              <Typography variant="body1">
                <strong>China</strong>
              </Typography>
              <Stepper
                activeStep={activeStep1} // Use the length of the warehouse data as the active step to show full progress
                alternativeLabel
                orientation="vertical"
                connector={<StyledStepConnector />}
                sx={{marginTop:"-10px"}}
              >
                  <Step >
                    <StepLabel
                      StepIconProps={{
                        icon: 
                          <CheckCircleIcon color="primary" />
                        
                      }}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <Typography
                          padding={"5px"}
                          fontWeight="bold"
                          variant="button"
                        >
                          Order generated
                        </Typography>
                        <Typography variant="caption">
                          <strong>Created At:</strong> {moment(items.attributes.createdAt).format(DATE_FORMATS.DATE_WITH_TIME)}
                          
                          
                        </Typography>
                        <Typography variant="caption" color={"white"}>
                          <strong>.</strong> 
                          
                          
                        </Typography>
                        
                      </div>
                    </StepLabel>
                  </Step>

                {filterCountry.map((status, index) =>(
                
                  <Step key={index}>
                    <StepLabel
                      StepIconProps={{
                        icon: status.dispachedAt ? (
                          <CheckCircleIcon color="primary" />
                        ) : (
                          <PanoramaFishEyeIcon color="primary" />
                        ),
                      }}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <Typography
                          padding={"5px"}
                          fontWeight="bold"
                          variant="button"
                        >
                          {status.warehouseName}
                        </Typography>
                        <Typography variant="caption">
                          <strong>Arrived At:</strong>{" "}
                          {status.recievedAt?country? moment.unix(status.recievedAt+((2 * 60 * 60 * 1000) + (30 * 60 * 1000))/1000).format(DATE_FORMATS.DATE_WITH_TIME): moment.unix(status.recievedAt).format(DATE_FORMATS.DATE_WITH_TIME): "PENDING STATUS"}
                        
                        </Typography>
                        <Typography variant="caption">
                          <strong>Dispached At</strong>{" "}
                          {status.dispachedAt && status.warehouseName !== "Delivered"?country?moment.unix(status.dispachedAt+((2 * 60 * 60 * 1000) + (30 * 60 * 1000))/1000).format(DATE_FORMATS.DATE_WITH_TIME): moment.unix(status.dispachedAt).format(DATE_FORMATS.DATE_WITH_TIME):"PENDING STATUS"}
                        </Typography>
                      </div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              </div>
            </div>
                {filterCountryIndia.length>0 &&
            <div style={{ display: "flex", flexDirection: "row",margin:isMobile&&"5px",marginTop:isMobile&&"-10px" }}>
              <div style={{marginLeft:isMobile&&"10%"}}>
                <>
              <Typography variant="body1">
                <strong>India:</strong>
              </Typography>

              <Stepper
              activeStep={activeStep} // Use the length of the warehouse data as the active step to show full progress
                alternativeLabel
                orientation="vertical"
                connector={<StyledStepConnector />}
                sx={{marginTop:"-10px"}}
              >
                

                {filterCountryIndia.map((status, index) => (
                  <Step key={status.label}>
                  <StepLabel
                    StepIconProps={{
                      icon: status.dispachedAt || status.warehouseName === "Delivered" ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <PanoramaFishEyeIcon color="primary" />
                      ),
                    }}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      <Typography
                        padding={status.warehouseName !== "Delivered" ?"5px": "5px"}
                        style={{ paddingTop:status.warehouseName == "Delivered" && "6px"}}
                        fontWeight="bold"
                        variant="button"
                      >
                        {status.warehouseName=="Delivered"?"Delivered To Customer":status.warehouseName }
                      </Typography>
                      { status.warehouseName == "Delivered" ?
                      <Typography variant="caption" color={"white"}>
                        .....
                      </Typography>
                      :
                      <Typography variant="caption">
                        <strong>Arrived At:</strong>{" "}
                        {status.recievedAt?country?moment.unix(status.recievedAt+((2 * 60 * 60 * 1000) + (30 * 60 * 1000))/1000).format(DATE_FORMATS.DATE_WITH_TIME):moment.unix(status.recievedAt).format(DATE_FORMATS.DATE_WITH_TIME):"PENDING STATUS"}
                      
                      </Typography>
                    }
                      { (status.warehouseName == "Delivered"  ) ?
                         <Typography variant="caption" color={"white"}>
                         .....
                       </Typography>
                      :
                      <Typography variant="caption">
                        <strong>Dispached At</strong>{" "}
                        {status.dispachedAt ?country?moment.unix(status.dispachedAt+((2 * 60 * 60 * 1000) + (30 * 60 * 1000))/1000).format(DATE_FORMATS.DATE_WITH_TIME):moment.unix(status.dispachedAt).format(DATE_FORMATS.DATE_WITH_TIME):"PENDING STATUS"}
                      </Typography>
                    }
                    </div>
                  </StepLabel>
                </Step>
                ))}
              </Stepper>
              </>

            </div>
            </div>
                     } 
          </div>
        </Card>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default TrackingModal;


