import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Fab, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { SaveAlt } from "@mui/icons-material";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import { DATE_FORMATS } from "../../constants";
import moment from "moment";
import SoModal from "../../components/modals/SoModal";
import QuotationAdmin from "../../components/modals/QuotationAdmin";
import ShowMultipleSoModal from "../../components/modals/showMultipleSoModal";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  overflow-x: ${isMobile ? "auto" : "hidden"}; /* Add horizontal scroll for mobile */
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;

export default function SoGeneration(props) {
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showItemsModal, setShowItemsModal] = useState();

  const [showMultipleModal, setShowMultipleModal] = useState(false)
  const [multipleDataSo, setMultipleDataSo] = useState()
  const generateSO = async (val) => {
    try {
      setLoading(true)
  
      
      let newPerson = Object.assign({}, val);
      delete newPerson.customer;
      delete newPerson.deliveryCity;
      delete newPerson.orderLineItem;
      delete newPerson.supplier;
      delete newPerson?.currentWarehouse;
      delete newPerson.shippingOrders;

      
      if(newPerson.createdBy.warehouse) {
        newPerson = {...newPerson, createdBy: {...newPerson.createdBy, warehouse: newPerson.createdBy.warehouse.id}}
      }     
      console.log(newPerson);

      let results = await window.Platform.database.generateSO(newPerson);

      fetchPendingQuotations()
      // return results;
      if(results?.status === 'Failed')
      {
        window.NotificationUtils.showInfo("SO Generated but Wechat message failed")
      
      }
      else
      window.NotificationUtils.showSuccess("SO Generated")
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
      console.error(error)
    }
    
    finally{
      setLoading(false)
      setShowItemsModal(null)
      setShowMultipleModal(null)
    }
  };
const onClickCheck = () => {

}

  const onClickApprove = (params) => {
    setShowItemsModal(null)

  
    function getObjectsByCategory(data) {
      if (data.length === 0) return [];
    
      const objectsByCategory = [];
      let currentCategory = data[0].category;
      let startIndex = 0;
    
      for (let i = 1; i < data.length; i++) {
        if (data[i].category !== currentCategory) {
          objectsByCategory.push(data.slice(startIndex, i));
          currentCategory = data[i].category;
          startIndex = i;
        }
      }
    
      objectsByCategory.push(data.slice(startIndex)); // Push the last set of objects
    
      return objectsByCategory;
    }
    // function getUniqueObjectsByCategory(data) {
    //   if (data.length === 0) return [];
    
    //   const uniqueObjectsByCategory = [];
    //   let currentCategory = data[0].category;
    //   let currentObjects = [];
    
    //   for (let i = 0; i < data.length; i++) {
    //     const item = data[i];
    
    //     if (item.category === currentCategory) {
    //       currentObjects.push(item);
    //     } else {
    //       uniqueObjectsByCategory.push(currentObjects);
    //       currentCategory = item.category;
    //       currentObjects = [item];
    //     }
    //   }
    
    //   uniqueObjectsByCategory.push(currentObjects); // Push the last set of objects
    
    //   return uniqueObjectsByCategory;
    // }
    function getObjectsByCategoryWithOtherData(sampleObject) {
      const itemsArray = sampleObject.items;
      const objectsByCategory = getObjectsByCategory(itemsArray);
    
      // Add other data to each category's array
      const result = objectsByCategory.map((group) => ({
        ...sampleObject, // Include the entire sampleObject
        items: group, // Replace the 'items' property with the items for the current category
      }));
    
      return result;
    }
  
    const sameCategory = getObjectsByCategoryWithOtherData(params);
  // console.log("samee",sameCategory)
    if(sameCategory.length===1)
    generateSO(params)
    else
    {
      setShowMultipleModal(sameCategory)
      setMultipleDataSo(params)
    }
    // window.confirmationModal(
  
    //   `Muliple SOs will be generated based on unique categories of items. Please confirm to continue.`,()=> generateSO({...params, uniqueIds: sameCategory})
  
    // );

    }

  useEffect(() => {
    fetchPendingQuotations();
  }, []);

  const fetchPendingQuotations = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchCustomerApprovedQuotations();
      setAccessChanges(results);
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    } finally {
      setLoading(false);
    }
  };

  const getEmployeeColumns = () => {
    let result = [
      {
        field: "id",
        headerName: "Quotation ID",
        sortable: false,
        width: 150,
        editable: true,
        valueGetter: (params) => params.row?.quotationId || 'N/A',

      },
      {
        field: "customerId",
        headerName: "Customer Code",
        sortable: false,
        width: 200,
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        sortable: false,
        width: 200,
      },
      {
        field: "supplierName",
        headerName: "Supplier Name",
        sortable: false,
        width: 200,
      },
      {
        field: "createdAt",
        headerName: "Created At",
        sortable: false,
        width: 200,
        valueGetter: (params) => moment(params.row.createdAt).format(DATE_FORMATS.DEFAULT),
      },
      
      {
        field: "deliveryDate",
        headerName: "Expected Delivery Date",
        sortable: false,
        width: 200,
        valueGetter: (params) => moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT),
      },
      {
        field: "deliveryPlace",
        headerName: "Delivery Place",
        sortable: false,
        width: 200,
      },
      {
        field: "warehouseName",
        headerName: "Initial Warehouse Destination",
        sortable: false,
        width: 200,
      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        sortable: false,
        width: 200,
      },
      {
        field: "totalWeight",
        headerName: "Total Weight",
        sortable: false,
        width: 200,
      },
      {
        field: "totalCbm",
        headerName: "Total CBM",
        sortable: false,
        width: 200,
      },
      {
        field: "typeOfShipment",
        headerName: "Type of shipment",
        sortable: false,
        width: 200,
      },
      {
        field: "Packaginglist",
        headerName: "Packaging list",
        width: 200,
        headerAlign: "center", // Align the header text to the center
        align: "center",
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row.packagingList || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <Tooltip title="Click to view packaging list"><IconButton variant="extended" color="primary" 
          disabled={!params.row.packagingList}
          onClick={(e) => {

            e.stopPropagation();
          openPackaging(value);}} >
           <RemoveRedEyeIcon/>
          </IconButton></Tooltip>
        }
      }
    ];

    return result;
  };

  const openPackaging = (val) => {
    window.open(val, '_blank');
  };


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "Sogeneration",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    const handleRowClick = (params) => {
      const rowData = params.row;
      setShowItemsModal(rowData);
    };

    const columns = getEmployeeColumns().map((column) => {
      if (column.field === "items") {
        return {
          ...column,
          renderCell: (params) => (
            <FabStyle
              variant="extended"
              color="primary"
              onClick={() => handleRowClick(params)}
            >
              View Items
            </FabStyle>
          ),
        };
      }
      return column;
    });
    return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={accessChanges}
          columns={columns}
          rowHeight={70}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
          onRowClick={handleRowClick}
        />
      </Box>
    );
  };

  return (
    <>
      {isMobile && <Drawer props={props}/>}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">SO generation</Typography>
        </HeaderContainer>
        <TableContainer>
          {loading && <OpaqueLoading />}
          {renderContent()}
        </TableContainer>
      </StaffEditorPageContainer>
      {showItemsModal && (
        <SoModal
          onClose={() => setShowItemsModal(null)}
          items={showItemsModal}
          onSubmit = {onClickApprove}
          loading={loading}
          mode={false}
        />
      )}
      {showMultipleModal &&
      <ShowMultipleSoModal
      onClose={() => setShowMultipleModal(null)}
      items={showMultipleModal}
      multipleData = {multipleDataSo}
      onSubmit = {generateSO}
      loading={loading}
     />
      }
    </>
  );
}
