import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, Tab, Tabs, CircularProgress, FormControl, MenuItem, Select, InputLabel, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DATE_FORMATS, ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";
import { Add, Done, Inventory, Save, SaveAlt, Send } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/WarehouseModal";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import PackagingListModal from "../../components/modals/packagingListModal";
import { useParams } from "react-router-dom";
import moment from "moment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import ForwardIcon from '@mui/icons-material/Forward';
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import PreviewIcon from '@mui/icons-material/Preview';
import PlannedSoModal from "../../components/modals/plannedSoModal";

const isMobile = window.innerWidth < 900;

const SHIPPING_ORDERS = [

]
const AIR_SHIPPING_ORDERS = [
    
]
const openPackaging = (val) => {
    window.open(val, '_blank');
  };





const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 205px);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const TabsContainer = styled.div`
//   ${isMobile &&
//   `
//     height: calc(100vh - 56px - 20px);
//   `}
// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PendingdeliverytoCustomer(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [shippingOrders, setShippingOrders] = useState(SHIPPING_ORDERS);
  const [showPackagingListModal, setShowPackagingListModal] = useState();
  const [showItemsModal, setShowItemsModal] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
const [statusInventory, setStatusInventory] = useState(10)
const [containerDetails,setContainerDetails] = useState();



const onRecievedButton = async(val) => {
let new_val={soId:val,warehouseId:id}
setLoading(true)
try {
  
    let results = await window.Platform.database.deliveredToCustomer(new_val);
    fetchData()
    window.NotificationUtils.showSuccess('Marked as delivered')
    setLoading(false)
} catch (error) {
    console.error(error);
    setLoading(false)
}
}



useEffect(()=>{
    // console.log("id",id)
 fetchData()
}, [statusInventory,selectedTab])

const fetchData = async () => {
  try {
    let results = await window.Platform.database.fetchPendingDeliveryToCustomer(id);
    // console.log("RESULTS",results)
    let tabvalue;

    if (selectedTab === 1) {
      tabvalue = results.filter(order => order.quotation.attributes.typeOfShipment === "Sea");
    } else if (selectedTab === 2) {
      tabvalue = results.filter(order => order.quotation.attributes.typeOfShipment === "Air");
    } else {
      tabvalue = results;
    }

    // console.log("TABVALUE", tabvalue);
    setShippingOrders(tabvalue);
  } catch (err) {
    console.log(err);
  }
};




const getInventoryColumns = () => {
  let result
  if(statusInventory == 10)
  {
   result = [
    
    {
      field: "id",
      headerName: "SO Id",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.id || 'N/A',
      renderCell: params => {
        // console.log(params.row.id)
        const value = params.row.id || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }},
      {
        field: "containerId",
        headerName: "Container Id",
        width: 200,
        sortable: false,
        valueGetter: params => params.row?.container?.id || 'N/A',
        renderCell: params => {
          // console.log(params.row.container.id)
          const value = params.row?.container?.id || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }},
        {
          field: "containerName",
          headerName: "Container Name",
          width: 200,
          sortable: false,
          valueGetter: params => params.row?.container?.attributes?.containerName|| 'N/A',
          renderCell: params => {
            // console.log(params.row?.container?.id)
            const value = params.row?.container?.attributes?.containerName|| 'N/A'
            return <Tooltip title={value}>{value}</Tooltip>
          }},
    {
      field: "deliveryDate",
      headerName: "Expected Delivery Date(Customer)",
      width: 250,
      sortable: false,
      valueGetter: params => moment.unix(params.row.quotation.attributes.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A',
      renderCell: params => {
        const val=params.row.quotation.attributes.deliveryDate;
        // console.log("VALL",val);
        const value = moment.unix(val).format(DATE_FORMATS.DEFAULT) || 'N/A';
        // console.log("VALUE",params.row.quotation.attributes.deliveryDate);
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
  //   {
  //     field: "totalCbm",
  //     headerName: "Total CBM",
  //     width: 200,
  //     sortable: false,
  //     valueGetter: params => params.row.totalCbm || 'N/A',
  //     renderCell: params => {
  //       const value = params.row.totalCbm || 'N/A'
  //       return <Tooltip title={value}>{value}</Tooltip>
  //     }
  //   },
  //   {
  //     field: "totalWeight",
  //     headerName: "Total Weight",
  //     width: 200,
  //     sortable: false,
  //     valueGetter: params => params.row.totalWeight || 'N/A',
  //     renderCell: params => {
  //       const value = params.row.totalWeight || 'N/A'
  //       return <Tooltip title={value}>{value}</Tooltip>
  //     }
  //   },
  //   {
  //     field: "totalVolume",
  //     headerName: "totalVolume",
  //     width: 200,
  //     sortable: false,
  //     valueGetter: params => params.row.totalVolume || 'N/A',
  //     renderCell: params => {
  //       const value = params.row.totalVolume || 'N/A'
  //       return <Tooltip title={value}>{value}</Tooltip>
  //     }
  //   },
  //   {
  //     field: "totalAmount",
  //     headerName: "Total Amount",
  //     width: 200,
  //     sortable: false,
  //     valueGetter: params => params.row.totalAmount || 'N/A',
  //     renderCell: params => {
  //       const value = params.row.totalAmount || 'N/A'
  //       return <Tooltip title={value}>{value}</Tooltip>
  //     }
  //   },
  
    {
      field: "typeOfShipment",
      headerName: "Shipment Type",
      width: 200,
      sortable: false,
      valueGetter: params => params.row.quotation.attributes.typeOfShipment || 'N/A',
      renderCell: params => {
        const value = params.row.quotation.attributes.typeOfShipment || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
    {
      field: "items",
      headerName: "Items",
      width: 200,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        const value = params.row.order || []
        // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
        return<Tooltip title="Click to View Items"> <IconButton variant="extended" color="primary" onClick={() => setShowItemsModal(value)} >
          <ViewComfyAltIcon/>
        </IconButton></Tooltip>
      }
    },
    {
      field: "containerDetails",
      headerName: "Container Details",
      width: 200,
      sortable: false,
      disableExport: true,
      renderCell: params => {
        const value = params?.row?.container?.attributes || []
        // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
        return <Tooltip title="Click to View Container Details"> <IconButton variant="extended" disabled={!params.row.container} color="primary" onClick={() => setContainerDetails(value)} >
          <PreviewIcon/>
        </IconButton></Tooltip>
      }
    },
    {
      field: "Packaginglist",
      headerName: "Packaging list",
      width: 200,
      headerAlign: "center", // Align the header text to the center
      align: "center",
      sortable: false,
      disableExport: true,
      renderCell: params => {
        const value = params.row.quotation.attributes.packagingList || []
        // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
        return <Tooltip title="Click to view packaging list"><IconButton variant="extended" color="primary" onClick={(e) => {
          e.stopPropagation();
        openPackaging(value);}} >
          <VisibilityIcon/>
        </IconButton></Tooltip>
      }
    }

  ];
  result.push({
    field:"  ",
    headerName: 'Actions',
    headerAlign: 'center',
    editable: false,
    minWidth:200,
    disableColumnMenu:true,
    disableExport:true,
    disableReorder:true,
    hideSortIcons:true,
    renderCell: (params) => {
      return (
        // <PinCodeChangeButtonContainer>
        <div style={{margin: '0 auto', width:'auto'}}>

        
        <Tooltip title="Click if Order Delivered">

          <IconButton variant="extended" color="primary" 
             onClick={() => onRecievedButton(params.row.id)}
                 >
  <ForwardIcon  />

</IconButton>
        </Tooltip>
                   
            </div>

        // </PinCodeChangeButtonContainer>
      );
    },
  },)
}
else{

result = [
  {
    field: "soId",
    headerName: "SO Id",
    sortable: false,
    width: 150,

    editable: true,
  },
  {
    field: "customerName",
    headerName: "Customer Name",
    width: 200,
    sortable: false,
    valueGetter: params => params.row.customer?.name || 'N/A',
    renderCell: params => {
      const value = params.row.customer?.name || 'N/A'
      return <Tooltip title={value}>{value}</Tooltip>
    }

  },
  {
    field: "customerMobile",
    headerName: "Customer Mobile",
    width: 200,
    sortable: false,
    valueGetter: params => params.row.customer?.mobile || 'N/A',
    renderCell: params => {
      const value = params.row.customer?.mobile || 'N/A'
      return <Tooltip title={value}>{value}</Tooltip>
    }
  },
  {
    field: "customerCity",
    headerName: "Customer City",
    width: 200,
    sortable: false,
    valueGetter: params => params.row.customer?.city || 'N/A',
    renderCell: params => {
      const value = params.row.customer?.city || 'N/A'
      return <Tooltip title={value}>{value}</Tooltip>
    }
  },
  {
    field: "supplierName",
    headerName: "Supplier Name",
    width: 200,
    sortable: false,
    valueGetter: params => params.row.supplier?.name || 'N/A',
    renderCell: params => {
      const value = params.row.supplier?.name || 'N/A'
      return <Tooltip title={value}>{value}</Tooltip>
    }
  },
  {
    field: "supplierWechatNumber",
    headerName: "Supplier Wechat Number",
    width: 200,
    sortable: false,
    valueGetter: params => params.row.supplier?.wechatNumber || 'N/A',
    renderCell: params => {
      const value = params.row.supplier?.wechatNumber || 'N/A'
      return <Tooltip title={value}>{value}</Tooltip>
    }
  },
  {
    field: "deliveryDate",
    headerName: "Delivery Date",
    width: 200,
    sortable: false,
    valueGetter: params => params.row.supplier?.deliveryDate || 'N/A',
    renderCell: params => {
      const value = params.row.supplier?.deliveryDate || 'N/A'
      return <Tooltip title={value}>{value}</Tooltip>
    }
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    sortable: false,
    valueGetter: params => params.row.status || 'N/A',
    renderCell: params => {
      const value = params.row.status || 'N/A'
      return <Tooltip title={value}>{value}</Tooltip>
    }
  },
  {
    field: "shipmentType",
    headerName: "Shipment Type",
    width: 200,
    sortable: false,
    valueGetter: params => params.row.shipmentType || 'N/A',
    renderCell: params => {
      const value = params.row.shipmentType || 'N/A'
      return <Tooltip title={value}>{value}</Tooltip>
    }
  },
  {
    field: "items",
    headerName: "Items",
    width: 200,
    sortable: false,
    disableExport: true,
    renderCell: params => {
      const value = params.row.items || []
      // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
      return <FabStyle variant="extended" color="primary" onClick={() => setShowItemsModal(value)} >
        View Items
      </FabStyle>
    }
  },
  {
    field: "packagingList",
    headerName: "Packaging List",
    width: 200,
    sortable: false,
    disableExport: true,
    renderCell: params => {
      const value = params.row.packagingList || []
      // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
      return <FabStyle variant="extended" disabled={!params.row.packagingList} color="primary" onClick={() => setShowPackagingListModal(value)} >
        View Packaging List
      </FabStyle>
    }
  },

];
result.push({
  field:"  ",
  headerName: 'Actions',
  headerAlign: 'center',
  editable: false,
  minWidth:200,
  disableColumnMenu:true,
  disableExport:true,
  disableReorder:true,
  hideSortIcons:true,
  renderCell: (params) => {
    return (
      // <PinCodeChangeButtonContainer>
      <div style={{margin: '0 auto', width:'auto'}}>

      
      <Tooltip title="Click to generate">

        <FabStyle variant="extended" color="primary" 
        //  onClick={() => onSentButton(params.row)}
          //  onClick={() => {
             
          //    window.NotificationUtils.showSuccess(
          //      "Changes successfully made"
          //      );
          //     }}
               >
<Send  />
Send to next warehouse
</FabStyle>
      </Tooltip>
                 
          </div>

      // </PinCodeChangeButtonContainer>
    );
  },
},)
}

  return result
}
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "pendingDeliveryToCustomer",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleTabChange = async (value) => {
    setShippingOrders([]);
    setSelectedTab(value);
    // if(value === TABS.SEA){
      setShippingOrders(SHIPPING_ORDERS)
    // }
    // else if(value === TABS.AIR)
    // {
    //   setShippingOrders(AIR_SHIPPING_ORDERS)
    // }
  }

  const renderLoading = () => {
    return(
      <LoadingContainer>
        <CircularProgress color='primary'/>
      </LoadingContainer>
    )
  }

  const renderTabContent = () => {
   
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={shippingOrders}
          columns={getInventoryColumns()}
          rowHeight={70}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const renderModals = () => {
    return <>
      {showItemsModal && <InventoryItemsModal
        onClose={() => setShowItemsModal(null)}
        items={showItemsModal}
      />}
      {showPackagingListModal && <PackagingListModal
        onClose={() => setShowPackagingListModal(null)}
        list={showPackagingListModal}
      />}
      {
        containerDetails && <PlannedSoModal mode={null} temp={containerDetails}  onClose={()=>setContainerDetails(null)}/>
      }
    </>
  }

  return (
    <>
      {isMobile && (
        <Drawer props={props}/>
      )}
      <StaffEditorPageContainer>
      {loading && <OpaqueLoading />}
        <HeaderContainer>
          <Typography variant="h5">Pending Delivery To Customer</Typography>
          {/* <FormControl>
  <InputLabel id="demo-simple-select-label">Inventory Status</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    sx={{width: 150}}
    id="demo-simple-select"
    defaultValue={10}
    value={statusInventory || ''}
    label="Inventory Status"
    onChange={(e,value)=> setStatusInventory(e.target.value)}
  >
    <MenuItem value={10}>Pending SOs</MenuItem>
    <MenuItem value={20}>Recieved SOs</MenuItem>
  </Select>
</FormControl> */}
        </HeaderContainer>

        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="All Items" />
              <Tab label="Sea Inventories" />
              <Tab label="Air Inventories" />
            </StyledTabs>
            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
        {renderModals()}
      </StaffEditorPageContainer>

    </>
  );
}
