import React, { useState, useEffect } from "react";
import {
    Autocomplete,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid"; // Assuming the library import has changed
import moment from "moment";
import styled from "styled-components";
import {
  BULK_DELETE_FAILURE_CODES,
  DATE_FORMATS,
  ROLES,
} from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { MuiPickersUtilsProvider } from "@mui/lab"; // Updated import for date picker utils
import MomentUtils from "@mui/lab/AdapterMoment"; // Updated import for date picker adapter

// The rest of your code...

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
const isMobile = window.innerWidth < 900;

const ModalHeaderContainer = styled.div`
  display: flex;
  width:100%;
//   gap:50%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 65vh;
  .payrollGrid
    .MuiDataGrid-row
    .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    outline: none;
  }
`;

const ModalActionsContainer = styled.div`
  display: flex;
  
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const BulkCostingAndBilling = ({ onClose, data, fetchData }) => {
  // console.log(`Approve`, data);
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [fosUsers, setFosUsers] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [reasonObj, setReasonObj] = useState({});
  const [autocomplete,setAutocomplete] = useState();
  const [container,setContainer]=useState()
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [view,setView] = useState(false)
  const [form, setForm] = useState({})
  const [disableCheck, setDisableCheck] = useState(false)

  useEffect(() => {
    setFosUsers(data);
}, []);
useEffect(()=>{
updateDataGrid(selectedContainer)
},[selectedContainer])
const checkConditions = () => {
    const conditionsMet =
      form.chaAmount?.length &&
      form.chaName?.length &&
      form.dutyPaidByAmount?.length &&
      form.dutyPaidByName?.length &&
      form.chaReceiptedAmount?.length &&
      form.chaReceiptedName?.length &&
      form.chaKkAmount?.length &&
      form.chaKkName?.length &&
      form.freightAmountExRate?.length &&
      form.freightAmount?.length &&
      form.chennaiToMumAmount?.length &&
      form.chennaiToMumName?.length &&
      form.chennaiToDlAmount?.length &&
      form.chennaiToDlName?.length &&
      form.dlToMumAmount?.length &&
      form.dlToMumName?.length &&
      form.portNsToDlAmount?.length &&
      form.portNsToDlName?.length &&
      form.portNsToMumAmount?.length &&
      form.portNsToMumName?.length &&
      form.generalAmount?.length &&
      form.generalName?.length &&
      form.detentionAmount?.length &&
      form.detentionName?.length;

      setDisableCheck(!conditionsMet);
  };


  useEffect(() => {
    checkConditions();
  }, [form]);
const updateDataGrid = (selectedContainer) => {
    if (!selectedContainer) {
      // If no container is selected, display all data
      setFosUsers(data);
    } else {
      // Filter the data based on the selected container
      const filteredData = data.filter(item => item.container?.id === selectedContainer.id);
      setFosUsers(filteredData);
    }
  };

  const removeDuplicates = () => {
    const filteredArray = fosUsers.filter(item => item.container !== undefined );
    // console.log("FILTEREARRAYTEST",filteredArray)
    const idAndNameArray = filteredArray.map(item => ({ id: item.container.id, name: item.container.attributes.containerName}));
    // console.log("FILTEREARRAYTEST1",idAndNameArray)


    // const idAndNameArray = dataArray.map(item => (if(){ id: item.containerid, name: item.name }));
    const uniqueData = idAndNameArray.reduce((accumulator, currentValue) => {
        // console.log("ACCUMLATOR",accumulator)
        // console.log("ACCUMLATOR1",currentValue)
      // Check if a similar entry (based on name and id) already exists in the accumulator
      const exists = accumulator.some(
        (item) => item.id === currentValue.id && item.name === currentValue.name
      );
  
      // If not, add it to the accumulator
      if (!exists) {
        accumulator.push(currentValue);
      }
  
      return accumulator;
    }, []);
  // console.log("ACCUMLATOR",uniqueData)
    return uniqueData;
  };
  useEffect(()=>{
    autoCompleteoptions()
  },[fosUsers])
  const autoCompleteoptions=()=>{
    const data=removeDuplicates()
    setAutocomplete(data)
}

//   const handleChange = (name, value, id) => {
//     setReasonObj((prev) => {
//       let reasonObj = prev[id] ? prev[id] : {};

//       return {
//         ...prev,

//         [id]: {
//           ...reasonObj,

//           [name]: value,
//         },
//       };
//     });
//   };

  const getReason = (id) => {
    if (reasonObj[id]) {
      return reasonObj[id]?.reason || "";
    }

    return "";
  };

// console.log("SETSELCETDROW",selectedRowIds)
  
  const changeHandler = (fieldName, value) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const bulkApprove = async () => {
    try {
      // check for errors
//       if(!form.chaAmount?.length || !form.chaName?.length ||
//         !form.dutyPaidByAmount?.length || !form.dutyPaidByName?.length ||
//         !form.chaReceiptedAmount?.length || !form.chaReceiptedName?.length ||
//         !form.chaKkAmount?.length || !form.chaKkName?.length ||
//         !form.freightAmountExRate?.length || !form.freightAmount?.length ||
//         !form.chennaiToMumAmount?.length || !form.chennaiToMumName?.length ||
//         !form.chennaiToDlAmount?.length || !form.chennaiToDlName?.length ||
//         !form.dlToMumAmount?.length || !form.dlToMumName?.length ||
//         !form.portNsToDlAmount?.length || !form.portNsToDlName?.length ||
//         !form.portNsToMumAmount?.length || !form.portNsToMumName?.length ||
//         !form.generalAmount?.length || !form.generalName?.length ||
//         !form.detentionAmount?.length || !form.detentionName?.length 

//         ){
//     window.NotificationUtils.showError("Please enter the required details")
//        return ;        
// }
        let users = [...fosUsers];
      let usersToBeDeleted = [];
      if (selectedRowIds.length > 0) {
        // take those users from the users array for deletion
        selectedRowIds.map((id) => {
          let index = users.findIndex((member) => member.id === id);

        //   if (index !== -1) {
        //     users[index]["reason"] = reasonObj[id]?.reason || "N/A";
        //     // users[index]['terminationDate'] = reasonObj[id]?.terminationDate || moment(new Date()).startOf("day").unix();
        //   } else {
        //     users[index]["reason"] = "N/A";
        //     // users[index]['terminationDate'] = "";
        //   }
          let indexdata = users[index];
          // console.log("indexiii", indexdata);
          let sent_data = {
            id: indexdata.id,
          };
          usersToBeDeleted.push(indexdata.id);
          // console.log("usersToBeDeleted", usersToBeDeleted);
        });
      }
      // console.log("usersToBeDeleted1", usersToBeDeleted);
      let newRows = [...users];

      let i = 0;
      // while (i !== usersToBeDeleted.length) {

      //   let hasError = dataRowCheck(usersToBeDeleted[i]);
      //   if (hasError) {
      //     let index = newRows.findIndex((row) => row.id === usersToBeDeleted[i].id);
      //     if (index !== -1) {
      //       newRows[index] = {
      //         ...newRows[index],
      //         deleteStatus: "failure",
      //         failureReason: hasError,
      //       };
      //     }
      //     usersToBeDeleted.splice(i, 1);
      //   } else if (usersToBeDeleted[i].deleteStatus === 'success') {
      //     usersToBeDeleted.splice(i, 1);
      //   }
      //   else {
      //     i++;
      //   }
      // }
      // setFosUsers(newRows);
      // console.log("usersToBeDeleted", usersToBeDeleted);
      setLoading(true);
      const temp={id:usersToBeDeleted,...form}
      let response = await window.Platform.database.bulkCostingAndBillingUpdate(
        temp
      );
    setView(false)
      // console.log("RESPONSEStemp", temp);
    // let response=[]
     setSelectedRowIds([])
     setSelectedContainer()
      let newFosRows = [...newRows];
      for (let responseEntry of response) {
        let rowIndex = newFosRows.findIndex(
          (row) => row.id === responseEntry.id
        );
        if (rowIndex !== -1) {
          newFosRows[rowIndex].deleteStatus = responseEntry.deleteStatus;
          newFosRows[rowIndex].failureReason = "";
          if (responseEntry.deleteStatus === "failure") {
            newFosRows[rowIndex].failureReason = responseEntry.failureReason;
          }
        }
      }

      if (!deleted) {
        setDeleted(true);
      }
      // closeModal()
    //   setFosUsers(newFosRows);
    //   removeDeletedEmployees();
      setLoading(false);
      window.NotificationUtils.showSuccess('Details Updated succesfully')
      // window.NotificationUtils.showSuccess("Successfully deleted employee");
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error While Recieving Please Wait and try again");
      setLoading(false);
    }
  };

  const getRowClassName = (params) => {
    let deleteStatus = params.row.deleteStatus || "";
    if (deleteStatus === "success") {
      return "successRow";
    } else if (deleteStatus === "failure") {
      return "failureRow";
    } else {
      return "";
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "bulkCostingandBilling",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const removeDeletedEmployees = () => {
    let employeeToBeRemoved = [...fosUsers];
    // let allEmployees = [...props.visibleEmployees];

    employeeToBeRemoved.map((employee) => {
      if (employee.deleteStatus === "success") {
        // let index = allEmployees.findIndex(member => member.id === employee.id);
        // if(index !== -1){
        //   // allEmployees.splice(index, 1);
        // }
      }
    });

    // props.setVisibleEmployees(allEmployees);
  };

  const closeModal=()=>{
    fetchData()
    onClose()
  }


  const getGridColumns = () => {
    let result = [
            {
              field: "id",
              headerName: "SO Id",
              width: 200,
              sortable: false,
              valueGetter: params => params.row?.id || 'N/A',
              renderCell: params => {
                // console.log(params.row.quotation.attributes.shippingOrders.parent.id)
                const value = params.row?.id || 'N/A'
                return <Tooltip title={value}>{value}</Tooltip>
              }},
              {
                field: "Costing Details Update",
                headerName: "Costing Details Update",
                width: 200,
                sortable: false,
                valueGetter: params => params.row?.costing? 'Updated' : 'Pending' || 'N/A',
                renderCell: params => {
                  const value = params.row?.costing? 'Updated' : 'Pending' || 'N/A'
                  return <Tooltip title={value}>{value}</Tooltip>
                }
          
              },
              {
                field: "containerId",
                headerName: "Container Id",
                width: 200,
                sortable: false,
                valueGetter: params => params.row?.container?.id || 'N/A',
                renderCell: params => {
                  // console.log(params.row?.container?.id)
                  const value = params.row?.container?.id || 'N/A'
                  return <Tooltip title={value}>{value}</Tooltip>
                }},
                {
                    field: "containerName",
                    headerName: "Container Name",
                    width: 250,
                    sortable: false,
                    valueGetter: params => params.row?.container?.attributes?.containerName|| 'N/A',
                    renderCell: params => {
                      // console.log(params.row?.container?.id)
                      const value = params.row?.container?.attributes?.containerName|| 'N/A'
                      return <Tooltip title={value}>{value}</Tooltip>
                    }},
            {
              field: "deliveryDate",
              headerName: "Expected Delivery Date(Customer)",
              width: 250,
              sortable: false,
              valueGetter: params => moment.unix(params.row.quotation.attributes.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A',
              renderCell: params => {
                const val=params.row.quotation.attributes.deliveryDate;
                // console.log("VALL",val);
                const value = moment.unix(val).format(DATE_FORMATS.DEFAULT) || 'N/A';
                // console.log("VALUE",params.row.quotation.attributes.deliveryDate);
                return <Tooltip title={value}>{value}</Tooltip>
              }
            },
          //   {
          //     field: "totalCbm",
          //     headerName: "Total CBM",
          //     width: 200,
          //     sortable: false,
          //     valueGetter: params => params.row.totalCbm || 'N/A',
          //     renderCell: params => {
          //       const value = params.row.totalCbm || 'N/A'
          //       return <Tooltip title={value}>{value}</Tooltip>
          //     }
          //   },
          //   {
          //     field: "totalWeight",
          //     headerName: "Total Weight",
          //     width: 200,
          //     sortable: false,
          //     valueGetter: params => params.row.totalWeight || 'N/A',
          //     renderCell: params => {
          //       const value = params.row.totalWeight || 'N/A'
          //       return <Tooltip title={value}>{value}</Tooltip>
          //     }
          //   },
          //   {
          //     field: "totalVolume",
          //     headerName: "totalVolume",
          //     width: 200,
          //     sortable: false,
          //     valueGetter: params => params.row.totalVolume || 'N/A',
          //     renderCell: params => {
          //       const value = params.row.totalVolume || 'N/A'
          //       return <Tooltip title={value}>{value}</Tooltip>
          //     }
          //   },
          //   {
          //     field: "totalAmount",
          //     headerName: "Total Amount",
          //     width: 200,
          //     sortable: false,
          //     valueGetter: params => params.row.totalAmount || 'N/A',
          //     renderCell: params => {
          //       const value = params.row.totalAmount || 'N/A'
          //       return <Tooltip title={value}>{value}</Tooltip>
          //     }
          //   },
          
            {
              field: "typeOfShipment",
              headerName: "Shipment Type",
              width: 200,
              sortable: false,
              valueGetter: params => params.row.quotation.attributes.typeOfShipment || 'N/A',
              renderCell: params => {
                const value = params.row.quotation.attributes.typeOfShipment || 'N/A'
                return <Tooltip title={value}>{value}</Tooltip>
              }
            },
    ];
    if (deleted) {
      result.unshift(
        {
          field: "deleteStatus",

          headerName: "Status",

          width: 100,

          renderCell: (params) => {
            let name = "";

            if (!params.row.deleteStatus) {
              name = "N/A";
            } else {
              name =
                params.row.deleteStatus === "failure" ? "failure" : "Success";
            }

            return (
              <Tooltip title={name}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },

          editable: false,
        },
        {
          field: "failureReason",

          headerName: "Failure Reason",

          width: 200,

          renderCell: (params) => {
            let name = params.row.failureReason || "N/A";

            return (
              <Tooltip title={name}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },

          editable: false,
        }
      );
    }
    return result;
  };

  const renderModalContent = () => {
    return (
        <>
      {!view &&
      <>
        <>
          <DataGridContainer>
            <DataGrid
              className="payrollGrid"
              disableSelectionOnClick={false}
              disableRowSelectionOnClick
              isRowSelectable={(params) => params.row.deleteStatus === 'success' ? false : true}

              rows={fosUsers}
              columns={getGridColumns()}
              checkboxSelection={true}
              onRowSelectionModelChange={(selectedValues) => {
                // console.log("sss", selectedValues);
                setSelectedRowIds(selectedValues);
              }}
              rowSelectionModel={selectedRowIds}
              getRowClassName={getRowClassName}
              autoPageSize
            //   disableClickEventBubbling={false}
              components={{
                Toolbar: CustomToolbar,
              }}
              density="comfortable"
            />
          </DataGridContainer>
        </>

        <ModalActionsContainer>
          <FirstButton
            disabled={loading || selectedRowIds.length === 0}
            // onClick={onClose}
            onClick={()=>setView(true)}
            variant="contained"
            color="primary"
          >
            Save & Next
          </FirstButton>
          
        </ModalActionsContainer>
      </>}

      {view &&   <>
        <div style={{maxHeight:"480px",minHeight:"300px",overflowY:"scroll"}}>
        <ModalHeaderContainer>
          <Typography variant="h5">Clearance Cost</Typography >

          {/* <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton> */}
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>CHA:</Typography>
       
    
      <TextField
      
        label="Name"
        //   disabled={!disableCheck}
        value={form?.chaName}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("chaName", e.target.value)
          }
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      
        label="Amount"
        type="number"

        name="awbBlNumber"
        value={form?.chaAmount}
        // value={formData.awbBlNumber}
        // disabled={!disableCheck}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("chaAmount", e.target.value)
          }
        fullWidth
        // margin="normal"
      />
      </Stack>
               

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px', width: '300px'}}>Duty Paid By:</Typography>
       
    
      <TextField

        label="Name"
        name="shipperName"
        value={form?.dutyPaidByName}
        onChange={(e) =>
            changeHandler("dutyPaidByName", e.target.value)
          }
        //   disabled={!disableCheck}
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      value={form?.dutyPaidByAmount}

      type="number"

        label="Amount"
        name="awbBlNumber"
        // disabled={!disableCheck}
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("dutyPaidByAmount", e.target.value)
          }
        fullWidth
        // margin="normal"
      />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>CHA Receipted:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={!disableCheck}
        value={form?.chaReceiptedName}
        name="shipperName"
        // value={formData.shipperName}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("chaReceiptedName", e.target.value)
          }
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        type="number"

        // disabled={!disableCheck}
        value={form?.chaReceiptedAmount}
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("chaReceiptedAmount", e.target.value)
          }
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>CHA KK:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={!disableCheck}
        value={form?.chaKkName}
        onChange={(e) =>
            changeHandler("chaKkName", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        type="number"

        // disabled={!disableCheck}
       value={form?.chaKkAmount}
        onChange={(e) =>
            changeHandler("chaKkAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Freight Amount INR:</Typography>
       
    
      <TextField

        label="Ex Rate"
        name="shipperName"
        // disabled={!disableCheck}
        value={form?.freightAmountExRate}
        type="number"

        // value={formData.shipperName}
        // onChange={handleInputChange}
        onChange={(e) =>
            changeHandler("freightAmountExRate", e.target.value)
          }
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
          value={form?.freightAmount}
        type="number"

        //   disabled={!disableCheck}
        onChange={(e) =>
            changeHandler("freightAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Divider style={{width:'100%'}}/>

 <ModalHeaderContainer>
          <Typography variant="h5">Transport Cost</Typography>

          {/* <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton> */}
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Chennai to Mum:</Typography>
       
    
      <TextField

        label="Name"
        name="shipperName"
        // disabled={!disableCheck}
        // value={formData.shipperName}
        value={form?.chennaiToMumName}
        onChange={(e) =>
            changeHandler("chennaiToMumName", e.target.value)
          }
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        // disabled={!disableCheck}
        type="number"

        value={form?.chennaiToMumAmount}
        onChange={(e) =>
            changeHandler("chennaiToMumAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
               

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px', width: '300px'}}>Chennai to DL:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={!disableCheck}
        value={form?.chennaiToDlName}
        // value={formData.shipperName}
        onChange={(e) =>
            changeHandler("chennaiToDlName", e.target.value)
          }
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        type="number"

        value={form?.chennaiToDlAmount}
        // disabled={!disableCheck}
        onChange={(e) =>
            changeHandler("chennaiToDlAmount", e.target.value)
          }

        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>DL to MUM:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={!disableCheck}
        value={form?.dlToMumName}
        // value={formData.shipperName}
        onChange={(e) =>
            changeHandler("dlToMumName", e.target.value)
          }
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        type="number"

        // disabled={!disableCheck}
        value={form?.dlToMumAmount}
        onChange={(e) =>
            changeHandler("dlToMumAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>PORT NS TO DL:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={!disableCheck}
        value={form?.portNsToDlName}
        onChange={(e) =>
            changeHandler("portNsToDlName", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        type="number"

        // disabled={!disableCheck}
        value={form?.portNsToDlAmount}
        onChange={(e) =>
            changeHandler("portNsToDlAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>PORT NS TO MUM:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={!disableCheck}
        value={form?.portNsToMumName}
        onChange={(e) =>
            changeHandler("portNsToMumName", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        // disabled={!disableCheck}
        type="number"

        value={form?.portNsToMumAmount}
        onChange={(e) =>
            changeHandler("portNsToMumAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>General:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={!disableCheck}
        value={form?.generalName}
        // value={formData.shipperName}
        onChange={(e) =>
            changeHandler("generalName", e.target.value)
          }
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        type="number"

        // disabled={!disableCheck}
        value={form?.generalAmount}
        onChange={(e) =>
            changeHandler("generalAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Divider style={{width:'100%'}}/>
</Stack>

<ModalHeaderContainer>
          <Typography variant="h5">Extra(if any) Cost</Typography>

          {/* <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton> */}
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
            <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Detention:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={!disableCheck}
        value={form?.detentionName}
        onChange={(e) =>
            changeHandler("detentionName", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField

        label="Amount"
        // disabled={!disableCheck}
        type="number"

        value={form?.detentionAmount}
        onChange={(e) =>
            changeHandler("detentionAmount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
               

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px', width: '300px'}}>Remark:</Typography>
       
    
      <TextField

        label="Name"
        // disabled={disableCheck}
        value={form?.extraRemark1Name}
        onChange={(e) =>
            changeHandler("extraRemark1Name", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
        label="Amount"
        type="number"

        // disabled={disableCheck}
        value={form?.extraRemark1Amount}
        onChange={(e) =>
            changeHandler("extraRemark1Amount", e.target.value)
          }

        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Remark:</Typography>
       
    
      <TextField
        label="Name"
        value={form?.extraRemark2Name}
        // disabled={disableCheck}
        onChange={(e) =>
            changeHandler("extraRemark2Name", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
        label="Amount"
        value={form?.extraRemark2Amount}
        type="number"

        onChange={(e) =>
            changeHandler("extraRemark2Amount", e.target.value)
          }
        //   disabled={disableCheck}
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Remark:</Typography>
       
    
      <TextField
      value={form?.extraRemark3Name}
        label="Name"
        // disabled={disableCheck}
        onChange={(e) =>
            changeHandler("extraRemark3Name", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
        label="Amount"
        // disabled={disableCheck}
        value={form?.extraRemark3Amount}
        type="number"

        onChange={(e) =>
            changeHandler("extraRemark3Amount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography style={{paddingTop: '13px',width: '300px'}}>Remark:</Typography>
       
    
      <TextField
      
        label="Ex Rate"
        // disabled={disableCheck}
        value={form?.extraRemark4Name}
        onChange={(e) =>
            changeHandler("extraRemark4Name", e.target.value)
          }
        // value={formData.shipperName}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      
        label="Amount"
        // disabled={disableCheck}
        type="number"

        value={form?.extraRemark4Amount}
        onChange={(e) =>
            changeHandler("extraRemark4Amount", e.target.value)
          }
        // value={formData.awbBlNumber}
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      </Stack>
      <Divider style={{width:'100%'}}/>
</Stack>
      

          </Stack>
         
        </div>
        <ModalActionsContainer >
          <FirstButton
            disabled={loading || selectedRowIds.length === 0}
            // onClick={onClose}
            onClick={()=>setBack()}
            variant="contained"
            color="primary"
            sx={{marginTop:"5px"}}
          >
            Back
          </FirstButton>
          <FirstButton
            disabled={loading }
            // onClick={onClose}
            onClick={bulkApprove}
            variant="contained"
            color="primary"
            sx={{marginTop:"5px"}}
          >
            Update
          </FirstButton>
          
        </ModalActionsContainer>
          </>
          }
      </>
    );
  };

  const setBack=()=>{
    setSelectedRowIds(selectedRowIds)
    setView(false)
  }

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="lg"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Bulk Costing/Billing Update</Typography>
          
         {!view&& <Autocomplete
              // value={cityVal.find((city) => city.id === deliveryCity) || ''}
            //   value={deliveryCity || null}
            clearOnBlur={false}
  // disableClearable
              options={autocomplete}
              value={selectedContainer}
              getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
              style={{ width: isMobile ? "40%" : "30%",marginLeft:"40%"}}
              noOptionsText={loading ? "Loading..." : "No option"}
              onChange={async(event, value) => {
                setSelectedContainer(value);
                 updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = autocomplete.find((option) => option.name === value);

                if (!matchingOption ) {
                  // If there's no matching option, set the otherValue to null
                //   setDeliveryCity(null);
                setSelectedContainer(null);
                return;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter By Container"
                  variant="outlined"
                //   inputRef={fields[12]}
                  

                />
              )}
            />}
          <IconButton disabled={loading} onClick={()=>closeModal()}>
            <CloseOutlinedIcon />
          </IconButton>
        
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkCostingAndBilling;
