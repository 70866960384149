import React, { useContext } from "react";

import Drawer from "../../components/common/drawer/drawer";

import styled from "styled-components";

import Typography from "@mui/material/Typography";

import { NavLink } from "react-router-dom";

import { PRIMARY_COLOR } from "../../constants";
import { AuthContext } from "../../components/contextAPI/ContextAPI";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 82px)`};

  width: 100%;

  background: none;

  padding: 10px 15px;

  ${isMobile &&
  `

    position: relative;

    top:10px;

    `}
`;

const HeaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: space-between;

  margin-bottom: 10px;
`;

const ShortcutsContainer = styled.div`
  padding: 20px;

  overflow: auto;

  height: ${isMobile ? "calc(100vh - 125px)" : "calc(100% - 43px)"};

  width: 100%;

  border-radius: 8px;

  border: solid 1px lightGrey;

  display: flex;

  gap: 20px;

  ${isMobile &&
  `

    flex-direction:column;

    align-items:center;

    `}
`;

const ShortcutCard = styled.div`
  height: 200px;

  width: 200px;

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
  &:hover {
    box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 20%),
      0px 3px 5px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  }
`;

const CardIcon = styled.i`
  font-size: 112px;

  color: ${PRIMARY_COLOR};
`;

const StyledCardHeading = styled(Typography)`
  text-transform: none;

  letter-spacing: 0.5px;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
`;



const OrderManager = (props) => {
  const { auth, setAuth } = useContext(AuthContext);
  let test = [];
  test = auth?.permissions?.filter((val) => val.role === auth.user.role)[0];

  // console.log('tessst',test)

  const SHORTCUTS = [
    {
      name: "Quotation Generation ",
  
      icon: "design_services",
  
      pathTo: "/quotationgenerate",
      role: test && test["quotation generate"]?.read ? [auth.user.role] : [],
    },
  
    {
      name: "Quotation Approvals(s) ",
  
      icon: "manage_search",
  
      pathTo: "/quotationgeneration",
      role: test && test['quotation generation']?.read ? [auth.user.role] : [],
    },
  
    // {
    //   name: "Items Management ",
  
    //   icon: "category",
  
    //   pathTo: "/master-manager-items",
    // },
  
    // {
    //   name: "Templates Management",
  
    //   icon: "format_indent_decrease",
  
    //   pathTo: "/master-manager-templates",
    // },
    {
      name: "Pending Quotation(s)",
  
      icon: "pending_actions",
  
      pathTo: "/quotationcustomerapproval",
      role: test && test['tracking']?.read?[auth.user.role] : [],
      
    },
    {
      name: "SO Generation",
  
      icon: "charging_station",
  
      pathTo: "/sogeneration",
      role: test && test['so generation']?.read?[auth.user.role] : [],
    },
    // {
    //   name: "Timeline",
  
    //   icon: "calendar_month",
  
    //   pathTo: "/timeline",
    // },
    //   pathTo: "/timeline",
    // },
    {
      name: "Quotation History",
  
      icon: "history",
  
      pathTo: "/quotationhistory",
      role: test && test['quotation history']?.read?[auth.user.role] : [],
    },
  
  
  ];
  const renderShortcut = () => {

    const shortcut = SHORTCUTS.filter((card) =>
    {  
      return card.role.includes(auth?.user?.role)
    }
  );
  // console.log('shortcut',shortcut);
  return shortcut.map((shortcut, index) => (
    <ShortcutCard>
        <StyledNavLink to={shortcut.pathTo || "/dashboard"}>
          <CardIcon className="icon material-icons">{shortcut.icon}</CardIcon>

          <StyledCardHeading variant="h6" align="center">
            {shortcut.name}
          </StyledCardHeading>
        </StyledNavLink>
      </ShortcutCard>
  ));
  };

  return (
    <>
      {isMobile && (
        <Drawer
          setShowNotifications={props.setShowNotifications}
          onAuthUserChanged={props.onAuthUserChanged}
          user={props.user}
          props = {props}
        />
      )}

      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Q/So Management</Typography>

          <div></div>
        </HeaderContainer>

        <ShortcutsContainer>
          {renderShortcut()}
        </ShortcutsContainer>
      </StaffEditorPageContainer>
    </>
  );
};

export default OrderManager;
