import React, { useEffect, useState } from "react";
import {
    Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const QuotationRemarkModal = ({ onCancel, initialData ,loading, onSubmit, idResponse }) => {
    const [form, setForm] = useState(idResponse || {});

// console.log(idResponse);
  
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
      // console.log(form)
    };
  useEffect(()=>{
    // console.log("forrm",form)
  },[form])
    const submitHandler = () => {
      // console.log("creating activity...", form);
    //   console.log({...form, supplierName: initialData.SupplierName});
    if(  form.typeOfShipment )
      onSubmit({...form})
      // console.log(form)
      else
      window.NotificationUtils.showError("Please fill all the fields")
    };
    const options = [ { value: 'Air', label: 'Air' },
    { value: 'Sea', label: 'Sea' },
  ]
  
    return (
      <StyledDialog disableEnforceFocus maxWidth="xs" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add Remark and type of shipment</Typography>

          <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
          {/* <Typography variant="h10">Supplier Name: {initialData.SupplierName}</Typography> */}
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
        <Stack gap="20px" style={{ padding: "10px" }}>

        <Stack>

<Autocomplete
   // size="small"
   options={options}
   // style={{ width: !isMobile?'200px':'90%' }}
   // disabled={supplierChoosed}
   value= {form.typeOfShipment || ''}
   onChange={(e, value)=>{
     changeHandler("typeOfShipment", value?.value)

   }}

   // style={{ width: !isMobile ? "400px" : "90%" }}
   renderInput={(params) => (
     <TextField  {...params} label="Shipment Type" variant="outlined" required
   //   style={{ width: !isMobile?'200px':'100%' }}
   //   inputRef={fields[3]}
     onKeyUp={(e) => {
       if (e.key === 'Enter') {
         // e.preventDefault();
     changeHandler("typeOfShipment", e.target.value)

         e.preventDefault();
        
       }
     }}
     />
   )}
 />
</Stack>
<Stack>
              <TextField
                // required
                variant="outlined"
                label="Delivery Place"
                // defaultValue={form["name"]}
                value= {form.deliveryPlace || ''}
                onChange={(e) =>
                  changeHandler("deliveryPlace", e.target.value)
                }
              />
            </Stack>
            {/* {loading && <OpaqueLoading />} */}
            <Stack>
              <TextField
                // required
                variant="outlined"
                value= {form.remark || ''}
                label="Remarks"
                // defaultValue={form["name"]}
                onChange={(e) =>
                  changeHandler("remark", e.target.value)
                }
              />
            </Stack>
            {/* <Stack>

             <Autocomplete
                // size="small"
                options={options}
                // style={{ width: !isMobile?'200px':'90%' }}
                // disabled={supplierChoosed}
                value= {form.typeOfShipment || ''}
                onChange={(e, value)=>{
                  changeHandler("typeOfShipment", value?.value)
                }}
             
                // style={{ width: !isMobile ? "400px" : "90%" }}
                renderInput={(params) => (
                  <TextField  {...params} label="Shipment Type" variant="outlined" required
                //   style={{ width: !isMobile?'200px':'100%' }}
                //   inputRef={fields[3]}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      // e.preventDefault();
                  changeHandler("typeOfShipment", e.target.value)

                      e.preventDefault();
                     
                    }
                  }}
                  />
                )}
              />
            </Stack>
            <Stack>
              <TextField
                required
                variant="outlined"
                label="Delivery Place"
                // defaultValue={form["name"]}
                value= {form.deliveryPlace || ''}
                onChange={(e) =>
                  changeHandler("deliveryPlace", e.target.value)
                }
              />
            </Stack> */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap="10px"
              align-items="center"
            >
              <Button color="error" onClick={onCancel}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
               Send for approval
              </Button>
            </Stack>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default QuotationRemarkModal;
  