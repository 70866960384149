import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { DATE_FORMATS, PRIMARY_COLOR, SHADOW } from "../../constants";
import { Button, IconButton, Tooltip, Typography } from "@mui/material"; // Update import to "@mui/material"
import {
    DataGrid,
    GridColDef,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
  } from "@mui/x-data-grid";
//   import GridToolbar from "@mui/x-data-grid/GridToolbar"; // Import GridToolbar separately

import moment from "moment";
import AddCityModal from "../../components/modals/addCityModal";
import { Delete } from "@mui/icons-material";
const isMobile = window.innerWidth < 900;

const ContentContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  flex-direction: column;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 145px);
  width: 100%;
  position: relative;
`;

const DataGridStyled = styled(DataGrid)`
  background: white;
  border-radius: 10px !important;
  box-shadow: ${SHADOW};
  .MuiDataGrid-overlay{
    .MuiCircularProgress-root{
      color: ${PRIMARY_COLOR}
    }
  }
`;

const Toolbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const CitiesManager = (props) => {
  const [loading, setLoading] = useState(false);

  const [cities, setCities] = useState([]);

  const [selectedCity, setSelectedCity] = useState(null);

const gridColumns = () =>{
  let results = [
    {
        field: "cityName",
        headerName: "City Name",
        minWidth: 200,
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.cityName;
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
    },
    {
      field: "districtName",
      headerName: "District Name",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      valueGetter: (params) => {
        return params.row.district.districtName;
      },
      renderCell: (params) => {
        let name = params.row.district.districtName;
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "stateName",
      headerName: "State Name",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      valueGetter: (params) => {
        return params.row.state.stateName;
      },
      renderCell: (params) => {
        let name = params.row.state.stateName;
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        let name = moment(params.row.createdAt).format(DATE_FORMATS.DATE_WITH_TIME);
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      minWidth: 200,
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        let name = moment(params.row.updatedAt).format(DATE_FORMATS.DATE_WITH_TIME);
        return (
          <Tooltip title={name}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },

  ];
  results.push({

    field: '  ',

    headerName: 'Actions',

    editable: false,

    minWidth: 150,

    flex: 0.22,

    maxWidth:160,

    disableColumnMenu: true,

    disableExport: true,

    disableReorder: true,

    hideSortIcons: true,

    renderCell: (params) => {

      return (

        <>

          <IconButton

            size="large"

            color="primary"

            onClick={(e) => {

              e.stopPropagation();

              onClickDeleteEmployee(params.row)

            }}

          >

           <Delete/>

          </IconButton>

         

        </>

      );

    },

  });
  return results;
}

const onDelete=async(val)=>{

  try{

      let results = await window.Platform.database.deleteCity({val})
      fetchCities();
  }
  catch(error)
  {
    window.NotificationUtils.showError("Something went wrong");
    console.log(error.message)
  }

}




const onClickDeleteEmployee = (params) => {

  // console.log(`onClickDeleteEmployee`,params)

  window.confirmationModal(

    `Are you sure you want to deactivate ${params.cityName}

     (${params.id})?`,()=> onDelete(params.id)

  );
  }

  const fetchCities = async () => {
    try {
      setLoading(true);
      let response = await window.Platform.database.fetchCities();
      setCities(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching cities");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCities();
  }, []);

  const onCloseModal = (update=null) => {
    if (update) {
      let citiesCopy = [...cities];
      let index = citiesCopy.findIndex(
        (city) => city.id === update.id
      );
      if (index !== -1) {
        citiesCopy[index] = update;
      } else {
        citiesCopy.unshift(update);
      }
      setCities(citiesCopy);
    }
    setSelectedCity(null);
  };

  const renderAddCityModal = () => {
    return (
      <AddCityModal
        closeModal={onCloseModal}
        selectedCity={selectedCity || {}}
      />
    );
  };

  const renderHeader = () => {
    return (
      <Toolbar>
        <Typography variant="h5">Cities Manager</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => setSelectedCity({})}
          >
            Add City
          </Button>
        </div>
      </Toolbar>
    );
  };

  const renderData = () => {
    return (
      <DataGridContainer>
        <DataGridStyled
          loading={loading}
          rows={cities}
          columns={gridColumns()}
          disableSelectionOnClick
          autoPageSize
          checkboxSelection
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              csvOptions: {
                fileName: `citiesList`,
              },
            },
          }}
          onRowClick={(rowParams, event) => {
            if (event.target.tagName !== "INPUT") {
              setSelectedCity(rowParams.row);
            }
          }}
        />
      </DataGridContainer>
    );
  };

  return (
    <>
      {isMobile && (
        <Drawer
          setShowNotifications={props.setShowNotifications}
          onAuthUserChanged={props.onAuthUserChanged}
          user={props.user}
        />
      )}
      <ContentContainer>
        {renderHeader()}
        {renderData()}
        {selectedCity && renderAddCityModal()}
      </ContentContainer>
    </>
  );
};

export default CitiesManager;
