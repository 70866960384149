
import React from "react";
import styled from "styled-components";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
// import { Typography } from "@material-ui/core";

const LabelText = styled(Typography)`
  font-size: 13px !important;
  letter-spacing: 0.5px;
`;

const Container = styled.div`
.Mui-selected {
    background-color: #409d8e !important;
    color: white !important;
  }
  
.Mui-selected h6 {
    color: inherit;
  }
`

const CustomToggle = (props) => {
  const handleChange = (event, value) => {
    if (value === props.optionOne.value || value === props.optionTwo.value) {
      props.onChange(value);
    }
  };

  return (
    <Container>
      <ToggleButtonGroup
        exclusive
        value={props.selected}
        onChange={props.onChange}
      >
        <ToggleButton value={props.optionOne.value}>
          <LabelText variant="h6">{props.optionOne.label}</LabelText>
        </ToggleButton>
        <ToggleButton value={props.optionTwo.value}>
          <LabelText variant="h6">{props.optionTwo.label}</LabelText>
        </ToggleButton>
      </ToggleButtonGroup>
    </Container>
  );
};

export default CustomToggle;