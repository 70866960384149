import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

const isMobile = window.innerWidth < 900;

const TotalAmountTypography = styled(Typography)`

  position: absolute;

  bottom: 10px;

  right: 10px;

  font-size: ${isMobile ? "14px" : "inherit"};

  bottom: ${isMobile ? "85px" : "70px"};

  right: ${isMobile ? "15px" : "70px"};

`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;

const DataGridContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  
//   height: 500px;
  padding:10px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const SoModal = ({ onClose, items, onSubmit, mode }) => {
  const [data,setData]=useState()
  const [loading,setLoading]=useState(false)
  useEffect(()=>{
    if(mode === true){
      fetchSOById()
    }
  },[])
  const fetchSOById = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchSoById({id:items});
      // console.log("RESPONSEFETCHSOBYID",results);
      setData(results[0])
      // setAccessChanges(results);
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    } finally {
      setLoading(false);
    }
  };

  const getItemColumns = () => {
    let result = [
      {
        field: "item",
        headerName: "Name",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "shippingMark",
        headerName: "Shipping Mark",
        sortable: false,
        width: 150,
        valueGetter: (params) => 
        {
          console.log(params.row)
         return params.row?.shippingMark || 'N/A'
        }

        // editable: true,
      },
      {
        field: "category",
        headerName: "Category",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "volume",
        headerName: "Volume",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "qty",
        headerName: "Quantity",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "ctn",
        headerName: "Carton",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "weight",
        headerName: "Weight",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "cbm",
        headerName: "Cubic Meter",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "unitOfAmount",
        headerName: "Unit of Amount",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        width: 150,
        // editable: true,
      },
    ];

    return result;
  };
  const getItemColumns1 = () => {
    let result = [
      {
        field: "item",
        headerName: "Name",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.item,

        
        // editable: true,
      },
      {
        field: "category",
        headerName: "Category",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.category,

        // editable: true,
      },
      {
        field: "volume",
        headerName: "Volume",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.volume,

        // editable: true,
      },
      {
        field: "qty",
        headerName: "Quantity",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.qty,

        // editable: true,
      },
      {
        field: "ctn",
        headerName: "Carton",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.ctn,

        // editable: true,
      },
      {
        field: "weight",
        headerName: "Weight",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.weight,

        // editable: true,
      },
      {
        field: "cbm",
        headerName: "Cubic Meter",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.cbm,

        // editable: true,
      },
      {
        field: "unitOfAmount",
        headerName: "Unit of Amount",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.unitOfAmount,

        // editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.rate,

        // editable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.attributes?.amount,

        // editable: true,
      },
    ];

    return result;
  };


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "items-list",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const generateSO = async(val) => {
    // console.log(val)
    onSubmit(val)
        // try {
    //   console.log(val);
    //   // let results = await window.fdatabase.approveAdminQuotation(val)
    //   window.NotificationUtils.showSuccess("Quotation Approved");
    //   // return results
    // } catch (error) {
    //   window.NotificationUtils.showError(error);
    // }
  }

  const renderContent = (params) => {
    return (
      <>
        <DataGridContainer>
          {/* <Box sx={{ height: "100%", width: "100%" }}> */}
            <DataGrid
              className="payrollGrid"
              sx={{height:isMobile ? "300px":"300px"}}
              checkboxSelection={true}
              rows={mode?(data?.orderLineItems||[]):(items?.items || [])}
              columns={mode?getItemColumns1():getItemColumns()}
              rowHeight={30}
              disableSelectionOnClick
              disableRowSelectionOnClick
              autoPageSize
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          {/* </Box> */}
        </DataGridContainer>
        {/* {loading && <OpaqueLoading />} */}
        <ModalActionsContainer>
        <TotalAmountTypography sx={{bottom:mode&&"20px"}}  variant= {isMobile ?"body2":"button"}><strong>Total Amount:</strong> {mode?data?.totalAmount:items.totalAmount}</TotalAmountTypography>
          <div style={{ textAlign: "center", marginRight: 8 }}>
            {!mode&&<Tooltip title="Click to generate">
              <FabStyle
                variant="extended"
                color="primary"
                onClick={() => generateSO(items)}
              >
                <CheckIcon  /> {/* Replace the SaveAlt icon with the CheckIcon */}
                Generate
              </FabStyle>
            </Tooltip>}
          </div>

          
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg">
    <ModalHeaderContainer style={{marginBottom:0,paddingBottom:0}}>
       <Typography  variant="h5">
         {/* {items?.customerName} */}
         Shipping Order Generation Details-
         <Tooltip title={mode?"SO Id":"Quotation ID"}>

           {mode? "("+items+")"  :"("+items?.id+")"}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose}>
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
{loading&&<OpaqueLoading/>}
     <div style={{ padding: "10px",marginLeft:"10px", display: isMobile ?"-ms-grid":"flex", gap: "10px", maxHeight:"500px" }}>
       {/* First Column */}
       <div style={{ flex: !isMobile && "1 1 40%" }}>
         
         <Typography variant= {isMobile && "body2"}> 
           <strong>Created At:</strong> {mode?(moment(data?.quotation?.attributes?.createdAt).format("YYYY-MM-DD")):(moment(items.createdAt).format("YYYY-MM-DD"))}
         </Typography>
        {!isMobile && <br/>}
         <Typography  variant= {isMobile && "body2"}>
           <strong>Customer Name:</strong> {mode?data?.quotation?.attributes?.customerName:items.customerName}
         </Typography>
         {!isMobile && <br/>}

         <Typography  variant= {isMobile && "body2"}>
           <strong>Customer ID:</strong> {mode?data?.quotation?.attributes?.customerId:items.customerId}
         </Typography>
         {!isMobile && <br/>}

           <Typography  variant= {isMobile && "body2"}><strong>Type of shipment:</strong> {mode?data?.quotation?.attributes?.typeOfShipment:items.typeOfShipment}</Typography>
         {/* <Typography>
           <strong>Expected Delivery Date:</strong> {items.deliveryDate}
         </Typography>
         <Typography>
           <strong>Delivery Place:</strong> {items.deliveryPlace}
         </Typography>
         <Typography>
           <strong>Initial Warehouse Destination:</strong> {items.warehouseName}
         </Typography>
         <Typography>
           <strong>Total Weight:</strong> {items.totalWeight}
         </Typography> */}
       </div>
       <div style={{ flex:  !isMobile &&"1 1 45%" }}>
       <Typography  variant= {isMobile && "body2"}>
           <strong>Expected Delivery Date:</strong> {mode?moment.unix(data?.quotation?.attributes?.deliveryDate).format("YYYY-MM-DD"):moment.unix(items.deliveryDate).format("YYYY-MM-DD")}
         </Typography>
         {!isMobile && <br/>}

         <Typography  variant= {isMobile && "body2"}>
           <strong>Delivery Place:</strong> {mode?data?.quotation?.attributes?.deliveryPlace:items.deliveryPlace}
         </Typography>
         {!isMobile && <br/>}

         <Typography  variant= {isMobile && "body2"}>
           <strong>Initial Warehouse Destination:</strong> {mode?data?.quotation?.attributes?.warehouseName:items.warehouseName}
         </Typography>
         {!isMobile && <br/>}

         <Typography  variant= {isMobile && "body2"}>
           <strong>Total Weight:</strong> {mode?data?.quotation?.attributes?.totalWeight:items.totalWeight}
         </Typography> 
         {!isMobile && <br/>}

         </div>

       {/* Second Column */}
       <div style={{ flex: !isMobile && "1 1 20%" }}>
         <Typography  variant= {isMobile && "body2"}><strong>Supplier Name:</strong> {mode?data?.quotation?.attributes?.supplierName:items.supplierName}</Typography>
         {!isMobile && <br/>}

         <Typography  variant= {isMobile && "body2"}><strong>Supplier ID:</strong> {mode?data?.quotation?.attributes?.supplierId:items.supplierId}</Typography>
         {!isMobile && <br/>}

         {/* <Typography  variant= {isMobile && "body2"}><strong>Total Amount:</strong> {items.totalAmount}</Typography> */}
         {/* {!isMobile && <br/>} */}

         <Typography variant={isMobile && "body2"}><strong>Total CBM:</strong> {mode?data?.quotation?.attributes?.totalCbm:items.totalCbm}</Typography>
         {!isMobile && <br/>}
         
       </div>
     </div>
   
     {renderContent()}

   </Dialog>
  );
};

export default SoModal;
