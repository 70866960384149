import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;


const AddSupplierModal = ({ onCancel, initialData, loading, onSubmit, setLoading }) => {
  const [form, setForm] = useState({

  });

  const [supplierAlready, setSupplierAlready] = useState([]);
  const [apiLoading, setApiLoading] = useState(false)
  const [cityVal, setCityVal] = useState(["Delhi"]);
  const fetchCities = async () => {
    try {
      setApiLoading(true);
      let response = await window.Platform.database.fetchCities();
      setCityVal(response);
      setApiLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching cities");
      setApiLoading(false);
    }
  };

  useEffect(() => {
    fetchCities()
  }, [])

  const options = [
    { value: "Qty / Ctn", label: "Qty / Ctn" },
    { value: "CTN", label: "CTN" },
    { value: "QTY", label: "QTY" },
    { value: "WEIGHT (KGS)", label: "WEIGHT (KGS)" },
    { value: "CBM", label: "CBM" },
  ];
  const [checkExisting, setCheckExisting] = useState(false);


  const changeHandler = (fieldName, value) => {
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const submitHandler = () => {
    if(!checkExisting ){
      if(!form.supplier ){
    
      return  window.NotificationUtils.showError("Enter all details");
  }}
  else{
    if(!form.supplierName || !form.supplierCity||!form.supplierMobile||!form.supplierWeChatId){
      return  window.NotificationUtils.showError("Enter all details");
    }
  }

    onSubmit({ ...form, customerName: initialData.customerName })
    // else
    // onSubmit({...form, customerName: initialData.customerName})
  };


  const updateSupplier = () => {

    setCheckExisting(!checkExisting)
    setForm({})

  }
  const fetchSupplierVal = async (val) => {
    try {
      // setApiLoading(true)

      let result = await window.Platform.database.getAllSuppliers(val);
      setSupplierAlready(result);
      // return
    }
    catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      // setApiLoading(false)

    }
    // console.log(results)
  };
  useEffect(() => {
    fetchSupplierVal(initialData)
  }, [])


  return (
    <StyledDialog disableEnforceFocus maxWidth="xs" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add New Supplier</Typography>

          <IconButton disabled={loading} onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        <ModalHeaderContainer>
          <Typography variant="h10">Customer Name: {initialData?.customerName}</Typography>
          <Button variant="contained" size="small" onClick={updateSupplier}>{!checkExisting ? "Add New Supplier" : "Add From Existing"}</Button>

        </ModalHeaderContainer>
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
        {!checkExisting &&
          <>
            <Stack gap="20px" style={{ padding: "10px" }}>

              <Stack>

                <Autocomplete
                  // size="small"
                  options={supplierAlready}
                  // style={{ width: !isMobile ? "200px" : "90%" }}
                  // defaultValue={null}
                  // value={categoryValue || ""}
                  getOptionLabel={(option) => option.value}
                  // disabled={supplierChoosed}
                  onChange={(e, value) =>
                    changeHandler("supplier", supplierAlready.filter((val) => val.value == value?.value)[0])
                  }
                  noOptionsText={apiLoading ? 'Loading...' : 'No option'}
                  // onChange={(e,value) => {
                  // changeHandlerForItem("category", value?.value);
                  // setCategoryValue(value?.value)
                  // }}
                  // style={{ width: !isMobile ? "400px" : "90%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Existing Suppliers"
                      variant="outlined"
                      required
                    // defaultValue={null}
                    // style={{ width: !isMobile ? "200px" : "100%" }}
                    // inputRef={fields[3]}
                    // onKeyUp={(e) => {
                    //   if (e.key === "Enter") {
                    //     if(e.target.value)
                    //       {
                    //     setCategoryValue(e.target.value)
                    //     e.preventDefault();
                    //     console.log(e.target.value);
                    //     changeHandlerForItem("category", e.target.value);
                    //     fields[4].current.focus();
                    //       }
                    //   }
                    // }}
                    />
                  )}
                />
              </Stack>

              <Stack
                direction="row"
                justifyContent="flex-end"
                gap="10px"
                align-items="center"
              >

                <Button variant="contained" onClick={submitHandler} >Link this supplier</Button>
              </Stack>
            </Stack>

          </>
        }
        {/* {apiLoading && <OpaqueLoading />} */}
        {checkExisting &&
          <Stack gap="20px" style={{ padding: "10px" }}>
            <Stack>
              <TextField
              required
                variant="outlined"
                label="Supplier Name"
                // defaultValue={form["name"]}
                onChange={(e) =>
                  changeHandler("supplierName", e.target.value)
                }
              />
            </Stack>
            <Stack>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="cityLabel">Select City</InputLabel>
                <Select
                required
                  labelId="cityLabel"
                  id="citySelect"
                  // value={district.id || ""}
                  // value={cityVal || ""}
                  label="Select City"
                  // disabled={loading}
                  onChange={(e) =>
                    changeHandler("supplierCity", e.target.value)
                  }
                >
                  {cityVal.map((cityEntry) => (
                    <MenuItem value={cityEntry?.id} key={cityEntry?.id}>
                      {cityEntry?.cityName} ({cityEntry?.state?.stateName})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Stack>


            <Stack>
              <TextField
                required
                onChange={(e) => {
                  const input = e.target.value;
                  // Custom validation to limit the input to a maximum of 10 digits
                  if (input.length <= 15 && /^\d*$/.test(input)) {
                    changeHandler("supplierMobile", input);
                  }
                }}
                variant="outlined"
                label="Supplier Mobile Number"
                fullWidth
                type="number" // Use type="text" for alphanumeric input
              />

            </Stack>

            <Stack>
              <TextField
              
                variant="outlined"
                label="Supplier WeChat Id"
                // defaultValue={form["name"]}
                onChange={(e) =>
                  changeHandler("supplierWeChatId", e.target.value)
                }
              />
            </Stack>

            {/* <Stack>
              <TextField
                variant="outlined"
                label="Value"
                value={form["value"]}
                onChange={(e) =>
                  changeHandler("value", e.target.value)
                }
              />
            </Stack>
            <Stack>
              <TextField
                variant="outlined"
                label="Error Message"
                value={form["errorMessage"]}
                onChange={(e) =>
                  changeHandler("errorMessage", e.target.value)
                }
              />
            </Stack> */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap="10px"
              align-items="center"
            >
              <Button color="error" onClick={onCancel}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                Create
              </Button>
            </Stack>
          </Stack>
        }
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default AddSupplierModal;
