import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./calender.css"
import TimelineSo from "../../components/modals/TimelineSos";
import { DATE_FORMATS } from "../../constants";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import styled from "styled-components";
const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 199px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;
const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 25px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  // padding:10px;
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
  padding:10px;
`

function Calendar({props,tempAir,tempSea}) {
  // console.log('propssss', tempSea)
  // console.log('propssss', tempAir)
  const [timelines, setTimelines] = useState(null)
  const {id}=useParams()
  const [events,setEvents]=useState([])
  const [modeTransport,setModeTransport] = useState(0)

  useEffect(() => {
    const selectedData = modeTransport === 0 ? tempAir : tempSea;

    if (selectedData && selectedData.length > 0) {
      const extractedEvents = selectedData.map((shipment) => ({
        title: `Container Id : ${shipment.id}`,
        start: moment.unix(shipment.deliveryDate).format("YYYY-MM-DD"),
      }));
      setEvents(extractedEvents);
    } else {
      setEvents([]);
    }
  }, [tempAir, tempSea,modeTransport ]);
  // const eventContent = (arg) => {
  //   console.log('arrrrg',arg)
  //   return (
  //     <div>
  //       <strong>{arg.timeText}</strong>
  //       <br />
  //       {arg.event.title}
  //     </div>
  //   );
  // };

  
  

  const handleDateClick = (arg) => {
    // const title = prompt("Enter a title for the event:");
    // if (title) {
    //   const calendarApi = arg.view.calendar;
    //   calendarApi.addEvent({
    //     title,
    //     start: arg.date,
    //     allDay: arg.allDay,
    //   });
    // }

    // console.log('event',arg)
    let mode;
    if(modeTransport===0){
      mode="Air"
 }
 else{
  mode="Sea"
 }
 let date=moment(arg.date).unix()
// let date = moment(arg.date).format(DATE_FORMATS.DEFAULT)
let sent_data={date:date,wid:id,mode:mode}
// console.log("MODDE",sent_data)
    setTimelines(sent_data)


  };
  // const events = [
  //   {
  //     title: ["Event 1", "Event 2", "Event"],
  //     start: "2023-08-11",
  //   },
  //   {
  //     title: "Event 2",
  //     start: "2023-08-15"
  //   },
  //   {
  //     title: "Event 3",
  //     start: "2023-08-20",
  //   },
  //   // Add more event objects as needed
  // ];

  const handleCustomButtonClick = () => {

    // Add your custom button logic here
  };

  const handleSecondButtonClick = () => {
    // Add your second button logic here
  };
  // const highlightedDates = ["2023-08-10", "2023-08-15", "2023-08-20"];

  const handleToggleChange1=(e,value)=> {
    //  setShippingOrders([])
      // console.log("value",modeTransport)
      setModeTransport(value)}


      // console.log("EVENTS",events)
  return (
    <StaffEditorPageContainer>
      <TableContainer>
      <Tabs value={modeTransport} onChange={handleToggleChange1} variant="fullWidth" sx={{marginBottom:"10px",borderBottom:"1px solid grey"}} >
    <Tab label="Air" />
    <Tab label="Sea"  />
    </Tabs>
      
        <TabsContainer >
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        // plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={"dayGridMonth"}
       i headerToolbar={{
          start: isMobile?"title":"today,prev,next",
          center: isMobile?"today,prev,next":"title",
          end: !isMobile&&"dayGridMonth,timeGridWeek,timeGridDay",
        }}
        // customButtons={{
        //   customButton1: {
        //     text: "Custom Button 1",
        //     click: handleCustomButtonClick,
        //   },
        //   customButton2: {
        //     text: "Custom Button 2",
        //     click: handleSecondButtonClick,
        //   },
        // }}
        height={"67vh"}
        // eventContent={eventContent}
        dateClick={handleDateClick}
        events={events}
        eventClassNames={() => ["event-custom-color"]}
      />
      {timelines &&
      <TimelineSo 
      timeline = {timelines}
      onClose = {()=>setTimelines(null)}
      />
        }
        </TabsContainer>
        </TableContainer>
    </StaffEditorPageContainer>
  );
}

export default Calendar;
