import React, { useState } from "react";

import {

  Button,

  Checkbox,

  CircularProgress,

  Dialog,

  DialogContent,

  FormControl,

  FormControlLabel,

  FormGroup,

  IconButton,

  InputLabel,

  MenuItem,

  Select,

  TextField,

  Typography,

} from "@mui/material";

import { CloseOutlined } from "@mui/icons-material";

import { Stack } from "@mui/material";

import styled from "styled-components";

import { ACTIVITY_STATUS, ERROR_CODES } from "../../constants";

import OpaqueLoading from "../opaqueLoading/opaqueLoading";

import { useEffect } from "react";
import { Link } from "react-router-dom";




const StyledDialogContent = styled(DialogContent)`

  padding: 16px;

`;




const StyledDialog = styled(Dialog)`

  // position: relative;

  // max-width: 700px;

`;




const ModalHeaderContainer = styled.div`

  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;

`;




const AddNewSupplier = ({ onCancel, onSubmit, template, loading, setLoading }) => {
// console.log("TEMPLATE",template)
  const [form, setForm] = useState({

    supplierId: 0,

    supplierName: "",

    supplierCity: "",

    supplierMobile: "",

    supplierWeChatId: "",

  });




  const [isEditMode, setIsEditMode] = useState(!!template);

  const [cityVal, setCityVal] = useState(["Delhi"]);

  // const [loading, setLoading] = useState()

  // console.log("template.city.attributes.cityName ",template.city.attributes.cityName )


  useEffect(() => {

    // If a template is provided (in "Edit" mode), pre-fill the form fields

    if (template) {
      // console.log(template)
      // console.log("temmmm", {...template , city: template.city.id})
      //   setForm(
      //     {...template , city: template.city.id}
      //   );

    }

  }, [template]);

  // console.log(template)



  const changeHandler = (fieldName, value) => {

    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));

  };
  const isValidChinaMobileNumber = (mobileNumber) => {
    const chinaRegex =   /^1[0-9]{10}$/;
    return chinaRegex.test(mobileNumber);
  };
  const isValidWeChatId=(weChatID) => {
  const wechatregex= /^[a-zA-Z0-9_-]{6,20}$/
  return wechatregex.test(weChatID);
  }
  const checkForErrors = () => {
    if (form && !form.supplierName) {
      window.NotificationUtils.showError("Please enter a Supplier name");
      return true;
    }
   
    if ( form &&!form.supplierCity) {
      window.NotificationUtils.showError("Please Select a Supplier City");
      return true;
    }
    if (form && !form.supplierMobile) {
      window.NotificationUtils.showError(ERROR_CODES.NO_MOBILE);
      return true;
    }
    if(form&&!form.supplierWeChatId){
      window.NotificationUtils.showError("Please enter WeChat Id");
      
      return true;
    }

    // if (!form.customerMobile || isValidChinaMobileNumber(form.supplierMobile) == false) {
    //   window.NotificationUtils.showError(ERROR_CODES.INVALID_MOBILE);
    //   return true;
    // }
    // // if (form && !form.supplierMobile) {
    //   window.NotificationUtils.showError(ERROR_CODES.NO_MOBILE);
    //   return true;
    // }
    // if (!form.customerMobile || isValidWeChatId(form.supplierWeChatId) == false) {
    //   window.NotificationUtils.showError("Invalid wechatID");
    //   return true;
    // }
  
    return false;
  };




  const submitHandler = async () => {

   if(checkForErrors()){
    return ;
   }

    //  console.log("check",form)
    onSubmit(form)
    // let response = await window.Platform.database.createNewSupplier(form);

    // let response_Data={customerDetails:sent_data}

    // let response = await window.Platform.database.addSupplierMaster(sent_data);

    // console.log("creating activity...", response);

    // onSubmit(form)

  };

  const fetchCities = async () => {

    try {

      setLoading(true);




      let response = await window.Platform.database.fetchCities();




      setCityVal(response);




      setLoading(false);

    } catch (error) {

      console.error(error);




      window.NotificationUtils.showError("Error fetching cities");




      setLoading(false);

    }

  };




  useEffect(() => {

    fetchCities();

  }, []);



  useEffect(() => {

    // If a template is provided (in "Edit" mode), pre-fill the form fields

    if (template) {

      setForm({

        supplierId: template.id || "",

        supplierName: template.name || "",

        supplierCity: template.city.id || "",

        supplierMobile: template.mobile || "",

        supplierWeChatId: template.weChatId || "",

      });

    }

    // console.log(`my amir form: ${JSON.stringify(template)}`);

  }, [template]);

  useEffect(() => {

    // console.log(loading);

  }, [loading]);




  return (

    // <StyledDialog

    //   disableEnforceFocus

    //   maxWidth="xs"

    //   fullWidth={true}

    //   open={true}

    // >

    //   <StyledDialogContent>

    //     <ModalHeaderContainer>

    //       <Typography variant="h5">

    //         {isEditMode ? "Edit Supplier" : "Add New Supplier"}

    //       </Typography>

    //       <IconButton onClick={onCancel}>

    //         <CloseOutlined />

    //       </IconButton>

    //     </ModalHeaderContainer>

    //     {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}

    //     <Stack gap="20px" style={{ padding: "10px" }}>

    //       {loading && <OpaqueLoading />}

    //       <Stack>

    //         <TextField

    //           variant="outlined"

    //           label="Supplier Name"

    //           value={template.supplierName || ''}

    //           onChange={(e) => changeHandler("customerName", e.target.value)}

    //         />

    //       </Stack>

    //       <Stack>

    //         <FormControl fullWidth variant="outlined">

    //           <InputLabel id="cityLabel">Select City</InputLabel>




    //           <Select

    //             labelId="cityLabel"

    //             id="citySelect"

    //             // value={district.id || ""}




    //             // value={cityVal || ""}




    //             label="Select City"

    //             // disabled={loading}




    //             value={template.city.id || ""}

    //             onChange={(e) => changeHandler("customerCity", e.target.value)}

    //           >

    //             {cityVal.map((cityEntry) => (

    //               <MenuItem value={cityEntry?.id} key={cityEntry?.id}>

    //                 {cityEntry?.cityName} ({cityEntry?.state?.stateName})

    //               </MenuItem>

    //             ))}

    //           </Select>

    //         </FormControl>

    //       </Stack>




    //       <Stack>

    //         <TextField

    //           variant="outlined"

    //           type="number"

    //           label="Supplier Mobile Number"

    //           value={template.supplierMobile || ''}

    //           onChange={(e) => changeHandler("customerMobile", e.target.value)}

    //         />

    //       </Stack>




    //       <Stack

    //         direction="row"

    //         justifyContent="flex-end"

    //         gap="10px"

    //         align-items="center"

    //       >

    //         <Button color="error" onClick={onCancel}>

    //           Close

    //         </Button>

    //         <Button color="primary" variant="contained" onClick={submitHandler}>

    //           {isEditMode ? "Save" : "Create"}

    //         </Button>

    //       </Stack>

    //     </Stack>

    //   </StyledDialogContent>

    // </StyledDialog>

    <StyledDialog disableEnforceFocus maxWidth="xs" fullWidth={true} open={true}>

      <StyledDialogContent>

        <ModalHeaderContainer>

          <Typography variant="h5">{isEditMode ? "Edit Supplier" : "Add New Supplier"}</Typography>

          <IconButton onClick={onCancel}>

          </IconButton>
         {isEditMode && <Link to={`/master-manager-items/` + form.supplierId} >
            <Button variant="contained" size="small">Item Details</Button>
          </Link>}

        </ModalHeaderContainer>

        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}

        <Stack gap="20px" style={{ padding: "10px" }}>

          {loading && <OpaqueLoading />}

          <Stack>

            <TextField
            required

              variant="outlined"

              label="Supplier Name"

              value={form.supplierName}

              onChange={(e) =>

                changeHandler("supplierName", e.target.value)

              }

            />

          </Stack>

          <Stack>

            {/* <TextField

              variant="outlined"

              label="Customer City"

              value={form.customerCity}

              onChange={(e) =>

                changeHandler("customerCity", e.target.value)

              }

            /> */}
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="cityLabel">Select City</InputLabel>
              <Select
              required
                labelId="cityLabel"
                id="citySelect"
                // value={district.id || ""}
                // value={cityVal || ""}
                label="Select City"
                // disabled={loading}
                value={form.supplierCity}
                onChange={(e) =>
                  changeHandler("supplierCity", e.target.value)
                }
              >
                {cityVal.map((cityEntry) => (
                  <MenuItem value={cityEntry?.id} key={cityEntry?.id}>
                    {cityEntry?.cityName} ({cityEntry?.state?.stateName})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Stack>



          {/* <Stack>
            <TextField
              variant="outlined"
              type="number"
              label="Supplier Mobile Number"
              value={form.supplierMobile}
              onChange={(e) =>
                changeHandler("supplierMobile", e.target.value)
              }
            />
          </Stack> */}

          <Stack>
          <TextField
              required
              value={form.supplierMobile}
              onChange={(e) => {
                const input = e.target.value;
                // Custom validation to limit the input to a maximum of 10 digits
               
                  changeHandler("supplierMobile", input)
                
              }}
              variant="outlined"
              label="Supplier Mobile Number"
              fullWidth
              type="text" // Use type="text" for alphanumeric input
            />
          </Stack>

          <Stack>

            <TextField
            

              variant="outlined"

              label="Supplier WeChat Id"

              value={form.supplierWeChatId}

              onChange={(e) =>

                changeHandler("supplierWeChatId", e.target.value)

              }

            />

          </Stack>



          <Stack

            direction="row"

            justifyContent="flex-end"

            gap="10px"

            align-items="center"

          >

            <Button color="error" onClick={onCancel}>

              Close

            </Button>

            <Button

              color="primary"

              variant="contained"

              onClick={submitHandler}

            >

              {isEditMode ? "Save" : "Create"}

            </Button>

          </Stack>

        </Stack>

      </StyledDialogContent>

    </StyledDialog>

  );

};




export default AddNewSupplier;