import React, { useState } from "react";

import {

  Button,

  Checkbox,

  CircularProgress,

  Dialog,

  DialogContent,

  FormControl,

  FormControlLabel,

  FormGroup,

  IconButton,

  InputLabel,

  MenuItem,

  Select,

  TextField,

  Typography,

} from "@mui/material";

import { CloseOutlined } from "@mui/icons-material";

import { Stack } from "@mui/material";

import styled from "styled-components";

import { ACTIVITY_STATUS } from "../../constants";

import OpaqueLoading from "../opaqueLoading/opaqueLoading";

import { useEffect } from "react";




const StyledDialogContent = styled(DialogContent)`

  padding: 16px;

`;




const StyledDialog = styled(Dialog)`

  // position: relative;

  // max-width: 700px;

`;




const ModalHeaderContainer = styled.div`

  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;

`;




const AddNewItem = ({ onCancel, loading, onSubmit, template }) => {

  const [form, setForm] = useState({

    customerName: "",

  });

// console.log("temp",template)

  const [isEditMode, setIsEditMode] = useState(!!template);




  useEffect(() => {

    // If a template is provided (in "Edit" mode), pre-fill the form fields

    if (template) {

      setForm({

        itemName: template.value || "",
        category: template.category || "",

      });

    }

  }, [template]);




  const changeHandler = (fieldName, value) => {

    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));

  };




  const submitHandler = () => {

  if(!form.itemName || !form.category){
    return window.NotificationUtils.showError("Enter all fields");
  }



    onSubmit(form)

  };




  useEffect(() => {

    // console.log(loading);

  }, [loading]);




  return (

//     <StyledDialog

//       disableEnforceFocus

//       maxWidth="xs"

//       fullWidth={true}

//       open={true}

//     >

//       <StyledDialogContent>

//         <ModalHeaderContainer>

//           <Typography variant="h5">

//             {isEditMode ? "Edit Item" : "Add New Item"}

//           </Typography>

//           <IconButton onClick={onCancel}>

//             <CloseOutlined />

//           </IconButton>

//         </ModalHeaderContainer>

//         {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}

//         <Stack gap="20px" style={{ padding: "10px" }}>

//           {loading && <OpaqueLoading />}

//           <Stack>

//             <TextField

//               variant="outlined"

//               label="Item Name"

//               value={form.itemName}

//               onChange={(e) => changeHandler("customerName", e.target.value)}

//             />

//           </Stack>

// <Stack>
// <FormControl fullWidth variant="outlined">
//   <InputLabel id="cityLabel">Category</InputLabel>
//             <Select
//           // variant="contained"
//           value={form.category || ''}
//           label="Category"
//             // defaultValue={params.value}
//             onChange={(e,value) => {
//               // console.log(form,"jcndjnci")
//               console.log(value?.props.value)
//               changeHandler("category", value?.props.value)
//             }}
//           >
//             <MenuItem value="Regular">Regular</MenuItem>
//             <MenuItem value="Managable">Managable</MenuItem>
//             <MenuItem value="Special">Special</MenuItem>

          
//           </Select>
//           </FormControl>
// </Stack>


//           <Stack

//             direction="row"

//             justifyContent="flex-end"

//             gap="10px"

//             align-items="center"

//           >

//             <Button color="error" onClick={onCancel}>

//               Close

//             </Button>

//             <Button color="primary" variant="contained" onClick={submitHandler}>

//               {isEditMode ? "Save" : "Create"}

//             </Button>

//           </Stack>

//         </Stack>

//       </StyledDialogContent>

//     </StyledDialog>
<StyledDialog disableEnforceFocus maxWidth="xs" fullWidth={true} open={true}>

<StyledDialogContent>

  <ModalHeaderContainer>

    <Typography variant="h5">{isEditMode?"Edit Item":"Add New Item"}</Typography>

    <IconButton  onClick={onCancel}>

      <CloseOutlined />

    </IconButton>

  </ModalHeaderContainer>

  {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}

    <Stack gap="20px" style={{ padding: "10px" }}>

      {loading && <OpaqueLoading />}

      <Stack>

        <TextField
          required
          variant="outlined"

          label="Item Name"

          value={form.itemName}

          onChange={(e) =>

            changeHandler("itemName", e.target.value)

          }

        />

      </Stack>

      <Stack>

        {/* <TextField

          variant="outlined"

          label="Customer City"

          value={form.customerCity}

          onChange={(e) =>

            changeHandler("customerCity", e.target.value)

          }

        /> */}
           <FormControl fullWidth variant="outlined" required>
  <InputLabel id="cityLabel">Category</InputLabel>
             <Select
             required
          // variant="contained"
          value={form.category || ''}
          label="Category"
            // defaultValue={params.value}
            onChange={(e,value) => {
              // console.log(form,"jcndjnci")
              changeHandler("category", value?.props.value)
            }}
          >
            <MenuItem value="Regular">Regular</MenuItem>
            <MenuItem value="Managable">Managable</MenuItem>
            <MenuItem value="Special">Special</MenuItem>

          
          </Select>
          </FormControl>

      </Stack>

     

    

     

      <Stack

        direction="row"

        justifyContent="flex-end"

        gap="10px"

        align-items="center"

      >

        <Button color="error" onClick={onCancel}>

          Close

        </Button>

        <Button

          color="primary"

          variant="contained"

          onClick={submitHandler}

        >

          {isEditMode?"Save":"Create"}

        </Button>

      </Stack>

    </Stack>

    </StyledDialogContent>

</StyledDialog>

  );

};




export default AddNewItem;