import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import {

  IconButton,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Drawer from "../../components/common/drawer/drawer";

import { DATE_FORMATS } from "../../constants";
import moment from "moment";
import ContainerSOSplit from "../../components/modals/containerSOSplit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomToggle from "../CalenderTimeline/customToggle";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import PlannedSoModal from "../../components/modals/plannedSoModal";
import { AuthContext } from "../../components/contextAPI/ContextAPI";
import { Timeline } from "@mui/icons-material";
import { CalendarIcon } from "@mui/x-date-pickers";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const isMobile = window.innerWidth < 900;
const isMobile1 = window.innerWidth > 900 && window.innerWidth < 1400;
const TableContainer = styled.div`
  height: calc(100% - 37px);

  width: 100%;

  border: solid 1px lightGrey;
  padding-bottom:${!isMobile&&`49px`};

  border-radius: 8px;
`;
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `150vh` : `auto`};
  width: 100%;
  maxWidth: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 150 },
];

const rows = [
  { id: 1, name: "Item 1" },
  { id: 2, name: "Item 2" },
  { id: 3, name: "Item 3" },
  { id: 4, name: "Item 4" },
];

export default function DataGridTransfer(props) {

  const [leftRows, setLeftRows] = useState([]);
  const [rightRows, setRightRows] = useState([]);
  const [modeTransport, setModeTransport] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [totalLeftWeight, setTotalLeftWeight] = useState(0);
  const [totalLeftCBM, setTotalLeftCBM] = useState(0);
  const [tabs, setTabs] = useState([{ label: `Container` }]);
  const [selectedTab, setSelectedTab] = useState(0);
  const {id}=useParams()
  const [loading,setLoading]=useState(false);
  const [plannedData,setPlannedData] =useState();
  const [weight,setWeight] = useState();
  const [volume,setVolume] = useState();
  const [amount,setAmount] = useState();
  const [editMode,setEditMode] = useState(false);
  const [editPlannedData,setEditPlannedData] = useState();
  const [editresponse,setEditResponse] = useState();
    const location = useLocation();
    const {auth,setAuth} = useContext(AuthContext)
const [changeColorExceed, setChangeColorExceed] = useState(false);
const [selectedToggle, setSelectedToggle] = useState(true);

    const clearLocalStorage=()=>{
      localStorage.removeItem('wid');
      localStorage.removeItem('phase');
      localStorage.removeItem('editTime');
      localStorage.removeItem('typeShip');
      localStorage.removeItem('time');
      localStorage.removeItem('mode');
    }
    // useEffect(() => {
    //   // Clear localStorage when the route changes
    //   console.log('Clear localStorage',props)
    //   if(props.history.location.pathname!==`/containerplanning/${id}`){
    //     console.log('Clear localStoragecheck12',location.pathname)
    //   clearLocalStorage()
    // }
    // else
    // {
    //   console.log('changroute');
    // }
    //   // clearLocalStorage();
    // }, [props.history.location.pathname,id]);
  useEffect(()=>{
    const editphase=localStorage.getItem('phase')
    // console.log("CHECK!!!!!")
    if(editphase==="edit"){
     setEditMode(true)
    //  console.log("CHECK!!!!!")
     fetchData()
    }
    else{
     setEditMode(false)
     fetchData()
    }
    let transport=localStorage.getItem('typeShip')
    // console.log(transport)
    setModeTransport(transport)

    if (transport==="Air") {
     const totalWeight = calculateTotalWeight(leftRows[selectedTab] || []);
     setTotalLeftWeight(totalWeight);
   } else {
     const totalCBM = calculateTotalCBM(leftRows[selectedTab] || []);
    //  console.log("ABCD", totalCBM);
     setTotalLeftCBM(totalCBM);
   }
 
 
   },[editMode])
  const getColumns = () => {
    let result = [
      {
        field: "id",
        headerName: "SO ID",
        sortable: false,
        width: 150,
        editable: false,
      },
      {
        field: "customerId",
        headerName: "Customer Code",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.quotation.attributes.customerId || 'N/A',
      renderCell: params => {
        // console.log(params)
        const value = params.row.quotation.attributes.customerId || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.quotation.attributes.customerName || 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = params.row.quotation.attributes.customerName || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },

      {
        field: "supplierName",
        headerName: "Supplier Name",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.quotation.attributes.supplierName || 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = params.row.quotation.attributes.supplierName || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "createdAt",
        headerName: "Created At",
        sortable: false,
        width: 200,
        valueGetter: (params) =>
          moment(params.row.createdAt).format(DATE_FORMATS.DEFAULT),
      },

      {
        field: "deliveryDate",
        headerName: "Expected Delivery Date",
        sortable: false,
        width: 200,
        // valueGetter: (params) =>
        //   moment(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT),
        dataGetter: params => moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = moment.unix(params.row.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "deliveryPlace",
        headerName: "Delivery Place",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.quotation.attributes.deliveryPlace || 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = params.row.quotation.attributes.deliveryPlace || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
        
      },
      {
        field: "warehouseName",
        headerName: "Initial Warehouse Destination",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.quotation.attributes.warehouseName || 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = params.row.quotation.attributes.warehouseName || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.totalAmount|| 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = params.row.totalAmount 
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "totalWeight",
        headerName: "Total Weight",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.totalWeight || 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = params.row.totalWeight 
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "totalCbm",
        headerName: "Total CBM",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.totalCbm || 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = params.row.totalCbm 
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "totalCtn",
        headerName: "Total CTN",
        sortable: false,
        width: 200,
        dataGetter: params => params.row.totalCtn || 'N/A',
        renderCell: params => {
          // console.log(params)
          const value = params.row.totalCtn 
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
    ];

    return result;
  };

  const getInviteId = () => {
    let queryParams = "";
    try {
      const url = new URL(window.location.href);
      queryParams = url.search.toString().slice(1);
    } catch (e) {
      console.error(e);
    }
    return queryParams;
  };

  const idAll = getInviteId();

if(idAll){
  localStorage.removeItem('phase');

}


// console.log("================================",editMode);
  useEffect(() => {
    let check=localStorage.getItem('mode')
    // console.log('checkkkk', idAll);
    if(idAll && !check){
      
      localStorage.setItem("mode",'Air')
      setSelectedToggle(true)
      
    }
    if(!editMode){
   let transport=localStorage.getItem('mode')

  //  console.log(transport)
   setModeTransport(transport)
  //  console.log('Transport', transport)
    if (modeTransport==="Air") {
      const totalWeight = calculateTotalWeight(leftRows[selectedTab] || []);
      setSelectedToggle(true)
      setTotalLeftWeight(totalWeight);
    } else {
      const totalCBM = calculateTotalCBM(leftRows[selectedTab] || []);
      // console.log("ABCD", totalCBM);
      setSelectedToggle(false)
      setTotalLeftCBM(totalCBM);
    }

    // console.log("IDDDDDDDD",id);
  }
  }, [leftRows, modeTransport, selectedTab]);


  const calculateTotalWeight = (selectedRows) => {
    // console.log(selectedRows)
    if (!Array.isArray(selectedRows)) {
      return 0; // Default value if selectedRows is not an array
    }
    // console.log('Calculating total weight',selectedRows)
    const totalWeight = selectedRows.reduce(
      (sum, row) => sum + row.totalWeight,
      0
    );
    return Math.ceil(totalWeight);
  };
  const calculateTotalCBM = (selectedRows) => {
    if (!Array.isArray(selectedRows)) {
      return 0;
    }
    const totalCBM = selectedRows.reduce((sum, row) => sum + row.totalCbm, 0);
    return Math.ceil(totalCBM);
  };

  const handleMoveToRight = () => {
    const selectedRows = leftRows[selectedTab].filter((row) => row.isSelected);

    setLeftRows((prevLeftRows) => {
      const updatedLeftRows = { ...prevLeftRows };
      updatedLeftRows[selectedTab] = prevLeftRows[selectedTab].filter(
        (row) => !row.isSelected
      );
      return updatedLeftRows;
    });

    // Reset isSelected to false for the transferred rows
    const updatedSelectedRows = selectedRows.map((row) => ({
      ...row,
      isSelected: false,
    }));

    setRightRows((prevRightRows) => [...prevRightRows, ...updatedSelectedRows]);
  };
  
  useEffect(() => {
    fetchData()
  },[selectedToggle])
  // Use useEffect to handle calculations and color change
  useEffect(() => {
    let totalValue;
    if (modeTransport === "Air") {
      totalValue = calculateTotalWeight(leftRows[selectedTab]);
      if (totalValue > 3500) {
        setChangeColorExceed(true);
        if(!changeColorExceed)
        window.NotificationUtils.showInfo("Container limit exceeded");

      } else {
        setChangeColorExceed(false);
      }
    } else {
      totalValue = calculateTotalCBM(leftRows[selectedTab]);
      if (totalValue > 68) {
        setChangeColorExceed(true);
        if(!changeColorExceed)
        window.NotificationUtils.showInfo("Container limit exceeded");
      } else {
        setChangeColorExceed(false);
      }
    }
  }, [leftRows]);

  const handleMoveToLeft = () => {
    // console.log("HandleMoveToLeft",rightRows)
    const selectedRows = rightRows.filter((row) => row.isSelected===true);
    // console.log("Selected rows",selectedRows)

    let totalValue;
    let limit;

    if (modeTransport==="Air") {
      totalValue = calculateTotalWeight(selectedRows);
    //   if(totalValue > 3500)
    //   setChangeColorExceed(true)
    // else
    // setChangeColorExceed(false)
      limit = 35000;
    } else {
      totalValue = calculateTotalCBM(selectedRows);
      limit = 6800;
    //   if(totalValue > 68)
    //   setChangeColorExceed(true)
    // else
    // setChangeColorExceed(false)
    }

    const totalLeft = modeTransport==="Air"
      ? calculateTotalWeight(leftRows[selectedTab] || [])
      : calculateTotalCBM(leftRows[selectedTab] || []);

    if (totalLeft + totalValue <= limit) {
      // If total weight or CBM after transfer is within the limit, proceed with moving rows
      setRightRows((prevRightRows) =>
        prevRightRows.filter((row) => !row.isSelected)
      );

      // Reset isSelected to false for the transferred rows
      const updatedSelectedRows = selectedRows.map((row) => ({
        ...row,
        isSelected: false,
      }));

      setLeftRows((prevLeftRows) => {
        const updatedLeftRows = { ...prevLeftRows };
        updatedLeftRows[selectedTab] = [
          ...(prevLeftRows[selectedTab] || []),
          ...updatedSelectedRows,
        ];
        return updatedLeftRows;
      });
    } else {
      const updatedSelectedRows = selectedRows.map((row) => ({
        ...row,
        isSelected: false,
      }));

      // console.log("Total exceeds the limit. Rows not transferred.");
      window.NotificationUtils.showInfo("Total exceeds the limit");
    }
  };

  // console.log("Total exceeds the limit", editMode)

  
  const fetchData = async () => {
    // console.log("timmeeee",timeline)
    try{
      if(tabs.length>1){
        handleDeleteTab(selectedTab)
      }
      setLoading(true)
      if(editMode===false){
      let date=localStorage.getItem('time')
      let mode=localStorage.getItem('mode')
          let timeline={date:date,wid:id,mode:mode}
          // console.log("TIMELINE",timeline)
          let results;
          
          if(idAll){
          results = await window.Platform.database.fetchSoByExpectedDateAll(timeline)
          console.log("hello")
          }
        else
           results = await window.Platform.database.fetchSoByExpectedDate(timeline)
          // console.log("RESULTSS",results)
          results.sort((a, b) => b.totalAmount - a.totalAmount);
          // console.log("RESULTSSSort",results)
          let totalWeightLeft = 0;
          const leftRows = [];
          const rightRows = [];
          if(mode==="Air"){
            for (const row of results) {
              if (totalWeightLeft + row.totalWeight <= 3500) {
                leftRows.push({ ...row, isSelected: false });
                totalWeightLeft += row.totalWeight;
              } else {
                rightRows.push({ ...row, isSelected: false });
              }
            }
           
        
        }
          else{
            for (const row of results) {
              if (totalWeightLeft + row.totalCbm <= 68) {
                leftRows.push({ ...row, isSelected: false });
                totalWeightLeft += row.totalCbm;
              } else {
                rightRows.push({ ...row, isSelected: false });
              }
            }
          }
          
      
          // console.log("Left rows: ", leftRows);
          // console.log("Right rows: ", rightRows);
      
          setLeftRows({[selectedTab]:leftRows});
          setRightRows(rightRows);}
          else if(editMode===true){
            // console.log("APICHECK")
            let date=localStorage.getItem('editTime')
      let mode=localStorage.getItem('typeShip')
      let wid=localStorage.getItem('wid')
          let timeline={date:date,wid:wid,mode:mode}
          // console.log("TIMELINE",timeline)
          let results = await window.Platform.database.fetchSoByExpectedDate(timeline)
    let results1 = await window.Platform.database.fetchPlannedContainerById({id:id})
    // console.log("RESPONSEEE",results1)
    setEditResponse(results1)
    setLeftRows({[selectedTab]:results1})
    setRightRows(results)

          // console.log("RESULTSS",results1)
          let totalWeightLeft = 0;
          const leftRows = [];
          const rightRows = [];
          }
setLoading(false)
  }
  catch(err){
    setLoading(false)
console.log('error', err)
  }
}
// const fetchData = async () => {
//   try {
//     if (tabs.length > 1) {
//       handleDeleteTab(selectedTab);
//     }
//     setLoading(true);

//     let date;
//     let mode;
//     let timeline;
//     if (editMode === false) {
//       date = localStorage.getItem('time');
//       mode = localStorage.getItem('mode');
//       timeline = { date: date, wid: id, mode: mode };
//     } else if (editMode === true) {
//       date = localStorage.getItem('editTime');
//       mode = localStorage.getItem('typeShip');
//       const wid = localStorage.getItem('wid');
//       timeline = { date: date, wid: wid, mode: mode };
//     }

//     const results = await window.Platform.database.fetchSoByExpectedDate(timeline);

//     // Sort the results by total amount in descending order
//     results.sort((a, b) => b.totalAmount - a.totalAmount);

//     let totalWeightLeft = 0;
//     const leftRows = [];
//     const rightRows = [];

//     if(mode==="Air"){
//     for (let i = 0; i < results.length; i++) {
//       const row = results[i];
//       if (totalWeightLeft + row.totalWeight <= 3500 || i === results.length - 1) {
//         leftRows.push({ ...row, isSelected: false });
//         totalWeightLeft += row.totalWeight;
//       } else {
//         rightRows.push({ ...row, isSelected: false });
//       }
//     }
//     else{}

//     setLeftRows({ [selectedTab]: leftRows });
//     setRightRows(rightRows);
//     setLoading(false);
//   } catch (err) {
//     setLoading(false);
//     console.error('Error:', err);
//   }
// };



  useEffect(() => {

    
    setTabs([{ label: "Container" }])
   
  }, []);

  const setModalData = (params) => {
    // console.log("setModalData", params);
    setSelectedRow({orders : params.row.orderLineItems, id: params.row.id} || []);
    setShowModal(true);
  };

 

  const handleAddTab = () => {
   
    const newTabIndex = tabs.length + 1;
    const newTab = { label: `Container `
    // ${newTabIndex}
    
   };
    setTabs([...tabs, newTab]);
  
    let totalWeightLeft = 0;
    const leftRowsForNewTab = [];
    const rightRowsForNewTab = [];
  if(modeTransport==="Air"){
    for (const row of rightRows) {
      console.log("TESTING",row)
      if (totalWeightLeft + row.totalWeight <= 3500) {
        leftRowsForNewTab.push({ ...row, isSelected: false });
        totalWeightLeft += row.totalWeight ;
      } else {
        rightRowsForNewTab.push({ ...row, isSelected: false });
      }
    }
  
    setLeftRows((prevLeftRows) => {
      const updatedLeftRows = { ...prevLeftRows };
      updatedLeftRows[newTabIndex-1] = leftRowsForNewTab;
      return updatedLeftRows;
    });
  
    setRightRows(rightRowsForNewTab);}
    else{
      for (const row of rightRows) {
        if (totalWeightLeft + row.totalCbm <= 68) {
          leftRowsForNewTab.push({ ...row, isSelected: false });
          totalWeightLeft += row.totalCbm;
        } else {
          rightRowsForNewTab.push({ ...row, isSelected: false });
        }
      }
    
      setLeftRows((prevLeftRows) => {
        const updatedLeftRows = { ...prevLeftRows };
        updatedLeftRows[newTabIndex-1] = leftRowsForNewTab;
        return updatedLeftRows;
      });
    
      setRightRows(rightRowsForNewTab);
    }
  };
  

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // console.log("TABSCHANGE", leftRows[selectedTab]);
  // console.log("TABSCHANGE1", leftRows);


  const handleDeleteTab = (deletedTabIndex) => {
    if (tabs.length === 1) {
      // console.log("Cannot delete the last tab.");
      return;
    }
  
    // Get the data from the deleted tab
    const deletedTabData = leftRows[deletedTabIndex];
  
    if (deletedTabData) {
      // Move the data from the deleted tab back to the right rows
      setRightRows((prevRightRows) => [...prevRightRows, ...deletedTabData]);
  
      // Create a copy of the leftRows object without the deleted tab's data
      const updatedLeftRows = { ...leftRows };
      delete updatedLeftRows[deletedTabIndex];
      setLeftRows(updatedLeftRows);
  
      // If the deleted tab was the selected tab, update selectedTab accordingly
      if (deletedTabIndex === selectedTab) {
        setSelectedTab(Math.min(deletedTabIndex, tabs.length - 2));
      }
  
      // Remove the deleted tab from the tabs array
      const updatedTabs = tabs.filter((_, index) => index !== deletedTabIndex);
      setTabs(updatedTabs);
    } else {
      const updatedTabs = tabs.filter((_, index) => index !== deletedTabIndex);
      setTabs(updatedTabs);
    }
  };



const calculateTotalCtn=(selectedRows)=>{

    if (!Array.isArray(selectedRows)) {
      return 0;
    }
    const totalCBM = selectedRows.reduce((sum, row) => sum + row.totalCtn, 0);
    return Math.ceil(totalCBM);
}

  const planContainer = () => {
    const selectedTabData = leftRows[selectedTab] || [];
    // console.log("selectedTab",selectedTabData)

    const selectedIds = selectedTabData.map(item => item.id);
    // console.log("Selected IDs:", selectedIds);
    console.log("selectedTab", selectedTabData)
    let date=localStorage.getItem('time')
    console.log("selectedTab", selectedTabData)

    let maxDeliveryDate = selectedTabData[0].deliveryDate;
    

    // Iterate through the array to find the maximum deliveryDate
    for (const order of selectedTabData) {
      if (order.deliveryDate > maxDeliveryDate) {
        maxDeliveryDate = order.deliveryDate;
      }
    }
    if(idAll){
      date = null;
    }
    console.log('Maximum Delivery Date (Timestamp):', maxDeliveryDate);


    let transport=localStorage.getItem('mode')
    const temp={
      wid:id,soId:selectedIds,totalCBM:calculateTotalCBM(leftRows[selectedTab]),totalCtn:calculateTotalCtn(leftRows[selectedTab]),totalWeight:calculateTotalWeight(leftRows[selectedTab]),deliveryDate:date || String(maxDeliveryDate),typeOfShipment:transport
    }
  console.log('fff',temp)
 setPlannedData(temp)
  
  };
  const editPlannedContainer=()=>{
    const selectedTabData = leftRows[selectedTab] || [];
    // console.log("selectedTab",selectedTabData)
    const containerData=leftRows[selectedTab][0].container.attributes
    // console.log("selectedTabEDIT",containerData) 
    const selectedIds = selectedTabData.map(item => item.id);
    const preResponseId=editresponse.map(item => item.id);
    // console.log("Selected IDs:", selectedIds);
    let date=localStorage.getItem('editTime')
    let transport=localStorage.getItem('typeShip')
    let wid=localStorage.getItem('wid')
    const temp={
     wid:wid,prevId:preResponseId ,cid:id,soId:selectedIds,data:containerData,totalCBM:calculateTotalCBM(leftRows[selectedTab]),totalCtn:calculateTotalCtn(leftRows[selectedTab]),totalWeight:calculateTotalWeight(leftRows[selectedTab]),deliveryDate:date,typeOfShipment:transport
    }
    setEditPlannedData(temp)
  }
  
  //  console.log("IDDDDD",id)

  return (
    <>
    {isMobile && (
      <Drawer props={props}/>
    )}
    <StaffEditorPageContainer>
      
      <HeaderContainer>
        <Stack direction={isMobile?"column":"row"} spacing={1}>
          <Typography variant="h5">
            {editMode?"Edit Planned Container":`Container Planning(${modeTransport})`}
          </Typography>
          { idAll &&
          <CustomToggle
        selected={selectedToggle}
        optionOne={{ value: true, label: "Air" }}
        optionTwo={{ value: false, label: "Sea" }}
        onChange={(e,value)=> {
          if(value===null){
            return;
          }
          else{
          // console.log("valueTesting",value)
          setSelectedToggle(value)
          if(value===true){
            setModeTransport("Air")
            localStorage.setItem("mode",'Air')
            
          }
          else{
            setModeTransport("Sea")
            localStorage.setItem("mode",'Sea')
          }
          // modeTransport
        }
      }}
      />}
<Link to={editMode?`/timeline/`+localStorage.getItem("wid"):`/timeline/`+ id} >
            <Tooltip title="Go to timelines">
<IconButton><CalendarIcon /></IconButton>
</Tooltip>
</Link>
{loading && <OpaqueLoading/>}
          <Stack direction="row" spacing={1} alignItems="center" sx={{position:isMobile&&"relative",left:"50px"}}>
          {/* <CustomToggle
        selected={modeTransport}
        optionOne={{ value: true, label: "Air" }}
        optionTwo={{ value: false, label: "Sea" }}
        onChange={handleToggleChange}
      /> */}
            {/* <Typography>Sea</Typography>
            <Tooltip title="Toggle to change view">
              <Switch
                checked={modeTransport}
                onChange={(e, value) => {
                  console.log("value", modeTransport);
                  setModeTransport(value);
                }}
              />
            </Tooltip>
            <Typography>Air</Typography> */}
          </Stack>
        </Stack>
        {!editMode&&
        <Stack direction={"row"} spacing={1}>
          <Button variant="contained" onClick={handleAddTab}>
            <AddIcon /> Container
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDeleteTab(selectedTab)}
            disabled={tabs.length === 1}
          >
            <DeleteIcon /> Container
          </Button>
        </Stack>
}
      </HeaderContainer>
      <TableContainer>
        <div style={{ width: "100%" }}>
          <StyledTabs
            value={selectedTab}
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            onChange={handleChangeTab}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </StyledTabs>
        </div>
        {/* <Grid
          container
          minWidth={isMobile?380:800}
          spacing={2}
          // justifyContent="center"
          // alignItems="center"
          sx={{ marginTop: "8px" ,
          marginLeft: isMobile?"-15px":"-8px",
            display: 'flex',     // Make the container a flex container
            flexDirection:isMobile ? 'column' : 'row', // Set the direction to horizontal
            alignItems: isMobile?"left":'center', // Center items vertically
        padding:"2px"
          }}
        >
          <Grid item> */}
            {/* <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}> */}
            <Stack direction={isMobile?"column":"row"} marginLeft={1} spacing={1} marginRight={1}>

            {/* <div style={{ height: 350, maxWidth: isMobile ? 345 : isMobile1?550: 680,minWidth: isMobile ? 345 : isMobile1?500: 600,marginLeft:isMobile&&"-2px" }}> */}
            {/* <div style={{  }}> */}
        <div style={{height: 350, width:isMobile?"100%":"45%",marginTop:"10px", marginLeft: isMobile ? "0" : "8px", marginRight: isMobile ? "0" : "0px", }}>

              <Typography variant="button" sx={{marginBottom:"8px"}}>
                <strong>Planned</strong>
              </Typography>
              <DataGrid
                style={{
                  backgroundColor:
                 changeColorExceed
                        ? '#FFCCCB'
                        : 'inherit' // Use a default background color or 'inherit' if not met
                      // : calculateTotalCBM(leftRows[selectedTab]) > 68
                      // ? '#FFCCCB'
                      // : 'inherit' // Use a default background color or 'inherit' if not met
                }}
                rows={leftRows[selectedTab] || []}
                columns={getColumns()}
                checkboxSelection
                disableRowSelectionOnClick
                autoPageSize
                onRowSelectionModelChange={(newSelection) => {
                  setLeftRows((prevLeftRows) => {
                    const updatedLeftRows = { ...prevLeftRows };
                    updatedLeftRows[selectedTab] = prevLeftRows[
                      selectedTab
                    ]?.map((row) => ({
                      ...row,
                      isSelected: newSelection.includes(row.id),
                    }));
                    return updatedLeftRows;
                  });
                }}
                // onRowSelectionModelChange={(newSelection) => {
                //   setLeftRows((prevLeftRows) =>
                //     prevLeftRows.map((row) => ({
                //       ...row,
                //       isSelected: newSelection.includes(row.id),
                //     }))
                //   );
                // }}
              />
            </div>
            {/* </div> */}
          {/* </Grid> */}
          {/* <Grid item> */}
            {/* <Grid container direction="column" alignItems={isMobile?"left":"center"} mx={isMobile&&15} mt={isMobile&&2} width={isMobile&&"50px"}> */}
            {/* <div style={{  > */}
        <div style={{ height: isMobile?120:350, width: "10%",marginRight: isMobile ? "0px" : "4px",paddingLeft:isMobile&&"150px",paddingTop:!isMobile?"110px":"30px",alignItems:"center",flexDirection:"column" ,display:"flex" }}>

              <Button
                sx={{ my: 0.5 }}
                variant="contained"
                size="small"
                onClick={handleMoveToRight}
                disabled={
                  !leftRows[selectedTab] || // Check if there are left rows for the selected tab
                  !Array.isArray(leftRows[selectedTab]) || // Check if leftRows[selectedTab] is an array
                  leftRows[selectedTab].every((row) => !row.isSelected) 
                }
                aria-label="move selected right"
              >
                &gt;&gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="contained"
                size="small"
                onClick={handleMoveToLeft}
                disabled={
                  rightRows.every((row) => !row.isSelected) ||
                  (rightRows.some((row) => row.isSelected) &&
                    ((modeTransport && totalLeftWeight > 3500) ||
                      (!modeTransport && totalLeftCBM > 68)))
                }
                aria-label="move selected left"
              >
                &lt; &lt;
              </Button>
            {/* </Grid> */}
          {/* </Grid> */}
          {/* </div> */}
          </div>
          {/* <Grid item> */}
          {/* <div style={{ height:350, maxWidth: isMobile ? 345 :isMobile1?550: 680,minWidth: isMobile ? 345 : isMobile1?500: 600,marginLeft:isMobile?"-2px":"9px" }}> */}
          {/* <div style={{ > */}
        <div style={{ height: 350,width:isMobile?"100%":"45%", marginRight: isMobile ? "0" : "8px" ,marginTop:"10px" }}>
              <Typography variant="button" sx={{marginBottom:"8px"}}>
              {/* <Typography variant="button" > */}
                <strong>Unplanned</strong>
              </Typography>
              <DataGrid
                rows={rightRows}
                columns={getColumns()}
                checkboxSelection
                disableRowSelectionOnClick
                autoPageSize
                // onRowSelectionModelChange={(newSelection) => {
                //   setRightRows((prevRightRows) =>
                //     prevRightRows.map((row) =>
                //       newSelection.indexOf(row.id) !== -1
                //         ? { ...row, isSelected: true }
                //         : row
                //     )
                //   );
                // }}
                onRowClick={(params, e) => {
                  // console.log('fffffff',params)
                  setModalData(params)
                }
                }
                onRowSelectionModelChange={(newSelection) => {
                  setRightRows((prevRightRows) =>
                    prevRightRows.map((row) => ({
                      ...row,
                      isSelected: newSelection.includes(row.id),
                    }))
                  );
                }}
              />
            </div>
          {/* </Grid> */}
        {/* </Grid> */}
        {/* </div> */}
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 3 : modeTransport ? 30 : 30}
          sx={{ marginTop: "40px", marginLeft:isMobile ?"5%":"15%" }}
        >
          <Stack direction={"row"} spacing={1} sx={{ width: isMobile?"100%":"45%",marginTop:!isMobile&&"-5px" }}>
            <Typography sx={{ marginTop: "13px" }}>
              {modeTransport==="Air" ? (
                <strong>Total Weight :</strong>
              ) : (
                <strong>Total CBM :</strong>
              )}{" "}
            </Typography>
            <TextField
              value={
                modeTransport ==="Air"
                  ? calculateTotalWeight(leftRows[selectedTab])
                  : calculateTotalCBM(leftRows[selectedTab])
              }
              disabled
            />
          </Stack>
          {/* <Stack sx={{marginLeft:"20%"}}> */}
          {/* <Stack direction={isMobile?"column":"row"} spacing={2}  sx={{position:isMobile&&"relative",left:isMobile&&"40px",height:isMobile?"50px":"35px",width:isMobile?"70%":"50%"}}> */}
            {/* <Button
              variant="contained"
              disabled={
                !leftRows[selectedTab] || leftRows[selectedTab]?.length === 0
              }
            >
              Save as Draft{" "}
            </Button> */}
            <div style={{marginLeft:isMobile?"10%":"40%",marginTop:"20px"}}>
           {editMode? <Button
              variant="contained"
              disabled={
                !leftRows[selectedTab] || leftRows[selectedTab]?.length === 0
              }
              onClick={()=>editPlannedContainer()}
            >
              Plan Container
            </Button>
           :
            <Button
              variant="contained"
              disabled={
                !leftRows[selectedTab] || leftRows[selectedTab]?.length === 0
              }
              onClick={()=>planContainer()}
            >
              Plan Container
            </Button>}
            </div>
          {/* </Stack> */}
        </Stack>
      </TableContainer>
      {showModal && (
        <ContainerSOSplit
          onClose={() => setShowModal(false)}
          temp={selectedRow}
          fetchData={()=>fetchData()}
        />
      )}
      {
        plannedData && <PlannedSoModal mode={false} temp={plannedData} fetchData={()=>fetchData()} onClose={()=>setPlannedData(null)}/>
      }
      {editPlannedData && <PlannedSoModal mode={true} temp={editPlannedData} fetchData={()=>fetchData()} onClose={()=>setEditPlannedData(null)}/>}

    </StaffEditorPageContainer>
    </>
  );
}
