import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@mui/lab";

import { DATE_FORMATS } from "../../constants";


const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DeletionReasonModal = (props) => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [value, setValue] = useState('');
  const [reason, setReason] = useState('');
  const [terminationDate, setTerminationDate] = useState(null);
  const [terminationDateString, setTerminationDateString] = useState(null);
  // console.log("sdasasdas",props)

  const onClickDelete = async() => {
    try {
      if(!props.data.reason){
        return window.NotificationUtils.showError("reason is required.");
      }
      if(!props.data.terminationDate){
        return window.NotificationUtils.showError("Termination date is required.");
      }
        // console.log("deletevalue", reason)
      setLoading(true);
      if(props.onClickDeleteEmployee){ 
        await props.onClickDeleteEmployee(value);
        props.closeModal()
      }
      setLoading(false);
    } catch (error) {
      window.NotificationUtils.showError("Error deleting employee");
      setLoading(false);
    }
  }

  const updateDateValue = (value, stringValue = "") => {
    setTerminationDate(value.startOf("day").unix());
    setTerminationDateString(stringValue);
  };
  
  const shouldDisableDate = (date) => {
    return date.isBefore(props.selectedMember.createdAt, 'day');
  };
  
  const dateErrorUpdate = (message) => {
    // if(message){
    //   if(!this.dateError){
    //     this.dateError = true;
    //   }
    // }
    // else{
    //   if(this.dateError){
    //     this.dateError = false;
    //   }
    // }
  }
  
  const renderModalContent = () => {
    return (
      <>
        <Typography variant="body1">{`Please submit the reason to deactivate ${
          props && props.selectedMember && props.selectedMember.firstName
        }${
          props && props.selectedMember && props.selectedMember.lastName
            ? ` ${props && props.selectedMember && props.selectedMember.lastName}`
            : ""
        }`}</Typography>
        <InputFieldContainer>
            <TextField
            
                fullWidth={true}
                label="Enter Reason"
                required
                size='medium'
                variant="outlined"
                value={reason || ""}
                onChange={(e) => {
                  setReason(e.target.value.toString() || "");
                  props.setData("reason", e.target.value.toString())
                }}
                />
        </InputFieldContainer>
        <InputFieldContainer>
       

<LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    fullWidth
    disableFuture
    shouldDisableDate={shouldDisableDate}
    required
    size='medium'
    onError={dateErrorUpdate}
    allowKeyboardControl={false}
    autoOk
    label="Termination Date"
    // value={terminationDate ? moment.unix(terminationDate) : null}
    onChange={(date) => {
      // console.log(date.unix())
      const value = date.unix();
      // console.log("chjeclDatae",value)
      updateDateValue(date, value);
      props.setData("terminationDate", value);
    }}
    renderInput={(params) => <TextField {...params} />}
    PopoverProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    }}
    // openTo="day"
  />
</LocalizationProvider>
          </InputFieldContainer>
        <ModalActionsContainer>
          <FirstButton
            disabled={loading}
            onClick={props.closeModal}
            variant="contained"
            color="primary"
          >
            Close
          </FirstButton>
          <Button
            disabled={loading}
            onClick={onClickDelete}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog maxWidth="xs" fullWidth={true} open={showModal}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Reason for Deactivation?</Typography>
          <IconButton disabled={loading} onClick={props.closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default DeletionReasonModal;
