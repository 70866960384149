import react, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import {

  DataGrid,

  GridColDef,

  GridToolbarColumnsButton,

  GridToolbarContainer,

  GridToolbarDensitySelector,

  GridToolbarExport,

  GridToolbarFilterButton,

  GridValueGetterParams,

} from "@mui/x-data-grid";

import styled from "styled-components";

import Drawer from "../../components/common/drawer/drawer";

import MoreVertIcon from '@mui/icons-material/MoreVert';

import {

  Typography,

  Button,

  Checkbox,

  Fab,

  Tab,

  Tabs,

  CircularProgress,

  IconButton,

  Popover,

  MenuItem,

} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";

import { ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";

import { Add, Inventory, Save, SaveAlt } from "@mui/icons-material";

import WarehouseModal from "../../components/modals/WarehouseModal";

import InventoryItemsModal from "../../components/modals/inventoryItemsModal";

import PackagingListModal from "../../components/modals/packagingListModal";

import { useParams } from "react-router-dom";

import AddCustomerModal from "../../components/modals/CustomerModal";

import ItemModal from "../../components/modals/itemModal";

import TemplateModal from "../../components/modals/TemplateModal";



const isMobile = window.innerWidth < 900;





const StaffEditorPageContainer = styled.div`

  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};

  width: 100%;

  background-color: white;

  padding: 10px 15px;

  ${isMobile &&

  `

      position: relative;




      `}

`;




const ContentContainer = styled.div`




  display: flex;




  gap: 10px;




  padding: 10px 15px;




  flex-direction: column;




`;




const HeaderContainer = styled.div`

  display: flex;

  align-items: center;

  justify-content: ${isMobile ? "flex-start" : "space-between"};

  margin-bottom: 10px;

  flex-direction: ${isMobile ? "column" : "row"};

`;




const HeaderButtonsContainer = styled.div`

  ${isMobile && "margin-top:10px;"}

`;




const TableContainer = styled.div`

  height: calc(

    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}

  );

  width: 100%;

  border: solid 1px lightGrey;

  border-radius: 8px;

`;

const TabsContainer = styled.div`

  ${isMobile &&

  `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}

`;

const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;

const AvatarContainer = styled.div`

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

`;




const DataGridContainer = styled.div`

  height: calc(100vh - 145px);

  overflow-x: auto;

`;




const LoadingContainer = styled.div`

  height: calc(100% - 49px);

  display: flex;

  justify-content: center;

  align-items: center;

`;




// const TabsContainer = styled.div`

//   ${isMobile &&

//   `

//     height: calc(100vh - 56px - 20px);

//   `}

// `;




const GenerateReportButton = styled(Button)`

  margin-right: 10px !important;

`;

const BulkChangePinCodeButton = styled(Button)`

  margin-right: 10px !important;

`;




const PinCodeChangeButtonContainer = styled.div`

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

`;




const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;




const StyledHeader = styled.div`

  display: flex;

  justify-content: center;

  align-items: center;

`;

const Toolbar = styled.div`




  width: 100%;




  display: flex;




  align-items: center;




  justify-content: space-between;




  gap: 10px;




`;




export default function TemplateManager(props) {

  const { id } = useParams();




  const [loading, setLoading] = useState(false);

  const [Template, setTemplate] = useState(false);

  const [selectedRow,setSelectedRow]=useState(null);

  const [showModal,setShowModal]=useState(false);

  const [templateData, setTemplateData]=useState(null);




  const [openPopover, setOpenPopover] = useState(null);

  useEffect(() => {
    fetchdata();
  },[showModal])

  const handleOpenPopover = (event) => {

    setOpenPopover(event.currentTarget);

  };

  const handleClosePopover = () => {

    setOpenPopover(null);

  };




  const getTemplateColumns=()=>{

    let result=[
      {

        field: "id",

        headerName: "ID",

        minWidth: 400,

        maxWidth:400,




      flex: 0.32,

        sortable: false,

        dataGetter: params => params.row?.id || 'N/A',

        renderCell: params => {


          const value = params.row?.id || 'N/A'

          return <Tooltip title={value}>{value}</Tooltip>

        }

 

      },
      {

        field: "templateName",

        headerName: "Channel Name",

        minWidth: 400,

        maxWidth:400,




      flex: 0.32,

        sortable: false,

        dataGetter: params => params.row?.channel || 'N/A',

        renderCell: params => {


          const value = params.row?.channel || 'N/A'

          return <Tooltip title={value}>{value}</Tooltip>

        }

 

      },

      {

        field: "template",

        headerName: "Template 1",

        minWidth: 300,

        maxWidth: 600,

      flex: 0.22,

        sortable: false,

        dataGetter: params => (params.row?.templates) ? params.row.templates[0] : 'N/A',

        renderCell: params => {

          const value = (params.row?.templates) ? params.row.templates[0] : 'N/A'

          return <Tooltip title={value}>{value}</Tooltip>

        }

 

      },
      {

        field: "template2",

        headerName: "Template 2",

        minWidth: 300,

        maxWidth: 600,

      flex: 0.22,

        sortable: false,

        dataGetter: params => (params.row.templates) ? params.row?.templates[1] : 'N/A',

        renderCell: params => {

          const value = (params.row.templates) ? params.row?.templates[1] : 'N/A'

          return <Tooltip title={value}>{value}</Tooltip>

        }

 

      },
      {

        field: "template3",

        headerName: "Template 3",

        minWidth: 300,

        maxWidth: 600,

      flex: 0.22,

        sortable: false,

        dataGetter: params => (params.row?.templates) ? params.row.templates[2] : 'N/A',

        renderCell: params => {

          const value = (params.row?.templates) ? params.row.templates[2] : 'N/A'

          return <Tooltip title={value}>{value}</Tooltip>

        }

 

      }

 

 

    ]

 

    return result;

  }




  const setModalData=(params)=>{

    setSelectedRow(params.row)

    setShowModal(true)

  }




  const createCustomDataGrid = (rows, columns) => {

    // Define the custom toolbar function here

    const CustomToolbar = () => {

      return (

        <GridToolbarContainer>

          <GridToolbarColumnsButton />

          <GridToolbarFilterButton />

          <GridToolbarDensitySelector />

          <GridToolbarExport

            csvOptions={{

              fileName: "fos-list",

            }}

          />

        </GridToolbarContainer>

      );

    };




    return (

      <DataGridContainer>

        <DataGrid

          className="payrollGrid"

          checkboxSelection={true}

          rows={rows||[]}

          columns={columns}

          rowHeight={70}

          onRowClick={(params,e)=>setModalData(params)}

          disableRowSelectionOnClick

          autoPageSize

          components={{

            Toolbar: CustomToolbar,

          }}

        />

      </DataGridContainer>

    );

  };




  const renderLoading = () => {

    return (

      <LoadingContainer>

        <CircularProgress color="primary" />

      </LoadingContainer>

    );

  };

  const fetchdata = async () => {
    try {
      setLoading(true);

      let response = await window.Platform.database.getTemplates(
        // {page: paginationModel.page, // Pass the current page
        // pageSize: paginationModel.pageSize, // Set your desired page size
        // filterModel: { items: [] }}
      );

      console.log("RESPONSE CUSTOMER", response);

      setTemplateData(response)
    } catch (error) {
      window.NotificationUtils.showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const saveTemplate = async (text, channel, selectedTab) => {

    try {
      setLoading(true);
  
      let response = await window.Platform.database.saveTemplate(
        {text : text,tab: selectedTab, channel: channel}
        // {page: paginationModel.page, // Pass the current page
        // pageSize: paginationModel.pageSize, // Set your desired page size
        // filterModel: { items: [] }}
      );
  fetchdata()
  // setShowModal(false)
        
      console.log("RESPONSE CUSTOMER", response);
  
      // setTemplateData(response)
    } catch (error) {
      window.NotificationUtils.showError(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  
  }

  const activateTemplate = async(template, selectedTab) => {

    try {
      setLoading(true);
  
      let response = await window.Platform.database.activateTemplate(
        {channel : template.channel,tab: selectedTab}
        // {page: paginationModel.page, // Pass the current page
        // pageSize: paginationModel.pageSize, // Set your desired page size
        // filterModel: { items: [] }}
      );
  fetchdata()
  // setShowModal(false)
        
      console.log("RESPONSE CUSTOMER", response);
  
      // setTemplateData(response)
    } catch (error) {
      window.NotificationUtils.showError(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  
  }

  const renderTabContent = () => {

    if (loading) {

      return renderLoading();

    } else {

      let row = [{id:"001",name:"WhattsApp",mess:"Hi From WhattsAppHi From WhattsAppHi From WhattsAppHi From WhattsAppHi From WhattsAppHi From WhattsAppHi From WhattsAppHi From WhattsAppHi From WhattsApp"},{id:"002",name:"WeChat",mess:"Hi From WeChat"}];



      return createCustomDataGrid(templateData, getTemplateColumns());

    }

  };



  const renderModals = () => {

    // console.log("seeee", selectedRow);
    return (

      <>

        {selectedRow && showModal && (

        <TemplateModal

          template={selectedRow}

          open={showModal}

          onClose={() => setShowModal(false)}

          activateTemplate={activateTemplate}
          saveTemplate = {saveTemplate}

        />

      )}

       

      </>

    );

  };




  const renderHeader = () => {

    return (

      <Toolbar>

        <Typography variant="h5">Template Manager</Typography>




        <div>

          {/* <Button

            variant="contained"

            color="primary"

            disabled={loading}

            onClick={()=>setTemplate(true)}

          >

            Add Template

          </Button> */}

        </div>

      </Toolbar>

    );

  };




  return (

    <>

      {isMobile && <Drawer props={props}/>}

      <ContentContainer>




        {renderHeader()}




        {renderTabContent()}




        {renderModals()}




      </ContentContainer>

      {/* <StaffEditorPageContainer>

        <HeaderContainer>

          <Typography variant="h5">Items Manager</Typography>

        </HeaderContainer>

        <TableContainer>{renderTabContent()}</TableContainer>

        {renderModals()}

      </StaffEditorPageContainer> */}

    </>

  );

}