import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, DATE_FORMATS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import moment from "moment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Link } from "react-router-dom";

const isMobile = window.innerWidth < 900;


const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding:10px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom: 0px;
  }
`;
// const StyledContainer = styled.div`
//   /* Default styles */
//   width: 100%;
//   max-width: 960px;
//   margin: 0 auto;
  
//   /* Mobile styles */
//   @media (max-width: 600px) {
//     padding: 0 10px;
//   }
// `;
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top:10px;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  
//   height: 500px;
  padding:10px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;
  
  const ViewPlannedSOModal = ({ onClose, temp }) => {
// console.log('timeee,', temp)
const [data,setData]=useState(temp);
const [loading,setLoading]=useState(false);


useEffect(()=>{
   setData(temp)

  },[])
//   console.log("DATAGRID",data.shippingOrder)

//   const fetchData = async () => {
//     console.log("timmeeee",timeline)
//     try{
//       setLoading(true)
// // let date = moment(timeline).format(DATE_FORMATS.DEFAULT)


//           let results = await window.Platform.database.fetchSoByExpectedDate(timeline)
//           setData(results)
//           console.log('resilts', results)
// setLoading(false)
//   }
//   catch(err){
//     setLoading(false)
// console.log('error', err)
//   }
// }

// const setLocalStorage=()=>{

// //   const inputDate = new Date(timeline.date);

// // // Adjust the time to UTC
// // const utcTime = inputDate.getTime() - (inputDate.getTimezoneOffset() * 60000); // Convert to UTC

// // // Format the UTC time as required ("YYYY-MM-DDTHH:mm:ss.sssZ")
// // const formattedDateString = new Date(utcTime).toISOString();
// // console.log("FORMATTEDDATE",formattedDateString);
//   localStorage.setItem("mode",timeline.mode)
//   localStorage.setItem('time',timeline.date);
// }
// console.log("DATAAA",data)

  const getItemColumns = () => {
    let result = [
        {
            field: "soId",
            headerName: "SO Id",
            sortable: false,
            width: 150,
            // editable: true,
            renderCell: params => {
              // console.log("PARAMS", params.row.attributes.quotation.id);
              const value = params.row.id || 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
          },
          {
            field: "quotationId",
            headerName: "Quotation Id",
            width: 200,
            sortable: false,
            dataGetter: params => params.row.attributes.quotation.id || 'N/A',
            renderCell: params => {
              const value = params.row.attributes.quotation.id || 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
    
          },
          
          {
            field: "deliveryDate",
            headerName: "Delivery Date",
            width: 200,
            sortable: false,
            // dataGetter: params => params.row.supplier?.name || 'N/A',
            renderCell: params => {
              const value = moment.unix(params.row.attributes.deliveryDate).format(DATE_FORMATS.DEFAULT) || 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
          },
          {
            field: "totalVol",
            headerName: "Total Volume",
            width: 200,
            sortable: false,
            dataGetter: params => params.row.attributes.totalVolume || 'N/A',
            renderCell: params => {
              const value = params.row.attributes.totalVolume || 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
          },
          {
            field: "totalCbm",
            headerName: "Total CBM",
            width: 200,
            sortable: false,
            // dataGetter: params => params.row.supplier?.deliveryDate || 'N/A',
            renderCell: params => {
              const value =params.row.attributes.totalCbm || 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
          },
          {
            field: "totalAmount",
            headerName: "Total Amount",
            width: 200,
            sortable: false,
            // dataGetter: params => params.row.status || 'N/A',
            renderCell: params => {
              const value =params.row.attributes.totalAmount|| 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
          },
          {
            field: "totalWeight",
            headerName: "Total Weight",
            width: 200,
            sortable: false,
            // dataGetter: params => params.row.status || 'N/A',
            renderCell: params => {
              const value =params.row.attributes.totalWeight|| 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
          },
          {
            field: "totalCtn",
            headerName: "Total CTN",
            width: 200,
            sortable: false,
            // dataGetter: params => params.row.status || 'N/A',
            renderCell: params => {
              const value =params.row.attributes.totalCtn|| 'N/A'
              return <Tooltip title={value}>{value}</Tooltip>
            }
          },
          // {
          //   field: "shipmentType",
          //   headerName: "Shipment Type",
          //   width: 200,
          //   sortable: false,
          //   dataGetter: params => params.row.shipmentType || 'N/A',
          //   renderCell: params => {
          //     const value = params.row.shipmentType || 'N/A'
          //     return <Tooltip title={value}>{value}</Tooltip>
          //   }
          // },
        
         
      
    ];
  
  
    return result
  }
  
    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport
            csvOptions={{
              fileName: "items-list",
            }}
          />
        </GridToolbarContainer>
      );
    }
  
    const renderContent = () => {
      return (
        <DataGridContainer>
            {/* <Stack> */}

        {/* <Box sx={{ height: "100%", width: "100%" }}> */}
          <DataGrid
            sx={{height:isMobile ? "300px":"300px"}}
            className="payrollGrid"
            // checkboxSelection={true}
            rows={data.shippingOrder}
            columns={getItemColumns()}
            rowHeight={70}
            disableSelectionOnClick
            disableRowSelectionOnClick
            autoPageSize
            // components={{
            //     Toolbar: CustomToolbar,
            // }}
            />
        {/* </Box> */}
            {/* </Stack> */}
            <ModalActionsContainer>
                
        <Stack
                direction="row"
                justifyContent="flex-end"
                gap="10px"
                align-items="center"
                >
{/* <Link to={`/containerplanning/${timeline.wid}`}>
                <Button variant="contained" onClick={()=>setLocalStorage()}  >Plan Container</Button>
</Link> */}
              </Stack>
                  </ModalActionsContainer>
        </DataGridContainer>
      );
    };
  
    return (
      <Dialog open={true}  maxWidth="lg">

      <ModalHeaderContainer style={{ marginBottom: 0, paddingBottom: 0 }}>

        <Typography variant="h5">

          {/* {items?.customerName} */}

          SO(s) in container


 

       </Typography>
       <IconButton  onClick={()=>onClose()}>
            <CloseOutlinedIcon />
          </IconButton>
       </ModalHeaderContainer>
       {loading && <OpaqueLoading/>}
      
      {renderContent()}

      {/* {showModal && <EditQuotationModal template={items} onCancel={()=>setShowModal(false)}/>} */}

    </Dialog>
    );
  };
  
  export default ViewPlannedSOModal;
  