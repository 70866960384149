import react, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import styled from "styled-components";

import Drawer from "../../components/common/drawer/drawer";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Typography,
  Button,
  Checkbox,
  Fab,
  Tab,
  Tabs,
  CircularProgress,
  IconButton,
  Popover,
  MenuItem,
  Stack,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";

import { ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";

import { Add, Inventory, Save, SaveAlt } from "@mui/icons-material";

import WarehouseModal from "../../components/modals/WarehouseModal";

import InventoryItemsModal from "../../components/modals/inventoryItemsModal";

import PackagingListModal from "../../components/modals/packagingListModal";

import { useParams } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";

import AddNewItem from "../../components/modals/AddNewMasterItem";
import BulkItemAdd from "../../components/modals/bulkItemAdd";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};

  width: 100%;

  background-color: white;

  padding: 10px 15px;

  ${isMobile &&
  `

      position: relative;




      `}
`;

const ContentContainer = styled.div`
  display: flex;

  gap: 10px;

  padding: 10px 15px;

  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: ${isMobile ? "flex-start" : "space-between"};

  margin-bottom: 10px;

  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );

  width: 100%;

  border: solid 1px lightGrey;

  border-radius: 8px;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const AvatarContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 145px);

  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);

  display: flex;

  justify-content: center;

  align-items: center;
`;

// const TabsContainer = styled.div`

//   ${isMobile &&

//   `

//     height: calc(100vh - 56px - 20px);

//   `}

// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;

const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;

const StyledHeader = styled.div`
  display: flex;

  justify-content: center;

  align-items: center;
`;

const Toolbar = styled.div`
  width: 100%;

  display: flex;

  align-items: center;

  justify-content: space-between;

  gap: 10px;
`;

export default function ItemsManager(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [Items, setItems] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [row, setRow] = useState([]);
  const [subLoading, setSubLoading] = useState(false);
  const [bulk,setBulk]= useState(false);

  const getItemsColumns = () => {
    let result = [
      {
        field: "nameItem",

        headerName: "Item Name",

        minWidth: 200,

        maxWidth: 250,

        flex: 0.22,

        sortable: false,

        dataGetter: (params) => params.row.customername || "N/A",
        valueGetter: (params) =>params.row.name || "N/A",

        renderCell: (params) => {
          const value = params.row.name || "N/A";

          return <Tooltip title={value}>{value}</Tooltip>;
        },
      },
      {
        field: "category",

        headerName: "Category",

        minWidth: 200,

        maxWidth: 250,

        flex: 0.22,

        sortable: false,

        dataGetter: (params) => params.row.category || "N/A",
        valueGetter: (params) =>params.row.category || "N/A",

        renderCell: (params) => {
          const value = params.row.category || "N/A";

          return <Tooltip title={value}>{value}</Tooltip>;
        },
      },
    ];

    result.push({
      field: "  ",

      headerName: "Actions",

      editable: false,

      minWidth: 150,

      flex: 0.22,

      maxWidth: 160,

      disableColumnMenu: true,

      disableExport: true,

      disableReorder: true,

      hideSortIcons: true,

      renderCell: (params) => {
        return (
          <>
            <IconButton
              size="large"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();

                onClickDeleteEmployee(params.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    });

    return result;
  };

  const onSubmitCustomer = async (form) => {
    if (selectedRow) form = { ...form, id: selectedRow.id };


    form = { ...form, supplierId: id };
    try {
      setSubLoading(true);

      // let sent_data={customerName: form.customerName, customerCity: form.customerCity, customerMobile:form.customerMobile}

      // let response_Data={customerDetails:sent_data}

      let response = await window.Platform.database.createNewItem(form);
      window.NotificationUtils.showSuccess("Item added to the system");

      setShowModal(false);
      // console.log("creating activity...", response);
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong");

    } finally {
      setSubLoading(false);
      fetchdata();
    }
  };

  const fetchdata = async () => {
    try {
      setLoading(true);
      let response = await window.Platform.database.getAllItemsMaster({
        id: id,
      });



      setRow(response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const onDelete = async (val) => {
    try {
      setLoading(true);

      let response = await window.Platform.database.deleteItems(val);



      fetchdata();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onClickDeleteEmployee = (params) => {

    let newParams = { name: params.name, id: params.id };

    window.confirmationModal(
      `Are you sure you want to deactivate ${params.label}

       (${params.id})?`,
      () => onDelete(newParams)
    );
  };

  const setModalData = (params) => {
    setSelectedRow(params.row);

    setShowModal(true);
  };

  const handleAddCustomer = () => {
    setShowModal(true);

    setSelectedRow(null);
  };

  const createCustomDataGrid = (rows, columns) => {
    const CustomToolbar = () => {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />

          <GridToolbarFilterButton />

          <GridToolbarDensitySelector />

          <GridToolbarExport
            csvOptions={{
              fileName: "Item Manager",
            }}
          />
        </GridToolbarContainer>
      );
    };

    return (
      <DataGridContainer>
        <DataGrid
          loading={loading}
          className="payrollGrid"
          checkboxSelection={true}
          rows={rows}
          columns={columns}
          rowHeight={70}
          onRowClick={(params, e) => setModalData(params)}
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };
  const renderLoading = () => {
    return (
      <LoadingContainer>
        <CircularProgress color="primary" />
      </LoadingContainer>
    );
  };

  const renderTabContent = () => {
    return createCustomDataGrid(row, getItemsColumns());
  };

  const renderModals = () => {
    return (
      <>
        {selectedRow && showModal && (
          <AddNewItem
            template={selectedRow}
            onSubmit={onSubmitCustomer}
            onCancel={() => setShowModal(false)}
            loading={subLoading}
          />
        )}

        {!selectedRow && showModal && (
          <AddNewItem
            template={null}
            onSubmit={onSubmitCustomer}
            onCancel={() => setShowModal(false)}
            loading={subLoading}
          />
        )}

        {bulk && <BulkItemAdd onClose={()=>setBulk(false)} fetchdata={()=>fetchdata()} id={id}/>}
      </>
    );
  };

  const renderHeader = () => {
    return (
      <Toolbar>
        <Typography variant="h5">Items Manager</Typography>

        <Stack direction={"row"} spacing={1}>
        <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={()=>setBulk(true)}
          >
            Add Items Bulk
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleAddCustomer}
          >
            Add Items
          </Button>
        </Stack >
      </Toolbar>
    );
  };

  return (
    <>
      {isMobile && <Drawer props = {props}/>}

      <ContentContainer>
        {renderHeader()}

        {renderTabContent()}

        {renderModals()}
      </ContentContainer>

      {/* <StaffEditorPageContainer>

        <HeaderContainer>

          <Typography variant="h5">Items Manager</Typography>

        </HeaderContainer>

        <TableContainer>{renderTabContent()}</TableContainer>

        {renderModals()}

      </StaffEditorPageContainer> */}
      
    </>
  );
}
