import { Button, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { Navigate, useNavigate } from "react-router-dom";
// import { Button } from "@mui/base";

const isMobile = window.innerWidth < 900;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;

  // max-width: 700px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const PlannedSoModal = ({ temp, onClose,fetchData,mode }) => {
  // console.log("TEMP", temp);

  const [newCtnValues, setNewCtnValues] = React.useState({});
  const [formvalue,setFormValue]=useState([])
  const [gridRows,setGridRows]=useState(temp.orders)
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();
  const [disableCheck,setDisableCheck]=useState(false)
  const [formData, setFormData] = useState({
    consigneeName: '',
    shipperName: '',
    awbBlNumber: '',
    warehouse: '',
    typeOfGoods: '',
    forwarder: '',
    freightAmount: '',
    portOfDestination: '',
    portLoading: '',
    containerNoSealNo: '',
    shippingLine: '',
  });
  useEffect(()=>{
    if(mode===true){
    setFormData({
      consigneeName: temp.data.consigneeName||"",
      shipperName: temp.data.shipperName,
      awbBlNumber: temp.data.awbBlNumber,
      warehouse: temp.data.warehouse,
      typeOfGoods: temp.data.typeOfGoods,
      forwarder: temp.data.forwarder,
      freightAmount: temp.data.freightAmount,
      portOfDestination: temp.data.portOfDestination,
      portLoading: temp.data.portLoading,
      containerNoSealNo: temp.data.containerNoSealNo,
      shippingLine: temp.data.shippingLine,

    })
    }
  }, [])
  useEffect(()=>{
    if(mode===null){
      setDisableCheck(true)
      setFormData({
        consigneeName: temp.consigneeName||"",
        shipperName: temp.shipperName,
        awbBlNumber: temp.awbBlNumber,
        warehouse: temp.warehouse,
        typeOfGoods: temp.typeOfGoods,
        forwarder: temp.forwarder,
        freightAmount: temp.freightAmount,
        portOfDestination: temp.portOfDestination,
        portLoading: temp.portLoading,
        containerNoSealNo: temp.containerNoSealNo,
        shippingLine: temp.shippingLine,
  
      })
  }
  },[])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log("NAME", name)
    // console.log("VALUE", value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateFormData = () => {
    const requiredFields = [
      "consigneeName",
      "shipperName",
      "awbBlNumber",
      "warehouse",
      "typeOfGoods",
      "forwarder",
      "freightAmount",
      "portOfDestination",
      "portLoading",
      "containerNoSealNo",
      "shippingLine",
    ];
    // console.log("ERRROOOOOR")

    return requiredFields.some((fieldName) => !formData[fieldName]);
  };
  const clearLocalStorage=()=>{
    localStorage.removeItem('wid');
    localStorage.removeItem('phase');
    localStorage.removeItem('editTime');
    localStorage.removeItem('typeShip');
    // localStorage.removeItem('time');
    // localStorage.removeItem('mode');
  }

  const handleSubmit = async() => {
    try{
      // const hasErrors = validateFormData();
      // if (hasErrors) {
      //   // setLoading(false)
      //   window.NotificationUtils.showError(
      //     "Please enter all fields"
      //   );
      //   return;
      // }
      setLoading(true)
      const requestData = {
        tempData: temp,
        formData: formData}
        // console.log("REQUESTED", requestData)
    
    let results = await window.Platform.database.planContainer({tempData: temp, formData: formData});
    // Perform additional actions like sending data to the server
    fetchData()
    // Close the modal
    onClose();
    clearLocalStorage()
    window.NotificationUtils.showSuccess('Container Planned Successfully')

    setLoading(false);
  }
    catch(err){
      // console.log(err);
      setLoading(false);
    }
  };

  const handleEdit = async() => {
    try{
      // const hasErrors = validateFormData();
      // if (hasErrors) {
      //   // setLoading(false)
      //   window.NotificationUtils.showError(
      //     "Please enter all fields"
      //   );
      //   return;
      // }
      setLoading(true)
      const requestData = {
        tempData:{prevId:temp.prevId,cId: temp.cid,wId:temp.wid,deliveryDate:temp.deliveryDate,soId:temp.soId,totalCBM:temp.totalCBM,totalCtn:temp.totalCtn,totalWeight:temp.totalWeight,typeOfShipment:temp.typeOfShipment},
        formData: formData}
        // console.log("REQUESTED", requestData)
    
    let results = await window.Platform.database.editPlanContainer({tempData: requestData.tempData, formData:requestData.formData});
    // Perform additional actions like sending data to the server
    // fetchData()
    // Close the modal
    onClose();
    
    setLoading(false);
    clearLocalStorage();
    window.NotificationUtils.showSuccess('Container Updated succesfully')

    navigate(`/timeline/${temp.wid}`)
  }
    catch(err){
      // console.log(err);
      setLoading(false);
    }
  };




// console.log("================================",formData)

  return (
    <StyledDialog
      disableEnforceFocus
      // maxWidth="xs"
      fullWidth={true}
      open={true}
      PaperProps={{
        style: isMobile
          ? {}
          : {
              minWidth: 750,

              maxWidth: 850,

              minHeight: 500,

              maxHeight: 550,
            },
      }}
    >
      <StyledDialogContent>
        {loading && <OpaqueLoading/>}
        <ModalHeaderContainer>
          <Typography variant="h5">Plan SOs </Typography>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </ModalHeaderContainer>
        <Stack direction={"column"} spacing={2}>
        <Stack direction={"row"} spacing={2}>
        <TextField
        label="Consignee Name"
        name="consigneeName"
        
        value={formData.consigneeName || ''}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
      <TextField
      
        label="Shipper Name"
        name="shipperName"
        value={formData.shipperName}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
        // sx={{marginTop:"30px"}}
      />
      <TextField
      
        label="AWB/BL Number"
        name="awbBlNumber"
        value={formData.awbBlNumber}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
      <TextField
      
        label="Warehouse"
        name="warehouse"
        value={formData.warehouse}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
      <TextField
      
        label="Type of Goods"
        name="typeOfGoods"
        value={formData.typeOfGoods}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
      <TextField
      
        label="Forwarder"
        name="forwarder"
        value={formData.forwarder}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
       </Stack>
       <Stack direction={"row"} spacing={2}>
       <TextField
       
        label="Freight Amount"
        name="freightAmount"
        type="number"
        value={formData.freightAmount}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
      <TextField
      
        label="Port of Destination"
        name="portOfDestination"
        value={formData.portOfDestination}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
      <TextField
      
        label="Port Loading"
        name="portLoading"
        value={formData.portLoading}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
      </Stack>
      <Stack direction={"row"} spacing={2}>
      <TextField
      
        label="Container No/SealNo"
        name="containerNoSealNo"
        // type="number"
        value={formData.containerNoSealNo}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />
      <TextField
      
        label="Shipping Line"
        name="shippingLine"
        value={formData.shippingLine}
        onChange={handleInputChange}
        fullWidth
        disabled={disableCheck}
        // margin="normal"
      />


        </Stack>
        <Stack direction={"row"} spacing={2}>
        <TextField
        
        label="Total Weight"
        name="TotalWeight"
        value={temp.totalWeight}
        onChange={handleInputChange}
        disabled
        fullWidth
        // margin="normal"
      />
      <TextField
      
        label="Total CBM"
        name="TotalCbm"
        value={temp.totalCBM}
        disabled
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />
      <TextField
        label="Total No of CTN "
        name="TotalCTN"
        value={temp.totalCtn}
        disabled
        // onChange={handleInputChange}
        fullWidth
        // margin="normal"
      />

        </Stack>

        </Stack>
        <ModalFooter>
          {mode!=null&&
        <Typography variant="body2" color="textSecondary" >
            Note: All fields are necessary for submission.
          </Typography>}
          {mode===true&&  <Button variant="contained" onClick={handleEdit} >Save Changes</Button>}
          
     {mode===false &&  <Button variant="contained" onClick={handleSubmit} >Save Changes</Button>
          }</ModalFooter>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default PlannedSoModal;
