import react, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import styled from "styled-components";

import Drawer from "../../components/common/drawer/drawer";

import DeleteIcon from "@mui/icons-material/Delete";

import {
  Typography,
  Button,
  Checkbox,
  Fab,
  Tab,
  Tabs,
  CircularProgress,
  IconButton,
  Popover,
  MenuItem,
  Stack,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";

import { DATE_FORMATS, ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";

import { Add, Inventory, Save, SaveAlt } from "@mui/icons-material";

import WarehouseModal from "../../components/modals/WarehouseModal";

import InventoryItemsModal from "../../components/modals/inventoryItemsModal";

import PackagingListModal from "../../components/modals/packagingListModal";

import { useParams } from "react-router-dom";

import AddNewCustomer from "../../components/modals/AddNewMasterCustomer";
import moment from "moment";
import BulkCustomerAdd from "../../components/modals/bulkCustomerAdd";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};

  width: 100%;

  background-color: white;

  padding: 10px 15px;

  ${isMobile &&
  `

 

      position: relative;

 

 

 

 

      `}
`;

const ContentContainer = styled.div`
  display: flex;

  gap: 10px;

  padding: 10px 15px;

  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: ${isMobile ? "flex-start" : "space-between"};

  margin-bottom: 10px;

  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );

  width: 100%;

  border: solid 1px lightGrey;

  border-radius: 8px;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `

 

    height: calc(100vh - 56px - 20px - 43.77px);

 

  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const AvatarContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 145px);

  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);

  display: flex;

  justify-content: center;

  align-items: center;
`;

// const TabsContainer = styled.div`

//   ${isMobile &&

//   `

//     height: calc(100vh - 56px - 20px);

//   `}

// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;

const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const FabStyle = styled(Fab)`

 

  && {

 

    z-index: 1};

 

  }

 

`;

const StyledHeader = styled.div`
  display: flex;

  justify-content: center;

  align-items: center;
`;

const Toolbar = styled.div`
  width: 100%;

  display: flex;

  align-items: center;

  justify-content: space-between;

  gap: 10px;
`;

export default function CustomerManager(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [Customer, setCustomer] = useState(false);

  const [showPackagingListModal, setShowPackagingListModal] = useState();

  const [showItemsModal, setShowItemsModal] = useState();

  const [selectedRow, setSelectedRow] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [subLoading, setSubLoading] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const [row, setRow] = useState([]);
  const [bulk,setBulk]=useState(false);

  const [rowCount, setRowCount] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 5,
});

const [queryOptions, setQueryOptions] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const fetchdata = async () => {
    try {
      setLoading(true);

      let response = await window.Platform.database.getCustomer(
        {page: paginationModel.page, // Pass the current page
        pageSize: paginationModel.pageSize, // Set your desired page size
        filterModel: { items: [] }}
      );

      // console.log("RESPONSE CUSTOMER", response);

      setRow(response.results);
      setRowCount(response.totalCount)
    } catch (error) {
      window.NotificationUtils.showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      // Fetch all data from the server for export
      const response = await window.database.getCustomer();

      // console.log("EXPORT CUSTOMER", response);
      // Create a CSV file content using the exported data
      // const csvContent = // ... create CSV content from data ...
  
      const blob = new Blob(response, { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'exported_data.csv';
      link.click();
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const onFilterChange = async(filterModel, page, pageSize) => {

    if(!filterModel)
    {
      // console.log("queryyyyy",queryOptions.filterModel)
      filterModel = queryOptions.filterModel
  }
    try{  
      setLoading(true);
      // console.log(paginationModel,'................................................................')
      const response = await window.Platform.database.filterCustomers({ ...filterModel,page:page || paginationModel.page, // Pass the current page
      pageSize: pageSize || paginationModel.pageSize })
      // console.log("respponse",response);
      setRow(response.customers);
      setRowCount(response.totalCount)
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  
    }
    // setQueryOptions({ filterModel: { ...filterModel } });
  };

  useEffect(() => {
    // fetchdata();
  }, []);
  useEffect(() => {
    // console.log('check page', paginationModel)
    if(!queryOptions)
    fetchdata();
    else{

      onFilterChange(queryOptions, paginationModel.page, paginationModel.pageSize)
    // console.log('Fetching states with pagination',paginationModel.page);
    }
  }, [paginationModel.page]);

  const getCustomercolumns = () => {
    let result = [
      {
        field: "name",

        headerName: "Customer Name",

        minWidth: 200,

        flex: 0.22,

        sortable: 1,

        dataGetter: (params) => params.row.customer?.name || "N/A",
        valueGetter: (params) =>params.row.label || "N/A",

        renderCell: (params) => {
          // console.log(params)

          const value = params.row.label || "N/A";

          return <Tooltip title={value}>{value}</Tooltip>;
        },
      },{

        field: "updatedAt",

        headerName: "Updated At",

        minWidth: 200,




      flex: 0.22,

        sortable: 1,

        valueGetter: (params) =>  moment(params.row.updatedAt).format(DATE_FORMATS.DATE_WITH_TIME) || 'N/A',
        renderCell:( params) => {
          // console.log("params in dataGetter:", params);
          const value = params.row.updatedAt || 'N/A'

          return <Tooltip title={moment(value).format(DATE_FORMATS.DATE_WITH_TIME)}>{moment(value).format(DATE_FORMATS.DATE_WITH_TIME)}</Tooltip>

        }

      },

      {
        field: "mobile",

        headerName: "Customer Mobile",

        minWidth: 200,

        flex: 0.22,

        sortable: 1,

        dataGetter: (params) => params.row.customerMobile?.mobile || "N/A",
        valueGetter: (params) =>params.row.mobile || "N/A",
        renderCell: (params) => {
          const value = params.row.mobile || "N/A";

          return <Tooltip title={value}>{value}</Tooltip>;
        },
      },

      {
        field: "city",

        headerName: "Customer City",

        minWidth: 200,

        flex: 0.22,

        sortable: 1,

        dataGetter: (params) => params.row.customerCity || "N/A",
        valueGetter: (params) =>params.row.city || "N/A",
        renderCell: (params) => {
          const value = params.row.city || "N/A";

          return <Tooltip title={value}>{value}</Tooltip>;
        },
      },
    ];

    result.push({
      field: "  ",

      headerName: "Actions",

      editable: false,

      minWidth: 150,

      flex: 0.22,

      maxWidth: 160,

      disableColumnMenu: true,

      disableExport: true,

      disableReorder: true,

      hideSortIcons: true,

      renderCell: (params) => {
        return (
          <>
            <IconButton
              size="large"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();

                onClickDeleteEmployee(params.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    });

    return result;
  };

  const onDelete = async (val) => {
    try {
      setLoading(true)
      let results = await window.Platform.database.deleteCustomer(val);

      fetchdata();
      setLoading(false)
    } catch (error) {
      window.NotificationUtils.showError(error.message);
      setLoading(false)
    }
  };

  const onClickDeleteEmployee = (params) => {


    window.confirmationModal(
      `Are you sure you want to deactivate ${params.label}

 

       (${params.id})?`,
      () => onDelete(params)
    );
  };

  const setModalData = (params) => {


    setSelectedRow(params.row);

    setShowModal(true);
  };

  const handleAddCustomer = () => {
    setShowModal(true);

    setSelectedRow(null); // Set selectedRow to null to indicate "Create" mode
  };

  const createCustomDataGrid = (rows, columns) => {
    // Define the custom toolbar function here

    const CustomToolbar = () => {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />

          <GridToolbarFilterButton />

          <GridToolbarDensitySelector />

          <GridToolbarExport
              onClick={handleExport}
            csvOptions={{
              fileName: "CustomerList",

            }}
          />
        </GridToolbarContainer>
      );
    };

    return (
      <DataGridContainer>
        {/* {loading ? renderLoading(): */}

        <DataGrid
          className="payrollGrid"
          loading={loading}
          checkboxSelection={true}
          rows={rows || []}
          columns={columns}
          rowHeight={70}
          onRowClick={(params, e) => setModalData(params)}
          // disableRowSelectionOnClick
          disableSelectionOnClick

          // autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}

          filterMode="server"
          onFilterModelChange={(val)=>
            {
              // console.log("vvvv",val);
              setQueryOptions(val);
            onFilterChange(val)
          }
          }
          componentsProps={{
            toolbar: {
              // csvOptions: {
              //   fileName: `customersList`,
              // },
              exportButton: {
                onClick: handleExport,
                csvOptions: {

                  allColumns: true, // Export all columns
                  suppressTextAsCDATA: false, // Set to true if your data contains HTML content
                  
                },
                label: 'Export All', // Button label
              },
              
            },
          }}
          rowCount={rowCount}
        
          pageSizeOptions={[5]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </DataGridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <LoadingContainer>
        <CircularProgress color="primary" />
      </LoadingContainer>
    );
  };

  const renderTabContent = () => {
    return createCustomDataGrid(row, getCustomercolumns());
       
  };

  const onSubmitCustomer = async (form) => {
    if (selectedRow) form = { ...form, id: selectedRow.id };



    try {
      setSubLoading(true);

      if (!form.customerName || !form.customerCity || !form.customerMobile) {
        window.NotificationUtils.showWarning("Please enter All Fields");

        return;
      }

      // let sent_data={customerName: form.customerName, customerCity: form.customerCity, customerMobile:form.customerMobile}

      // let response_Data={customerDetails:sent_data}

      let response = await window.Platform.database.createNewCustomer(form);
      setShowModal(false);

      window.NotificationUtils.showSuccess("Customer created successfully")
      // console.log("creating activity...", response);
    } catch (error) {
      window.NotificationUtils.showError(error.message);
    } finally {
      

      setSubLoading(false);

      fetchdata();
    }
  };

  const renderModals = () => {
    return (
      <>
        {selectedRow && showModal && (
          <AddNewCustomer
            template={selectedRow}
            onSubmit={onSubmitCustomer}
            onCancel={() => setShowModal(false)}
            loading={subLoading}
            setLoading={setSubLoading}
          />
        )}

        {!selectedRow && showModal && (
          <AddNewCustomer
            template={null}
            onCancel={() => setShowModal(false)}
            onSubmit={onSubmitCustomer}
            loading={subLoading}
            setLoading={setSubLoading}
          />
        )}
        {
          bulk && <BulkCustomerAdd onClose={()=>setBulk(false)} fetchdata={()=>fetchdata()}/>
        }
      </>
    );
  };

  const renderHeader = () => {
    return (
      <Toolbar>
        <Typography variant="h5">Customer Manager</Typography>

        <Stack direction={"row"} spacing={2}>
        <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={()=>setBulk(true)}
          >
            Add Customer Bulk
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleAddCustomer}
          >
            Add Customer
          </Button>
        </Stack>
      </Toolbar>
    );
  };

  return (
    <>
      {isMobile && <Drawer props = {props} />}

      <ContentContainer>
        {renderHeader()}

        {renderTabContent()}

        {renderModals()}
      </ContentContainer>

      {/* <StaffEditorPageContainer>

 

        <HeaderContainer>

 

          <Typography variant="h5">Customer Manager</Typography>

 

        </HeaderContainer>

 

        <TableContainer>{renderTabContent()}</TableContainer>

 

        {renderModals()}

 

      </StaffEditorPageContainer> */}
    </>
  );
}
