import react, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import {
  Typography,
  Button,
  Checkbox,
  Fab,
  TextField,
  Autocomplete,
  IconButton,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR, PRIMARY_COLOR } from "../../constants";
import {
  Add,
  Delete,
  HelpOutline,
  Navigation,
  QuestionMark,
  Save,
  SaveAlt,
  Upload,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useCallback } from "react";
import AddModal from "../../components/modals/CustomerModal";
import AddSupplierModal from "../../components/modals/SupplierModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { grey } from "@mui/material/colors";
import moment from "moment";
import ItemModal from "../../components/modals/itemModal";
import QuotationRemarkModal from "../../components/modals/quotationRemarkModal";
import { useNavigate, useParams } from "react-router-dom";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import QuotationPackagingModal from "../../components/modals/QuotationPackagingModal";
import dayjs from "dayjs";

const isMobile = window.innerWidth < 900;
const TextFieldContainer = styled.div`
  // margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: ${isMobile ? "10px" : "5px"};
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ResponsiveTextField = styled(TextField)`
  // flex: 1;
  width: 250px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
const AutocompleteContainer = styled(Autocomplete)`
  width: 250px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const BodyRight = styled.div`
  display: ${!isMobile && "flex"};
  align-items: ${!isMobile && "center"};

  margin-bottom: ${!isMobile && "10px"};
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  padding-right: ${!isMobile && "10px"};
  text-align: ${!isMobile && "right"};
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const SmallDatePicker = styled(DatePicker)({
  "& .MuiInputBase-root": {
    fontSize: "14px", // Adjust the font size as needed
    height: "37px", // Adjust the height as needed
  },
  //  "& .MuiInputLabel-root": {
  //   transform: "translate(14px, 20px) scale(1)", // Adjust the positioning as needed
  // },
});
// const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
    position: relative;
    // top:56px;
    `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
  display:flex;
  align-items: center;
  gap: 10px;
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "64px - 20px - 50px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  overflow: auto;
`;

const BulkUploadButton = styled(Button)`
  margin-right: 10px !important;
`;

const TabsContainer = styled.div`
  height: 100%;
`;

// const StyledTabs = styled(Tabs)`
//   border-bottom: solid 1px lightGrey;
// `;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 350px;
  margin-bottom: 10px;
  && .highlighted-row {
    background-color: #FFCCCB !important;
  }
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function QuotationGenerate(props) {

 


  const [currentField, setCurrentField] = useState(0);
  const navigation = useNavigate()
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [customerChoosed, setCustomerChoosed] = useState(true);
  const [supplierChoosed, setSupplierChoosed] = useState(true);
  const [apiLoading, setApiLoading] = useState(false)
  const [supplierValue, setSupplierValue] = useState(null)
  const [itemValue, setItemValue] = useState(null)
  const [deliveryDate, setDeliveryDate] = useState(null)
  const [warehouseValue, setWarehouseValue] = useState(null)
  const [deliveryCity, setDeliveryCity] = useState(null)
  const [shippingMarkValue, setShippingMarkValue] = useState(null)
  const [unitValue, setUnitValue] = useState(null)
  const [customerValue, setCustomerValue] = useState(null)
  const [cityVal, setCityVal] = useState([]);
  const [wrongcheck, setwrongcheck] = useState(null);
const [packagingList, setPackagingList] = useState(null)
const [idResponseData, setIdResponseData] = useState(null)
const [quotationId, setQuotationId] = useState(null)

const getInviteId = () => {
 
  let queryParams = "";
  try {
    const url = new URL(window.location.href);
    queryParams = url.searchParams.get("quotationId"); // Get the value of the "warehouseId" parameter
  } catch (e) {
    console.error(e);
  }
  return queryParams;

}

// let id = getInviteId();
let id = getInviteId();
useEffect(() => {
  // console.log("daaate",moment().add(2, 'days').unix())
if(id){

 fetchData(id)
}

},[])
const fetchData = async(id) => {
// console.log("iddd",id)
setLoading(true)
  try{

    const response = await window.Platform.database.fetchEditQuotationById({id});
    console.log("RESPONSE",response)
    
    setIdResponseData(response);
    fetchSupplierVal(response.customerName, response.supplierName)
    fetchCities(response.deliveryCity)
    setCustomerChoosed(false)
    setSupplierChoosed(false)
    setShippingMarkValue(response.shippingMark)
    setQuotationId(response.quotationId)
    // setDeliveryCity(response.deliveryCity.id)
      fetchWarehouseVal(response.warehouseName)
      fetchItemVal(response.supplierName)

    // setItemChoosed(false)
    setCustomerValue(response.customerName)
    setAccessChanges(response.items)
    // setWarehouseValue(response.warehouseName)
    const deliveryDateValue = response.deliveryDate
    ?  new Date(response.deliveryDate * 1000) 
    : null;
    const formattedDate = deliveryDateValue.toISOString().split('T')[0];
    console.log("DELIVERY",formattedDate)
  setDeliveryDate(formattedDate);
    
    // setSupplierValue(response.supplierName)
    setPackagingList(response.packagingList)
    delete response.customer
    delete response.deliveryCity
    delete response.orderLineItem
    delete response.shippingOrders
    delete response.supplier
    setForm(response)
    // if(response)
    // setForm(response)
    // console.log("responseee", response)
    setLoading(false)
  }
  catch(error){
    console.error(error)
    setLoading(false)
  }
}

  const fetchCities = async (val) => {
    try {
      
      // setLoading(true);
      setApiLoading(true)
      let response = await window.Platform.database.fetchCities();
      setCityVal(response);
      // setLoading(false);
      // console.log("responseee", response)

      if(id)
     {
      // console.log("deliveryCityValue", val?.attributes)
      //  setDeliveryCity(val?.attributes)
      let city =response.find(res => res.id === val?.id)
      // console.log("cityyyy", city);
      setDeliveryCity(city)
      }
      setApiLoading(false)
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching cities");
      // setLoading(false);
      setApiLoading(false)
    }
  };

  useEffect(() => {
    fetchCities()
  }, [])
  // const [tex]
  const fields = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  // const volumeTextFieldRef = useRef(null);
  const [showCustomerFormModal, setShowCustomerFormModal] = useState();
  const [showSupplierFormModal, setShowSupplierFormModal] = useState();
  const [showItemFormModal, setShowItemFormModal] = useState();
  const [showQuotationFormModal, setShowQuotationFormModal] = useState();

  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date().toISOString().split("T")[0];
  const [itemVal, setItemVal] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalCtn, setTotalCtn] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);

  const [totalCbm, setTotalCbm] = useState(0);
  const [customerAlready, setCustomerAlready] = useState([]);
  const [supplierAlready, setSupplierAlready] = useState([]);
  const [warehouseAlready, setWarehouseAlready] = useState([]);

  const [itemAlready, setItemAlready] = useState([]);

  //packaging modal
  const [isPackagingModal, setPackagingModal] = useState(false);

  // const [tableVal,]
  // useEffect(()=>{
  //   console.log(form)
  // },[form])

  const checkForErrors = () => {
    // if(packagingList === null)
    // {
    //   window.NotificationUtils.showError("Upload the packaging list to proceed")
    //   return true
    // }
    if (fields[9].current.value?.length == 0) {
      window.NotificationUtils.showError("Choose Customer Name")
      fields[9].current.focus()

      return true
    }
    if (fields[10].current.value?.length == 0) {
      window.NotificationUtils.showError("Choose Supplier Name")
      fields[10].current.focus()
      return true
    }
    // if (fields[15].current.value?.length == 0) {
    //   window.NotificationUtils.showError("Enter the Shipping Mark")
    //   fields[15].current.focus()
    //   return true
    // }
    // if (!deliveryDate || isNaN(deliveryDate)) {
    //   window.NotificationUtils.showError("Enter the expected delivery date")
    //   fields[11].current.focus()
    //   return true
    // }
    if (fields[12].current.value?.length == 0) {
      window.NotificationUtils.showError("Enter the delivery place")
      fields[12].current.focus()
      return true
    }
    if (fields[16].current.value?.length == 0) {
      window.NotificationUtils.showError("Enter ID for the quotation")
      fields[16].current.focus()
      return true
    }
    if (fields[13].current.value?.length == 0) {
      window.NotificationUtils.showError("Choose Warehouse Name")
      fields[13].current.focus()
      return true
    }

    if (!accessChanges?.length) {
      window.NotificationUtils.showError("Add Items")
      fields[9].current.focus()
      return true
    }
    for (const row of accessChanges) {
      const unit = row.unitOfAmount;
      let amount;
      if (unit === "CTN") {
        amount = (+row.rate * +row.ctn)?.toFixed(2);
      } else if (unit === "Qty / Ctn") {
        amount = (+row.rate * (+row.qty / +row.ctn))?.toFixed(2);
      } else if (unit === "QTY") {
        amount = (+row.rate * +row.qty)?.toFixed(2);
      } else if (unit === "CBM") {
        amount = (+row.rate * +row.cbm)?.toFixed(2);
      }else if(unit==="WEIGHT (KGS)"){
        amount = (+row.rate * +row.weight)?.toFixed(2);
      }
       else {
        amount = "wrong selection";
      }
      if (amount === "wrong selection") {
        window.NotificationUtils.showError("Invalid item details ");
        return true;
      }
    }

    return false
  }

  const checkForItemError = () => {
    if (fields[0].current.value.length == 0) {
      window.NotificationUtils.showError("Choose Item Name")
      fields[0].current.focus()
      return 1
    }
    if (fields[1].current.value.length == 0) {
      window.NotificationUtils.showError("Enter valid Volume")
      fields[1].current.focus()
      return 1;
    }
    if (fields[2].current.value.length == 0) {
      window.NotificationUtils.showError("Enter valid QTY")
      fields[2].current.focus()
      return 1
    }
    // if(fields[3].current.value.length == 0)
    // {
    //   window.NotificationUtils.showError("Choose Item Category")
    //   fields[3].current.focus()
    //   return 1;

    // }
    if (fields[4].current.value.length == 0) {
      window.NotificationUtils.showError("Enter valid CTN")
      fields[4].current.focus()
      return 1;

    }
    if (fields[5].current.value.length == 0) {
      window.NotificationUtils.showError("Enter valid Weight")
      fields[5].current.focus()
      return 1;

    }
    if (fields[6].current.value.length == 0) {
      window.NotificationUtils.showError("Enter valid CBM")
      fields[6].current.focus()
      return 1;

    }
    if (fields[7].current.value.length == 0) {
      window.NotificationUtils.showError("Enter valid Rate")
      fields[7].current.focus()
      return 1;

    }
    if (fields[15].current.value.length == 0) {
      window.NotificationUtils.showError("Please enter the value in shipping mark field")
      fields[15].current.focus()
      return 1;

    }
    if (fields[8].current.value.length == 0) {
      window.NotificationUtils.showError("Choose Unit of Amount")
      fields[8].current.focus()
      return 1;
    

    }
    return 0;
  }

  const checkingForQtyCtn = (item) => {
    return +itemVal.qty / +itemVal.ctn;
  };
  const checkingForAmount = (item, value) => {

    let unit = value;
    let amountdata = 0;
    if (unit === "CTN") {
      amountdata = (+item.rate) * (+item.ctn).toFixed(2);
    } else if (unit === "Qty / Ctn") {
      amountdata = +item.rate * (+item.qty / +item.ctn).toFixed(2);
    } else if (unit === "QTY") {
      amountdata = (+item.rate * +item.qty).toFixed(2);
    } else if (unit === "CBM") {
      amountdata = (+item.rate * +item.cbm).toFixed(2);
    } else if (unit === "WEIGHT (KGS)") {
      amountdata = (+item.rate * +item.weight).toFixed(2);
    } else amountdata = -1;
    return amountdata;
  };
  const changeHandler = (fieldName, value) => {
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };
  const changeHandlerForItem = (fieldName, value) => {
    // console.log(value)
    setItemVal((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  // useEffect(()=>{

  // },[itemVal])
  useEffect(() => {
    let totalAm = 0;
    let totalWei = 0;
    let totalcbm = 0;
    let totalctn = 0;
    let totalqty = 0;
    let totalVolume = 0

    if (accessChanges.length) {
      accessChanges.forEach((val) => {

        totalAm += +val.amount;
        totalWei += +val.weight;
        totalcbm += +val.cbm;
        totalctn += +val.ctn;
        totalqty += +val.qty;
        totalVolume += +val.volume;
      });
    }
    setTotalVolume(totalVolume);
    setTotalAmount(totalAm.toFixed(2));
    setTotalWeight(totalWei);
    setTotalCbm(totalcbm);
    setTotalCtn(totalctn);
    setTotalQty(totalqty);
  }, [accessChanges]);

  

  useEffect(() => {
    fetchCustomerVal();
    fetchWarehouseVal();
  }, []);

  const fetchCustomerVal = async () => {
    try {
      setApiLoading(true)
      let results = await window.Platform.database.getCustomer();
      setCustomerAlready(results);


    }
    catch (error) {
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    }
    finally {
      setApiLoading(false)

    }
  };
  const fetchWarehouseVal = async (val) => {
    try {
      setApiLoading(true)

      let results = await window.Platform.database.fetchWarehouses();
      // console.log("FETCHED WAREHOUSE",results);
      let warehouseNames = results
  .filter((result) => result.warehouseCountry === "China")
  .map((result) => result.name);
  // console.log("warehouseNames",warehouseNames)
      setWarehouseAlready(warehouseNames);
if(id)
{
  console.log("warehouseValue", warehouseNames)
setWarehouseValue(val)
}
    }
    catch (error) {
      console.error(error)
      // window.NotificationUtils.showError(error)
    }
    finally {

      setApiLoading(false)

    }
  };
  const closePackaging = () =>{
    setPackagingModal(null)
  }
  const fetchSupplierVal = async (val, supplierName) => {
    try {
      setApiLoading(true)

      let result = await window.Platform.database.getSupplier(val);
      // console.log(result);
      setSupplierAlready(result);

      if(supplierName)
      setSupplierValue(supplierName)
      return
    }
    catch (error) {
      // window.NotificationUtils.showError(error)
      console.error(error)
    }
    finally {
      setApiLoading(false)

    }
    // console.log(results)
  };
  const fetchItemVal = async (value) => {
    try {
      setApiLoading(true)

      let results = await window.Platform.database.getItem(value);
      setItemAlready(results);
    }
    catch (error) {
      // window.NotificationUtils.showError(error)
      console.error(error)
    }
    finally {
      setApiLoading(false)

    }
  };

  const handleKeyPress = (val) => {
    const value = val.target;
  };

  const onclicksubmit = () => {

    const errorGot = checkForErrors();
    if (!errorGot)
      setShowQuotationFormModal(" ");

  };
  const handleDeleteEmploye = async (id) => {
    // const response = await window.Platform.database.deleteUsers(ID);

    let res = accessChanges.filter((el) => {
      return el.id != id;
    });

    setAccessChanges(res);
  };
  const rows = [
    // {
    //   id:1,
    //   srNo: 1,
    //   volume: 1500,
    //   item: 'lcd',
    //   category: "Sensitive",
    //   qty: 500,
    //   ctn: 20,
    //   // qty: 10000,
    //   weight: 300,
    //   cbm: 5,
    //   unitOfAmount: "CTN",
    //   rate: 5,
    //   amount: 4000
    // },
  ];

  useEffect(() => {
    setAccessChanges(rows);
  }, []);
  useEffect(() => {
  }, [accessChanges]);
  const dropdownOptions = ["Option 1", "Option 2", "Option 3"];
  const options = [

    { value: "Regular", label: "Regular" },
    { value: "Managable", label: "Managable" },
    { value: "Special", label: "Special" },
  ];

  const units = [
    { value: "Qty / Ctn", label: "Qty / Ctn" },
    { value: "CTN", label: "CTN" },
    { value: "QTY", label: "QTY" },
    { value: "WEIGHT (KGS)", label: "WEIGHT (KGS)" },
    { value: "CBM", label: "CBM" },
  ];

  const columns = () => {
    let result = [
      // {
      //   field: "srNo",
      //   headerName: "SR No.",
      //   sortable: false,
      //   width: 150,

      //   // editable: true,
      // },
      // {
      //   field: "id",
      //   headerName: "Item ID",
      //   sortable: false,
      //   width: 150,

      // },
      {
        field: "item",
        headerName: "Item",
        sortable: false,
        width: 150,
        
      },
      {
        field: "shippingMark",
        headerName: "Shipping Mark",
        sortable: false,
        width: 150,

        // editable: true,
      },
      {
        field: "volume",
        headerName: "Volume",
        sortable: false,
        width: 150,

        editable: true,
      },
      {
        field: "category",
        headerName: "Goods Category",
        sortable: false,
        width: 150,
        // renderCell: (params) => (
        //   <Select
        //   variant="standard"
        //     defaultValue={params.value}
        //     onChange={(e,value) => {
        //       console.log(e.target.value)
        //       handleEditUnitCellChange(params, e)
        //       params.api.setEditCellValue({
        //         id: params.id,
        //         field: params.field,
        //         value: e.target.value,
        //       });
        //     }}
        //   >
        //     <MenuItem value="Regular">Regular</MenuItem>
        //     <MenuItem value="Managable">Managable</MenuItem>
        //     <MenuItem value="Special">Special</MenuItem>


        //   </Select>
        // ),
        // editable: true,
      },
      {
        field: "qtyCtn",
        headerName: "Qty/ Ctn",
        sortable: false,
        width: 150,
        valueGetter: (params) => (+params.row.qty / +params.row.ctn).toFixed(2) || "N",

        // },
        // editable: true,

      },
      {
        field: "ctn",
        headerName: "CTN",
        sortable: false,
        width: 150,
        editable: true,
        onCellEditCommit: (params) => {

          // The value that the user edited and committed
          const newValue = params.value;
          return newValue
        }
      },
      {
        field: "qty",
        headerName: "QTY",
        sortable: false,
        width: 150,

        editable: true,
      },
      {
        field: "weight",
        headerName: "Weight(kgs)",
        sortable: false,
        width: 150,

        editable: true,
      },
      {
        field: "cbm",
        headerName: "CBM",
        sortable: false,
        width: 150,

        editable: true,
      },
      {
        field: "unitOfAmount",
        headerName: "Dropdown for unit",
        width: 200,
        editable: true,
        renderCell: (params) => (
          <Select
            variant="standard"
            defaultValue={params.value}
            onChange={(e, value) => {
              handleEditUnitCellChange(params, e)
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          >
            <MenuItem value="CTN">CTN</MenuItem>
            <MenuItem value="CBM">CBM</MenuItem>
            <MenuItem value="QTY">QTY</MenuItem>
            <MenuItem value="WEIGHT (KGS)">WEIGHT (KGS)</MenuItem>
            <MenuItem value="Qty / Ctn">Qty / Ctn</MenuItem>


          </Select>
        ),
        // renderCell: (params) => (
        //   <select
        //     value={params.value}
        //     onChange={(e) => {
        //       params.api.setEditCellValue({ id: params.id, field: params.field, value: e.target.value });
        //     }}
        //   >
        //     {dropdownOptions.map((option) => (
        //       <option key={option} value={option}>
        //         {option}
        //       </option>
        //     ))}
        //   </select>
        // ),
        // The following function is called when the cell editing is committed
        // You can handle the value update as per your requirements
        // In this example, we are just logging the updated value
        // onCellEditCommit: (params: GridCellEditCommitParams) => {
        //   console.log(`New value for ${params.field} column: ${params.value}`);
        // },
      },
      {
        field: "rate",
        headerName: "Rate",
        sortable: false,
        width: 150,

        // editable: true,
      },
    
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        width: 150,
        renderCell: (params) => {
          const unit = params.row.unitOfAmount;
          let amount;
          if (unit === "CTN") {
            amount = (+params.row.rate * +params.row.ctn)?.toFixed(2);
          } else if (unit === "Qty / Ctn") {
            amount = (+params.row.rate * (+params.row.qty / +params.row.ctn))?.toFixed(2);
          } else if (unit === "QTY") {
            amount = (+params.row.rate * +params.row.qty)?.toFixed(2);
          } else if (unit === "CBM") {
            amount = (+params.row.rate * +params.row.cbm)?.toFixed(2);

          } 
          else if (unit === "WEIGHT (KGS)") {
            amount = (+params.row.rate * +params.row.weight)?.toFixed(2);
          }else {
            amount = "wrong selection";
          }
// return amount
          return (
            <div style={{ color: amount === "wrong selection" ? "red" : ""}}>
              {amount}
            </div>
          );


          // if()
          // return +params.row.qty / +params.row.qtyctn
        },
      },
    ];

    result.push({
      field: "  ",
      headerName: "Actions",
      editable: false,
      minWidth: 100,
      // disableColumnMenu:true,
      // disableExport:true,
      // disableReorder:true,
      // hideSortIcons:true,
      renderCell: (params) => {
        return (
          // <PinCodeChangeButtonContainer>
          <div style={{ margin: "0 auto", width: "auto" }}>
            <Tooltip title="Delete">
              <IconButton onClick={() => handleDeleteEmploye(params.row.id)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </div>

          // </PinCodeChangeButtonContainer>
        );
      },
    });

    return result;
  };

  const getRowClassName = (params) => {
    const unit = params.row.unitOfAmount;
    let amount;
    if (unit === "CTN") {
      amount = (+params.row.rate * +params.row.ctn)?.toFixed(2);
    } else if (unit === "Qty / Ctn") {
      amount = (+params.row.rate * (+params.row.qty / +params.row.ctn))?.toFixed(2);
    } else if (unit === "QTY") {
      amount = (+params.row.rate * +params.row.qty)?.toFixed(2);
    } else if (unit === "CBM") {
      amount = (+params.row.rate * +params.row.cbm)?.toFixed(2);
    }
    else if (unit === "WEIGHT (KGS)") {
      amount = (+params.row.rate * +params.row.weight)?.toFixed(2);
    } 
    else {
      amount = "wrong selection";
    }

    if (amount === "wrong selection") {
      return "highlighted-row";
    }

    return "";
  };



  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />

      </GridToolbarContainer>
    );
  }

  const updateCustomerData = async (value) => {
    try {
      setLoading(true);

      let results = await window.Platform.database.createNewCustomer(value);
      window.NotificationUtils.showSuccess("Customer added to the system");
      fetchCustomerVal();
    } catch (error) {
      
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
      console.error(error)
    } finally {
      setLoading(false);
      setShowCustomerFormModal(null);
    }
  };

  const updateSupplierData = async (value) => {
    try {
      setLoading(true);
      let results = await window.Platform.database.createNewSupplier(value);
      window.NotificationUtils.showSuccess("Supplier added to the system");
      fetchSupplierVal(value.customerName)
    } catch (error) {
      console.log(error);
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    } finally {
      setLoading(false);
      setShowSupplierFormModal(null)
    }
  };

  const updateItemData = async (value) => {
    try {
      setLoading(true);

      let results = await window.Platform.database.createNewItem(value);
      window.NotificationUtils.showSuccess("Item added to the system");
      fetchItemVal(value.supplierName)
    } catch (error) {
      console.log(error);
      window.NotificationUtils.showError("Something went wrong");
      console.log(error.message)
    } finally {
      setLoading(false);
      setShowItemFormModal(null)
    }
  };

  // console.log("ACCESSCHANGESS",accessChanges)
  const updateQuotationData = async (value) => {
    let val = {
      ...form,
      ...value,
      
      items: accessChanges,
      totalAmount:+totalAmount,
      totalCbm,
      totalWeight,
      totalVolume,
      packagingList: packagingList
    };
console.log("VALLL",val)
    

    try {
      if(!id)
      {
        if(val.typeOfShipment === 'Air')
    {
      val.deliveryDate = moment().add(3, 'days').unix();
    }
    else if(val.typeOfShipment === 'Sea')
    {val.deliveryDate = moment().add(30, 'days').unix();
  }
      setLoading(true)
      let results = await window.Platform.database.createNewQuotation(val);
      setShowQuotationFormModal(null)
      window.NotificationUtils.showSuccess("Quotation Generated Successfully")
      setSupplierValue(null)
      setItemValue(null)
      setDeliveryCity(null)
      setDeliveryDate(null)
      setWarehouseValue(null)
      setPackagingList(null)
      // setCategoryValue(null)
      setUnitValue(null)
      setAccessChanges([])
      setShippingMarkValue(null)
      setCustomerValue(null)
      fields[1].current.value = null
      fields[2].current.value = null

      fields[4].current.value = null
      fields[5].current.value = null
      fields[6].current.value = null
      fields[7].current.value = null
      fields[8].current.value = null
      fields[0].current.value = null
      fields[9].current.value = null

      fields[10].current.value = null
      // fields[11].current.value = null
      fields[12].current.value = null
      fields[13].current.value = null
      fields[14].current.value = null
      fields[15].current.value = null
      fields[16].current.value = null
      setQuotationId(null)
      }
      else{
        // val = {...val, id}
        console.log("VALUEEE",val)
        const myDate = new Date(deliveryDate);
        const timestamp = myDate.getTime();
        const timestampInSeconds = Math.floor(timestamp / 1000);
      
          let sent_data={
            ...form,
            typeOfShipment:val.typeOfShipment,
            deliveryPlace:val.deliveryPlace,
            remark: val.remark,
             id:id,
             customerId:val.customerId,
             customerName: val.customerName,
deliveryCityId: val.deliveryCityId,
deliveryDate: timestampInSeconds,
supplierId: val.supplierId,
supplierName:val.supplierName,
warehouseName:val.warehouseName,

            items: accessChanges,
            totalAmount:+totalAmount,
            totalCbm,
            totalWeight,
            totalVolume,
            packagingList: packagingList
          }        
       
console.log("VALCHECK",sent_data)
        setLoading(true)



        let results = await window.Platform.database.editQuotation(sent_data);
        // console.log("vvvv",val)
        setShowQuotationFormModal(null)
        window.NotificationUtils.showSuccess("Quotation Edited Successfully")
        setSupplierValue(null)
        setItemValue(null)
        setDeliveryDate(null)
        setDeliveryCity(null)
        // setDeliveryDate(null)
        setWarehouseValue(null)
        // setCategoryValue(null)
        setUnitValue(null)
        setAccessChanges([])
        setCustomerValue(null)
        setPackagingList(null)
        fields[1].current.value = null
        fields[2].current.value = null
  
        fields[4].current.value = null
        fields[5].current.value = null
        fields[6].current.value = null
        fields[7].current.value = null
        fields[8].current.value = null
        fields[0].current.value = null
        fields[9].current.value = null
  
        fields[10].current.value = null
        // fields[11].current.value = null
        fields[12].current.value = null
        fields[13].current.value = null
        fields[14].current.value = null
        fields[16].current.value = null
      setQuotationId(null)


      }
    }
    catch (error) {
      // console.log();
      if(error.message==='Quotation with the same ID already exists')
      window.NotificationUtils.showError(error.message)
else
      window.NotificationUtils.showError("Something went wrong");
      // console.log(error.message)
    }
    finally {
      setLoading(false)
    }
  };

  const handleEditUnitCellChange = (params, event) => {
    let amountCheck = params.row.amount;

    const updatedChanges = accessChanges.map((row) => {
      if (row.id === params.row.id) {
        if (["unitOfAmount"].includes(params.field)) {

          amountCheck = checkingForAmount(
            row, event.target.value,
          );

        }
        return {
          ...row,
          [params.field]: event.target.value,
          amount: amountCheck,
        };
      }
      return row;
    });

    setAccessChanges(updatedChanges);
  };

  // console.log("FORMMMMMMM",form)

  const handleEditCellChange = (params, event) => {
    let amountCheck = params.row.amount;


    const updatedChanges = accessChanges.map((row) => {
      if (row.id === params.row.id) {
        if (["ctn", "qty", "weight", "cbm", "qtyCtn"].includes(params.field)) {
          amountCheck = checkingForAmount(
            { ...row, [params.field]: event.target?.value || params.row[params.field]},
            params.row.unitOfAmount
          );
        }
        return {
          ...row,
          [params.field]: event.target?.value || params.row[params.field] ,
          amount: amountCheck,
        };
      }
      return row;
    });
    setAccessChanges(updatedChanges);
  };

  const dataGridContainerStyles = {
    maxWidth: '100%',
    overflowX: 'auto',
  };

  // Inline styles for responsive adjustments (optional)
  const responsiveStyles = {
    '@media (max-width: 768px)': {
      fontSize: '12px',
      rowHeight: '40px',
    },
  };

  // const DataGridRow=()=>{
  //   if(wrongcheck){
  //     return  window.NotificationUtils.showError("Error : choose  correct fieled");
  //     ;
  //   }
  //   else{
  //     return accessChanges;
  //   }
  // }

  const renderContent = () => {
    return (
      <DataGridContainer style={dataGridContainerStyles}>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={accessChanges || []}
          columns={columns()}
          rowHeight={50}
          disableSelectionOnClick
          disableRowSelectionOnClick
          onCellEditStop={handleEditCellChange}
          autoPageSize
          getRowClassName={getRowClassName}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
};

  return (
    <>
      {isMobile && <Drawer props={props}/>}
      <StaffEditorPageContainer>

        {
          isPackagingModal?<QuotationPackagingModal packagingList={packagingList}
          setPackagingList={setPackagingList} onCancel={closePackaging}
          />:null
        }
        
        <HeaderContainer>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <Typography variant="h5">Quotation Generation</Typography>
            <Tooltip title="Pending Quotation(s)">
              <IconButton color="primary" onClick={() => navigation('/quotationcustomerapproval')}>
                <PendingActionsIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            {loading && <OpaqueLoading />}
            {/* <FabStyle> */}
          { !packagingList ? <FabStyle variant="extended" color="primary"
            onClick={()=> setPackagingModal(!isPackagingModal)}
            
            //  onClick={}
            >
              <Upload sx={{ mr: 1 }} />
              Upload PL
            </FabStyle> : 
            <>
            <FabStyle variant="extended" color="primary"
            onClick={()=> setPackagingModal(!isPackagingModal)}
            //  onClick={}
            >
              <Add sx={{ mr: 1 }} />
             Re-Upload Packaging
            </FabStyle>
            </>

            }

            {/*  */}
            {/* </FabStyle> */}
            {/* )} */}
          </div>
        </HeaderContainer>


        {/* /////////////////// */}

        <TextFieldContainer>
          <div style={{ display: "flex" }}>
            <AutocompleteContainer
              options={customerAlready || "Search matching"}
              noOptionsText={apiLoading ? 'Loading...' : 'No option'}
              disableClearable
              forcePopupIcon={false}
              value={customerValue || ''}
              // size="small"
              onChange={(e, value) => {
                fetchSupplierVal(value?.value);
                changeHandler("customerName", value?.value);
                changeHandler("customerId", customerAlready?.filter((val) => val.value == value?.value)[0]?.id);
                setCustomerValue(value?.value)
                setCustomerChoosed(false);
                setSupplierChoosed(false)
                // fields[10].current.value =null

                setSupplierValue(null)
                setItemValue(null)
                setDeliveryDate(null)
                setWarehouseValue(null)
                setSupplierChoosed(true)
                fields[12].current.value = null

                fields[10].current.value = 0
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = customerAlready.find((option) => option.value === value);

                if (!matchingOption && !id) {
                  // If there's no matching option, set the otherValue to null
                  setCustomerValue(null);
                }
              }}
              renderInput={(params) => (
                <ResponsiveTextField
                  // size="small"
                  // inputRef={fields[8]}
                  required
                  {...params}
                  label="Choose Customer"
                  variant="outlined"

                  // inputRef={params.InputProps.ref}
                  inputRef={fields[9]}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      //   // e.preventDefault();
                      // e.preventDefault();
                      // handleKeyPress(e)
                      if (e.target.value) {
                        fetchSupplierVal(e.target.value);
                        changeHandler("customerName", e.target.value);
                        changeHandler("customerId", customerAlready?.filter((val) => val.value == e.target.value)[0]?.id);
                        // console.log("customerId", customerAlready.filter(( val)=> val.value == e.target.value)[0].id)
                        setCustomerChoosed(false);
                        setSupplierValue(null)
                        setSupplierChoosed(true)
                        setItemValue(null)
                        setDeliveryDate(null)
                        setWarehouseValue(null)
                        fields[12].current.value = null
                        // console.log(params.inputProps.ref.current.value)
                        // changeHandlerForItem("item", e.target.value);
                        // console.log(fields[0].current.value)
                        fields[10].current.focus();
                      }
                      // setCurrent\F\ield(currentField + 1);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          // disabled={!customerChoosed}
                          onClick={() => setShowCustomerFormModal(" ")}
                          style={{
                            borderRadius: "4px",
                            backgroundColor: PRIMARY_COLOR,
                            color: "white",
                            margin: "-8px", // Adjust margin to align the background color
                            padding: "15px", // Adjust padding to align the background color
                            fontSize: "50px",
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Tooltip title="Choose Customer from the dropdown, if not use the add button to create a new customer">
              <IconButton
                onClick={() =>
                  window.NotificationUtils.showInfo(
                    "Choose Customer from the dropdown, if not use the add button to create new customer"
                  )
                }
                sx={{

                  '&:hover': {

                    backgroundColor: 'transparent',

                  },

                }}

              >
                <HelpOutline sx={{ fontSize: 22 }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ display: "flex" }}>
            <AutocompleteContainer
              // sx={{ width: 190 }}
              // options={options}
              options={supplierAlready}
              noOptionsText={apiLoading ? 'Loading...' : 'No option'}
              disableClearable
              disabled={customerChoosed}
              value={supplierValue || ''}
              // size="small"
              // onChange={(e, value) => {
              //   // changeHandler("supplierName", e.target.value)
              //   const selectedValueExists = supplierAlready.some(
              //     (option) => option.value === value?.value
              //   );
              //   setSupplierValue(value?.value)
              //   fetchItemVal(value?.value);
              //   changeHandler("supplierName", value?.value);
              //   changeHandler("supplierId", supplierAlready.filter((val)=> val.value == value?.value)[0]?.id);

              //   setSupplierChoosed(false);
              //   // setItemValue(null)
              //   setDeliveryDate(null)
              // }}
              onChange={(e, value) => {
                
                setSupplierValue(value?.value || null);
                if (value) {
                  fetchItemVal(value?.value);
                  changeHandler("supplierName", value?.value);
                  changeHandler(
                    "supplierId",
                    supplierAlready.filter((val) => val.value === value?.value)[0]?.id
                  );
                  setSupplierChoosed(false);
                  setDeliveryDate(null);
                }
                // other actions...
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = supplierAlready.find(
                  (option) => option.value === value
                );

                if (!matchingOption && !id) {
                  // If there's no matching option, set the supplierValue to null
                  setSupplierValue(null);
                }
              }}
              // inputRef={fields[10]}
              forcePopupIcon={false}
              renderInput={(params) => (
                <ResponsiveTextField
                  required
                  {...params}
                  // sx={{width: 190}}
                  label="Choose Supplier"
                  variant="outlined"

                  inputRef={fields[10]}
                  // onChange={(e,value) => {
                  //   console.log(e.target.value);
                  // }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      // e.preventDefault();
                      // e.preventDefault();
                      // handleKeyPress(e)
                      if (e.target.value) {
                        changeHandler("supplierId", supplierAlready?.filter((val) => val.value == e.target.value)[0]?.id);

                        setSupplierValue(e.target?.value)
                        fetchItemVal(e.target?.value);
                        changeHandler("supplierName", e.target?.value);
                        setSupplierChoosed(false);
                        setDeliveryDate(null)
                        setItemValue(null)
                        // console.log(params.inputProps.ref.current.value)
                        // changeHandlerForItem("item", e.target.value);
                        // console.log(fields[0].current.value)
                        fields[12].current.focus();
                      }
                      // setCurrent\F\ield(currentField + 1);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={customerChoosed}
                          //  style= {{color}}
                          onClick={() => {
                            setShowSupplierFormModal(" ");
                          }}
                          style={{
                            borderRadius: "4px",
                            backgroundColor: customerChoosed
                              ? grey
                              : PRIMARY_COLOR,
                            color: "white",
                            margin: "-8px", // Adjust margin to align the background color
                            padding: "15px", // Adjust padding to align the background color
                            fontSize: "50px",
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Tooltip title="Choose Supplier from the dropdown, if not use the add button to create new supplier for this customer">
              <IconButton
                onClick={() =>
                  isMobile &&
                  window.NotificationUtils.showInfo(
                    "Choose Supplier from the dropdown, if not use the add button to create new supplier for this customer"
                  )
                }
                sx={{

                  '&:hover': {

                    backgroundColor: 'transparent',

                  },

                }}
              >
                <HelpOutline sx={{ fontSize: 22 }} />
              </IconButton>
            </Tooltip>
          </div>
          {id&&<div >
            
          <ResponsiveTextField
          type="date"
          label="Expected Delivery Date"
          size="medium"
          // type="date"
          // fullWidth
        
          value={deliveryDate||''}
          InputProps={{
            style: { height: '53px' }, // Adjust the height as needed
          }}
          // onChange={handleChange}
          onChange={(e) => {
            console.log("moment",e.target.value)
            setDeliveryDate(e.target.value);
            // changeHandler("deliveryDate", newValue?.valueOf());
          }}
          InputLabelProps={{
            shrink: true,
          
          }}
          inputProps={{
            min: today,
            style: { height: '50px' },
          }}
          required
          
          />
          </div>}
          {/* <div style={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                value={deliveryDate || "" }
                // defaultValue={deliveryDate }
                // sx={{ width: "100%" }}
                
                // renderInput={(props) => <TextField {...props} fullWidth />}
                InputLabelProps={{ shrink: true }}
                disablePast
                label="Expected Delivery Date*"
                // onChange={(newValue) => changeHandler("deliveryDate", newValue)}
                onChange={(newValue) => {
                  // console.log("moment",newValue)
                  setDeliveryDate(newValue);
                  changeHandler("deliveryDate", newValue?.valueOf());
                }}
                // inputRef={fields[11]}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value) {
                      e.preventDefault();
                      changeHandler("deliveryDate", e.target.value);
                      // fields[12].current.focus();
                    }
                  }
                }}
              />

            </LocalizationProvider>
            </div> */}

          {/* <div style={{ display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY/MM/DD"
                value={deliveryDate || ''}
                sx={{ width: "100%" }}
                
                // renderInput={(props) => <TextField {...props} fullWidth />}
                InputLabelProps={{ shrink: true }}
                disablePast
                label="Expected Delivery Date*"
                // onChange={(newValue) => changeHandler("deliveryDate", newValue)}
                onChange={(newValue) => {
                  // console.log("moment",newValue)
                  setDeliveryDate(newValue);
                  changeHandler("deliveryDate", newValue?.valueOf());
                }}
                inputRef={fields[11]}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value) {
                      e.preventDefault();
                      changeHandler("deliveryDate", e.target.value);
                      fields[12].current.focus();
                    }
                  }
                }}
              />

            </LocalizationProvider>
            <Tooltip title="Enter the delivery date">
              <IconButton
                onClick={() =>
                  isMobile &&
                  window.NotificationUtils.showInfo(
                    "Enter the expected delivery date"
                  )
                }
                sx={{

                  '&:hover': {

                    backgroundColor: 'transparent',

                  },

                }}
              >
                <HelpOutline sx={{ fontSize: 22 }} />
              </IconButton>
            </Tooltip>
           
          </div> */}
          <div style={{ display: "flex" }}>
            {/* <TextField
              fullWidth
              defaultValue={null}
              onChange={(e) => changeHandler("deliveryPlace", e.target.value)}
              inputRef={fields[12]}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  if(e.target.value)
                  {
                  console.log(e.target.value);
                  changeHandler("deliveryPlace", e.target.value);
                  fields[13].current.focus();
                  }
                }
              }}
              variant="outlined"
              label="Delivery Place"
            /> */}

            {/* <FormControl fullWidth variant="outlined">
  <InputLabel id="cityLabel">Delivery City</InputLabel>
  <Select
  fullWidth
  style={{ width: !isMobile && "200px" }}
    labelId="cityLabel"
    inputRef={fields[12]}
    noOptionsText= 'loading...'
    id="citySelect"
    // value={district.id || ""}
    // value={cityVal || ""}
    label="Delivery City"
    // disabled={loading}
    onChange={(e) => changeHandler("deliveryCityId", e.target.value)}
    onKeyUp={(e) => {
      if (e.key === "Enter") {
        if(e.target.value)
        {
        console.log(e.target.value);
        changeHandler("deliveryCityId", e.target.value);
        fields[13].current.focus();
        }
      }
    }}
  >
    {cityVal.map((cityEntry) => (
      <MenuItem value={cityEntry?.id} key={cityEntry?.id}>
        {cityEntry?.cityName} ({cityEntry?.state?.stateName})
      </MenuItem>
    ))}
  </Select>
</FormControl> */}
            {/* <AutocompleteContainer
              value ={deliveryCity || null}
              options={cityVal}
              getOptionLabel={(option) => `${option?.cityName} (${option?.state?.stateName})`}
              getOptionValue={(option) => option?.id}
              style={{ width: isMobile ? "90%": "190px" }}
              noOptionsText= {apiLoading? 'Loading...': 'No option'}
              // size="small"
              onChange={(e,value) => {
console.log(value?.id)
const selectedCity = cityVal.find((city) => city?.id === value);
changeHandler("deliveryCityId", selectedCity?.id);
setDeliveryCity(selectedCity);
                fields[13].current.focus();

              
              }
              }
              renderInput={(params) => (
                <ResponsiveTextField

                  style={{ width: isMobile && "100%" }}
                  {...params}
                  label="Delivery City"
                  
                  variant="outlined"
                  inputRef={fields[12]}
                  onKeyDown={(e,v) => {
                    if (e.key === "Enter") {
                      // console.log(e)
                      // if(e.target.value)
                      // {
                      // console.log(v);
                      // changeHandler("deliveryCityId", e.target.value);
                      // setDeliveryCity(e.target.value || null)
                      // }
                    }
                  }}
                  required
                />
              )}
            /> */}
            <Autocomplete
              // value={cityVal.find((city) => city.id === deliveryCity) || ''}
              value={deliveryCity || null}
              options={cityVal}
              getOptionLabel={(option) => `${option?.cityName} (${option?.state?.stateName})`}
              getOptionValue={(option) => option?.id}
              style={{ width: isMobile ? "90%" : "190px" }}
              noOptionsText={apiLoading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                const selectedCity = cityVal.find((city) => city?.id === value?.id);
                // console.log("selectedCity",selectedCity)
                changeHandler("deliveryCityId", selectedCity?.id || null);
                setDeliveryCity(selectedCity || null);
                fields[16].current.focus();
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = cityVal.find((option) => option.value === value);

                if (!matchingOption && !id) {
                  // If there's no matching option, set the otherValue to null
                  setDeliveryCity(null);
                }
              }}
              renderInput={(params) => (
                <ResponsiveTextField
                  {...params}
                  label="Delivery City"
                  variant="outlined"
                  inputRef={fields[12]}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (e.target.value) {
                        const selectedCity = cityVal.find((city) => city?.id === e.target.value);
                        changeHandler("deliveryCityId", selectedCity?.id || null);
                        setDeliveryCity(selectedCity || null);
                        fields[16].current.focus();
                      }
                    }
                  }}
                  required
                />
              )}
            />

            <Tooltip title="Enter the delivery City details">
              <IconButton
                onClick={() =>
                  isMobile &&
                  window.NotificationUtils.showInfo(
                    "Enter the expected delivery City"
                  )
                }
                sx={{

                  '&:hover': {

                    backgroundColor: 'transparent',

                  },

                }}
              >
                <HelpOutline sx={{ fontSize: 22 }} />
              </IconButton>
            </Tooltip>
          </div>

          <div style={{ display: "flex" }}>
          <TextField
            // size="small"
            value={quotationId || ''}

            label="ID"
            style={{ width: !isMobile ? "auto" : "90%" }}
            defaultValue={null}
            required
            // disabled={supplierChoosed}
            // type="number"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (e.target.value) {
                  e.preventDefault();
                  fields[13].current.focus();
                }
                // setCurrentField(currentField + 1);
              }
            }}
            inputRef={fields[16]}
            onChange={(e, value) =>  {
              console.log("quotationId", e.target.value);
              changeHandler("quotationId", e.target.value);
              setQuotationId(e.target.value)
            }}
          />

          </div>
          <div style={{ display: "flex" }}>
            <AutocompleteContainer
              value={warehouseValue || ''}
              options={warehouseAlready}
              style={{ width: isMobile && "90%" }}
              noOptionsText={apiLoading ? 'Loading...' : 'No option'}
              // size="small"
              onChange={(e, value) => {
                setWarehouseValue(value)
                changeHandler("warehouseName", value)

              }
              }
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = warehouseAlready.find((option) => option.value === value);

                if (!matchingOption && !id) {
                  // If there's no matching option, set the otherValue to null
                  setWarehouseValue(null);
                }
              }}
              renderInput={(params) => (
                <ResponsiveTextField

                  style={{ width: isMobile && "100%" }}
                  {...params}
                  label="Choose warehouse"

                  variant="outlined"
                  inputRef={fields[13]}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      if (e.target.value) {
                        //   // e.preventDefault();
                        // e.preventDefault();
                        // handleKeyPress(e)
                        setWarehouseValue(e.target.value)
                        changeHandler("warehouseName", e.target.value);
                        setWarehouseValue(e.target.value)
                        // console.log(params.inputProps.ref.current.value)
                        // changeHandlerForItem("item", e.target.value);
                        // console.log(fields[0].current.value)
                        fields[0].current.focus();
                      }
                      // setCurrent\F\ield(currentField + 1);
                    }
                  }}
                  required
                />
              )}
            />
            
            {/* <Tooltip title="Choose the initial warehouse"
              >
            <IconButton
  onClick={() => isMobile && window.NotificationUtils.showInfo("Choose the initial warehouse")}
  
>

  <HelpOutline sx={{ fontSize: 22 }} />
</IconButton>
  </Tooltip> */}
          </div>
        </TextFieldContainer>

        {/* <Grid
          container
          spacing={5}
          style={{ paddingLeft: "10px", paddingBottom: "20px" }}
        > */}
        <TextFieldContainer>
          {/* <Grid item xs={12} sm={12}> */}
          {/* <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: "10px",
                paddingRight: "10px",
              }}
            > */}
          {/* <AutocompleteContainer
                // size="small"
                options={options}
                
                // style={{ width: !isMobile?'200px':'90%' }}
                // onChange={(e)=>{
                //   console.log(e.target.)
                
                // }}
                disabled={supplierChoosed}
                renderInput={(params) => (
                  <ResponsiveTextField {...params} label="Items" variant="outlined" required
                  // ref={fields[0]}
                  // value={}
                  inputRef={fields[0]}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton 
                           disabled= {customerChoosed}
                          //  style= {{color}}
                         onClick={()=> {
                          console.log("form",form)
                          setShowSupplierFormModal(' ')
                         }}
                        style={{borderRadius: '4px', 
          backgroundColor: customerChoosed?grey:PRIMARY_COLOR,
          color: 'white',
          margin: '-25px', // Adjust margin to align the background color
          padding: '15px', // Adjust padding to align the background color
          fontSize: '50px',}}>
                          <AddIcon  />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                     
                
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                  //   // e.preventDefault();
                    // e.preventDefault();
                    // handleKeyPress(e)
                    
                    console.log(e.target.value)
                    // console.log(params.inputProps.ref.current.value)
                    changeHandlerForItem("item", e.target.value); 
                    // console.log(fields[0].current.value)
                    fields[1].current.focus();
                    // setCurrent\F\ield(currentField + 1);
                  }
                }}
                  />
                  
                )}
              />*/}

          <Autocomplete
            style={{ width: !isMobile ? "200px" : "90%" }}
            disabled={supplierChoosed}
            options={itemAlready.map((val) => val.value)}
            noOptionsText={apiLoading ? 'Loading...' : 'No option'}
            //  noOptionsText= "Loading..."
            disableClearable
            value={itemValue || ''}
            forcePopupIcon={false}
            // size="small"
            onChange={(e, value) => {
              changeHandlerForItem("item", value);
              setItemValue(value)
              // console.log("itttt",itemAlready.filter((val)=> val.value == value?.value)[0].id);
              // changeHandlerForItem("id", itemAlready.filter((val)=> val.value == value)[0]?.id);
              let idNCategory = itemAlready?.filter((val) => val.value == value)[0]
              changeHandlerForItem("id", idNCategory.id);
              changeHandlerForItem("category", idNCategory.category);
              // console.log("tasting th",idNCategory)
              setUnitValue(null)
              fields[1].current.value = null
              fields[2].current.value = null

              fields[4].current.value = null
              fields[5].current.value = null
              fields[6].current.value = null
              fields[7].current.value = null
              fields[8].current.value = null
              //  setCustomerChoosed(false)
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              const matchingOption = itemAlready.map((val) => val.value).find((option) => option.value === value);

              if (!matchingOption) {
                // If there's no matching option, set the otherValue to null
                setItemValue(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                // size="small"
                // inputRef={fields[8]}
                style={{ width: !isMobile ? "200px" : "100%" }}
                required
                {...params}
                label="Choose Item"
                variant="outlined"
                // inputRef={params.InputProps.ref}
                inputRef={fields[0]}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    //   // e.preventDefault();

                    // e.preventDefault();
                    // handleKeyPress(e)
                    if (e.target.value) {
                      setUnitValue(null)

                      // setCategoryValue(null)

                      setItemValue(e.target.value)

                      // console.log(params.inputProps.ref.current.value)
                      changeHandlerForItem("item", e.target.value);
                      let idNCategory = itemAlready?.filter((val) => val.value == e.target.value)[0]
                      changeHandlerForItem("id", idNCategory?.id);
                      changeHandlerForItem("category", idNCategory?.category);


                      // console.log("itttt",itemAlready.filter((val)=> val.value == e.target.value)[0].id);

                      fields[1].current.value = null
                      fields[2].current.value = null

                      fields[4].current.value = null
                      fields[5].current.value = null
                      fields[6].current.value = null
                      fields[7].current.value = null
                      fields[8].current.value = null

                      fields[1].current.focus();
                    }
                    // setCurrent\F\ield(currentField + 1);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={supplierChoosed}
                        onClick={() => setShowItemFormModal(" ")}
                        style={{
                          borderRadius: "4px",
                          backgroundColor: supplierChoosed
                            ? grey
                            : PRIMARY_COLOR,
                          color: "white",
                          margin: "-8px", // Adjust margin to align the background color
                          padding: "15px", // Adjust padding to align the background color
                          fontSize: "50px",
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <TextField
            style={{ width: !isMobile ? "100px" : "90%" }}
            disabled={supplierChoosed}
            // size="small"
            label="Volume"
            defaultValue={null}
            required
            inputRef={fields[1]}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (e.target.value) {
                  e.preventDefault();
                  fields[2].current.focus();
                }
                // setCurrentField(currentField + 1);
              }
            }}
            type="number"
            onChange={(e, value) => changeHandlerForItem("volume", (e.target.value)/10000)}
          />
          <TextField
            style={{ width: !isMobile ? "100px" : "90%" }}
            disabled={supplierChoosed}
            //  size="small"
            label="Qty"
            defaultValue={null}
            type="number"
            required
            inputRef={fields[2]}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (e.target.value) {
                  e.preventDefault();
                  fields[4].current.focus();
                  // setCurrentField(currentField + 1);
                }
              }
            }}
            onChange={(e, value) => changeHandlerForItem("qty", e.target.value)}
          />
          {/* <Autocomplete
            // size="small"
            options={options}
            style={{ width: !isMobile ? "200px" : "90%" }}
            // defaultValue={null}
            value={categoryValue || ""}
            disabled={supplierChoosed}
            noOptionsText= {apiLoading? 'Loading...': 'No option'}
            onChange={(e,value) => {
              changeHandlerForItem("category", value?.value);
              setCategoryValue(value?.value)
            }}
            // style={{ width: !isMobile ? "400px" : "90%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                variant="outlined"
                required
                // defaultValue={null}
                style={{ width: !isMobile ? "200px" : "100%" }}
                inputRef={fields[3]}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if(e.target.value)
                      {
                    // e.preventDefault();
                    setCategoryValue(e.target.value)
                    e.preventDefault();
                    console.log(e.target.value);
                    changeHandlerForItem("category", e.target.value);
                    fields[4].current.focus();
                      }
                    // setCurrentField(currentField + 1);
                  }
                }}
              />
            )}
          /> */}
          <TextField
            // size="small"
            disabled={supplierChoosed}
            label="Ctn"
            style={{ width: !isMobile ? "100px" : "90%" }}
            required
            defaultValue={null}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (e.target.value) {
                  e.preventDefault();
                  fields[5].current.focus();
                }
                // setCurrentField(currentField + 1);
              }
            }}
            type="number"
            inputRef={fields[4]}
            onChange={(e, value) => changeHandlerForItem("ctn", e.target.value)}
          />
          <TextField
            // size="small"
            label="Weight"
            style={{ width: !isMobile ? "100px" : "90%" }}
            defaultValue={null}
            required
            disabled={supplierChoosed}
            type="number"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (e.target.value) {
                  e.preventDefault();
                  fields[6].current.focus();
                }
                // setCurrentField(currentField + 1);
              }
            }}
            inputRef={fields[5]}
            onChange={(e, value) => changeHandlerForItem("weight", e.target.value)}
          />
          <TextField
            // size="small"
            label="CBM"
            style={{ width: !isMobile ? "100px" : "90%" }}
            defaultValue={null}
            required
            disabled={supplierChoosed}
            type="number"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (e.target.value) {
                  e.preventDefault();
                  fields[7].current.focus();
                }
                // setCurrentField(currentField + 1);
              }
            }}
            inputRef={fields[6]}
            onChange={(e, value) => changeHandlerForItem("cbm", e.target.value)}
          />

          <TextField
            disabled={supplierChoosed}
            defaultValue={null}
            label="Rate"
            style={{ width: !isMobile ? "100px" : "90%" }}
            required
            type="number"
            inputRef={fields[7]}
            onChange={(e) => changeHandlerForItem("rate", e.target.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (event.target.value) {
                  event.preventDefault(); // Prevent form submission

                  // else

                  fields[15].current.focus();
                }
              }
            }}
          />
          <TextField
           // size="small"
           disabled={supplierChoosed}

          //  value={shippingMarkValue || ''}

           label="Shipping Mark"
           style={{ width: !isMobile ? "auto" : "90%" }}
           defaultValue={null}
           required
           // disabled={supplierChoosed}
           // type="number"
           
           inputRef={fields[15]}
           onChange={(e) => changeHandlerForItem("shippingMark", e.target.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (event.target.value) {
                  event.preventDefault(); // Prevent form submission

                  // else

                  fields[8].current.focus();
                }
              }
            }}
          />
          <Autocomplete
            disabled={supplierChoosed}
            value={unitValue}
            options={units}
            noOptionsText={apiLoading ? 'Loading...' : 'No option'}
            onChange={(e, value) => {
              changeHandlerForItem("unitOfAmount", value?.value);
              setUnitValue(value?.value);
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              const matchingOption = units.find((option) => option.value === value);

              if (!matchingOption) {
                // If there's no matching option, set the otherValue to null
                setUnitValue(null);
              }
            }}
            disableCloseOnSelect   // Add this line to disable typing
            style={{ width: !isMobile ? "200px" : "90%" }}
            renderInput={(params) => (
              <TextField
                style={{ width: !isMobile ? "200px" : "100%" }}
                {...params}
                label="Units of Amount"
                variant="outlined"
                required
                inputRef={fields[8]}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value) {
                      setUnitValue(e.target.value);
                      changeHandlerForItem("unitOfAmount", params.inputProps.value);
                      fields[14].current.click();
                    }
                  }
                }}
              />
            )}
            required
          />

          <Button
            ref={fields[14]}
            variant="contained"
            style={{ width: isMobile && "90%" }}
            disabled={supplierChoosed}
            onClick={() => {

              let isErrorCheck = checkForItemError()

              if (!isErrorCheck) {


                let checkSameItem = accessChanges.filter((val) => {
                  return (val.id == itemVal.id)
                })
                // console.log("itemDiff",itemVal);

                if (checkSameItem.length) {
                  let dataChange = false
                  let sameValueHandle = accessChanges.map((val) => {
                    if (val.id == checkSameItem[0].id && val.unitOfAmount == itemVal.unitOfAmount && val.rate == itemVal.rate) {

                      return {
                        ...val, qty: +val.qty + +itemVal.qty, cbm: +val.cbm + +itemVal.cbm, ctn: +val.ctn + +itemVal.ctn
                        , rate: +itemVal.rate, weight: +val.weight + +itemVal.weight, volume: +val.volume + +itemVal.volume
                        , qtyCtn: (+val.qty + +itemVal.qty) / (+val.ctn + +itemVal.ctn), amount: checkingForAmount(val, val.unitOfAmount, itemVal), category: itemVal.category
                      }
                    }
                    else {
                      dataChange = true
                      return val
                    }
                  })
                  let qtyctnData = checkingForQtyCtn(itemVal);
                  let amountdata = checkingForAmount(itemVal, itemVal.unitOfAmount);
                  setAccessChanges(
                    sameValueHandle
                  );
                  fields[0].current.value = null
                  setItemValue(null)
                  fields[0].current.focus()
                }
                else {
                  let qtyctnData = checkingForQtyCtn(itemVal);
                  let amountData = checkingForAmount(itemVal, itemVal.unitOfAmount);

                  let srNo = accessChanges.length;


                  setAccessChanges([
                    ...accessChanges,
                    {
                      ...itemVal,
                      qtyCtn: qtyctnData, amount: amountData
                    }
                  ]);
                }

              }
              fields[0].current.value = null
              setItemValue(null)
              fields[0].current.focus()
            }
            }

          >
            Add Item
          </Button>
        </TextFieldContainer>
        {renderContent()}

        <BodyRight>
          <div style={{ display: !isMobile && "flex" }}>
            <Typography
              style={{
                padding: "10px",
                fontSize: "17px",
                whiteSpace: "nowrap",
              }}
            >
              Total CTN:{" "}
            </Typography>
            <TextField
              disabled
              size="small"
              // label="Amount"
              value={totalCtn}
              style={{ paddingRight: "20px" }}
            />
            <Typography
              style={{
                padding: "10px",
                fontSize: "17px",
                whiteSpace: "nowrap",
              }}
            >
              Total Qty:{" "}
            </Typography>
            <TextField
              disabled
              size="small"
              // label="Amount"
              value={totalQty}
              style={{ paddingRight: "20px" }}
            />
            <Typography
              style={{
                padding: "10px",
                fontSize: "17px",
                whiteSpace: "nowrap",
              }}
            >
              Total Weight(Kgs):{" "}
            </Typography>
            <TextField
              disabled
              size="small"
              // label="Amount"
              value={totalWeight}
              style={{ paddingRight: "20px" }}
            />
            <Typography
              style={{
                padding: "10px",
                fontSize: "17px",
                whiteSpace: "nowrap",
              }}
            >
              Total CBM:{" "}
            </Typography>
            <TextField
              disabled
              size="small"
              // label="Amount"
              value={totalCbm}
              style={{ paddingRight: "20px" }}
            />
            <Typography
              style={{
                padding: "10px",
                fontSize: "17px",
                whiteSpace: "nowrap",
              }}
            >
              Total amount:{" "}
            </Typography>
            <TextField
              disabled
              size="small"
              // label="Amount"
              value={totalAmount}
              style={{ paddingRight: "20px" }}
            />
            {/* <Typography
              style={{
                padding: "10px",
                fontSize: "17px",
                whiteSpace: "nowrap",
              }}
            >
              Total amount:{" "}
            </Typography>
            <TextField
              // size="small"
              // label="Amount"
              value={
                  totalAmount
              }
              style={{ paddingRight: "20px" }}
            /> */}
          </div>
          {/* <Typography>23</Typography> */}
          <FabStyle
            variant="extended"
            color="primary"
            onClick={() => {
              onclicksubmit();
              // console.log(accessChanges);

              // window.NotificationUtils.showSuccess("Changes successfully made");
            }}
          >
            {/* <SaveAlt sx={{ mr: 1 }} /> */}
            Submit
          </FabStyle>
        </BodyRight>
        {/* </TableContainer> */}

        {/* </TableContainer> */}
        {/* </TableContainer> */}
        {showCustomerFormModal && (
          <AddModal
            onCancel={() => setShowCustomerFormModal(null)}
            // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
            loading={loading}
            onSubmit={updateCustomerData}
            setLoading={setLoading}
          // loading={subLoading}
          />
        )}
        {showSupplierFormModal && (
          <AddSupplierModal
            onCancel={() => setShowSupplierFormModal(null)}
            initialData={form}
            loading={loading}
            onSubmit={updateSupplierData}
            // setLoading={setLoading}
          />
        )}
        {showItemFormModal && (
          <ItemModal
            onCancel={() => setShowItemFormModal(null)}
            initialData={form}
            loading={loading}
            onSubmit={updateItemData}
          />
        )}
        {showQuotationFormModal && (
          <QuotationRemarkModal
            onCancel={() => setShowQuotationFormModal(null)}
            initialData={form}
            loading={loading}
            onSubmit={updateQuotationData}
            idResponse={idResponseData}
          />
        )}
      </StaffEditorPageContainer>
    </>
  );
}
