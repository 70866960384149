import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  Tooltip,
    Fab,
} from "@mui/material";

import styled from "styled-components";

import OpaqueLoading from "../opaqueLoading/opaqueLoading";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import {
  BULK_UPLOAD_FAILURE_CODES,
  ERROR_CODES,
  LEADS_TABS,
  LEAD_STATUS,
  LEAD_TYPES,
  ROLES,
  TABS,
} from "../../constants";

// import * as XLSX from "xlsx";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import isEmail from "validator/lib/isEmail";
import { Upload } from "@mui/icons-material";

 

 

 

const ModalHeaderContainer = styled.div`
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-bottom: 15px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;

  display: flex;

  justify-content: center;

  align-items: center;

  gap: 10px;

  label {
    margin-bottom: 0px;
  }
`;

const ModalActionsContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: flex-end;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;

  overflow-x: auto;

  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const QuotationPackagingModal = (props) => {
  const { formId, uploadFormat, callback } = props;

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [storeRows, setStoreRows] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const [downloadLink, setDownloadLink] = useState("");

  const hiddenFileInput = useRef(null);

  // const createXLSXDownload = (columnNames) => {
  //   const worksheet = XLSX.utils.json_to_sheet([{}], { header: columnNames });

  //   const workbook = XLSX.utils.book_new();

  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  //   const file = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  //   return file;
  // };

  const createDownloadLink = async () => {
    // const columns = uploadFormat.map((data) => data.databaseValue)
    // const file = createXLSXDownload(columns)
    // const fileBlob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // const fileLink = URL.createObjectURL(fileBlob)
    // setDownloadLink(fileLink)
  };

  useEffect(() => {
    createDownloadLink();
  }, []);

  // const getFileRows = async (file) => {
  //   try {
  //     let data = await file.arrayBuffer();

  //     const wb = XLSX.read(data, { type: "binary" });

  //     const wsname = wb.SheetNames[0];

  //     const ws = wb.Sheets[wsname];

  //     let dataParse = XLSX.utils.sheet_to_json(ws);

  //     if (dataParse.length > 0) {
  //       // dataParse = dataParse.map((entry, index) => {
  //       //   let lead = { id: index }
  //       //   uploadFormat.forEach(field => {
  //       //     lead[field.databaseValue] = entry[field.databaseValue]
  //       //   });
  //       //   return lead
  //       // });
  //     }

  //     let element = document.getElementById("filePickerButton");

  //     if (element) {
  //       element.value = "";
  //     }

  //     return dataParse;
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  const handleUpload = async () => {
    try {
      setLoading(true);
      let uploadInput = document.getElementById("filePickerButton");
      if (uploadInput) {
        if (uploadInput.files.length > 0) {
          let file = uploadInput.files[0];
          const allowedExtensions = /\.(docx?|pdf|jpe?g|png|gif|xlsx|csv?)$/i;
          const fileExtension = file.name;
          if (!allowedExtensions.test(fileExtension)) {
            window.NotificationUtils.showError("Please select a valid file (doc, docx, pdf, jpg, jpeg, png, gif, xlsx,csv) for upload");
            setLoading(false);
            return;
          }
          let response = await window.Platform.database.profilePictureUpload(file);

          props.setPackagingList(response._url);
          props.onCancel();
          setLoading(false);
          window.NotificationUtils.showSuccess('Packaging List uploaded successfully')
        }
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retrieving user data");
      setLoading(false);
    }
  };
  
  
  
  
  // const handleUpload = async () => {
  //   try {
  //     setLoading(true)
  //     let uploadInput = document.getElementById("filePickerButton");
  //     if (uploadInput) {
  //       if (uploadInput.files.length > 0) {
  //         // handleUploadStart();
  //         let file = uploadInput.files[0];
  //         let response = await window.Platform.database.profilePictureUpload(
  //           file
  //         );
  //         console.log("responseeee", response._url);
  //         props.setPackagingList(response._url);

  //         props.onCancel()
  //         setLoading(false);
  //         // if(response._url){
  //         //   handleUploadSuccess(response._url);
  //         // }
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);

  //     window.NotificationUtils.showError("Error retreiving user data");

  //     setLoading(false);
  //   }
  // };

  const hasErrors = () => {
    try {
      if (!storeRows) {
        window.NotificationUtils.showError(
          "Please select a valid file for upload"
        );

        return true;
      }

      return false;
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <InputFieldContainer>
          <a target="_blank" href={downloadLink} download="leads template">
            {/* {console.log('downloadLink', downloadLink)} */}

            {/* <Button variant="contained" color="primary" disabled={!downloadLink}>

 

              {!!downloadLink ? 'Download Template'}

 

            </Button> */}
          </a>

          <label htmlFor="filePickerButton">
            <Input
              //   accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

              id="filePickerButton"
              type="file"
              ref={hiddenFileInput}
              onChange={handleUpload}
              maxSize={5242880}
            />

 

<FabStyle variant="extended" color="primary"

disabled={loading}

component="span"

 //  onClick={}

 >

   <Upload />

   Select File



 </FabStyle>
 

          </label>
        </InputFieldContainer>

        <ModalActionsContainer>

        <FabStyle variant="extended" color="primary"
           disabled={loading}

 

           onClick={()=>props.onCancel()}




           component="span"
            //  onClick={}
            >
              {/* <Upload sx={{ mr: 1 }} /> */}
              Close

            </FabStyle> 
 
 

          

 

         

 

        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="md"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Packaging List Upload</Typography>

          <IconButton disabled={loading} onClick={() => props.onCancel()}>
            <CloseOutlinedIcon />
          </IconButton>
        </ModalHeaderContainer>

        {loading && <OpaqueLoading />}

        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default QuotationPackagingModal;
