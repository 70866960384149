import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { COMPONENTS, ERROR_COLOR, ROLES } from "../../constants";
import { Save, SaveAlt } from "@mui/icons-material";
import { toTitleCase } from "../../helpers";

const isMobile = window.innerWidth < 900;

// const ROLE_DATA = [
//   {
//     id: 1,
//     role: "Admin",
//     component1: { write: true, read: true },
//     usermanagement: { write: true, read: true },
//     tracking: { write: true, read: true },
//     changeable: false,
//   },
//   {
//     id: 2,
//     role: "India Manager",
//     component1: { write: false, read: true },
//     usermanagement: { write: true, read: true },
//     tracking: { write: true, read: true },
//     changeable: true,
//   },
//   {
//     id: 3,
//     role: "China Manager",
//     component1: { write: true, read: true },
//     usermanagement: { write: false, read: true },
//     tracking: { write: false, read: true },
//     changeable: true,
//   },
//   {
//     id: 4,
//     role: "Sales Person",
//     component1: { write: false, read: false },
//     usermanagement: { write: false, read: true },
//     tracking: { write: true, read: true },
//     changeable: true,
//   },
// ];

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100%);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function AccessManagement(props) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [accessChangePermissions, setAccessChangePermissions] = useState([]);

  const updateRolesAccess = async() => {
    try {
      setLoading(true)
      const response = await window.Platform.database.updateRolesAccess(accessChanges)
      setLoading(false)
      if(response) window.NotificationUtils.showSuccess('Changes Updated.')
      window.Platform.permissions = accessChanges
    } catch (error) {
      setLoading(false)
      window.NotificationUtils.showError('Something went wrong.')
    }
  }

  const fetchAccessChangePermissions = async() => {
    try {
      setLoading(true)
      const response = await window.Platform.database.fetchAccessChangePermissions()
      setLoading(false)
      setAccessChangePermissions(response)
    } catch (error) {
      setLoading(false)
      window.NotificationUtils.showError('Something went wrong.')
    }
  }

  const getRolesAccess = async() => {
    try {
      setLoading(true)
      const response = await window.Platform.database.getRolesAccess()
      setLoading(false)
      const mappedResponse = Object.keys(ROLES).map(roleKey => {
        let role = response.filter(res => res.role === ROLES[roleKey])[0]
        role = role ? role : {}
        return {
          ...Object.keys(COMPONENTS).reduce((object, componentKey) => {
            const permissions = role[COMPONENTS[componentKey]] ? role[COMPONENTS[componentKey]] : {read: false, write: false}
            return {
                ...object,
                [COMPONENTS[componentKey]]:permissions
            }
        },{}),
        role: ROLES[roleKey]
      }
      })
      // console.log("mapppppp",mappedResponse)
      setAccessChanges(mappedResponse)
    } catch (error) {
      setLoading(false)
      window.NotificationUtils.showError('Something went wrong.')
    }
  }

  useEffect(() => {
    getRolesAccess()
    fetchAccessChangePermissions()
  }, []);


  const getColumns = () => {
    let result = [
      {
        field: "role",
        headerName: "Roles",
        sortable: false,
        width: 300,
        editable: true,

        renderCell: params => {
          const value = params.row.role
          return <Tooltip title={value}>{value}</Tooltip>
        }
      }
    ]

    result.push(...Object.keys(COMPONENTS).map(componentKey => {
      const component = COMPONENTS[componentKey]
      // console.log('hello',component)
      let headerName = toTitleCase(component);
      if (componentKey === "QUOTATION_GENERATE") {
        headerName = "Quotation Generation";
      } else if (componentKey === "QUOTATION_GENERATION") {
        headerName = " Quotation Approval(s)";
      }
      return { 
        field: component,
        headerName: headerName,
        width: 200,
        filterable: false,
        headerAlign: 'center',
        sortable: false,
        renderCell: (params) => {
          // console.log('..............', accessChangePermissions[params.row.role][component])
          // console.log('......',accessChangePermissions['Super Admin']?.[component]?.read, component)
          // console.log('..............', params.row.role)
          return (
            <>
              <div style={{ margin: "0 auto", width: "auto" }}>
                {/* <div> */}
                <Checkbox
                  {...label}
                  defaultChecked={params.row[component].read}
                  disabled={!accessChangePermissions[params.row.role]?.[component]?.read}
                  checked={params.row[component].read}
                  onClick={(event) => {
                    let rows = [...accessChanges];
                    const rowIndex = rows.findIndex(
                      (row) => row.role === params.row.role
                    );

                    if (rowIndex !== -1) {
                      rows[rowIndex][component].read = event.target.checked;
                    }
                    // console.log(rows)
                    setAccessChanges(rows);
                  }}
                />
                <Typography >Access</Typography>
                {/* </div> */}
              </div>

            <div style={{ margin: "0 auto", width: "auto" }}>
                {/* <div> */}
                {/* <Checkbox
                  {...label}
                  defaultChecked={params.row[component]?.write}
                  disabled={!accessChangePermissions[params.row.role][component]?.read}
                  checked={params.row[component]?.write}
                  onClick={(event) => {
                    // params.row[component].read = event.target.checked
                    let rows = [...accessChanges.map((obj) => ({ ...obj }))];
                    console.log("rows", rows)
                    const rowIndex = rows.findIndex(
                      (row) => row.role === params.row.role
                    );

                    rows[rowIndex][component].write = event.target.checked;
                    if (rowIndex !== -1 && rows[rowIndex][component].read !== true) {
                      rows[rowIndex][component].read = event.target.checked;
                    }
                    setAccessChanges(rows);
                    //   console.log(rows);
                  }}
                />
                <Typography>Write</Typography> */}
                {/* </div> */}
              </div>

              {/* <Checkbox {...label} />
          <Typography>Write</Typography> */}
            </>
          );
        },
      }

    })
    )
    

    return result
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "Access-Management-List",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderLoading = () => {
    return (
      <div className="loadingCentered">
        <CircularProgress color="primary" />
      </div>
    );
  };

  const renderContent = () => {
    return (
      loading ? renderLoading() :
       <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={accessChanges}
          columns={getColumns()}
          rowHeight={70}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
          getRowId={row => row.role}
        />
      </Box>
    );
  };

  return (
    <>
      {isMobile && (
        <Drawer
        setShowNotifications={props.setShowNotifications}
        onAuthUserChanged={props.onAuthUserChanged}
        user={props.user}
        props = {props}
        />
      )}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Access Management</Typography>
          <div>
            {/* <FabStyle> */}

            <FabStyle variant="extended" color="primary"
              onClick={updateRolesAccess} >
              <SaveAlt />
              Save Changes
            </FabStyle>
            {/* </FabStyle> */}
            {/* )} */}
          </div>
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
    </>
  );
}
